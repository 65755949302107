import { FC, Fragment, memo } from "react";
import parse from "html-react-parser";

import { Message } from "../../components";
import TextMessageButton from "./TextMessageButton";

import styles from "./TextMessage.module.scss";
import type { ResponseMessageProp } from "../../models";

const TextMessage: FC<ResponseMessageProp> = ({
  responseMessage,
  datetime,
  taskType,
  isCsRead,
}) => {
  return (
    <>
      {responseMessage.chatContents.map((chatContent, index) => {
        if (
          index === responseMessage.chatContents.length - 1 &&
          chatContent.replies.length > 0
        ) {
          return (
            <Fragment key={index}>
              <Message
                className={
                  responseMessage.chatRecordType === "A"
                    ? ""
                    : styles["user-message"]
                }
                paragraphClassName="w-spec wii-w-full"
                // BUG: chatContent may be null
                message={parse(chatContent.text ?? "")}
                datetime={responseMessage.createdTime}
                isCsRead={isCsRead}
              />
              <TextMessageButton
                data={chatContent.replies}
                taskType={taskType}
              />
            </Fragment>
          );
        }
        return (
          <Message
            key={index}
            className={
              responseMessage.chatRecordType === "A"
                ? ""
                : styles["user-message"]
            }
            // BUG: chatContent may be null
            message={parse(chatContent.text ?? "")}
            datetime={responseMessage.createdTime}
            isCsRead={isCsRead}
          />
        );
      })}
    </>
  );
};

export default memo(TextMessage);
