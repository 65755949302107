import { PropsWithChildren, useEffect } from "react";
import { ActiveStatus, LoginStatus } from "../../models";
import { ChatbotService, MemberService } from "../../services";
import { useAppDispatch, useTypedSelector } from "../../store/store";

import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MemberActions, defaultUserInfo } from "../../store/member-slice";
import styles from "./../../App.module.scss";
import {
  useGetLanguageListQuery,
  useGetSignalRNoQuery,
} from "../../services/api/Account/accountApi";
import i18n from "../../i18n";
import axios from "axios";
import { LANG_HEADER } from "../../utils/constants";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";
import { getBrowserFingerprint, getBrowserInfo } from "@/utils/browser";

const SiteComponent = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const showBotIcon = useTypedSelector((state) => state.toggles.showBotIcon);

  const { data: signalRNo } = useGetSignalRNoQuery();

  const [localStorageValue, setLocalStorageValue] = useLocalStorage();

  const { data: botSetting } = useGetBotSettingsHubQuery();

  const { isEmbed } = useTypedSelector((state) => state.settings.embedSetting);
  const { info: memberInfo } = useTypedSelector((state) => state.member);
  const { data } = useGetLanguageListQuery();

  useEffect(() => {
    async function getEmbedInfo() {
      try {
        // TODO:  get valid askerId and sessionNo
        let askId = memberInfo.askerId;
        let sessionNo = memberInfo.sessionNo;

        // TODO: get valid askerId and session =>
        if (!askId) {
          const resp = await ChatbotService.getVisitorInfo();
          askId = resp.askerId;
          sessionNo = resp.sessionNo;
        }

        const fingerprint = getBrowserFingerprint();
        const browser = getBrowserInfo();

        const {
          isAuth,
          token,
          status,
          userInfo,
          isAutoRealTime,
          defaultLanguage,
        } = await MemberService.loginEmbeddedInfo({
          askerId: askId,
          sessionNo: sessionNo,
          memberToken: localStorageValue.token,
          memberId: +localStorageValue.memberKey,
          fingerPrint: fingerprint,
          signalRHubNo: signalRNo?.data.signalRNo
            ? signalRNo?.data.signalRNo
            : undefined,
          ...browser,
        });

        if (isAuth) {
          if (defaultLanguage && data) {
            const isInLangList = data.find((i) => i.key === defaultLanguage);
            if (isInLangList) {
              i18n.changeLanguage(isInLangList.code);
              axios.defaults.headers.common[LANG_HEADER] = isInLangList.key;
            }

            if (!isInLangList) {
              const isDefaultList = data.find((i) => i.isDefault);
              if (isDefaultList) {
                i18n.changeLanguage(isDefaultList.code);
                axios.defaults.headers.common[LANG_HEADER] = isDefaultList.key;
              }
            }
          }

          dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));
          dispatch(
            MemberActions.setUserInfo({
              askerId: memberInfo.askerId,
              memberKey: userInfo.memberKey,
              sessionNo: userInfo.sessionNo,
              token: token,
              isAutoRealTime: isAutoRealTime,
              loginAccount: userInfo.loginAccount,
              accountId: userInfo.accountId,
              name: userInfo.name,
              email: userInfo.email,
              pictureUrl: userInfo.pictureUrl,
              provider: userInfo.provider,
              loginKind: userInfo.loginKind,
              isVIP: userInfo.isVIP,
            })
          );
        } else {
          switch (status) {
            case LoginStatus.Err: {
              throw Error("account-password-error");
            }
            case LoginStatus.Cs: {
              throw Error("cs-connecting-error");
            }
            case LoginStatus.Ok: {
              throw Error("account-password-error");
            }
            default: {
              throw Error("account-password-error");
            }
          }
        }
      } catch (err) {
        dispatch(
          MemberActions.setUserInfo({
            ...defaultUserInfo,
            askerId: memberInfo.askerId,
            sessionNo: memberInfo.sessionNo,
          })
        );
        setLocalStorageValue({
          memberKey: 0,
          token: "",
        });
      }
    }

    if (botSetting?.kind === "ChatRoom") return;

    if (
      isEmbed &&
      !showBotIcon &&
      localStorageValue.memberKey &&
      localStorageValue.token &&
      !memberInfo.token
    ) {
      dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));

      getEmbedInfo();
    }
  }, [
    dispatch,
    isEmbed,
    showBotIcon,
    localStorageValue.memberKey,
    localStorageValue.token,
    setLocalStorageValue,
    memberInfo.token,
    memberInfo.askerId,
    memberInfo.sessionNo,
    data,
    botSetting?.kind,
    signalRNo?.data.signalRNo,
  ]);

  return (
    <>
      <>
        <input type="checkbox" id="chatbot" className="wii-modal-toggle" />
        {showBotIcon ? null : (
          <div
            id="wii-modal-container"
            className={`${styles["chatbot-modal"]} wii-modal-container wii-modal wii-modal-bottom wii-max-h-full wii-shadow-xl md:wii-mt-auto md:wii-max-h-[675px]`}
            key={memberInfo.askerId + memberInfo.token}
          >
            <div
              className={`${styles["chatbot-box"]} wii-modal-box wii-max-h-full`}
            >
              {children}
            </div>
          </div>
        )}
        {/* {(process.env.REACT_APP_API_URL === undefined ||
          process.env.REACT_APP_API_URL === "") && (
          <div
            className="wii-p-4 wii-text-sky-600"
            onClick={() => {
              const toggleBotOpen = document.querySelector<HTMLDivElement>(
                "wii-chatbot > div > label"
              );
              toggleBotOpen?.click();
            }}
          >
            toggle
          </div>
        )} */}
      </>
    </>
  );
};

export default SiteComponent;
