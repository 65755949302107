import React, { useState } from "react";
import { Picture } from "@/components";
import { FloatModal } from "@/components/Modal";
import { useCsLeaveChatRoomMutation } from "@/services/api/ChatRoom/chatRoomApi";
import { useConversation } from "@/chatroom/chatRoom.context";
import { CustomStatus } from "@/models/format";
import { useAppDispatch, useChatRoomUser } from "@/store/store";
import { ChatroomActions } from "@/store/chatRoom-slice";
import { useTranslation } from "react-i18next";

type InfoContentProps = {
  currentUser: {
    name: string | undefined;
    headerUrl: string | null | undefined;
  };
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  auth: {
    isNoTalking: boolean;
    isUploadImage: boolean;
    isHeadImage: boolean;
  };

  viewMode?: "edit" | "view";
};

const InfoDetail = (props: InfoContentProps) => {
  const { currentUser, setEditMode, viewMode = "view" } = props;
  const [leave, setLeave] = useState(false);
  const { command } = useConversation();
  const [leaveMutate] = useCsLeaveChatRoomMutation();
  const dispatch = useAppDispatch();
  const user = useChatRoomUser();
  const [t] = useTranslation();

  return (
    <>
      <div className="wii-mx-4 wii-mb-4 wii-mt-2 wii-flex wii-gap-2">
        <div className="wii-flex wii-h-20 wii-w-20 wii-flex-shrink-0 wii-items-center wii-justify-center wii-rounded-full wii-bg-foreground">
          {currentUser && (
            <Picture
              url={currentUser?.headerUrl ?? ""}
              domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
              className="wii-h-16 wii-w-16 wii-rounded-full wii-object-scale-down"
              alt={user.userRole ? user.userRole : ""}
            />
          )}
        </div>

        <div className="wii-flex-shirnk-0 wii-flex wii-h-[120px] wii-w-auto wii-flex-grow wii-flex-col wii-gap-1 wii-text-sm wii-font-bold">
          <p>{currentUser?.name}</p>
          {viewMode === "edit" && (
            <div className="wii-mt-auto wii-flex wii-w-full wii-justify-between wii-gap-2">
              {user.userRole === "Cs" && (
                <button
                  className="wii-text-red-600 hover:wii-text-red-600/80 hover:wii-underline"
                  onClick={() => {
                    setLeave(true);
                  }}
                >
                  {t("leave-chat-room")}?
                </button>
              )}

              <button
                className="wii-ml-auto wii-flex-shrink-0 wii-cursor-pointer wii-self-end wii-text-sm wii-text-foreground hover:wii-opacity-90"
                onClick={() => {
                  if (setEditMode) setEditMode(true);
                }}
              >
                {t("edit")}
              </button>
            </div>
          )}
        </div>
      </div>

      <FloatModal
        show={leave}
        confirmText={t("confirm")}
        cancelText={t("back")}
        confirmBtnClass="wii-text-sm wii-btn-sm wii-bg-foreground hover:wii-bg-foreground hover:wii-opacity-80 wii-text-white"
        cancelBtnClass="wii-text-sm wii-btn-sm wii-bg-gray-300 hover:wii-bg-gray-300/80 wii-text-white"
        confirmEvent={() => {
          // TODO: api call
          leaveMutate({
            command,
          })
            .unwrap()
            .then((data) => {
              if (data.status === CustomStatus.Ok) {
                dispatch(ChatroomActions.kickOutChatRoom());
              }
            });
          setLeave(false);
        }}
        cancelEvent={() => {
          setLeave(false);
        }}
      >
        <>
          <h4 className="wii-mb-1 wii-text-left">
            <div className="wii-font-bold">{t("leave-chat-room")}</div>
          </h4>
        </>
      </FloatModal>
    </>
  );
};

export default InfoDetail;
