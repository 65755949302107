import type {
  EmbeddedMemberInfoRequest,
  EmbeddedMemberInfoResponse,
  EmbeddedMemberLoginRequest,
  EmbeddedMemberLoginResponse,
} from "../models";
import type { MemberLoginRequest, MemberLoginResponse } from "../models/member";
import http from "./api.service";
import { handleEncryptParams, transformReqAsync } from "./utils/transformRes";

export class MemberService {
  static login = transformReqAsync<MemberLoginResponse, MemberLoginRequest>(
    async (loginRequest: MemberLoginRequest) =>
      await http.post(
        "/api/Account/memberLogin",
        handleEncryptParams(loginRequest, "POST")
      )
  );
  static loginEmbedded = async (loginRequest: EmbeddedMemberLoginRequest) =>
    await http.post<EmbeddedMemberLoginResponse, EmbeddedMemberLoginRequest>(
      "/api/Account/memberEmbeddedLogin",
      loginRequest
    );
  static loginEmbeddedInfo = async (InfoRequest: EmbeddedMemberInfoRequest) =>
    await http.post<EmbeddedMemberInfoResponse, EmbeddedMemberInfoRequest>(
      "/api/Account/embeddedMemberInfo",
      InfoRequest
    );
}
