import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../basehttp";
import { LanguageListRes } from "../../../models";
import { CustomStatus, FormatResponse } from "@/models/format";

export const accountApi = createApi({
  reducerPath: "account",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "",
  }),

  tagTypes: ["LANG"],
  endpoints: (builder) => ({
    getLanguageList: builder.query<LanguageListRes, void>({
      query: () => ({
        url: "/api/Account/getLanguageList",
        method: "get",
      }),
    }),
    updateMemberLanguage: builder.mutation<
      unknown,
      {
        memberKey: number;
        languageKey: string;
      }
    >({
      query: (params) => ({
        url: "/api/Account/updateMemberLanguage",
        method: "POST",
        data: params,
      }),
    }),
    GetSignalRNo: builder.query<
      FormatResponse<{
        signalRNo: string;
      }>,
      void
    >({
      query: () => ({
        url: "/api/Account/GetSignalRNo",
        method: "get",
      }),
    }),
  }),
});

export const { useGetLanguageListQuery, useGetSignalRNoQuery } = accountApi;
export const { useUpdateMemberLanguageMutation } = accountApi;
