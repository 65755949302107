import { FC, memo } from "react";
import Image from "../Image";

import styles from "./Picture.module.scss";

const Picture: FC<{
  className?: string;
  url: string;
  pictureMemo?: string;
  domain?: string;
  alt?: string;
}> = ({ className, url, pictureMemo = "", domain, alt = "" }) => {
  return (
    <>
      <Image className={className} url={url} domain={domain} alt={alt} />
      {pictureMemo ? <div className={styles.memo}>{pictureMemo}</div> : <></>}
    </>
  );
};

export default memo(Picture);
