import { useTypedSelector } from "../store/store";

import { ActiveStatus, MemberInfo, UserInfo } from "../models";

const useMemberStore = (): {
  memberInfo: MemberInfo & UserInfo;
  activeStatus: ActiveStatus;
} => {
  const { info: memberInfo, activeStatus } = useTypedSelector(
    (state) => state.member
  );

  return { memberInfo, activeStatus };
};

export default useMemberStore;
