export enum SignalREvents {
  OnBroadcastMessage = "OnBroadcastMessage",
  OnNotifyMessage = "OnNotifyMessage",
  OnConnectPair = "OnConnectPair",
  OnNotifyTimes = "OnNotifyTimes",
}

export enum SignalRBotEvents {
  OnNotifyTimes = "OnNotifyTimes",
}

export type SignalREventsArgs = {
  [SignalREvents.OnBroadcastMessage]: OnBroadcastMessageArgs;
  [SignalREvents.OnNotifyMessage]: OnNotifyMessageArgs;
  [SignalREvents.OnConnectPair]: OnConnectPairArgs;
};

type OnBroadcastMessageArgs = {
  action: string;
  fromId: string;
  toId: string;
  message: string;
  timestamp: string;
};

type OnNotifyMessageArgs = {
  action: string;
  fromId: string;
  toId: string;
  message: string;
  timestamp: string;
  languageKey: string;
};
type OnConnectPairArgs = {
  action: string;
  fromId: string;
  toId: string;
  timestamp: string;
};

export enum SignalRDispatch {
  NotifyMessage = "NotifyMessage",
}

export enum SignalRBotDispatch {
  AliveTimer = "AliveTimer",
  LazyTimer = "LazyTimer",
}

export type SignalRDispatchArgs = {
  [SignalRDispatch.NotifyMessage]: NotifyMessageArgs;
};

export type NotifyMessageArgs = {
  action: string;
  fromId: string;
  toId: string;
  message: string;
  timestamp: string;
  languageKey: string;
};

export type SendMemberPictureArgs = {
  fromId: string;
  toId: string;
  base64String: string;
};
