import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type CsSliceType = {
  isConnectedSignalR: boolean;
  csUserId?: string;
  enabledWait: number;
  chatTimetableId?: number;
  currentQuestionId?: number;
  isLink: boolean;
  checkingWaitingState: boolean;
  ratingCsServiceState: boolean;
  clickLoginChangeToCsUser: boolean;
  keepUsingRatingState: boolean;
  csHeaderUrl: string;
};

const initialState: CsSliceType = {
  isConnectedSignalR: false,
  csUserId: undefined,
  enabledWait: 0,
  chatTimetableId: undefined,
  currentQuestionId: undefined,
  isLink: false,
  checkingWaitingState: false,
  ratingCsServiceState: false,
  clickLoginChangeToCsUser: false,
  keepUsingRatingState: false,
  csHeaderUrl: "",
};

const cSSlice = createSlice({
  name: "csService",
  initialState,
  reducers: {
    isConnected: (state) => {
      state.isConnectedSignalR = true;
    },
    isConnectedCsId: (
      state,
      action: PayloadAction<{ csId: string; chatQuestionId: number }>
    ) => {
      state.csUserId = action.payload.csId;
      state.currentQuestionId = action.payload.chatQuestionId;
    },
    isLinkCs: (state) => {
      state.isLink = true;
    },
    setCurrentQuestionId: (
      state,
      action: PayloadAction<{ questionId: number }>
    ) => {
      state.currentQuestionId = action.payload.questionId;
    },
    setConnectRealTimeState: (
      state,
      action: PayloadAction<{
        csId: string;
        enabledWait: number;
        chatTimetableId: number;
        currentQuestionId: number;
        isLink: boolean;
        headerUrl: string;
      }>
    ) => {
      state.chatTimetableId = action.payload.chatTimetableId;
      state.enabledWait = action.payload.enabledWait;
      state.csUserId = action.payload.csId;
      state.currentQuestionId = action.payload.currentQuestionId;
      state.isLink = action.payload.isLink;
      state.csHeaderUrl = action.payload.headerUrl;
    },
    setCsHeaderUrl: (state, action: PayloadAction<string>) => {
      state.csHeaderUrl = action.payload;
    },
    initialCsService: (state) => {
      return { ...initialState, csHeaderUrl: state.csHeaderUrl };
    },
    toggleWaitingQueueModalState: (state, action: PayloadAction<boolean>) => {
      state.checkingWaitingState = action.payload;
    },
    toggleRateCsModalState: (state, action: PayloadAction<boolean>) => {
      state.ratingCsServiceState = action.payload;
    },
    isLoginToCsUser: (state, action: PayloadAction<boolean>) => {
      state.clickLoginChangeToCsUser = action.payload;
    },
    toggleKeepUsingRateModalState: (state, action: PayloadAction<boolean>) => {
      state.keepUsingRatingState = action.payload;
    },
  },
});

export const csActions = cSSlice.actions;

export default cSSlice.reducer;
