import i18n from "../i18n";
import { useGetLanguageListQuery } from "../services/api/Account/accountApi";

const useLangKey = () => {
  const currentLang = i18n.language;
  const { data } = useGetLanguageListQuery();
  const keyLang = data?.find((i) => i.code === currentLang)?.key ?? "TW";

  return { keyLang };
};

export default useLangKey;
