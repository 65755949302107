import { useEffect } from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

import { useDispatch } from "react-redux";
import { useMemberStore, useMessages } from "../../../../hooks";
import transformAnsToQues from "../../../../utils/answer-to-question";
import { SignalREvents } from "../../method";
import { csActions } from "../../../../store/cs-slice";
import { useTranslation } from "react-i18next";

const useOnConnectPair = (
  SignalRHub: HubConnection | null,
  disconnect: () => Promise<void>
) => {
  const dispatch = useDispatch();
  const [send] = useMessages();
  const [t] = useTranslation();

  useEffect(() => {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected)
    ) {
      SignalRHub.on(
        SignalREvents.OnConnectPair,
        async (data: {
          action: string;
          fromId: string;
          toId: string;
          message: string;
          timestamp: string;
        }) => {
          //
          if (data.action === "ConnectOff") {
            send(
              transformAnsToQues({
                message: t("cs-left-conversation"),
                replyId: 0,
                kindId: 0,
                taskType: "OffBot",
                kind: "Divider",
                chatRecordType: "A",
              })
            );
          }
          // TODO: leave csUser mode and continue to chat
          if (SignalRHub) {
            await disconnect();
          }

          dispatch(csActions.toggleKeepUsingRateModalState(true));
        }
      );
    }
    return () => {
      if (SignalRHub) {
        SignalRHub.off(SignalREvents.OnConnectPair);
      }
    };
  }, [SignalRHub, dispatch, send]);
};

export default useOnConnectPair;
