import { MinusOutlined } from "@ant-design/icons";

const ChatRoomLabel = ({ hiddenChatModal }: { hiddenChatModal: any }) => {
  return (
    <label
      id="wii-modal-hidden"
      htmlFor="chatbot"
      className="wii-z-10 wii-mr-2 wii-flex wii-cursor-pointer wii-items-center wii-p-1 hover:wii-scale-110"
      onClick={hiddenChatModal}
    >
      <MinusOutlined />
    </label>
  );
};

export default ChatRoomLabel;
