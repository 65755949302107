import { BotSetting } from "../models";

/**
 *
 * @param {object} botSetting : 機器人的顏色，主色，底色，問題背景色，機器人回答色。
 * @returns {object} botSetting : 機器人的顏色，主色，底色，問題背景色，機器人回答色。
 */
export function themeParser(
  botSetting?: BotSetting["color"]
): BotSetting["color"] {
  if (!botSetting?.primary)
    return {
      primary: "#418b3fA0",
      backend: "#ffffff",
      supply: "#050505",
      demand: "#000000",
    };

  if (!botSetting?.backend) {
    return {
      primary: `${botSetting.primary}A0`,
      backend: `${botSetting.primary}0F`,
      demand: botSetting.primary,
      supply: "#ffffff",
    };
  }

  return botSetting;
}

export function calculateContrastColor(hexColor: string) {
  // check hex
  // const hexColorPattern = /^#([0-9A-Fa-f]{6})$/;

  // color to RGB
  hexColor = hexColor.replace(/^#/, "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 2), 16);
  const b = parseInt(hexColor.substring(4, 2), 16);

  // 亮度
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // 對比色
  if (luminance > 0.5) {
    return "#000000"; // 亮背景使用黑色文本
  } else {
    return "#FFFFFF"; // 暗背景使用白色文本
  }
}
