import { Backdrop } from "@/components/ui";
import { cn } from "@/utils/style-merge";
import React, { PropsWithChildren } from "react";

type RoomModalProps = {
  show: boolean;
  title?: string;
  onClose: () => void;
};
const RoomModal: React.FC<PropsWithChildren<RoomModalProps>> = (props) => {
  const { show, title = "", onClose, children } = props;
  return (
    <Backdrop>
      {show ? (
        <Backdrop.Item
          onClick={onClose}
          className={cn(
            "wii-z-[5] wii-bg-gray-100 wii-blur-xl",
            show ? "wii-overflow-hidden" : "wii-overflow-hidden"
          )}
        />
      ) : null}

      <Backdrop.Content
        className={cn(
          "wii-bottom-16 wii-m-4 wii-flex-col wii-items-center !wii-justify-start wii-rounded-lg wii-border-2 wii-bg-answer wii-shadow-sm",
          show ? "" : "wii-hidden"
        )}
      >
        <div className="wii-flex wii-w-full wii-items-center wii-px-2">
          {title !== "" && (
            <div className="wii-ml-2 wii-text-sm wii-font-bold wii-text-foreground">
              {title}
            </div>
          )}
          <div
            className="wii-ml-auto wii-mr-2 wii-w-fit wii-cursor-pointer wii-p-1 hover:wii-text-red-600"
            onClick={onClose}
          >
            ✕
          </div>
        </div>
        {children}
      </Backdrop.Content>
    </Backdrop>
  );
};

export default RoomModal;
