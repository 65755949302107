import type {
  MessageContent,
  VisitorInfo,
  ResponseMessageContent,
  FaqButtonMessage,
  ClickMessage,
  NotifyTimeSetting,
  SaveRecordsRequest,
  ChatHistoryRecordsRequest,
  ChatHistoryRecordsResponse,
  FaqCategoryOption,
  CustomerResponse,
  TChatBotQuestionAnswer,
  recordRealTimeQuestionProp,
  recordRealTimeAnswerProp,
  setChatFulfillProps,
  RefreshRealTimeResponse,
  RealTimeResponseType,
  MissionTaskRes,
  MissionTaskReq,
  BotSearchCommandItem,
} from "../models";

import http from "./api.service";
import { handleEncryptParams, transformReqAsync } from "./utils/transformRes";

export class ChatbotService {
  static getVisitorInfo = transformReqAsync<VisitorInfo, void>(
    async () => await http.get("/api/Chatbot/getVisitorInfo")
  );

  static getWelcomeInfo = async () =>
    await http.get<any, { botKind: string }>(
      `/api/Chatbot/getBotWellcomeInfoV2`,
      {
        botKind: process.env.REACT_APP_BOTKIND ?? `${0}`,
      }
    );

  static disconnect = async (params: setChatFulfillProps) =>
    await http.post(
      "api/Chatbot/setChatFulfill",
      handleEncryptParams(params, "POST")
    );

  static sendMessage = async (messageContent: MessageContent) =>
    await http.post<ResponseMessageContent, MessageContent>(
      "/api/Chatbot/sendQuestionToBot",
      handleEncryptParams({ ...messageContent }, "POST")
    );

  static sendAnswerMessage = async (answerMessage: TChatBotQuestionAnswer) =>
    await http.post<ResponseMessageContent, TChatBotQuestionAnswer>(
      "/api/Chatbot/getQuestionModuleAnswer",
      handleEncryptParams(answerMessage, "POST")
    );

  static sendButtonMessage = async (answerMessage: TChatBotQuestionAnswer) =>
    await http.post<ResponseMessageContent, TChatBotQuestionAnswer>(
      "/api/Chatbot/getButtonModuleAnswer",
      handleEncryptParams(answerMessage, "POST")
    );

  static sendGuideButtonMessage = async (faqMessage: FaqButtonMessage) =>
    await http.post<ResponseMessageContent, FaqButtonMessage>(
      "/api/Chatbot/getGuideButtonAnswer",
      handleEncryptParams(faqMessage, "POST")
    );

  static sendClickMessage = async (clickMessage: ClickMessage) =>
    await http.post<ResponseMessageContent, ClickMessage>(
      "/api/Chatbot/getClickAnswer",
      handleEncryptParams(clickMessage, "POST")
    );

  static idleNotification = async (sessionNo: number) =>
    await http.get<ResponseMessageContent, unknown>(
      `/api/Chatbot/getIdleNotifyV2?sessionNo=${sessionNo}`
    );

  static endChatNotification = async (sessionNo: number) =>
    await http.get<ResponseMessageContent, unknown>(
      `/api/Chatbot/getEndChatNotifyV2?sessionNo=${sessionNo}`
    );

  static getNotifyTimes = async () =>
    await http.get<NotifyTimeSetting, unknown>("/api/Chatbot/getNotifyTimes");

  static getChatRecover = async (sessionNo: number) =>
    await http.get<ResponseMessageContent, unknown>(
      `/api/Chatbot/getRecoverChat?sessionNo=${sessionNo}`
    );

  static saveRecords = async (req: SaveRecordsRequest) =>
    await http.post<void, SaveRecordsRequest>(
      `/api/Chatbot/setChatFulfillWithRecords`,
      req
    );

  static saveRecordsAndNewSession = async (req: SaveRecordsRequest) =>
    await http.post<number, SaveRecordsRequest>(
      `/api/Chatbot/getNewSessionNoAndChatFufill`,
      req
    );

  static getChatHistoryRecords = async (req: ChatHistoryRecordsRequest) =>
    await http.post<ChatHistoryRecordsResponse, ChatHistoryRecordsRequest>(
      `/api/Chatbot/getChatRecordHistory`,
      handleEncryptParams(req, "POST")
    );

  static getRealTimeWaitModuleMessage = async (req: {
    sessionNo: number;
    resType: RealTimeResponseType;
  }) =>
    await http.post<
      ResponseMessageContent,
      { sessionNo: number; resType: number }
    >("/api/Chatbot/getRealTimeWaitModuleMessage", req);

  static getFaqCategoryOptions = async () =>
    await http.get<FaqCategoryOption[], unknown>(
      `/api/Chatbot/getFaqCategoryOptions`
    );

  static createMemberMessage = async (
    categoryFaqSettingId: number,
    sessionNo: number,
    memberKey: number,
    text: string
  ) =>
    await http.post(`/api/Chatbot/createMemberMessage`, {
      categoryFaqSettingId,
      sessionNo,
      memberKey,
      text,
    });

  static changeToRealTimeCustomerService = transformReqAsync<
    CustomerResponse,
    {
      sessionNo: number;
      memberKey: number;
      demand?: string;
      fromChat?: number;
    }
  >(
    async ({
      sessionNo,
      memberKey,
      demand,
      fromChat,
    }: {
      sessionNo: number;
      memberKey: number;
      demand?: string;
      fromChat?: number;
    }) =>
      await http.post(
        `/api/Chatbot/changeToRealTimeCustomerService`,
        handleEncryptParams(
          {
            sessionNo,
            memberKey,
            fromChat: fromChat ?? 0,
            demand: demand ?? "轉真人客服",
          },
          "POST"
        )
      )
  );

  static recordRealTimeQuestion = async (req: recordRealTimeQuestionProp) =>
    await http.post<number, recordRealTimeQuestionProp>(
      `/api/Chatbot/recordRealTimeQuestion`,
      handleEncryptParams(req, "POST")
    );

  static recordRealTimeAnswer = async (req: recordRealTimeAnswerProp) =>
    await http.post<
      { recordId: number; answer: string },
      recordRealTimeAnswerProp
    >(`/api/Chatbot/recordRealTimeAnswer`, req);

  static getMemberRealTimeInfo = async (chatTimetableId: number) =>
    await http.get<RefreshRealTimeResponse, { chatTimetableId: number }>(
      `/api/Chatbot/getMemberRealTimeInfo`,
      { chatTimetableId: chatTimetableId }
    );
  static cancelRealTimeQueue = async (sessionNo: number) =>
    await http.post<
      any,
      {
        sessionNo: number;
      }
    >("/api/Chatbot/cancelRealTimeQueue", {
      sessionNo,
    });

  static convertFaq = async (req: { faq: string }) =>
    await http.post<string[], { faq: string }>("/api/Chatbot/convertFaq", req);

  static getMissionTask = async (req: MissionTaskReq) =>
    await http.post<MissionTaskRes, MissionTaskReq>(
      "/api/Chatbot/getMissionTask",
      req
    );
  static getNewChatSession = async (req: { loginKind: number }) =>
    await http.get<number, { loginKind: number }>(
      "/api/Chatbot/getNewChatSession",
      req
    );

  static getConvertQuestion = async (req: { text: string }) =>
    await http.post<{ text: string }, { text: string }>(
      "/api/Chatbot/getConvertQuestion",
      req
    );

  static getBotSearchCommand = async () =>
    await http.get<BotSearchCommandItem[], void>(
      "/api/Chatbot/getBotSearchCommand"
    );
  static getCommandSearchResult = async (req: { text: string }) =>
    await http.get<{ text: string }, { text: string }>(
      "/api/Chatbot/getCommandSearchResult",
      req
    );

  static enterChatBot = async (req: { sessionNo: number }) =>
    await http.get<{ sessionNo: number }, { sessionNo: number }>(
      "/api/Chatbot/enterChatBot",
      handleEncryptParams(req, "GET")
    );
}
