import { LoginKind } from "../../models";
import Image from "../../components/Image";
import type { LoginSetting } from "../../models/";
import { TogglesActions } from "../../store/toggles-slice";
import { useAppDispatch } from "../../store/store";

type TLoginOptions = {
  loginMethod: LoginSetting;
};
const LoginOptions = (props: TLoginOptions) => {
  const { loginMethod } = props;
  const dispatch = useAppDispatch();

  const loginButtonEvent: Record<LoginKind, () => void> = {
    [LoginKind.Member]: () => dispatch(TogglesActions.setLoginToggle(true)),
    [LoginKind.Line]: () => void 0,
    [LoginKind.Telegram]: () => void 0,
    [LoginKind.Wecaht]: () => void 0,
    [LoginKind.Discord]: () => void 0,
    [LoginKind.Messenger]: () => void 0,
    [LoginKind.Embed]: () => {
      dispatch(TogglesActions.setLoginToggle(true));
    },
  };

  return (
    <li
      key={loginMethod.name}
      className="login-icon wii-transition-all wii-duration-300 wii-ease-in-out hover:wii-scale-105"
    >
      {loginMethod.kind === LoginKind.Member ||
      loginMethod.kind === LoginKind.Embed ||
      loginMethod.url === "" ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            loginButtonEvent[loginMethod.kind]();
          }}
          className={`${
            loginMethod.kind !== LoginKind.Member &&
            loginMethod.kind !== LoginKind.Embed
              ? "wii-cursor-not-allowed wii-opacity-20"
              : ""
          }`}
        >
          <Image
            width={"100%"}
            height={"100%"}
            url={loginMethod.iconUrl}
            alt={loginMethod.name}
          />
        </div>
      ) : (
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
          href={
            loginMethod.url.match("https://")
              ? loginMethod.url
              : "https://" + loginMethod.url
          }
          target="_blank"
          rel="noreferrer noopener"
        >
          <Image width={"100%"} height={"100%"} url={loginMethod.iconUrl} />
        </a>
      )}
    </li>
  );
};

export default LoginOptions;
