import React, { Fragment } from "react";
import { cn } from "@/utils/style-merge";

type BackdropTypes = {
  className?: string;
  onClick?: () => void;
};
const Backdrop = (props: React.PropsWithChildren<{}>) => {
  return <Fragment>{props.children}</Fragment>;
};
const BackdropItem: React.FC<BackdropTypes> = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={cn(
        "wii-absolute wii-right-0 wii-top-0 wii-h-full wii-w-full wii-bg-gray-300 wii-opacity-20",
        className
      )}
      onClick={onClick}
    ></div>
  );
};

const BackdropContent: React.FC<React.PropsWithChildren<BackdropTypes>> = (
  props
) => {
  const { children, className = "" } = props;
  return (
    <div
      className={cn(
        "wii-absolute wii-left-0 wii-right-0 wii-top-1/2 wii-z-[50] wii-m-auto wii-flex wii-justify-center",
        className
      )}
    >
      {children}
    </div>
  );
};

Backdrop.Item = BackdropItem;
Backdrop.Content = BackdropContent;

export default Backdrop;
