import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../basehttp";
import {
  BotSearchCommandItem,
  BotSetting,
  CommandSearchResultItem,
  GetCSOnlineStatusRes,
  RefreshRealTimeResponse,
} from "../../../models";

export const chatbotApi = createApi({
  reducerPath: "chatbot",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "",
  }),

  tagTypes: ["/api/Chatbot/getCSOnlineStatus", "LANG"],
  endpoints: (builder) => ({
    getBotUserInfo: builder.query<BotSetting, string | undefined>({
      query: (req) => ({
        url: "/api/Chatbot/getBotUserInfo",
        method: "get",
        params: {
          botKind: process.env.REACT_APP_BOTKIND ?? `${0}`,
          signalrHubNo: req === "" ? undefined : req,
        },
      }),
      providesTags: ["LANG"],
    }),
    getBotSearchCommand: builder.query<BotSearchCommandItem[], void>({
      query: () => ({
        url: "/api/Chatbot/getBotSearchCommand",
        method: "get",
      }),
    }),
    getCommandSearchResult: builder.query<
      CommandSearchResultItem[],
      { text: string }
    >({
      query: (req) => ({
        url: "/api/Chatbot/getCommandSearchResult",
        method: "get",
        params: req,
      }),
    }),
    getCSOnlineStatus: builder.query<GetCSOnlineStatusRes, void>({
      query: () => ({
        url: "/api/Chatbot/getCSOnlineStatus",
        method: "post",
      }),
      providesTags: ["/api/Chatbot/getCSOnlineStatus"],
    }),

    // getMemberRealTimeInfo: builder.query<
    //   RefreshRealTimeResponse,
    //   { chatTimetableId: number }
    // >({
    //   query: (req) => ({
    //     url: "/api/Chatbot/getMemberRealTimeInfo",
    //     method: "get",
    //     params: req,
    //   }),
    // }),

    getMemberRealTimeInfo: builder.mutation<
      RefreshRealTimeResponse,
      { chatTimetableId: number }
    >({
      query: (req) => ({
        url: "/api/Chatbot/getMemberRealTimeInfo",
        method: "get",
        params: req,
      }),
    }),

    acceptCsInvite: builder.mutation<
      { chatTimetableId: number },
      {
        isAccept: boolean;
        sessionNo: number;
        memberKey: number;
        csUserId: string;
        noInvite?: boolean;
      }
    >({
      query: (params) => ({
        url: "/api/Chatbot/acceptCsInvite",
        method: "POST",
        data: params,
      }),
    }),
  }),
});

export const {
  useGetBotUserInfoQuery,
  useGetBotSearchCommandQuery,
  useLazyGetBotSearchCommandQuery,
  useLazyGetCommandSearchResultQuery,
  useGetCSOnlineStatusQuery,
} = chatbotApi;

export const { useGetMemberRealTimeInfoMutation, useAcceptCsInviteMutation } =
  chatbotApi;
