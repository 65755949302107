import {
  IMAGE_CHATROOM_DEFAULT_PATH,
  IMAGE_PLACEHOLDER_PATH,
} from "@/utils/constants";
import { memo, MouseEventHandler, useState } from "react";

export interface ImageProps {
  className?: string;
  width?: string;
  height?: string;
  url?: string;
  alt?: string;
  domain?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
}

const Image = ({
  className = "",
  width,
  height,
  url = "",
  alt = "",
  domain = process.env.REACT_APP_IMAGE_URL || "",
  onClick,
}: ImageProps) => {
  const imgUrl =
    !url || /^http(s?):\/\//.test(url) || /^blob:/.test(url)
      ? url
      : `${domain}${url}`;
  const [imgSrc, setImgSrc] = useState(imgUrl);

  const handleError = () => {
    if (alt in IMAGE_CHATROOM_DEFAULT_PATH) {
      const defaultImage = IMAGE_CHATROOM_DEFAULT_PATH[alt];
      if (imgSrc === defaultImage) return;
      setImgSrc(defaultImage);
    } else {
      if (imgSrc === IMAGE_PLACEHOLDER_PATH) return;
      setImgSrc(IMAGE_PLACEHOLDER_PATH);
    }
  };

  return (
    <img
      className={className}
      width={width}
      height={height}
      src={imgSrc}
      alt={alt}
      onClick={onClick}
      onError={handleError}
    />
  );
};

export default memo(Image);
