import { useEffect, useState } from "react";
import InfoEdit from "./InfoEdit";
import InfoDetail from "./InfoDetail";
import { useForm, useWatch } from "react-hook-form";

type InfoContentProps = {
  currentUser:
    | {
        name: string;
        headerUrl: string | null;
      }
    | undefined;
  auth:
    | {
        isNoTalking: boolean;
        isUploadImage: boolean;
        isHeadImage: boolean;
      }
    | undefined;
};

type FormProps = {
  headerUrl: string;
  name: string;
};

const InfoContent = (props: InfoContentProps) => {
  const {
    currentUser,
    auth = {
      isNoTalking: true,
      isUploadImage: false,
      isHeadImage: false,
    },
  } = props;
  const [editMode, setEditMode] = useState(false);

  const forms = useForm<FormProps>({
    defaultValues: {
      name: currentUser?.name ?? "",
      headerUrl: currentUser?.headerUrl ?? "",
    },
  });

  const watchUser = useWatch({
    control: forms.control,
    name: "name",
  });
  const watchHeaderUrl = useWatch({
    control: forms.control,
    name: "headerUrl",
  });

  useEffect(() => {
    return () => {
      if (forms.getValues("headerUrl").startsWith("blob")) {
        URL.revokeObjectURL(forms.getValues("headerUrl"));
      }
    };
  }, [forms]);

  const user = {
    name: watchUser,
    headerUrl: watchHeaderUrl,
  };

  if (!currentUser) return null;

  return (
    <>
      {!editMode ? (
        <InfoDetail
          currentUser={user}
          setEditMode={setEditMode}
          auth={auth}
          viewMode="edit"
        />
      ) : (
        <InfoEdit
          key={editMode ? "edit" : "view"}
          setEditMode={setEditMode}
          auth={auth}
          viewMode="edit"
          forms={forms}
          watchHeaderUrl={watchHeaderUrl}
        />
      )}
    </>
  );
};

export default InfoContent;
