import axios from "axios";
import http from "../api.service";
import { SendMemberPictureArgs } from "./method";
import { handleEncryptParams } from "../utils/transformRes";

type UserPairArgs = {
  memberKey: string;
  csUserId: string;
};

export class SignalrService {
  static onPairNotify = async (params: UserPairArgs) =>
    await axios.post<UserPairArgs, unknown>(
      "http://192.168.1.203:5533/customerservice/signalr/member/connectpair",
      params
    );
  static startRealTimeConnect = async (params: UserPairArgs) =>
    await http.get<unknown, UserPairArgs>(
      "/api/Chatbot/startRealTimeConnect",
      handleEncryptParams(params, "GET")
    );

  static SendMemberPicture = async (params: SendMemberPictureArgs) =>
    await http.post<{ url: string | null }, SendMemberPictureArgs>(
      "/api/Chatbot/sendMemberPicture",
      params
    );
}
