import { encryptParams } from "@/utils/crypto";

const remapKeyRev: { [key: string]: string } = {
  sn: "sessionNo",
  cu: "csUserId",
  mk: "memberKey",
};

export function applyRecursiveGetter<T>(obj: any): T {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      applyRecursiveGetter(obj[key]);
    }

    if (remapKeyRev[key] !== undefined) {
      Object.defineProperty(obj, remapKeyRev[key], {
        get: function () {
          return this[key];
        },
        enumerable: true,
        configurable: true,
      });
    }
  }
  return obj;
}

export const transformReqAsync =
  <Res, Req>(fn: (req: Req) => Promise<Res>) =>
  (req: Req) =>
    fn(req).then((data: any) => {
      return applyRecursiveGetter<Res>(data);
    });

export function handleEncryptParams<
  T extends {
    sessionNo?: number | string;
    csUserId?: number | string;
    memberKey?: number | string;
  }
>(params: T, type: "GET" | "POST"): any {
  const { sessionNo, memberKey, csUserId, ...rest } = params;

  const sessionNoParam = sessionNo && encryptParams(sessionNo, type);
  const memberKeyParam = memberKey && encryptParams(memberKey, type);
  const csUserIdParam = csUserId && encryptParams(csUserId, type);

  return {
    sessionNoParam,
    memberKeyParam,
    csUserIdParam,
    ...rest,
  };
}
