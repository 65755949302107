import type { BotSetting, LoginSetting } from "../models";
import http from "./api.service";

export class SettingService {
  static checkEmbeddedAuth = async (url: string) =>
    await http.post<{ isAuth: boolean; isEmbed: boolean }, { url: string }>(
      "/api/Account/checkEmbeddedAuth",
      {
        url: url,
      }
    );
  static getLoginSettings = async () =>
    await http.get<LoginSetting[], unknown>("/api/Chatbot/getLoginSettingInfo");
}
