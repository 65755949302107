import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type MissionSliceType = {
  currentMissionId: number;
  isMission: boolean;
};

const initialState: MissionSliceType = {
  currentMissionId: 0,
  isMission: false,
};

const missionSlice = createSlice({
  name: "missionService",
  initialState,
  reducers: {
    isOnMission: (state) => {
      state.isMission = true;
    },
    isLeaveMission: (state) => {
      state.isMission = false;
    },
    setCurrentMissionId: (
      state,
      action: PayloadAction<{ missionId: number }>
    ) => {
      state.currentMissionId = action.payload.missionId;
    },
    setMissionState: (
      state,
      action: PayloadAction<{
        currentMissionId: number;
        isMission: boolean;
      }>
    ) => {
      state.currentMissionId = action.payload.currentMissionId;
      state.isMission = action.payload.isMission;
    },
    initialMissionService: () => {
      return { ...initialState };
    },
  },
});

export const missionActions = missionSlice.actions;

export default missionSlice.reducer;
