import MemberLogin from "../MemberLogin";
import { GuestLogin } from "../GuestLogin";
import { LoginKind } from "../../models";
import { Backdrop } from "../../components/ui";

import { useTypedSelector } from "../../store/store";
import { useMemberStore } from "../../hooks";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useGetLoginSettingsQuery } from "../../services/api/Setting/settingApi";

import FadingBalls from "react-cssfx-loading/lib/FadingBalls";

const LoginPage = () => {
  const { memberInfo } = useMemberStore();
  const [localStorageValue] = useLocalStorage();
  const { isEmbed } = useTypedSelector((state) => state.settings.embedSetting);
  const { data: loginOptionList, isLoading } = useGetLoginSettingsQuery();
  const showLoginList = loginOptionList?.filter((i) =>
    isEmbed ? i.kind !== LoginKind.Member : i.kind !== LoginKind.Embed
  );

  const showGuestLoginPage = isEmbed
    ? !memberInfo.sessionNo &&
      !localStorageValue.memberKey &&
      !localStorageValue.token
    : !memberInfo.sessionNo;

  if (!showLoginList || isLoading) return <></>;

  const rootElement = document.querySelector(":root") as Element;
  const color = getComputedStyle(rootElement).getPropertyValue("--color");

  return (
    <>
      {showGuestLoginPage && <GuestLogin loginSettings={showLoginList} />}

      {!showGuestLoginPage && !memberInfo.sessionNo ? (
        <Backdrop>
          <Backdrop.Item />
          <Backdrop.Content>
            <FadingBalls color={color} width="1rem" height="1rem" />
          </Backdrop.Content>
        </Backdrop>
      ) : null}

      <MemberLogin />
    </>
  );
};

export default LoginPage;
