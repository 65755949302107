import { useEffect } from "react";
import { ChatbotService } from "../services";
import useMemberStore from "./useMemberStore";
import { ChatFulfillType } from "../models";
import { useTypedSelector } from "../store/store";
import { WII_SESSION_STORAGE } from "../utils/constants";

const useBeforeunload = (): any => {
  const { memberInfo } = useMemberStore();
  const isLink = useTypedSelector((state) => state.csService.isLink);

  useEffect(() => {
    const beforeUnloadEvent = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      ChatbotService.disconnect({
        sessionNo: memberInfo.sessionNo,
        fullfillType: ChatFulfillType.RefreshAndClose,
        star: null,
        isRealTime: isLink,
      });

      if (localStorage.getItem(WII_SESSION_STORAGE)) {
        event.returnValue = false;
      }
    };

    window.addEventListener("beforeunload", beforeUnloadEvent);

    return () => {
      window.removeEventListener("beforeunload", beforeUnloadEvent);
    };
  }, [isLink, memberInfo.sessionNo]);
};

export default useBeforeunload;
