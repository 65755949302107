import { FC, Fragment, memo } from "react";
import parse from "html-react-parser";

import { Message } from "@/chatroom/Bot";
import TextMessageButton from "./TextMessageButton";

import styles from "./TextMessage.module.scss";
import type { ResponseMessageProp } from "@/models";

const TextMessage: FC<ResponseMessageProp & { isEnd: boolean }> = ({
  responseMessage,
  taskType,
  taskLanguageKey,
  isCsRead,
  isEnd,
}) => {
  const transformLinks = (text: string) => {
    const urlPattern = /https?:\/\/[^\s]+/g;
    return text
      .split(urlPattern)
      .reduce((acc: any[], part: string, index, arr) => {
        acc.push(parse(part));

        if (index < arr.length - 1) {
          const match = text.match(urlPattern)?.[index];
          acc.push(
            <a
              key={index}
              href={match}
              target="_blank"
              className="wii-underline"
              rel="noopener noreferrer"
            >
              {match}
            </a>
          );
        }
        return acc;
      }, []);
  };

  return (
    <>
      {responseMessage.chatContents.map((chatContent, index) => {
        if (
          index === responseMessage.chatContents.length - 1 &&
          chatContent.replies.length > 0
        ) {
          return (
            <Fragment key={index}>
              <Message
                className={
                  responseMessage.chatRecordType === "A"
                    ? ""
                    : styles["user-message"]
                }
                paragraphClassName="w-spec wii-w-full"
                message={transformLinks(chatContent.text ?? "")}
                datetime={responseMessage.createdTime}
                isCsRead={isCsRead}
                isEnd={isEnd}
                taskType={taskType}
                taskLanguageKey={taskLanguageKey}
              />
              <TextMessageButton
                data={chatContent.replies}
                datetime={responseMessage.createdTime}
                isEnd={isEnd}
                taskType={taskType}
                taskLanguageKey={taskLanguageKey}
              />
            </Fragment>
          );
        }
        return (
          <Message
            key={index}
            className={
              responseMessage.chatRecordType === "A"
                ? ""
                : styles["user-message"]
            }
            message={transformLinks(chatContent.text ?? "")}
            datetime={responseMessage.createdTime}
            isCsRead={isCsRead}
            isEnd={isEnd}
            taskType={taskType}
            taskLanguageKey={taskLanguageKey}
          />
        );
      })}
    </>
  );
};

export default memo(TextMessage);
