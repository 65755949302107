import React from "react";

import { SoundFilled, SoundOutlined } from "@ant-design/icons";

type AnnounceProps = {
  hasAnnounce: boolean;
  bulletin: boolean;
  slideToNext: () => void;
  setBulletin: React.Dispatch<React.SetStateAction<boolean>>;
  setTopItem: React.Dispatch<React.SetStateAction<boolean>>;
};

const AnnounceButton = (props: AnnounceProps) => {
  const { hasAnnounce, bulletin, slideToNext, setBulletin, setTopItem } = props;

  if (!hasAnnounce) return null;

  const Sound = bulletin ? SoundFilled : SoundOutlined;
  return (
    <>
      <button
        className="wii-absolute wii-left-1 wii-top-0 wii-z-[1001] wii-flex wii-h-10 wii-items-center"
        onClick={() => {
          if (bulletin) {
            slideToNext();
          } else {
            setBulletin(true);
            setTopItem(false);
          }
        }}
      >
        <Sound
          style={{ fontSize: "20px", color: "var(--theme-question)" }}
          type="setting"
        />
      </button>
    </>
  );
};

export default AnnounceButton;
