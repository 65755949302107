import React, { memo, useState } from "react";

import styles from "./MessageInput.module.scss";

import {
  EditFilled,
  PictureFilled,
  RollbackOutlined,
  SmileOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { TogglesActions } from "@/store/toggles-slice";
import {
  useAppDispatch,
  useChatRoomUser,
  useTypedSelector,
} from "@/store/store";
import {
  useChatRoomIsReplacePictureMutation,
  useChatRoomIsReplaceTextMutation,
  useChatRoomMemberInfoQuery,
  useSendChatRoomPictureMutation,
  useSendChatRoomTextMutation,
} from "@/services/api/ChatRoom/chatRoomApi";

import { ChatRoomListTagChat, ChatRoomMessageListItem } from "@/models";

import {
  ALLOWQ_IMAGE_SIZE,
  ALLOW_IMAGE_TYPE,
  CHATROOM_OFFSET,
} from "@/utils/constants";
import { UploadImage } from "@/containers";
import { CustomStatus } from "@/models/format";
import { Picture } from "@/components";
import { useConversation } from "@/chatroom/chatRoom.context";
import { customUuid } from "@/utils/uuid";
import { cn } from "@/utils/style-merge";
import EmojiPicker, { Categories, EmojiStyle } from "emoji-picker-react";
import { Backdrop } from "@/components/ui";
import { useTranslation } from "react-i18next";

import { useSignalRChatRoom } from "@/services/signalr-chat/context/signalR.context";

type MessageInputProps = {
  inputRef: React.RefObject<HTMLInputElement>;
  isEnd: boolean;
  tagChat: ChatRoomListTagChat | null;
  setTagChat: React.Dispatch<React.SetStateAction<ChatRoomListTagChat | null>>;
};

const MessageInput = (props: MessageInputProps) => {
  const { isEnd, inputRef, tagChat, setTagChat } = props;
  const [t] = useTranslation();

  const {
    command,
    responseMessage,
    setResponseMessage,
    scrollbarRef,
    setRecordParam,
    toShowOrToScroll,
    currentChatRoom,
    name,
  } = useConversation();

  const { isDisabled = false, roomSessionNo = "" } = currentChatRoom ?? {};

  const [mediaUrl, setMediaUrl] = useState<string[] | undefined>(undefined);
  const [emoji, setEmoji] = useState(false);
  const { isOnline } = useSignalRChatRoom();

  const { userId: ownerId, userRole, roomId } = useChatRoomUser();

  const toggles = useTypedSelector((state) => state.toggles);
  const dispatch = useAppDispatch();

  const [imageErr, setImageErr] = useState<
    { name: string; content: string } | undefined
  >(undefined);

  const { data: userInfo } = useChatRoomMemberInfoQuery(
    {
      command: command,
      id: ownerId!,
    },
    { skip: command === "" || userRole !== "Member" }
  );

  const authority = userInfo?.data.authority;

  const [triggerChat, state] = useSendChatRoomTextMutation();
  const [triggerImage, imageState] = useSendChatRoomPictureMutation();

  const [triggerTextReplace] = useChatRoomIsReplaceTextMutation();
  const [triggerImageReplace] = useChatRoomIsReplacePictureMutation();

  async function handleReply(inputValue: string) {
    if (tagChat?.replyTagType !== "Edit") return;

    if (mediaUrl === undefined && inputValue === "" && inputValue.trim() !== "")
      return;

    if (mediaUrl !== undefined) {
      const params = {
        command: command,
        id: tagChat.id,
        base64String: mediaUrl[0],
      };

      const result = await triggerImageReplace(params).unwrap();
      if (result.status === CustomStatus.Err) return;
      setTagChat(null);
      deleteImageHandler();

      const editMessage: ChatRoomMessageListItem[] = responseMessage.map(
        (i) => {
          if (tagChat.id === i.conversation?.id) {
            return {
              ...i,
              conversation: {
                ...i?.conversation,
                chatText: result.data.text,
                chatTextTypeName: "Image",
                isReplace: true,
                isShow: userRole === "Cs" ? true : false,
              },
            };
          }

          if (i.conversation?.tagChat?.id === tagChat.id) {
            return {
              ...i,
              conversation: {
                ...i.conversation,
                tagChat: {
                  ...i.conversation.tagChat,
                  chatText: result.data.text,
                  chatTextTypeName: "Image",
                  isShow: userRole === "Cs" ? true : false,
                },
              },
            };
          }
          return i;
        }
      );
      setResponseMessage(editMessage);
    } else {
      const params = {
        command: command,
        id: tagChat.id,
        text: inputValue,
      };

      const editMessage: ChatRoomMessageListItem[] = responseMessage.map(
        (i) => {
          if (tagChat.id === i.conversation?.id) {
            return {
              ...i,
              conversation: {
                ...i?.conversation,
                chatText: inputValue,
                chatTextTypeName: "Text",
                isReplace: true,
              },
            };
          }

          if (i.conversation?.tagChat?.id === tagChat.id) {
            return {
              ...i,
              conversation: {
                ...i.conversation,
                tagChat: {
                  ...i.conversation.tagChat,
                  chatText: inputValue,
                  chatTextTypeName: "Text",
                },
              },
            };
          }
          return i;
        }
      );
      setResponseMessage(editMessage);
      setTagChat(null);
      deleteImageHandler();

      triggerTextReplace(params)
        .unwrap()
        .then((data) => {
          if (data.status === CustomStatus.Err) {
            setResponseMessage(responseMessage);
          } else {
            const editMessage: ChatRoomMessageListItem[] = responseMessage.map(
              (i) => {
                if (tagChat.id === i.conversation?.id) {
                  return {
                    ...i,
                    conversation: {
                      ...i?.conversation,
                      chatText: data.data.text,
                      chatTextTypeName: "Text",
                      isReplace: true,
                    },
                  };
                }

                if (i.conversation?.tagChat?.id === tagChat.id) {
                  return {
                    ...i,
                    conversation: {
                      ...i.conversation,
                      tagChat: {
                        ...i.conversation.tagChat,
                        chatText: data.data.text,
                        chatTextTypeName: "Text",
                      },
                    },
                  };
                }
                return i;
              }
            );
            setResponseMessage(editMessage);
          }
        })
        .catch(() => {
          setResponseMessage(responseMessage);
        });
    }
  }
  async function handleSendText(inputValue: string) {
    if (inputValue === "" || !ownerId || !userRole) {
      return;
    }

    const uuid = customUuid();

    if (isEnd) {
      const reply: ChatRoomMessageListItem = {
        type: 1,
        time: new Date().toISOString(),
        user: {
          roleId: ownerId,
          name: name,
          headerUrl: "",
          role: userRole,
        },
        module: null,
        conversation: {
          id: uuid,
          roomSessionNo: roomSessionNo,
          chatTextTypeName: "Text",
          chatText: inputValue,
          languageKey: "TW",
          tagChat: tagChat ? { ...tagChat } : null,
          isShow: true,
          isShield: false,
          isTop: false,
          isReplace: false,
          isDeleted: false,
        },
      };

      setResponseMessage((prev) => {
        return [...prev, reply];
      });

      scrollbarRef?.current?.scrollToBottom();

      triggerChat({
        command: command,
        roomSessionNo: roomSessionNo,
        chatText: inputValue,
        tagChatTextId: tagChat?.id,
      })
        .unwrap()
        .then((result) => {
          // NOTE: show submit error
          if (result.status === CustomStatus.Err) return;

          setTagChat(null);

          const resultData = result.data;

          const reply: ChatRoomMessageListItem = {
            type: 1,
            time: new Date().toISOString(),
            user: {
              roleId: ownerId,
              name: name,
              headerUrl: "",
              role: userRole,
            },
            module: null,
            conversation: {
              id: resultData.id,
              roomSessionNo: roomSessionNo,
              chatTextTypeName: "Text",
              chatText: resultData.text,
              languageKey: "TW",
              tagChat: tagChat ? { ...tagChat } : null,
              isShow: true,
              isShield: false,
              isTop: false,
              isReplace: false,
              isDeleted: false,
            },
          };

          setResponseMessage((prev) => {
            const a = prev.map((i) => {
              if (i?.conversation?.id === uuid) return reply;
              return i;
            });
            return a;
          });
          inputRef.current?.focus();
        })
        .catch((err) => {
          const reply: ChatRoomMessageListItem = {
            type: 1,
            time: new Date().toISOString(),
            user: {
              roleId: ownerId,
              name: name,
              headerUrl: "",
              role: userRole,
            },
            module: null,
            conversation: {
              id: uuid,
              roomSessionNo: roomSessionNo,
              chatTextTypeName: "Text",
              chatText: t("offline"),
              languageKey: "TW",
              tagChat: tagChat ? { ...tagChat } : null,
              isShow: true,
              isShield: false,
              isTop: false,
              isReplace: false,
              isDeleted: false,
            },
          };
          setResponseMessage((prev) => {
            const a = prev.map((i) => {
              if (i?.conversation?.id === uuid) return reply;
              return i;
            });
            return a;
          });
        });

      setTagChat(null);

      setTimeout(() => {
        scrollbarRef?.current?.scrollToBottom();
      }, 0);
    } else {
      triggerChat({
        command: command,
        roomSessionNo: roomSessionNo,
        chatText: inputValue,
        tagChatTextId: tagChat?.id,
      })
        .unwrap()
        .then((result) => {
          // NOTE: show submit error
          if (result.status === CustomStatus.Err) return;
          setTagChat(null);
          setRecordParam((prev) => {
            if (prev) {
              return {
                ...prev,
                offsetAbove: CHATROOM_OFFSET.offsetAbove,
                offsetBelow: 12,
                id: undefined,
              };
            }
            return {
              command,
              offsetAbove: CHATROOM_OFFSET.offsetAbove,
              offsetBelow: 12,
              id: undefined,
            };
          });
        });
    }
  }

  async function handleUploadImage(event: React.ChangeEvent<HTMLInputElement>) {
    setImageErr(undefined);
    const files = event.target.files;
    const MASUPLODASIZE = 1;

    if (files) {
      const totalFileLength = files.length;
      if (totalFileLength > MASUPLODASIZE) {
        event.target.value = ""; // 清空已選擇的檔案
        setImageErr({
          name: t("image-length-title"),
          content: t("image-length-content"),
        });
        return; // 阻止上傳
      }

      let correctSize = true;
      Array.from(files).forEach((element) => {
        if (element.size > ALLOWQ_IMAGE_SIZE) {
          correctSize = false;
        }
      });

      if (!correctSize) {
        setImageErr({
          name: t("image-size-title"),
          content: t("image-size-content"),
        });

        return;
      }

      const awaitBuff: Promise<string>[] = [];
      Array.from(files).forEach((file) => {
        const fileName = file.name;
        const fileEndType = fileName.split(".").at(-1);

        if (
          fileEndType &&
          ALLOW_IMAGE_TYPE.includes(fileEndType.toLowerCase())
        ) {
          awaitBuff.push(
            new Promise<string>((resolve) => {
              const reader = new FileReader();

              reader.onload = (event) => {
                if (event.target?.result) {
                  resolve(event.target?.result as string);
                }
              };
              reader.readAsDataURL(file);
            })
          );
        } else {
          setImageErr({
            name: t("image-format-title"),
            content: t("image-format-content"),
          });
        }
      });

      const media = await Promise.all(awaitBuff);
      if (media.length === 0) {
        setMediaUrl(undefined);
      } else {
        setMediaUrl(media);

        if (tagChat?.replyTagType === "Edit" && inputRef.current) {
          inputRef.current.value = "";
          inputRef.current.style.height = "40px";
        }
        inputRef.current?.focus();
      }
    }
  }

  async function handleSendImage(mediaUrl: string[] | undefined) {
    if (
      mediaUrl === undefined ||
      !ownerId ||
      !userRole ||
      tagChat?.replyTagType === "Edit"
    )
      return;

    const result = await triggerImage({
      command: command,
      roomSessionNo: roomSessionNo,
      base64String: mediaUrl[0],
      tagChatTextId: tagChat?.id,
    }).unwrap();

    if (result.status === CustomStatus.Err) return;

    setTagChat(null);
    deleteImageHandler();

    const resultData = result.data;

    const reply: ChatRoomMessageListItem = {
      type: 1,
      time: new Date().toISOString(),
      user: {
        roleId: ownerId,
        name: name,
        headerUrl: "",
        role: userRole,
      },
      module: null,
      conversation: {
        id: resultData.id,
        roomSessionNo: roomSessionNo,
        chatTextTypeName: "Image",
        chatText: resultData.text,
        languageKey: "TW",
        tagChat: tagChat ? { ...tagChat } : null,
        isShow: userRole === "Cs" ? true : false,
        isShield: false,
        isTop: false,
        isReplace: false,
        isDeleted: false,
      },
    };

    toShowOrToScroll({ isEnd, reply, id: undefined });
  }

  function deleteImageHandler() {
    setMediaUrl(undefined);
  }

  function handleCloseEmoji() {
    setEmoji(false);
  }

  const disabled =
    isDisabled ||
    !isOnline ||
    state.isLoading ||
    imageState.isLoading ||
    roomId === "";

  const prefixName =
    tagChat?.role === "Bot" || tagChat?.role === "Cs"
      ? `[${tagChat.role}] `
      : undefined;

  return (
    <>
      <style>{`
      div .custom-emoji {
      --epr-emoji-size:20px;
      --epr-category-navigation-button-size:20px;
      }
      `}</style>

      <div className="wii-mx-8 wii-flex wii-gap-2">
        {mediaUrl !== undefined &&
          mediaUrl.map((i) => (
            <div
              key={i}
              className="wii-relative wii-bottom-0 wii-z-10 wii-mx-1 wii-flex wii-w-fit  wii-ring-1 wii-ring-primary/40"
            >
              <UploadImage
                imgUrl={i}
                deleteImageHandler={deleteImageHandler}
                loadingStoreState={false}
              />
            </div>
          ))}
      </div>
      {imageErr !== undefined && (
        <div className="wii-relative wii-mx-4 wii-mt-4 wii-bg-transparent">
          <div className="wii-flex wii-w-full wii-min-w-0 wii-items-start wii-truncate wii-rounded-xl wii-bg-primary/10 wii-px-4 wii-py-2">
            <div className="wii-flex wii-w-full wii-grow wii-flex-col wii-pr-4">
              <div className="wii-truncate wii-text-sm wii-font-bold">
                <WarningFilled className="wii-text-primary" /> {imageErr.name}
              </div>
              <p className="wii-w-full wii-shrink wii-truncate wii-indent-2 wii-text-sm">
                {imageErr.content}
              </p>
            </div>
            <button
              className="wii-ml-auto wii-w-fit wii-shrink-0 wii-grow wii-cursor-pointer wii-rounded-full wii-text-sm wii-text-question"
              onClick={() => {
                setImageErr(undefined);
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}

      {tagChat !== null && (
        <div className="wii-relative wii-mx-4 wii-mt-4 wii-bg-transparent">
          <div className="wii-flex wii-w-full wii-min-w-0 wii-items-start wii-truncate wii-rounded-xl wii-bg-gray-200/80 wii-px-4 wii-py-2">
            <div className="wii-flex wii-w-full wii-grow wii-flex-col wii-pr-4">
              <span className="wii-mb-0.5 wii-text-xs wii-font-bold wii-text-primary">
                {tagChat.replyTagType === "Reply" ? (
                  <span className="wii-flex">
                    <RollbackOutlined className="wii-mr-1 wii-font-bold" />
                    {t("reply-message")}
                  </span>
                ) : (
                  <span className="wii-flex">
                    <EditFilled className="wii-mr-1 wii-font-bold" />
                    {t("edit-message")}
                  </span>
                )}
              </span>

              {tagChat.replyTagType === "Reply" && (
                <div className="wii-truncate wii-text-sm wii-font-bold">
                  {prefixName
                    ? prefixName + (tagChat.name ?? "")
                    : tagChat.name ?? ""}
                </div>
              )}
              {tagChat.chatTextTypeName === "Text" ? (
                <p className="wii-w-full wii-shrink wii-truncate wii-indent-2 wii-text-sm">
                  {tagChat?.chatText}
                </p>
              ) : (
                <div>
                  <Picture
                    className={
                      "wii-h-24 wii-w-fit wii-rounded-t-md wii-object-scale-down wii-py-1.5"
                    }
                    url={tagChat.chatText}
                    domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                    pictureMemo=""
                  />
                </div>
              )}
            </div>
            <button
              className="wii-ml-auto wii-w-fit wii-shrink-0 wii-grow wii-cursor-pointer wii-rounded-full wii-text-sm wii-text-question"
              onClick={() => {
                setTagChat(null);
                if (tagChat.replyTagType === "Edit" && inputRef.current) {
                  inputRef.current.value = "";
                  inputRef.current.style.height = "40px";
                }
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}
      <div
        className="theme-background wii-relative wii-flex wii-items-end"
        onClick={() => {
          dispatch(TogglesActions.setMenuToggle(false));
        }}
      >
        <div className="wii-absolute wii-bottom-full wii-right-2">
          <EmojiPicker
            open={emoji}
            categories={[
              {
                category: Categories.SUGGESTED,
                name: "Recently Used",
              },
              {
                category: Categories.SMILEYS_PEOPLE,
                name: "Smileys & People",
              },
            ]}
            hiddenEmojis={["1fae0", "1fac2", "1f972"]}
            skinTonesDisabled={true}
            height="20rem"
            width="15em"
            searchDisabled={true}
            reactionsDefaultOpen={true}
            emojiStyle={EmojiStyle.FACEBOOK}
            onEmojiClick={(v) => {
              if (inputRef.current) {
                inputRef.current.value += v.emoji;
                inputRef.current.focus();
              }
            }}
            className="custom-emoji wii-z-[6]"
            previewConfig={{
              showPreview: false,
            }}
          />
        </div>
        <button
          className="wii-w-1/12 wii-pb-4"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(TogglesActions.setMenuToggle(!toggles.menuToggle));
          }}
        >
          <i className="icon-menu wii-text-foreground" />
        </button>

        <div className="wii-mx-1.5 wii-flex wii-items-center wii-pb-4">
          <label>
            <input
              type="file"
              accept="image/*"
              multiple
              onClick={(e) => {
                const inputValue = e.target as HTMLInputElement;
                inputValue.value = "";
              }}
              onChange={handleUploadImage}
              className={"wii-peer wii-hidden"}
              readOnly={
                disabled || (userRole === "Member" && !authority?.isUploadImage)
              }
              disabled={
                disabled ||
                (userRole === "Member" && !authority?.isUploadImage) ||
                (userRole === "Member" && authority?.isNoTalking)
              }
            />
            <PictureFilled
              className={cn(
                "wii-h-5 wii-w-5 wii-cursor-pointer wii-text-foreground hover:wii-opacity-80 peer-disabled:wii-cursor-default peer-disabled:wii-text-primary/40"
              )}
            />
          </label>
        </div>

        <div className={`${styles.message} wii-relative`}>
          <div className="wii-flex wii-min-w-0 wii-flex-1">
            <textarea
              // @ts-ignore
              ref={inputRef}
              className={cn(
                "wii-outline wii-w-full wii-resize-none wii-overflow-y-hidden wii-rounded-[1.0em] wii-border-none wii-py-2 wii-text-base wii-outline-1 wii-outline-primary/60",
                "wii-max-h-[7rem] wii-pl-2.5 wii-pr-16",
                "focus:wii-outline-primary/60"
              )}
              style={{
                height: `${inputRef.current?.scrollHeight ?? 40}px` ?? "40px",
                overflowY: "auto",
              }}
              readOnly={
                (userRole === "Member" && authority?.isNoTalking) ||
                roomId === ""
              }
              disabled={
                isDisabled ||
                (userRole === "Member" && authority?.isNoTalking) ||
                roomId === "" ||
                (tagChat?.replyTagType === "Edit" && mediaUrl !== undefined)
              }
              placeholder={
                !isOnline
                  ? t("offline")
                  : userRole === "Member" && authority?.isNoTalking
                  ? t("be-muted")
                  : ""
              }
              onClick={() => {
                handleCloseEmoji();
              }}
              onChange={(e) => {
                handleCloseEmoji();
                e.target.style.height = "40px";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
              onKeyDown={async (e) => {
                const value = (e.target as HTMLInputElement).value;

                if (e.key === "Enter" && !e.shiftKey && isOnline) {
                  e.preventDefault();
                  handleCloseEmoji();

                  if (tagChat?.replyTagType === "Edit") {
                    await handleReply(value);
                  } else {
                    await handleSendImage(mediaUrl);
                    if (value.trim() !== "") {
                      await handleSendText(value);
                    }
                  }

                  (e.target as HTMLInputElement).value = "";
                  (e.target as HTMLInputElement).focus();
                  (e.target as HTMLInputElement).style.height = "40px";
                }
              }}
            />
          </div>
          <div
            className={cn(
              "wii-absolute wii-bottom-0 wii-right-4 wii-flex wii-items-center wii-gap-1 wii-pb-4"
            )}
          >
            <div>
              <SmileOutlined
                className="wii-h-5 wii-w-5 wii-text-primary data-[disabled=true]:wii-text-primary/40"
                data-disabled={
                  disabled ||
                  (userRole === "Member" && authority?.isNoTalking) ||
                  roomId === "" ||
                  (tagChat?.replyTagType === "Edit" && mediaUrl !== undefined)
                }
                onClick={() => {
                  if (
                    disabled ||
                    (userRole === "Member" && authority?.isNoTalking) ||
                    roomId === "" ||
                    (tagChat?.replyTagType === "Edit" && mediaUrl !== undefined)
                  )
                    return;

                  setEmoji((ej) => !ej);
                }}
              />
            </div>

            <button
              disabled={disabled}
              className="disabled:wii-opacity-60"
              onClick={async () => {
                if (inputRef.current === null) return;
                const value = inputRef.current.value;

                handleCloseEmoji();

                if (tagChat?.replyTagType === "Edit") {
                  await handleReply(value);
                } else {
                  await handleSendImage(mediaUrl);
                  if (value.trim() !== "") {
                    await handleSendText(value);
                  }
                }

                inputRef.current.value = "";
                inputRef.current.focus();
                inputRef.current.style.height = "40px";
              }}
            >
              <i className="icon-send wii-text-foreground" />
            </button>
          </div>
        </div>
      </div>
      {emoji && (
        <Backdrop>
          <Backdrop.Item
            onClick={handleCloseEmoji}
            className={cn(
              "wii-bottom-14 wii-top-[initial] wii-z-[5] wii-bg-transparent wii-blur-xl",
              "wii-overflow-hidden"
            )}
          />
        </Backdrop>
      )}
    </>
  );
};

export default memo(MessageInput);
