import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActiveStatus, MemberInfo, UserInfo, UserLoginSite } from "../models";
import { WII_MEMBER_STORAGE, WII_SESSION_STORAGE } from "../utils/constants";

export const defaultInfo: MemberInfo = {
  isAutoRealTime: false,
  token: "",
  askerId: 0,
  memberKey: 0,
  sessionNo: 0,
};

export const defaultUserInfo: MemberInfo & UserInfo = {
  isAutoRealTime: false,
  token: "",
  askerId: 0,
  memberKey: 0,
  sessionNo: 0,
  loginAccount: "",
  accountId: "",
  name: "",
  email: "",
  pictureUrl: "",
  provider: "",
  loginKind: UserLoginSite.Guest,
  isVIP: false,
};

const initialState = {
  info: defaultUserInfo,
  activeStatus: ActiveStatus.Active,
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setMemberInfo: (state, action: PayloadAction<MemberInfo>) => {
      localStorage.removeItem(WII_SESSION_STORAGE);
      localStorage.removeItem(WII_MEMBER_STORAGE);
      const newState = Object.assign({}, state, { info: action.payload });
      localStorage.setItem(
        WII_SESSION_STORAGE,
        `${newState.info.sessionNo}` || ""
      );
      localStorage.setItem(WII_MEMBER_STORAGE, newState.info.token || "");

      return newState;
    },
    setActiveStatus: (state, action: PayloadAction<ActiveStatus>) => {
      state.activeStatus = action.payload;
    },

    setUserInfo: (state, action: PayloadAction<MemberInfo & UserInfo>) => {
      localStorage.removeItem(WII_SESSION_STORAGE);
      localStorage.removeItem(WII_MEMBER_STORAGE);
      const newState = Object.assign({}, state, { info: action.payload });
      localStorage.setItem(
        WII_SESSION_STORAGE,
        `${newState.info.sessionNo}` || ""
      );
      localStorage.setItem(WII_MEMBER_STORAGE, newState.info.token || "");

      return newState;
    },
    setNewSession: (state, action: PayloadAction<number>) => {
      state.info.sessionNo = action.payload;
    },
    logout: (state) => {},
  },
});

export const MemberActions = memberSlice.actions;

export default memberSlice.reducer;
