import { SignalRProvider } from "@/services/signalr/context/signalR.context";

import ChatRoomApp from "@/chatroom/App";
import App from "@/App";
import { useChatRoomUser } from "@/store/store";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";

const KindComponent = () => {
  const user = useChatRoomUser();

  const { data: botSetting } = useGetBotSettingsHubQuery();

  const isChatRoom = botSetting?.kind === "ChatRoom" || user.roomId;

  return (
    <>
      {isChatRoom ? (
        <ChatRoomApp />
      ) : (
        <SignalRProvider>
          <App />
        </SignalRProvider>
      )}
    </>
  );
};

export default KindComponent;
