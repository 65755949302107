import { FC, memo } from "react";
import { Timestamp } from "@/components";

import "./Message.module.scss";
import { cn } from "@/utils/style-merge";
import { PushpinFilled } from "@ant-design/icons";
import "./Message.module.scss";
import { ResponseMessageContent } from "@/models";

// /**
//  * @deprecated
//  */

type FaqClickProps = {
  id: string;
  text: string;
  memberKey: number;
  sessionNo: number;
};

type TextClickProps = {
  id: string;
  text: string;
  value: string;
  memberKey: number;
  sessionNo: number;
};

const Message: FC<{
  className?: string;
  paragraphClassName?: string;

  message: string | JSX.Element | JSX.Element[];
  datetime?: string | number;
  isCsRead?: boolean;
}> = ({
  className = "",
  message = "",
  datetime,
  paragraphClassName = "",
  isCsRead,
}) => {
  async function faqClick(
    props: FaqClickProps
  ): Promise<ResponseMessageContent> {
    const { id, memberKey, text, sessionNo } = props;

    // return ChatbotService.sendMessage({
    //   faqId: +id.replace("faq_", ""),
    //   message: text,
    //   memberKey: memberKey,
    //   sessionNo: sessionNo,
    //   user: "System",
    // });

    //     GET
    // /api/ChatRoom/ChatRoomFaqAnswer
    // 聊天室FAQ

    return new Promise(() => {});
  }

  async function textClick(
    props: TextClickProps
  ): Promise<ResponseMessageContent> {
    const { id, memberKey, text, value, sessionNo } = props;
    // return ChatbotService.sendClickMessage({
    //   clickId: +id.replace("click_", ""),
    //   chatClickTypeId: +value,
    //   demand: text,
    //   memberKey: memberKey,
    //   sessionNo: sessionNo,
    //   taskType: "SupplyBot",
    // });

    return new Promise(() => {});
  }

  async function webUrlCheck(respContent: ResponseMessageContent) {
    // send(respContent);
  }

  async function moduleClick(event: any) {
    const element = event.target as HTMLElement;
    const text = element.innerHTML;
    const value = element.getAttribute("value") || "";
    const id = element.id;
    const type = id.replace(/_\d+/, "");

    // if (type === "csUser") {
    //   if (!memberInfo.token) {
    //     dispatch(TogglesActions.setLoginToggle(true));
    //   }

    //   return;
    // }

    // if (type === "faq") {
    //   send(
    //     transformAnsToQues({
    //       message: text,
    //       replyId: 0,
    //       kindId: 0,
    //       taskType: "",
    //     })
    //   );
    // }

    // if (type === "faq") {
    //   const response = await faqClick({ id, text, memberKey, sessionNo });
    //   webUrlCheck(response);
    // }
    // if (type === "click") {
    //   const response = await textClick({
    //     id,
    //     text,
    //     value,
    //     memberKey,
    //     sessionNo,
    //   });
    //   webUrlCheck(response);
    // }
  }

  if (!message) return <></>;

  return (
    <div className={`wii-flex wii-flex-row ${className}`.trimEnd()}>
      <p
        className={cn(
          "message-text wii-z-[1] !wii-flex wii-items-center",
          "group-data-[state=open]:wii-cursor-default group-data-[state=open]:wii-ring-1 group-data-[state=open]:wii-ring-foreground",
          "group-data-[view=ban]:!wii-outline group-data-[view=ban]:!wii-bg-transparent group-data-[view=ban]:!wii-text-theme-color group-data-[view=ban]:!wii-outline-1 group-data-[view=ban]:!wii-outline-theme-color",
          paragraphClassName
        )}
      >
        <PushpinFilled
          className={cn(
            "wii-mr-1",
            "wii-hidden group-data-[top=true]:wii-block"
          )}
        />

        <span>{message}</span>
      </p>
      {isCsRead && (
        <span className="wii-self-end wii-px-2 wii-text-[0.75rem] wii-text-[#888888] group-hover:wii-hidden">
          已讀
        </span>
      )}
      <Timestamp
        datetime={datetime}
        className={"timestamp wii-self-end group-hover:!wii-block"}
      />
    </div>
  );
};

// /**
//  * @deprecated
//  */
export default memo(Message);
