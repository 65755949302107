import {
  Suspense,
  memo,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { Scrollbar } from "react-scrollbars-custom";

import { MessageItems, MessageInput } from "@/chatroom/containers";
import { Menu } from "@/chatroom/containers";
import {
  chatRoomApi,
  useChatRoomAnnouncementListQuery,
  useChatRoomRecordListQuery,
  useChatRoomRecordTopListQuery,
  useLazyChatRoomRecordListQuery,
} from "@/services/api/ChatRoom/chatRoomApi";
import { Backdrop } from "@/components/ui";
import { FadingBalls } from "react-cssfx-loading";

import { useAppDispatch, useChatRoomUser } from "@/store/store";
import {
  ChatRoomImageRefresh,
  ChatRoomListConversation,
  ChatRoomListTagChat,
  ChatRoomMessageListItem,
} from "@/models";
import { ArrowDownOutlined } from "@ant-design/icons";

import "./ChatContent.module.scss";
import { cn } from "@/utils/style-merge";
import { useConversation } from "@/chatroom/chatRoom.context";
import { useSignalRChatRoom } from "@/services/signalr-chat/context/signalR.context";
import { OnChatRoomNotifyMessageType } from "@/services/signalr-chat/method";

import { CHATROOM_OFFSET } from "@/utils/constants";

import { ChatroomActions } from "@/store/chatRoom-slice";

import Bulletin from "../Bulletin/Bulletin";
import { useTranslation } from "react-i18next";

const ChatContent = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const [t] = useTranslation();

  const {
    command,
    scrollbarRef,
    responseMessage,
    setResponseMessage,
    recordParam,
    setRecordParam,
    currentChatRoom,
    currentId,
  } = useConversation();

  const owner = useChatRoomUser();
  const [isPending, startTransition] = useTransition();

  const [tagChat, setTagChat] = useState<ChatRoomListTagChat | null>(null);

  const { SignalRHub } = useSignalRChatRoom();

  const [showToBottom, setShowToBottom] = useState(false);
  const [hasMore, setHasMore] = useState({
    isTop: false,
    isEnd: false,
  });

  const skipRequest = command === "";

  const commandArg = {
    command,
  };

  const skipArg = {
    skip: skipRequest || owner.roomId === "",
  };

  const { data: topList } = useChatRoomRecordTopListQuery(commandArg, skipArg);

  const { data: announceList } = useChatRoomAnnouncementListQuery(
    commandArg,
    skipArg
  );

  const [fetchNextChatMessage, nextChatMessageData] =
    useLazyChatRoomRecordListQuery();

  const { data, isLoading, isFetching } = useChatRoomRecordListQuery(
    recordParam!,
    {
      skip:
        skipRequest ||
        recordParam === undefined ||
        currentChatRoom === undefined,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  const currentTopId = responseMessage[0]?.conversation?.id ?? -1;
  const currentBottomId =
    responseMessage[responseMessage.length - 1]?.conversation?.id ?? -1;

  const status = data?.data.status;
  const isTop = status?.isTop ? status.isTop : hasMore.isTop;
  const isEnd = status?.isEnd ? status.isEnd : hasMore.isEnd;

  useEffect(() => {
    if (SignalRHub && owner.userRole) {
      SignalRHub.on(
        "OnChatRoomNotifyMessage",
        (message: OnChatRoomNotifyMessageType) => {
          if (message.action === "ChatRoomNotify") {
            const content = message.message[0];
            if (
              owner.roomId === message.toId &&
              isEnd &&
              content.type === "Message"
            ) {
              setResponseMessage((rm) => [...rm, content.value]);
            }
          }

          if (
            message.action === "ChatRoomBroadcast" &&
            !(currentChatRoom?.roomId === message.toId)
          )
            return;

          if (
            message.action === "ChatRoomBroadcast" ||
            message.action === "NotifyMessage"
          ) {
            for (const content of message.message) {
              if (content.type === "Message") {
                const chatContent = content.value;
                const conversation =
                  chatContent.conversation as ChatRoomListConversation;
                const user = chatContent.user;

                if (conversation === null) return;

                const updateList: ChatRoomMessageListItem[] =
                  responseMessage.map((i) => {
                    // NOTE: replace message with new item
                    if (i?.conversation?.id === chatContent?.conversation?.id) {
                      return chatContent;
                    }

                    // NOTE: check item tag
                    if (i?.conversation?.tagChat?.id === conversation?.id) {
                      return {
                        ...i,
                        conversation: {
                          ...i.conversation,
                          tagChat: i.conversation.tagChat
                            ? {
                                ...i.conversation.tagChat,
                                chatText: conversation?.chatText,
                                chatTextTypeName:
                                  conversation?.chatTextTypeName,
                                isShield: conversation?.isShield,
                                isShow: conversation?.isShow,
                                isTop: conversation?.isTop,
                                isReplace: conversation?.isReplace,
                                isDeleted: conversation?.isDeleted,
                                roleId: user.roleId,
                                name: conversation?.isDeleted ? "" : user.name,
                                role: user.role,
                                headerUrl: user.headerUrl,
                              }
                            : null,
                        },
                      };
                    }

                    return i;
                  });

                setResponseMessage(updateList);
              }
              if (content.type === "MemberList") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomMemberDetail",
                  ])
                );
              }
              if (content.type === "CsList") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomCsUserDetail",
                  ])
                );
              }
              if (content.type === "Top") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomRecordTopList",
                  ])
                );
              }
              if (content.type === "Member") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomMemberInfo",
                  ])
                );
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomCsUserInfo",
                  ])
                );
              }
              if (content.type === "Count") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomUsers",
                  ])
                );
              }
              if (content.type === "KickOut" || content.type === "Dismiss") {
                if (
                  content?.value?.roomId?.[0] === currentChatRoom?.roomId ||
                  message.toId === currentChatRoom?.roomId
                ) {
                  dispatch(ChatroomActions.kickOutChatRoom());
                }

                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomList",
                  ])
                );
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/MemberChatRoomList",
                  ])
                );
              }
              if (content.type === "ChatRoom") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomList",
                  ])
                );
              }
              if (content.type === "Image") {
                const conversation = content.value as ChatRoomImageRefresh[];

                const conversationMap = conversation.reduce(
                  (acc: { [key: string]: ChatRoomImageRefresh }, cur) => {
                    acc[cur.id] = cur;
                    return acc;
                  },
                  {}
                );

                const updateList: ChatRoomMessageListItem[] =
                  responseMessage.map((i) => {
                    // NOTE: replace message with new item
                    if (
                      i?.conversation?.id &&
                      conversationMap[i?.conversation?.id]
                    ) {
                      const image = conversationMap[i?.conversation?.id];

                      return {
                        ...i,
                        conversation: {
                          ...i.conversation,
                          chatText: image.chatText,
                          isShow: image.isShow,
                        },
                      };
                    }

                    // NOTE: check item tag
                    if (
                      i?.conversation?.tagChat?.id &&
                      conversationMap[i?.conversation?.tagChat?.id]
                    ) {
                      const image =
                        conversationMap[i?.conversation?.tagChat?.id];
                      return {
                        ...i,
                        conversation: {
                          ...i.conversation,
                          tagChat: i.conversation.tagChat
                            ? {
                                ...i.conversation.tagChat,
                                chatText: image.chatText,
                                isShow: image.isShow,
                              }
                            : null,
                        },
                      };
                    }

                    return i;
                  });
                setResponseMessage(updateList);
              }
              if (content.type === "Announcement") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomAnnouncementList",
                  ])
                );
              }
              if (content.type === "Freeze" || content.type === "UnFreeze") {
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/ChatRoomList",
                  ])
                );
                dispatch(
                  chatRoomApi.util.invalidateTags([
                    "/api/ChatRoom/MemberChatRoomList",
                  ])
                );
              }
            }
          }
        }
      );
    }

    return () => {
      if (SignalRHub && owner.userRole) {
        SignalRHub.off("OnChatRoomNotifyMessage");
      }
    };
  }, [
    SignalRHub,
    currentChatRoom?.roomId,
    dispatch,
    isEnd,
    owner.roomId,
    owner.userRole,
    responseMessage,
    scrollbarRef,
    setResponseMessage,
  ]);

  useEffect(() => {
    if (!nextChatMessageData?.data?.data) return;

    const list = nextChatMessageData.data.data.list;
    const status = nextChatMessageData?.data?.data.status;
    const dir = nextChatMessageData?.originalArgs?.dir;
    const originalArgs = nextChatMessageData?.originalArgs;

    setHasMore((prev) => ({
      isTop: prev.isTop ? prev.isTop : !!status.isTop,
      isEnd: prev.isEnd ? prev.isEnd : !!status.isEnd,
    }));

    if (originalArgs === undefined || dir === undefined || list === undefined)
      return;

    const isTop = dir === "top";
    const currentId = isTop ? +currentTopId : +currentBottomId;

    const currentIdIndex = list.findIndex((element) => {
      if (!element?.conversation?.id) return false;

      return +element?.conversation?.id === currentId;
    });

    if (currentIdIndex !== -1) {
      const dataSlice = isTop
        ? list.slice(0, currentIdIndex)
        : list.slice(currentIdIndex + 1);

      setResponseMessage((prev) =>
        isTop ? [...dataSlice, ...prev] : [...prev, ...dataSlice]
      );

      dispatch(
        chatRoomApi.util.updateQueryData(
          "ChatRoomRecordList",
          originalArgs,
          (p) => ({
            ...p,
            // @ts-ignore
            data: undefined,
          })
        )
      );

      if (isTop) {
        const viewNode = document.querySelector(
          `[data-obs="${currentId}"]`
        ) as HTMLDivElement;
        if (viewNode) {
          setTimeout(() => {
            viewNode.style.backgroundColor = "var(--theme-background)";
            setTimeout(() => {
              viewNode.style.background = "transparent";
            }, 1000);
          }, 0);

          viewNode.scrollIntoView({ behavior: undefined, block: "start" });
        }
      }
    }
  }, [
    currentBottomId,
    currentTopId,
    dispatch,
    nextChatMessageData,
    setResponseMessage,
  ]);

  useEffect(() => {
    if (!data || isFetching) return;

    startTransition(() => {
      setResponseMessage([...data.data.list]);
      setHasMore(data.data.status);
    });
  }, [data, isFetching, setResponseMessage]);

  const roomId = currentChatRoom?.roomId ?? "";

  useEffect(() => {
    if (!data || isPending || isFetching) return;

    if (!recordParam) return;

    const list = data.data.list;

    let id = recordParam?.id ?? list?.[list?.length - 1]?.conversation?.id;

    if (!id) return;

    const currentTagNode = document.querySelector(
      `[data-obs="${+id}"]`
    ) as HTMLDivElement;

    if (!currentTagNode) {
      scrollbarRef.current?.scrollToBottom();
      return;
    }

    const tagNode = currentTagNode?.previousElementSibling;

    const scrollNode = tagNode ?? currentTagNode;

    if (!scrollNode) return;

    if (recordParam?.id) {
      if (currentTagNode) {
        setTimeout(() => {
          currentTagNode.classList.add("wii-bg-primary/20");
          currentTagNode.classList.add(
            "animate__animated",
            "animate__headShake"
          );

          setTimeout(() => {
            currentTagNode.classList.remove(
              "wii-bg-primary/20",
              "animate__animated",
              "animate__headShake"
            );
          }, 4000);
        }, 0);
      }
      scrollNode.scrollIntoView({ behavior: undefined, block: "start" });
    } else {
      scrollNode.scrollIntoView({
        behavior: undefined,
        block: "center",
      });
    }
  }, [
    data,
    isPending,
    recordParam?.id,
    isFetching,
    scrollbarRef,
    roomId,
    recordParam,
    currentId,
  ]);

  /**
   *
   * @param tagId
   * @param showTag 能不能點擊來移動
   * @returns
   */
  async function scrollToTag(tagId: string | undefined, showTag?: boolean) {
    if (!tagId || !showTag) return;

    if (!recordParam) return;

    const currentTagNode = document.querySelector(
      `[data-obs="${tagId}"]`
    ) as HTMLDivElement;

    if (!currentTagNode) {
      setRecordParam((prev) => {
        if (prev) {
          return {
            ...prev,
            offsetAbove: CHATROOM_OFFSET.offsetAbove,
            offsetBelow: CHATROOM_OFFSET.offsetBelow,
            id: tagId,
          };
        }
        return {
          command,
          offsetAbove: CHATROOM_OFFSET.offsetAbove,
          offsetBelow: CHATROOM_OFFSET.offsetBelow,
          id: tagId,
        };
      });
    } else {
      currentTagNode.classList.add("wii-bg-primary/20");
      currentTagNode.classList.add("animate__animated", "animate__headShake");

      const tagNode = currentTagNode?.previousElementSibling;

      const scrollNode = tagNode ?? currentTagNode;

      scrollNode.scrollIntoView({ behavior: undefined, block: "start" });

      setTimeout(() => {
        currentTagNode.classList.remove(
          "wii-bg-primary/20",
          "animate__animated",
          "animate__headShake"
        );
      }, 4000);
    }
  }

  if (owner.roomId && owner.userId !== null && (!data || isLoading))
    return (
      <div className="wii-flex wii-items-center">
        <Backdrop>
          <Backdrop.Item />
          <Backdrop.Content>
            <FadingBalls color={"gray"} width="1rem" height="1rem" />
          </Backdrop.Content>
        </Backdrop>
      </div>
    );

  const isTopPadding =
    (announceList?.data.list ?? []).length > 0 ||
    (topList?.data?.list ?? []).length > 0;

  const resetBulletinKey = `${
    (announceList?.data?.list ?? []).length > 0 ? "announce" : "none"
  }}-${(topList?.data?.list ?? []).length > 0 ? "top" : "none"}`;

  return (
    <>
      <div className="wii-flex wii-shrink-0 wii-grow wii-flex-col">
        {owner.roomId === "" ? (
          <div className="wii-mx-4 wii-mt-6 wii-h-full wii-text-foreground">
            {t("chat-room-no-auth")}
          </div>
        ) : (
          <>
            <>
              <Bulletin
                key={resetBulletinKey}
                announceList={announceList?.data.list}
                topList={topList?.data.list}
                isEnd={isEnd}
                scrollToTag={scrollToTag}
                currentChatRoom={currentChatRoom}
                command={command}
              />
            </>

            <Scrollbar
              noScrollX={true}
              removeTrackYWhenNotUsed={true}
              ref={scrollbarRef as any}
              elementRef={scrollbarRef as any}
              onScroll={(e: any) => {
                if (
                  // @ts-ignore
                  e.scrollTop + e.clientHeight + 200 <= e.scrollHeight ||
                  !isEnd
                ) {
                  setShowToBottom(true);
                } else {
                  setShowToBottom(false);
                }
              }}
            >
              <div className="wii-relative wii-h-full wii-text-lg">
                <MessageItems
                  isTop={isTop || isFetching}
                  isEnd={isEnd || isFetching}
                  searchId={recordParam?.id}
                  inputRef={inputRef}
                  fetchNextChatMessage={fetchNextChatMessage}
                  tagChat={tagChat}
                  setTagChat={setTagChat}
                  isTopPadding={isTopPadding}
                  setShowToBottom={setShowToBottom}
                />
              </div>

              {showToBottom && (
                <button
                  className={cn(
                    "wii-fixed wii-bottom-20 wii-left-1/2 wii-right-1/2 wii-z-[5]",
                    isFetching ? "wii-hidden" : ""
                  )}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isEnd) {
                      scrollbarRef.current?.scrollToBottom();
                      return;
                    }

                    if (!isEnd && recordParam?.id === undefined) {
                      dispatch(
                        chatRoomApi.util.invalidateTags([
                          {
                            type: "/api/ChatRoom/ChatRoomRecordList",
                            id: undefined,
                          },
                        ])
                      );
                    }

                    if (!isEnd && recordParam?.id) {
                      setRecordParam((prev) => {
                        return prev
                          ? {
                              ...prev,
                              id: undefined,
                            }
                          : {
                              id: undefined,
                              command,
                              offsetAbove: CHATROOM_OFFSET.offsetAbove,
                              offsetBelow: CHATROOM_OFFSET.offsetBelow,
                            };
                      });
                    }
                  }}
                >
                  <div className="wii-ml-auto wii-mr-4 wii-flex wii-h-10 wii-w-10 wii-items-center wii-justify-center wii-rounded-full wii-bg-background wii-text-foreground wii-shadow-xl">
                    <ArrowDownOutlined />
                  </div>
                </button>
              )}
            </Scrollbar>
          </>
        )}

        <Suspense fallback={false}>
          <Menu />
        </Suspense>

        <MessageInput
          isEnd={isEnd}
          inputRef={inputRef}
          tagChat={tagChat}
          setTagChat={setTagChat}
        />
      </div>
      {isFetching && (
        <div className="wii-flex wii-items-center">
          <Backdrop>
            <Backdrop.Item />
            <Backdrop.Content>
              <FadingBalls color={"gray"} width="1rem" height="1rem" />
            </Backdrop.Content>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default memo(ChatContent);
