import dayjs from "dayjs";
import { FC, memo } from "react";

import "./Timestamp.module.scss";
import { cn } from "@/utils/style-merge";

const Timestamp: FC<{ datetime?: string | number; className?: string }> = memo(
  ({ datetime: dateTime = "", className = "" }) =>
    dateTime ? (
      <div
        className={cn(
          "timestamp wii-visible wii-flex-shrink-0 wii-px-1.5 group-hover:wii-visible md:wii-invisible",
          className
        )}
      >
        {dayjs(dateTime).format("hh:mm A")}
      </div>
    ) : (
      <></>
    )
);

export default memo(Timestamp);
