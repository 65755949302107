import { Suspense } from "react";

import { Language } from "../../../../containers/Shortcut";

type LanguageContainerProps = {
  onClose: () => void;
};

const LanguageContainer = ({ onClose }: LanguageContainerProps) => {
  return (
    <>
      <div className="">
        <Suspense fallback={false}>
          <Language onClose={onClose} />
        </Suspense>
      </div>
    </>
  );
};

export default LanguageContainer;
