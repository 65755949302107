import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "@/store/store";

import "@/index.scss";
import "@/i18n";
import { interceptor } from "@/services/api.service";
import {
  AuthComponent,
  ThemeComponent,
  SiteComponent,
} from "@/components/layout";

import KindComponent from "@/components/layout/KindComponent";
import { SignalRChatRoomProvider } from "./services/signalr-chat/context/signalR.context";
import { useLayoutEffect } from "react";

const root = ReactDOM.createRoot(
  document.querySelector("wii-chatbot") as HTMLElement
);
interceptor(store);

declare global {
  interface Window {
    WiiApp: {
      init: () => Promise<void>;
      connect: (cb: (id: string) => Promise<void>) => Promise<void>;
      connectedState: string;
      _wiiSignalRKey: string;
      click: (() => void) | undefined;
      close: () => Promise<void>;
      role: "Member" | "Cs" | undefined;
    };
  }
}

const Main = () => {
  useLayoutEffect(() => {
    window.WiiApp = window.WiiApp || {};
  }, []);

  return (
    <>
      <Provider store={store}>
        <AuthComponent>
          <ThemeComponent>
            <SignalRChatRoomProvider>
              <SiteComponent>
                <KindComponent />
              </SiteComponent>
            </SignalRChatRoomProvider>
          </ThemeComponent>
        </AuthComponent>
      </Provider>
    </>
  );
};

root.render(<Main />);

// const mainWebComponent = reactToWebComponent(Main, React, root);
// customElements.define("wii-chatbot", mainWebComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
