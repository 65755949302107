import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ChatRoomUserSliceType = {
  userId: string | null;
  userRole?: "Cs" | "Member";
};

type ChatRoomSliceType = {
  roomId: string | "";
};

const initialState: ChatRoomSliceType & ChatRoomUserSliceType = {
  userId: null,
  userRole: undefined,

  roomId: "",
};

const chatRoomSlice = createSlice({
  name: "chatRoom",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        userId: string | null;
        userRole?: "Member" | "Cs";
      }>
    ) => {
      state.userId = action.payload.userId; // NOTE: roomId + userId
      if (action.payload.userRole) {
        state.userRole = action.payload.userRole;
      }
    },
    setChat: (state, action: PayloadAction<{ roomId: string }>) => {
      state.roomId = action.payload.roomId;
    },
    kickOutChatRoom: (state) => {
      state.roomId = "";
    },
  },
});

export const ChatroomActions = chatRoomSlice.actions;

export default chatRoomSlice.reducer;
