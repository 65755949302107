import { memo, useEffect, useState, useMemo, useRef } from "react";
import { useTypedSelector, useAppDispatch } from "../../store/store";

import { Subject } from "rxjs";

import { useMemberStore, useMessages } from "../../hooks";
import { TogglesActions } from "../../store/toggles-slice";

import { MissionActionType, ResponseMessageContent } from "../../models";
import { ChatbotService } from "../../services";

import styles from "./MessageInput.module.scss";
import transformAnsToQues from "../../utils/answer-to-question";
import { replyActions } from "../../store/reply-slice";
import { useSignalRConnect } from "../../services/signalr/context/signalR.context";
import { csActions } from "../../store/cs-slice";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { missionActions } from "../../store/mission-slice";
import { HubConnectionState } from "@microsoft/signalr";

import { Scrollbar } from "react-scrollbars-custom";
import {
  useGetBotSearchCommandQuery,
  useLazyGetCommandSearchResultQuery,
} from "../../services/api/Chatbot/botApi";
import { debounce } from "../../utils/get-debounce";
import { PictureFilled } from "@ant-design/icons";
import UploadImage from "../UploadImage";
import { SignalrService } from "../../services/signalr";
import { ALLOW_IMAGE_TYPE, VIP_ICON } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const imgTypes = ALLOW_IMAGE_TYPE;

type MessageInputProps = {
  setCsRead: React.Dispatch<React.SetStateAction<boolean>>;
};
const MessageInput = (props: MessageInputProps) => {
  const { setCsRead } = props;
  const [userMessage, setUserMessage] = useState("");
  const [userMessages, setUserMessages] = useState<string[]>([]);

  const [mediaUrl, setMediaUrl] = useState<string[] | undefined>(undefined);

  const inputRef = useRef<HTMLInputElement>(null);

  const [showProductList, setShowProductList] = useState(false);
  const [showSearchCommand, setShowSearchCommand] = useState(false);

  // const [triggerSearchCommandResult, searchCommandResult] =
  //   useLazyGetBotSearchCommandQuery();

  const { data: searchCommandResult } = useGetBotSearchCommandQuery();

  const [triggerSearchListResult, searchListResult] =
    useLazyGetCommandSearchResultQuery();

  const [selectedTab, setSelectedTab] = useState<number | undefined>(undefined);

  const debounceMsgRef = useRef(
    debounce(
      (newValue: string) =>
        triggerSearchListResult({
          text: newValue,
        }),
      250
    )
  );

  const blurRef = useRef<boolean>(true);

  const dispatch = useAppDispatch();
  const [send] = useMessages();

  const { memberInfo } = useMemberStore();
  const messages$ = useMemo(() => new Subject<string[]>(), []);
  const toggles = useTypedSelector((state) => state.toggles);
  const loadingStoreState = useTypedSelector(
    (state) => state.replyLoading.isLoading
  );

  const {
    isLink,
    csUserId,
    enabledWait,
    isConnectedSignalR,
    checkingWaitingState,
  } = useTypedSelector((state) => state.csService);

  const { isMission, currentMissionId } = useTypedSelector(
    (state) => state.missionService
  );

  const {
    SignalRHub,
    notifyCsMessage,
    changeToRealTimeAction,
    notifyCsUserReadMessage,
    idleBotChatTimer,
  } = useSignalRConnect();

  const [t] = useTranslation();
  const [localStorageValue] = useLocalStorage();

  useEffect(() => {
    const messagesSubscription = messages$
      // NOTE: cs debonceTime????
      // .pipe(debounceTime(1000))
      .subscribe((resp) => {
        const combineMessage = resp.join("");
        if (!isLink && !isMission && combineMessage) {
          ChatbotService.sendMessage({
            faqId: 0,
            memberKey: memberInfo.memberKey,
            message: combineMessage,
            sessionNo: memberInfo.sessionNo,
            user: "System",
          }).then((respContent: ResponseMessageContent) => {
            send(respContent);
            setUserMessages([]);
          });

          return;
        }

        if (isLink && combineMessage) {
          notifyCsMessage({
            message: combineMessage,
          }).then((_) => {
            ChatbotService.recordRealTimeQuestion({
              sessionNo: memberInfo.sessionNo,
              memberKey: memberInfo.memberKey,
              message: combineMessage,
              csUserId: csUserId!,
            }).then((questionId) => {
              dispatch(
                csActions.setCurrentQuestionId({ questionId: questionId })
              );
            });
            setUserMessages([]);
          });
          return;
        }

        if (isMission && combineMessage) {
          ChatbotService.getMissionTask({
            missionId: currentMissionId,
            memberKey: memberInfo.memberKey,
            memberId: +localStorageValue.memberKey,
            memberToken: localStorageValue.token,
            sessionNo: memberInfo.sessionNo,
            faqSettingId: 0,
            kindId: 0,
            replyId: 0,
            text: combineMessage,
            actionType: MissionActionType.Reply,
          }).then((resp) => {
            if (resp.isSuccess) {
              send({
                responseMessage: resp.replyData,
                guideButtons: [],
                taskType: "MBot",
                taskLanguageKey: "TW",
              });
              setUserMessages([]);
            }
            if (resp.isMission === false) {
              // NOTE: mission ending =>  clearup mission state
              dispatch(missionActions.initialMissionService());
            }
          });
          return;
        }
      });

    return () => {
      messagesSubscription.unsubscribe();
    };
  }, [
    csUserId,
    currentMissionId,
    memberInfo,
    isLink,
    isMission,
    notifyCsMessage,
    send,
    dispatch,
    messages$,
    localStorageValue.memberKey,
    localStorageValue.token,
  ]);

  useEffect(() => messages$.next(userMessages), [messages$, userMessages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [mediaUrl]);

  useEffect(() => {
    if (
      !showSearchCommand &&
      showProductList &&
      userMessage.trim().slice(1) !== ""
    ) {
      // @ts-ignore
      debounceMsgRef.current(userMessage.trim());
    }
  }, [userMessage, showSearchCommand, showProductList]);

  useEffect(() => {
    if (inputRef.current && loadingStoreState === false) {
      inputRef.current.focus();
    }
  }, [loadingStoreState]);

  const userSendMessage = async (message: string) => {
    setUserMessage("");
    if (message) {
      setUserMessages((preMsgs) => [...preMsgs, message]);
      if (!isLink) {
        dispatch(replyActions.isLoadingState());
      }

      const data = await ChatbotService.getConvertQuestion({
        text: userMessage,
      });
      const convertMsg = data.text;
      send(
        transformAnsToQues({
          kindId: 0,
          message: convertMsg,
          replyId: 0,
          taskType: "",
        })
      );
    }
  };

  // NOTE: VIP轉真人
  const connectCustomerService = () => {
    if (!memberInfo.token) {
      dispatch(TogglesActions.setLoginToggle(true));
    }
    dispatch(csActions.isLoginToCsUser(true));
  };

  function selectCommandHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!showSearchCommand) return;
    if (!searchCommandResult) return;

    if (e.key === "Enter" && searchCommandResult && selectedTab !== undefined) {
      setUserMessage(searchCommandResult?.[selectedTab].command);
      setShowSearchCommand(false);
      setShowProductList(true);
      setSelectedTab(undefined);
      inputRef.current?.focus();
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedTab((prev) => {
        if (prev === undefined) {
          return 0;
        }
        if (prev === 0) {
          return searchCommandResult!.length - 1;
        }
        return prev - 1;
      });
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();

      setSelectedTab((prev) => {
        if (prev === undefined) {
          return 0;
        }
        if (prev === searchCommandResult!.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }
  }

  function selectProductHandler(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!showProductList) return;
    if (!searchListResult) return;
    if (!searchListResult.data) return;

    if (e.key === "Enter" && selectedTab !== undefined) {
      setUserMessage("@" + searchListResult?.data?.[selectedTab].name);
      setShowSearchCommand(false);
      setShowProductList(false);
      setSelectedTab(undefined);
      inputRef.current?.focus();
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedTab((prev) => {
        if (prev === undefined) {
          return 0;
        }
        if (prev === 0) {
          return searchListResult!.data!.length - 1;
        }
        return prev - 1;
      });
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();

      setSelectedTab((prev) => {
        if (prev === undefined) {
          return 0;
        }
        if (prev === searchListResult!.data!.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const MASUPLODASIZE = 1;

    if (files) {
      const totalFilesSize = files.length;
      if (totalFilesSize > MASUPLODASIZE) {
        event.target.value = ""; // 清空已選擇的檔案
        return; // 阻止上傳
      }

      const awaitBuff: Promise<string>[] = [];
      Array.from(files).forEach((file) => {
        const fileName = file.name;
        const fileEndType = fileName.split(".").at(-1);

        if (fileEndType && imgTypes.includes(fileEndType.toLowerCase())) {
          awaitBuff.push(
            new Promise<string>((resolve) => {
              const reader = new FileReader();

              reader.onload = (event) => {
                if (event.target?.result) {
                  resolve(event.target?.result as string);
                }
              };
              reader.readAsDataURL(file);
            })
          );
        }
      });

      const media = await Promise.all(awaitBuff);
      if (media.length === 0) {
        setMediaUrl(undefined);
      } else {
        setMediaUrl(media);
      }
    }
  };

  function deleteImageHandler() {
    setMediaUrl(undefined);
  }

  async function sendImageToCs() {
    if (mediaUrl === undefined) return;

    const { url: imagePath } = await SignalrService.SendMemberPicture({
      fromId: `${memberInfo.memberKey}`,
      toId: csUserId ? csUserId.toString() : "4",
      base64String: mediaUrl[0],
    });

    if (typeof imagePath === "string") {
      deleteImageHandler();
      ChatbotService.recordRealTimeQuestion({
        sessionNo: memberInfo.sessionNo,
        memberKey: memberInfo.memberKey,
        message: imagePath,
        csUserId: csUserId!,
        isImage: true,
      }).then((questionId) => {
        dispatch(csActions.setCurrentQuestionId({ questionId: questionId }));
      });

      send(
        transformAnsToQues({
          replyId: 0,
          kindId: 0,
          taskType: "",
          kind: imagePath ? "Picture" : "Text",
          message: "",
          pictureUrl: imagePath ? imagePath : t("upload-image-failed"),
          chatRecordType: "Q",
        })
      );
    } else {
      deleteImageHandler();
    }
  }

  return (
    <>
      <div className="theme-background wii-flex wii-items-end">
        <button
          className="wii-w-1/12 wii-pb-4"
          onClick={() => {
            dispatch(TogglesActions.setMenuToggle(!toggles.menuToggle));
          }}
        >
          <i className="icon-menu wii-text-foreground" />
        </button>

        <div className={`wii-mx-1.5 wii-flex wii-items-center wii-pb-4 `}>
          <label htmlFor="third.addArtworkImage" className="">
            <PictureFilled
              className={`wii-h-5 wii-w-5 ${
                isLink
                  ? "wii-cursor-pointer wii-text-foreground hover:wii-opacity-80"
                  : "wii-text-gray-500/80 "
              } `}
            />
          </label>
          <input
            id="third.addArtworkImage"
            type="file"
            accept="image/*"
            multiple
            onChange={handleChange}
            onClick={(e) => {
              const inputValue = e.target as HTMLInputElement;
              inputValue.value = "";
            }}
            className={"wii-hidden"}
            disabled={!isLink}
          />
        </div>

        <div className={`${styles.message} wii-relative`}>
          <div className="wii-absolute wii-bottom-14 wii-z-20 wii-mx-4 wii-flex wii-bg-zinc-200 wii-ring-1 wii-ring-gray-300">
            {mediaUrl !== undefined &&
              mediaUrl.map((i) => (
                <UploadImage
                  key={i}
                  imgUrl={i}
                  deleteImageHandler={deleteImageHandler}
                  loadingStoreState={loadingStoreState}
                />
              ))}
          </div>

          <div
            className={`${
              (showSearchCommand &&
                searchCommandResult &&
                searchCommandResult.length > 0) ||
              showProductList
                ? "wii-opacity-100"
                : "wii-hidden wii-opacity-0"
            } wii-transtion-all wii-absolute wii-bottom-[60px] wii-right-2 wii-z-10 wii-flex wii-h-40 wii-overflow-hidden wii-rounded-2xl wii-border wii-border-gray-100 wii-border-theme-color wii-bg-gray-50 wii-pb-2 wii-text-base wii-duration-300 md:wii-w-full`}
            onMouseEnter={() => {
              blurRef.current = false;
            }}
            onMouseLeave={() => {
              blurRef.current = true;
            }}
            onClick={(e) => {
              setShowSearchCommand(false);
              setShowProductList(false);
              setSelectedTab(undefined);
              inputRef.current?.focus();
            }}
          >
            {showSearchCommand &&
              searchCommandResult &&
              searchCommandResult.length > 0 && (
                <Scrollbar noScrollX={true}>
                  <div className="theme-question wii-sticky wii-top-0 wii-py-1.5 wii-pl-4 wii-text-white">
                    {t("command-list")}
                  </div>
                  <div className={"wii-my-1 wii-h-full wii-px-2"}>
                    {searchCommandResult.map((i, index) => {
                      return (
                        <div
                          className={`wii-h-full wii-w-full wii-cursor-pointer wii-overflow-hidden wii-text-ellipsis wii-whitespace-nowrap wii-px-2 wii-py-1 wii-text-black hover:wii-bg-gray-100 hover:wii-font-medium hover:wii-text-foreground ${
                            selectedTab === index
                              ? "wii-bg-gray-100 wii-font-medium wii-text-foreground"
                              : ""
                          }`}
                          key={i.command + i.name}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUserMessage(i.command);
                            setShowSearchCommand(false);
                            setShowProductList(true);
                            setSelectedTab(undefined);
                            inputRef.current?.focus();
                          }}
                        >
                          {i.command}
                          {i.name}
                        </div>
                      );
                    })}
                  </div>
                </Scrollbar>
              )}
            {showProductList && (
              <Scrollbar noScrollX={true}>
                <div className="theme-question wii-sticky wii-top-0 wii-py-1.5 wii-pl-4 wii-text-white ">
                  {t("search-results")}
                </div>
                <div className={"wii-my-1 wii-h-full wii-px-2"}>
                  {!searchListResult.isFetching &&
                  searchListResult.data &&
                  Array.isArray(searchListResult.data) &&
                  searchListResult.data.length > 0 ? (
                    searchListResult.data.map((i, index) => {
                      return (
                        <div
                          className={`wii-h-full wii-w-full wii-cursor-pointer wii-overflow-hidden wii-text-ellipsis wii-whitespace-nowrap wii-px-2 wii-py-1 wii-text-black hover:wii-bg-gray-100 hover:wii-font-medium hover:wii-text-foreground ${
                            selectedTab === index
                              ? "wii-bg-gray-100 wii-font-medium wii-text-foreground"
                              : ""
                          }`}
                          key={i.artno}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUserMessage("@" + i.name);
                            setShowSearchCommand(false);
                            setShowProductList(false);
                            setSelectedTab(undefined);
                            inputRef.current?.focus();
                          }}
                          ref={(node) => {
                            if (selectedTab === index) {
                              node?.scrollIntoView({
                                behavior: "smooth",
                                block: "end",
                                inline: "nearest",
                              });
                            }
                          }}
                        >
                          {i.artno} - {i.name}
                        </div>
                      );
                    })
                  ) : (
                    <div className="wii-h-full wii-w-full wii-cursor-pointer wii-overflow-hidden wii-text-ellipsis wii-whitespace-nowrap wii-px-2 wii-py-1 wii-text-sm wii-italic wii-text-gray-500">
                      {userMessage.slice(1) !== "" ||
                      (searchListResult.data &&
                        Array.isArray(searchListResult.data) &&
                        searchListResult.data.length === 0)
                        ? t("no-results-found")
                        : t("enter-product-information")}
                    </div>
                  )}
                </div>
              </Scrollbar>
            )}
          </div>
          <div className="wii-flex wii-items-center">
            <input
              autoFocus={true}
              ref={inputRef}
              // disabled={loadingStoreState}
              // type="text"
              placeholder={t("input-question")}
              value={userMessage}
              onFocus={(_) => {
                dispatch(TogglesActions.setMenuToggle(false));

                if (isLink && isConnectedSignalR) {
                  notifyCsUserReadMessage({ message: "" });
                }
              }}
              onChange={async (e) => {
                setUserMessage(e.target.value);

                const isCommand = e.target.value.startsWith("/");

                const isInCommand =
                  searchCommandResult !== undefined &&
                  searchCommandResult.findIndex((i) => i.command === "@") > -1;
                const isProductKey =
                  e.target.value.startsWith("@") && isInCommand;

                // if (isCommand && !isProductKey) {
                // if (!isSuccess) {
                //   triggerSearchCommandResult();
                // }
                // }

                setShowSearchCommand(isCommand);
                setShowProductList(isProductKey);
                setSelectedTab(undefined);
              }}
              onBlur={(e) => {
                if (blurRef.current) {
                  setShowSearchCommand(false);
                  setShowProductList(false);
                  setSelectedTab(undefined);
                }
              }}
              onKeyDown={async (e) => {
                selectCommandHandler(e);
                selectProductHandler(e);

                if (
                  !loadingStoreState &&
                  e.key === "Enter" &&
                  ((!showSearchCommand && !showProductList) ||
                    (showProductList && selectedTab === undefined))
                ) {
                  await sendImageToCs();
                  if (userMessage.trim() !== "") {
                    userSendMessage(userMessage);
                  }
                  setCsRead(false);
                  setShowSearchCommand(false);
                  setShowProductList(false);
                  if (
                    SignalRHub &&
                    (SignalRHub.state === HubConnectionState.Connecting ||
                      SignalRHub.state === HubConnectionState.Connected)
                  ) {
                    idleBotChatTimer();
                  }
                }
              }}
              onMouseMove={() => {}}
              // maxLength={200}
              className={`${styles["message-input"]} ${
                mediaUrl ? "!wii-pb-5 !wii-pt-24" : "!wii-pb-4 !wii-pt-4"
              } ${
                enabledWait !== undefined && enabledWait > 0 && memberInfo.isVIP
                  ? "!wii-pr-24"
                  : enabledWait !== undefined &&
                    enabledWait > 0 &&
                    !memberInfo.isVIP
                  ? "!wii-pr-24"
                  : "!wii-pr-8"
              }`}
            />
          </div>
          <div className={`${styles["icon-group"]}`}>
            {enabledWait !== undefined && enabledWait > 0 && (
              <button
                className={
                  "theme-color wii-h-6 wii-w-6 wii-rounded-full wii-text-center wii-text-white"
                }
                onClick={() => {
                  dispatch(csActions.toggleWaitingQueueModalState(true));
                }}
              >
                {enabledWait}
              </button>
            )}
            {memberInfo.token && memberInfo.isVIP ? (
              <button>
                <img
                  className="wii-w-6"
                  src={VIP_ICON}
                  alt={t("live-chat-support")}
                  onClick={connectCustomerService}
                />
              </button>
            ) : (
              <></>
            )}

            <button
              disabled={loadingStoreState}
              className="disabled:wii-opacity-20"
              onClick={async (_) => {
                await sendImageToCs();

                if (userMessage.trim() !== "") {
                  userSendMessage(userMessage);
                }

                setCsRead(false);
                setShowSearchCommand(false);
                setShowProductList(false);
                if (
                  SignalRHub &&
                  (SignalRHub.state === HubConnectionState.Connecting ||
                    SignalRHub.state === HubConnectionState.Connected)
                ) {
                  idleBotChatTimer();
                }
              }}
            >
              <i className="icon-send wii-text-foreground" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MessageInput);
