import { Subject } from "rxjs";
import type { ResponseMessageContent } from "../models/";
const respMsgContent$ = new Subject<ResponseMessageContent>();

const useMessages = (): [
  (responseMessageContent: ResponseMessageContent) => void,
  () => Subject<ResponseMessageContent>
] => {
  const send = (responseMessageContent: ResponseMessageContent) =>
    responseMessageContent.responseMessage?.length > 0
      ? respMsgContent$.next(responseMessageContent)
      : undefined;
  const receive$ = () => respMsgContent$;

  return [send, receive$];
};

export default useMessages;
