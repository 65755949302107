import { FC, memo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMemberStore, useMessages } from "../../hooks";
import {
  GuideButton,
  MessageKind,
  MissionActionType,
  ResponseMessage,
} from "../../models";
import { ChatbotService } from "../../services";

import style from "./GuideButtonsMessage.module.scss";
import transformAnsToQues from "../../utils/answer-to-question";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useAppDispatch } from "../../store/store";
import { missionActions } from "../../store/mission-slice";
import { TogglesActions } from "../../store/toggles-slice";
import { useSignalRConnect } from "../../services/signalr/context/signalR.context";

const GuideButtonsMessage: FC<{
  guideButtons: GuideButton[] | null;
  taskType: string;
  responseInfo?: ResponseMessage[];
}> = ({ guideButtons, taskType, responseInfo }) => {
  const { memberInfo } = useMemberStore();
  const [send] = useMessages();
  const [guideSendStatus, setGuideSendStatus] = useState(false);
  const dispatch = useAppDispatch();
  const [localStorageValue] = useLocalStorage();
  const { idleBotChatTimer } = useSignalRConnect();

  // NOTE: enshrine product search
  const isProductMission = responseInfo?.find((i) => {
    return i.kind === MessageKind.Text;
  })?.chatContents[0];

  const sendGuidBtnMessage = async (params: GuideButton) => {
    const { id, buttonId, buttonName } = params;

    setGuideSendStatus(true);

    idleBotChatTimer();

    if (!params.isMission) {
      send(
        transformAnsToQues({
          message: buttonName,
          replyId: 0,
          kindId: 0,
          taskType: "",
        })
      );
      const resp = await ChatbotService.sendGuideButtonMessage({
        id,
        buttonId,
        buttonName,
        sessionNo: memberInfo.sessionNo,
        memberKey: memberInfo.memberKey,
        taskType,
      });
      send(resp);
    } else {
      if (!memberInfo.token) {
        dispatch(TogglesActions.setLoginToggle(true));
        return;
      }
      send(
        transformAnsToQues({
          message: buttonName,
          replyId: 0,
          kindId: 0,
          taskType: "",
        })
      );

      if (isProductMission?.actionCode === "MI") {
        // NOTE: 進入任務
        const resp1 = await ChatbotService.getMissionTask({
          missionId: params.id,
          memberKey: memberInfo.memberKey,
          memberId: +localStorageValue.memberKey,
          memberToken: localStorageValue.token,
          sessionNo: memberInfo.sessionNo,
          faqSettingId: params.faqSettingId,
          kindId: 0,
          replyId: 0,
          text: params.buttonName,
          actionType: MissionActionType.Enter,
        });

        if (resp1.isSuccess === false) {
          send({
            responseMessage: resp1.replyData,
            guideButtons: [],
            taskType: "MBot",
            taskLanguageKey: "TW",
          });
          return;
        }

        send({
          responseMessage: resp1.replyData,
          guideButtons: [],
          taskType: "MBot",
          taskLanguageKey: "TW",
        });

        send(
          transformAnsToQues({
            message: isProductMission.pictureMemo,
            replyId: 0,
            kindId: 0,
            taskType: "",
          })
        );

        // NOTE:自動填入商品
        const resp2 = await ChatbotService.getMissionTask({
          missionId: params.id,
          memberKey: memberInfo.memberKey,
          memberId: +localStorageValue.memberKey,
          memberToken: localStorageValue.token,
          sessionNo: memberInfo.sessionNo,
          faqSettingId: params.faqSettingId,
          kindId: 0,
          replyId: 0,
          text: isProductMission.pictureMemo,
          actionType: MissionActionType.Reply,
        });

        if (resp2.isSuccess) {
          dispatch(
            missionActions.setMissionState({
              currentMissionId: params.id,
              isMission: true,
            })
          );
        }
        send({
          responseMessage: resp2.replyData,
          guideButtons: [],
          taskType: "MBot",
          taskLanguageKey: "TW",
        });

        if (resp2.isMission === false) {
          // NOTE: mission ending =>  clearup mission state
          dispatch(missionActions.initialMissionService());
        }
      } else {
        const resp = await ChatbotService.getMissionTask({
          missionId: params.id,
          memberKey: memberInfo.memberKey,
          memberId: +localStorageValue.memberKey,
          memberToken: localStorageValue.token,
          sessionNo: memberInfo.sessionNo,
          faqSettingId: params.faqSettingId,
          kindId: 0,
          replyId: 0,
          text: params.buttonName,
          actionType: MissionActionType.Enter,
        });
        if (resp.isSuccess) {
          dispatch(
            missionActions.setMissionState({
              currentMissionId: params.id,
              isMission: true,
            })
          );
        }
        send({
          responseMessage: resp.replyData,
          guideButtons: [],
          taskType: "MBot",
          taskLanguageKey: "TW",
        });

        if (resp.isMission === false) {
          // NOTE: mission ending =>  clearup mission state
          dispatch(missionActions.initialMissionService());
        }
      }
    }
  };

  return guideSendStatus ? (
    <></>
  ) : (
    <Swiper slidesPerView={"auto"} spaceBetween={5} grabCursor={true}>
      {guideButtons?.map((btn) => (
        <SwiperSlide key={btn.buttonId}>
          <button
            className={style.faq_btn}
            onClick={(_) => sendGuidBtnMessage(btn)}
          >
            {btn.buttonName}
          </button>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(GuideButtonsMessage);
