import axios, { AxiosError } from "axios";
import stores from "../store/store";
import { replyActions } from "../store/reply-slice";
import { WII_MEMBER_STORAGE, WII_SESSION_STORAGE } from "../utils/constants";
import { MemberActions } from "@/store/member-slice";
import i18next from "i18next";
import transformAnsToQues from "@/utils/answer-to-question";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";

axios.defaults.headers.common["Site-Url"] = process.env.REACT_APP_OWN_URL ?? "";

export const rejectLoadingApi: Record<string, 1> = {
  "/api/Chatbot/getRecoverChat": 1,
  "/api/Chatbot/getLoginSettingInfo": 1,
  "/api/Chatbot/getBotUserInfo": 1,
  "/api/Chatbot/getNotifyTimes": 1,
  "/api/Chatbot/getVisitorInfo": 1,
  "/api/Chatbot/getBotWellcomeInfoV2": 1,
  "/api/Chatbot/getChatRecordHistory": 1,
  "/api/Chatbot/recordRealTimeQuestion": 1,
  "/api/Chatbot/recordRealTimeAnswer": 1,
  "/api/Chatbot/getConvertQuestion": 1,
  "/api/Chatbot/getBotSearchCommand": 1,
  "/api/Chatbot/getCommandSearchResult": 1,
  "/api/Chatbot/sendMemberPicture": 1,
  "/api/Chatbot/getCSOnlineStatus": 1,
  "/api/Account/updateMemberLanguage": 1,
  "/api/Chatbot/acceptCsInvite": 1,
};

const TIME = 600;
const wait = () => new Promise((res) => setTimeout(res, TIME));

const errorAssistantI18Message: { [key: string]: string } = {
  "zh-TW": "小幫手忙碌中",
  en: "The chat assistant is currently busy.",
  vi: "The chat assistant is currently busy.",
};

export const interceptor = (store: typeof stores) => {
  axios.interceptors.request.use(
    (config) => {
      const configUrl = config.url?.split("?")[0];

      if (configUrl && !rejectLoadingApi[configUrl]) {
        store.dispatch(replyActions.isLoadingState());
      }

      // TODO: get default language or from localstorage

      const token = localStorage.getItem(WII_MEMBER_STORAGE);
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    function (error) {
      const configUrl = error.config.url?.split("?")[0];
      if (configUrl && rejectLoadingApi[configUrl]) {
        store.dispatch(replyActions.isLoadingState());
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async (response) => {
      response = {
        ...response,
      };

      const configUrl = response.config.url?.split("?")[0];
      if (configUrl && !rejectLoadingApi[configUrl]) {
        await wait().then(() => {
          store.dispatch(replyActions.isFinishedState());
        });
      }

      return response;
    },
    async function (error: AxiosError) {
      const configUrl = error.config.url?.split("?")[0];
      if (configUrl && !rejectLoadingApi[configUrl]) {
        await wait().then(() => {
          store.dispatch(replyActions.isFinishedState());
        });
      }

      if (
        error?.response?.status === 401 &&
        localStorage.getItem(WII_MEMBER_STORAGE)
      ) {
        localStorage.removeItem(WII_SESSION_STORAGE);
        localStorage.removeItem(WII_MEMBER_STORAGE);

        window.WiiApp.connectedState = "unknown";
        window.WiiApp.role = undefined;

        const toggleBotOpen = document.querySelector<HTMLDivElement>(
          "wii-chatbot > div > label"
        );
        toggleBotOpen?.click();
        store.dispatch(MemberActions.logout());

        return Promise.reject(error);
      }

      if (
        configUrl?.includes("Chatbot") &&
        !configUrl?.includes("getConvertQuestion") &&
        (error?.response?.status === 0 ||
          (error?.response?.status && error?.response?.status >= 400))
      ) {
        return {
          data: transformAnsToQues({
            message:
              errorAssistantI18Message[i18next.language] ??
              errorAssistantI18Message["en"],
            replyId: 0,
            kindId: 0,
            taskType: "ChatBot",
            chatRecordType: "A",
          }),
        };
      }

      if (
        configUrl?.includes("Chatbot") &&
        configUrl?.includes("getConvertQuestion") &&
        (error?.response?.status === 0 ||
          (error?.response?.status && error?.response?.status >= 400))
      ) {
        const text = JSON.parse(error.config.data);

        return {
          data: text,
        };
      }

      return Promise.reject(error);
    }
  );
};

const http = {
  get: async <TResponse, TParams>(url: string, params?: TParams) => {
    const httpResponse = await axios.get<TResponse>(url, { params });

    return httpResponse.data;
  },
  post: async <TResponse, TParams>(url: string, data: TParams) => {
    const httpResponse = await axios.post<TResponse>(url, data);

    return httpResponse.data;
  },
};

export default http;
