import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../basehttp";
import { LoginSetting } from "../../../models";

export const settingApi = createApi({
  reducerPath: "setting",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "",
  }),

  endpoints: (builder) => ({
    getLoginSettings: builder.query<LoginSetting[], void>({
      query: () => ({
        url: "/api/Chatbot/getLoginSettingInfo",
        method: "get",
      }),
    }),
    // updateMemberLanguage: builder.mutation<
    //   unknown,
    //   {
    //     memberKey: number;
    //     languageKey: string;
    //   }
    // >({
    //   query: (params) => ({
    //     url: "/api/Account/updateMemberLanguage",
    //     method: "POST",
    //     data: params,
    //   }),
    // }),
  }),
});

export const { useGetLoginSettingsQuery } = settingApi;

// export const { useUpdateMemberLanguageMutation } = settingApi;
