export function debounce(func: any, wait: any, immediate?: boolean) {
  let timeout: NodeJS.Timeout | null = null;

  return function () {
    // @ts-ignore
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}
