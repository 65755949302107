import { MessageKind, ResponseMessageContent, TChatContent } from "../models";

export default function transformAnsToQues(data: {
  kindId: number;
  replyId: number;
  message: string;
  taskType: string;
  kind?: keyof typeof MessageKind;
  pictureUrl?: string;
  chatRecordType?: "A" | "Q";
  messages?: string[];
}): ResponseMessageContent {
  const {
    kindId,
    replyId,
    message,
    kind = "Text",
    taskType,
    chatRecordType = "Q",
    pictureUrl = "",
    messages = [],
  } = data;

  const messagesContent: TChatContent[] =
    messages.length > 0
      ? messages.map((i) => {
          return {
            kindId: kindId,
            isReplyHide: false,
            replyId: replyId,
            text: i,
            sort: 0,
            pictureUrl: pictureUrl,
            pictureMemo: "",
            linkButtonId: 0,
            actionCode: "",
            replies: [],
          };
        })
      : [
          {
            kindId: kindId,
            isReplyHide: false,
            replyId: replyId,
            text: message,
            sort: 0,
            pictureUrl: pictureUrl,
            pictureMemo: "",
            linkButtonId: 0,
            actionCode: "",
            replies: [],
          },
        ];
  const now = new Date();

  const formattedDate = now.toISOString();

  const ansToQuestion: ResponseMessageContent = {
    // TODO: fix datetime
    responseMessage: [
      {
        kind: kind,
        chatRecordType: chatRecordType,
        createdTime: formattedDate,

        chatContents: messagesContent,
      },
    ],
    guideButtons: null,
    taskType: taskType,
    taskLanguageKey: "TW",
  };

  return ansToQuestion;
}
