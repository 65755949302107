export type MemberLoginRequest = {
  askerId: number;
  password: string;
  sessionNo: number;
  userName: string;

  fingerPrint: string;
  browser?: string;
  os?: string;
  platform?: string;
  signalRHubNo: string | number | undefined;
};

export enum LoginStatus {
  Ok,
  Err,
  Cs,
}

export type MemberLoginResponse = {
  isAuth: boolean;
  token: string;
  userInfo: UserInfo;
  isAutoRealTime: boolean;
  defaultLanguage: string;
  status: LoginStatus;
};

export enum UserLoginSite {
  Guest,
  Member,
  Embedding,
}

export type UserInfo = {
  memberKey: number;
  sessionNo: number;
  loginAccount: string;
  accountId: string;
  name: string;
  email: string;
  pictureUrl: string;
  provider: string;
  loginKind: UserLoginSite;
  isVIP: boolean;
};

export type VisitorInfo = {
  askerId: number;
  memberKey: number;
  sessionNo: number;
};

export type MemberState = {
  visitorInfo: VisitorInfo;
};

export enum ActiveStatus {
  Active = 1,

  Idle = 2,

  Disconnect = 3,

  Reconnect = 4,
}

export type MessageContent = {
  message: string;
  memberKey: number;
  sessionNo: number;
  user: string;
  faqId?: number;
};

export type MemberInfo = {
  memberKey: number;
  sessionNo: number;
  token: string;
  askerId: number;
  isAutoRealTime: boolean;
};
