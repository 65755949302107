import { ClockCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type WaitingContentProps = {
  enabledWait: number;
};

const WaitingContent = (props: WaitingContentProps) => {
  const { enabledWait } = props;
  const [t] = useTranslation();
  return (
    <div className="wii-flex wii-flex-col wii-items-center">
      <ClockCircleOutlined className="wii-mb-2 wii-text-2xl" />
      <strong>{t("apology-message")}</strong>
      <div className="wii-mb-4">
        {t("arrange-message")} {enabledWait.toString().padStart(2)}{" "}
        {t("waiting-message")}
      </div>
    </div>
  );
};

export default WaitingContent;
