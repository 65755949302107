export type ActionCodeReplyServiceMappingType = {
  "S1&S2": (data: SaveRecordsRequest) => void;
  S1: (data: SaveRecordsRequest) => void;
  S3: () => void;
  M0: (data: MissionTaskReq) => void;
  M1: (data: MissionTaskReq) => void;
  MI: (data: any) => void;
};

export type ResponseMessageContent = {
  responseMessage: ResponseMessage[];
  guideButtons: GuideButton[] | null;
  taskType: string; // NOTE: taskType === "" means user
  taskLanguageKey: string;
};

export type FaqButtonMessage = MemberSession & {
  buttonId: number;
  buttonName: string;
  id: number;
  taskType: string;
};

export type ClickMessage = MemberSession & {
  chatClickTypeId: number;
  clickId: number;
  demand: string;
  taskType: string;
};

export type ResponseMessage = {
  kind: keyof typeof MessageKind;
  chatRecordType: "A" | "Q";
  createdTime: string;
  chatContents: TChatContent[];
};

export type TChatContent = {
  kindId: number;
  isReplyHide: boolean;
  replyId: number;
  text: string;
  pictureUrl: string;
  sort: number;
  pictureMemo: string;
  pictureSetUrl?: string;
  linkButtonId: number;
  actionCode: keyof ActionCodeReplyServiceMappingType | "" | null;
  replies: TChatReply[];
};

export type TChatReply = {
  kindId: number;
  isReplyHide: boolean;
  replyId: number;
  text: string;
  pictureUrl: string;
  sort: number;
  pictureMemo: string;
  linkButtonId: number;
  actionCode: keyof ActionCodeReplyServiceMappingType | "" | null;
  replies: [];
};

export type GuideButton = {
  id: number;
  faqSettingId: number;
  sort: number;
  buttonId: number;
  buttonName: string;
  isMission: boolean;
};

export enum MessageKind {
  Text = "Text",
  Picture = "Picture",
  // Button = "Button",
  Question = "Question",
  WebUrl = "WebUrl",

  // 分隔線
  Divider = "Divider",
}

// NOTE: NEW response
export type ResponseMessageProp = {
  responseMessage: ResponseMessage;
  taskType: string;
  taskLanguageKey: string;
  datetime?: string | number;
  isReplyHide: boolean;

  // Signalr => csRead
  isCsRead?: boolean;
};

/**
 * 閒置提醒: 服務未回應時，主動詢問用戶
 * 結束提醒: 用戶長時間未使用，主動提醒使否結束
 * 恢復提醒: 服務回應未如預期，如用戶未點擊回覆的按鈕，此時追加詢問。
 */
export type NotifyTimeSetting = {
  // 真人閒置提醒
  lazyCsUserA: number;

  // Bot閒置提醒 // 對話閒置提醒
  lazyBotUserA: number;

  // 第一階段真人結束提醒秒數
  endCsUserQ: number;

  // 第一階段Bot結束提醒秒數
  endBotUserQ: number;

  // 第二階段真人結束提醒秒數
  endCsUserQWait: number;

  // 第二階段Bot結束提醒秒數
  endBotUserQWait: number;

  // 對話恢復提醒秒數30
  chatTaskS: number;
};

export enum NotifyType {
  None = 0,
  BotIdle = 1,
  BotEndSessionStep1 = 2,
  BotEndSessionStep2 = 3,
  BotRecover = 4,
  // TODO 真人客服
}

export type SaveRecordsRequest = MemberSession & {
  replyName: string;
  fullfillType: number;
  demand?: string;
  taskType?: string;
};

export type ChatHistoryRecordsRequest = MemberSession & {
  day: number;
  startDate?: string;
  endDate?: string;
};

export type ChatHistoryRecordsResponse = {
  hadRows?: boolean;
  guideButtons?: GuideButton[];
  responseMessage: HistoryMessages[];
  taskType: string;
};

export type HistoryMessages = {
  historyDate: string;
  chatData: ResponseMessage[];
};

export type MemberSession = {
  sessionNo?: number;
  memberKey: number;
};

export type FaqCategoryOption = {
  id: number;
  name: string;
  description: string;
  sort: number;
};

export type CustomerResponse = {
  chatTimetableId: number;
  csUserId: number;
  csUserKey: number;
  enabledWait: number;
  isLink: boolean;
  resType: RealTimeResponseType;
  token: string;
  wellcome: string;
  chatQuestionId: number;
  headerUrl: string;
};

export type RefreshRealTimeResponse = {
  csUserKey: number;
  enabledWait: number;
  csUserId: number;
  isLink: boolean;
  isFinish: boolean;
  isCancel: boolean;
  wellcome: string;
  headerUrl: string;
};

export enum RealTimeResponseType {
  CsOffline,
  LeaveMessage,
  AskedWait,
  StartConnection,
  InlineDuplicate, // no resType = 4
}

export type recordRealTimeQuestionProp = {
  sessionNo: number;
  memberKey: number;
  message: string;
  csUserId: string;
  isImage?: boolean;
};
export type recordRealTimeAnswerProp = {
  chatQuestionId: number;
  answer: string;
  languageBefore?: string;
  languageAfter?: string;
  isImage: boolean;
  isFaq: boolean;
};

export enum ChatFulfillType {
  CrossClose = 1,
  IdleClose = 2, // 閒置提醒選擇結束
  EndRemindClose = 3, // 結束提醒選擇結束
  EndRemindExpire = 4, // 結束提醒逾時
  SystemClose = 7,
  CsOff = 8,
  RefreshAndClose = 9, // beforeload
}
export type setChatFulfillProps = {
  sessionNo: number;
  fullfillType: ChatFulfillType;
  star: number | null;
  isRealTime: boolean;
};

export type MissionTaskReq = {
  missionId: number;
  memberKey: number;
  memberId: number;
  memberToken: string;
  sessionNo: number;
  kindId: number;
  faqSettingId: number;
  replyId: number;
  text: string;
  actionType: MissionActionType;
};

export enum MissionActionType {
  Enter,
  Reply,
  Leave,
}

export enum MissionActionCodeType {
  Enter = "M1",
  Reply = "None",
  Leave = "M0",
}

export type MissionTaskRes = {
  isSuccess: boolean;
  message: string;
  isMission: boolean;
  replyData: ResponseMessage[];
};

export type BotSearchCommandItem = {
  name: string;
  command: string;
};

export type CommandSearchResultItem = {
  name: string;
  artno: string;
  menuName: string;
};

export enum GetCSOnlineStatusEnum {
  "Offline",
  "Busy",
  "Waitting",
  "Ok",
}

export type GetCSOnlineStatusRes = {
  status: GetCSOnlineStatusEnum;
};
