import { useGetSignalRNoQuery } from "@/services/api/Account/accountApi";
import { useGetBotUserInfoQuery } from "@/services/api/Chatbot/botApi";

export const useGetBotSettingsHubQuery = () => {
  const { data: signalrHubData, isSuccess } = useGetSignalRNoQuery();

  const botData = useGetBotUserInfoQuery(
    signalrHubData?.data?.signalRNo !== undefined
      ? signalrHubData?.data?.signalRNo
      : "",
    {
      skip: !isSuccess,
    }
  );

  return botData;
};
