import React, { FC, memo, Fragment } from "react";

import { Picture } from "@/components";
import {
  TextMessage,
  QuestionMessage,
  PictureMessage,
  GuideButtonsMessage,
} from "@/chatroom/Bot";

import { Divider } from "@/containers";

import {
  MessageKind,
  ResponseMessageProp,
  ResponseMessageContent,
} from "@/models";

import { IMAGE_CHATROOM_DEFAULT } from "@/utils/constants";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";

const MessageItems: FC<{
  chatContents: ResponseMessageContent[];
  isEnd: boolean;
}> = ({ chatContents, isEnd }) => {
  const { data: botSetting } = useGetBotSettingsHubQuery();

  const messageComponents: Record<
    MessageKind,
    FC<ResponseMessageProp & { isEnd: boolean }>
  > = {
    [MessageKind.Text]: TextMessage,
    [MessageKind.Question]: QuestionMessage,
    [MessageKind.Picture]: PictureMessage,
    [MessageKind.Divider]: Divider,
    [MessageKind.WebUrl]: TextMessage,
  };

  if (!botSetting) return <></>;

  return (
    <>
      {chatContents.map((chatContent, cIndex) => {
        const isBot = chatContent.taskType;
        const taskLanguageKey = chatContent.taskLanguageKey;

        return (
          <Fragment key={`chat-content-${cIndex}`}>
            <div className={`wii-flex ${isBot ? "" : "wii-justify-end"}`}>
              {isBot ? (
                <div className="wii-avatar wii-flex-col wii-justify-end">
                  <div className="wii-m-1 wii-w-8 wii-rounded-full">
                    <Picture
                      url={botSetting.headerUrl}
                      alt={IMAGE_CHATROOM_DEFAULT.Bot}
                    />
                    {botSetting.headerUrl}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="message-content">
                {chatContent.responseMessage.map((responseMessage, mIndex) => {
                  const messageComponent =
                    messageComponents[responseMessage.kind] || TextMessage;
                  const taskType = chatContent.taskType;

                  const isReplyHide =
                    responseMessage.chatContents[0].isReplyHide;

                  return React.createElement(messageComponent, {
                    key: `chat-content-${cIndex}.response-message-${mIndex}`,
                    responseMessage,
                    taskType,
                    isReplyHide,
                    isCsRead: false,
                    isEnd,
                    taskLanguageKey,
                  });
                })}
              </div>
            </div>
            {(chatContent.guideButtons?.length || 0) > 0 &&
            chatContents.length - 1 === cIndex ? (
              <div
                className={`wii-transform-all wii-duration-100 wii-ease-in-out ${
                  chatContents.length - 1 === cIndex ? "" : "wii-hidden"
                }`}
              >
                <GuideButtonsMessage
                  guideButtons={chatContent.guideButtons}
                  taskType={chatContent.taskType}
                  responseInfo={chatContent.responseMessage}
                  isEnd={isEnd}
                />
              </div>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(MessageItems);
