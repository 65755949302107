import { FC, memo } from "react";
import parse from "html-react-parser";
import { ChatRoomRole } from "@/models";

type TextMessageProps = {
  chatContents: string | JSX.Element;
  chatRole: ChatRoomRole;
};

const TextMessage: FC<TextMessageProps> = (props) => {
  const { chatContents, chatRole } = props;

  const transformLinks = (text: string) => {
    const urlPattern = /https?:\/\/[^\s]+/g;
    return text
      .split(urlPattern)
      .reduce((acc: any[], part: string, index, arr) => {
        acc.push(chatRole === "Bot" ? parse(part) : part);

        if (index < arr.length - 1) {
          const match = text.match(urlPattern)?.[index];
          acc.push(
            <a
              key={index}
              href={match}
              target="_blank"
              className="wii-underline"
              rel="noopener noreferrer"
            >
              {match}
            </a>
          );
        }
        return acc;
      }, []);
  };

  const checkLink =
    (chatRole === "Bot" || chatRole === "Cs") &&
    typeof chatContents === "string"
      ? transformLinks(chatContents ?? "")
      : chatContents;

  return (
    <>
      <span className="wii-overflow-hidden wii-whitespace-pre-wrap">
        {checkLink}
      </span>
    </>
  );
};

export default memo(TextMessage);
