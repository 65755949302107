import React, { memo, useReducer } from "react";

import {
  SmileFilled,
  QuestionOutlined,
  FireFilled,
  StarFilled,
  RocketFilled,
  GlobalOutlined,
  CheckOutlined,
  WarningFilled,
} from "@ant-design/icons";

import { TogglesActions } from "@/store/toggles-slice";

import styles from "./Menu.module.scss";
import MenuDrawer from "./Drawer/MenuDrawer";
import LanguageContainer from "./Drawer/LanguageContainer";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useChatRoomUser,
  useTypedSelector,
} from "@/store/store";
import RoomModal from "@/chatroom/containers/Menu/Room/RoomModal";
import {
  useChatRoomCsUserInfoQuery,
  useChatRoomListQuery,
  useChatRoomMemberInfoQuery,
} from "@/services/api/ChatRoom/chatRoomApi";
import { ChatroomActions } from "@/store/chatRoom-slice";
import { encryptToSend } from "@/utils/crypto";

import { Image } from "@/components";
import { cn } from "@/utils/style-merge";
import { chatbotApi } from "@/services/api/Chatbot/botApi";
import { Scrollbar } from "react-scrollbars-custom";
import InfoModal from "@/components/Modal/InfoModal";
import { useConversation } from "@/chatroom/chatRoom.context";
import "animate.css";
import InfoContent from "./Info/InfoContent";
import { IMAGE_CHATROOM_DEFAULT } from "@/utils/constants";
import { useGetSignalRNoQuery } from "@/services/api/Account/accountApi";

type MenuButtonDataset =
  | "MEMBER"
  | "FAQ"
  | "HOT"
  | "language-settings"
  | "PLAYGROUND"
  | "NEWS";

type MenuReducerState = {
  name?: MenuButtonDataset;
  open: boolean;
};

type MenuReducerAction =
  | {
      type: MenuButtonDataset;
      value: boolean;
    }
  | {
      type: "INIT";
    };

const menuReducer = (
  state: MenuReducerState,
  action: MenuReducerAction
): MenuReducerState => {
  switch (action.type) {
    case "language-settings": {
      return {
        name: action.type,
        open: action.value,
      };
    }
    case "INIT": {
      return {
        name: undefined,
        open: false,
      };
    }
    default:
      return {
        name: action.type,
        open: action.value,
      };
  }
};

const initialMenuState = {
  name: undefined,
  open: false,
};

const Menu = () => {
  const [state, dispatchReducer] = useReducer(menuReducer, initialMenuState);
  const toggles = useTypedSelector((state) => state.toggles);
  const user = useChatRoomUser();

  const dispatch = useAppDispatch();

  const { data: signalrHubData } = useGetSignalRNoQuery();

  const { data } = useChatRoomListQuery(
    {
      command: encryptToSend({
        content: user.userId ?? "",
        role: user.userRole,
      }),
    },
    {
      skip: user.userId === null,
    }
  );

  const { command } = useConversation();

  const skipRequest = command === "";

  const { data: userInfo } = useChatRoomMemberInfoQuery(
    {
      command: command,
      id: user.userId!,
    },
    { skip: skipRequest || user.userRole !== "Member" }
  );

  const { data: csInfo } = useChatRoomCsUserInfoQuery(
    {
      command: command,
      id: user.userId!,
    },
    { skip: skipRequest || user.userRole !== "Cs" }
  );

  const [t] = useTranslation();

  function menuClickHandler(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();

    const buttonEle = (e.target as Element).closest(
      "[data-menu]"
    ) as HTMLButtonElement;

    if (!buttonEle) return;

    const buttonAction = buttonEle.dataset["menu"] as MenuButtonDataset;
    dispatchReducer({ type: buttonAction, value: true });
  }

  function onClose() {
    dispatchReducer({ type: "INIT" });
  }

  const currentUser: undefined | { name: string; headerUrl: string | null } =
    user.userRole === "Member"
      ? userInfo?.data
      : user.userRole === "Cs"
      ? csInfo?.data
      : undefined;

  return (
    <>
      <div
        className={`wii-modal wii-modal-bottom ${styles.menu}`}
        onClick={(_) => {
          dispatchReducer({ type: "INIT" });
          dispatch(TogglesActions.setMenuToggle(false));
        }}
        data-active={toggles.menuToggle}
      >
        <div
          className="wii-flex wii-flex-wrap wii-justify-center wii-bg-foreground wii-pt-1"
          onClick={menuClickHandler}
        >
          <button
            className={styles["menu-button"]}
            data-menu={"MEMBER"}
            onClick={() => {
              dispatch(TogglesActions.setMenuToggle(false));
            }}
          >
            <SmileFilled className={styles["menu-icon"]} />
            <span>{t("member-center")}</span>
          </button>
          <button
            className={styles["menu-button"]}
            data-menu={"common-questions"}
          >
            <QuestionOutlined className={styles["menu-icon"]} />
            <span>{t("common-questions")}</span>
          </button>
          <button className={styles["menu-button"]} data-menu={"hot-questions"}>
            <FireFilled className={styles["menu-icon"]} />
            <span>{t("hot-questions")}</span>
          </button>
          <button
            className={styles["menu-button"]}
            data-menu={"language-settings"}
          >
            <GlobalOutlined className={styles["menu-icon"]} />
            <span>{t("language-settings")}</span>
          </button>
          <button className={styles["menu-button"]} data-menu={"playground"}>
            <StarFilled className={styles["menu-icon"]} />
            <span>{t("playground")}</span>
          </button>
          <button
            className={styles["menu-button"]}
            data-menu={"NEWS"}
            onClick={() => {
              dispatch(TogglesActions.setMenuToggle(false));
            }}
          >
            <RocketFilled className={styles["menu-icon"]} />
            <span>{t("chat-room")}</span>
          </button>
        </div>
      </div>

      {state.name === "NEWS" && (
        <RoomModal
          show={state.name === "NEWS"}
          title={t("chat-room-list")}
          onClose={onClose}
        >
          <Scrollbar>
            <div className="wii-mb-4 wii-mt-2 wii-flex wii-h-full wii-w-full wii-flex-col wii-overflow-hidden wii-break-words wii-px-2">
              {data?.data?.list?.map((i) => {
                const isCurrentChatRoom = i.roomId === user?.roomId;
                return (
                  <div
                    key={i.id}
                    aria-disabled={i.isDisabled}
                    data-disabled={i.isDisabled}
                    className={cn(
                      "wii-mb-1 wii-flex wii-items-center wii-gap-2 wii-break-words wii-rounded-lg wii-px-4 wii-py-1.5 wii-transition-colors wii-duration-150 wii-ease-in-out hover:wii-cursor-pointer hover:wii-bg-foreground hover:wii-text-answer",
                      isCurrentChatRoom ? "wii-bg-[--theme-background]" : "",

                      "data-[disabled=true]:wii-cursor-default data-[disabled=true]:wii-bg-primary/20 data-[disabled=true]:wii-opacity-60 data-[disabled=true]:hover:wii-text-black"
                    )}
                    onClick={async () => {
                      if (i.isDisabled) return;

                      if (isCurrentChatRoom) return;

                      dispatch(
                        ChatroomActions.setUser({
                          userId: i.id,
                        })
                      );

                      dispatch(
                        ChatroomActions.setChat({
                          roomId: i.roomId,
                        })
                      );

                      dispatch(
                        chatbotApi.util.updateQueryData(
                          "getBotUserInfo",
                          signalrHubData?.data?.signalRNo !== undefined
                            ? `${signalrHubData?.data?.signalRNo}`
                            : "",
                          (sss) => {
                            return {
                              ...sss,
                              color: {
                                primary: i.colorUrl,
                                backend: i.colorBackUrl,
                                demand: i.colorDemandUrl,
                                supply: i.colorSupplyUrl,
                              },
                            };
                          }
                        )
                      );
                    }}
                  >
                    <div className="wii-flex wii-h-8 wii-w-8 wii-items-center wii-rounded-full wii-bg-theme-color">
                      <Image
                        url={i.headerUrl}
                        domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                        className="wii-rounded-full wii-object-scale-down"
                        alt={IMAGE_CHATROOM_DEFAULT.Header}
                      />
                    </div>
                    <div className="wii-flex-col">
                      <div className="wii-flex wii-items-center wii-gap-2">
                        {i.roomName}{" "}
                        {isCurrentChatRoom && !i.isDisabled && (
                          <span className="wii-ml-2">
                            <CheckOutlined
                              className={cn("wii-text-green-500")}
                            />
                          </span>
                        )}
                        {i.isDisabled && (
                          <div className="wii-flex wii-items-center">
                            <WarningFilled
                              className={cn("wii-text-primary/40")}
                            />
                            <span className="wii-text-xs">
                              {t("deactivated")}
                            </span>
                          </div>
                        )}
                      </div>
                      <p className="wii-text-xs wii-font-extralight">
                        {i.description}
                      </p>
                    </div>

                    {i?.unreads !== null && (
                      <div className="wii-ml-auto wii-rounded-xl wii-bg-red-600 wii-px-2 wii-text-sm wii-text-white">
                        {i.unreads}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </Scrollbar>
        </RoomModal>
      )}

      {state.name === "language-settings" && (
        <MenuDrawer
          show={state.name === "language-settings"}
          title={state.name!}
          onClose={onClose}
        >
          {state.name === "language-settings" && (
            <LanguageContainer onClose={onClose} />
          )}
        </MenuDrawer>
      )}

      {state.name === "MEMBER" && user.roomId !== "" && (
        <InfoModal
          show={state.name === "MEMBER" && user.roomId !== ""}
          title={t("chat-room-info")}
          onClose={onClose}
        >
          <div className="animate__animated animate__fadeIn animate__faster">
            <InfoContent
              key={`${currentUser?.headerUrl}`}
              currentUser={currentUser}
              auth={userInfo?.data?.authority}
            />
          </div>
        </InfoModal>
      )}
    </>
  );
};

export default memo(Menu);
