import { FC, PropsWithChildren, memo } from "react";
import parse from "html-react-parser";

import styles from "./MessageState.module.scss";
import { Picture, Timestamp } from "@/components";
import { PushpinFilled } from "@ant-design/icons";
import { cn } from "@/utils/style-merge";
import { ChatRoomListTagChat, ChatRoomRole } from "@/models";
import { useTranslation } from "react-i18next";

type MessageStateProps = {
  time: string;
  read?: boolean;
  chatRecordType: "A" | "Q";
  chatRole: ChatRoomRole;
  tagChat?: ChatRoomListTagChat | null;
  shieldText: string;
  scrollToTag?: (id: string, isShow: boolean) => void;
  edited?: boolean;
  className?: string;
};

const MessageState: FC<PropsWithChildren<MessageStateProps>> = (props) => {
  const {
    time,
    read,
    chatRecordType,
    chatRole,
    tagChat,
    shieldText,
    scrollToTag,
    edited,
    children,
    className,
  } = props;

  const [t] = useTranslation();

  const classNames = chatRecordType === "A" ? "" : styles["user-message"];

  const isOwnConversation = chatRecordType === "Q";

  const prefixName =
    tagChat?.role === "Bot" || tagChat?.role === "Cs"
      ? `[${tagChat?.role}] ${tagChat?.name ?? ""}`
      : tagChat?.name ?? "";

  function getTagContext(tag: ChatRoomListTagChat | null | undefined) {
    if (!tag) return;

    if (tag.isDeleted) {
      return (
        <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-[0.8rem] wii-italic">
          {t("message-be-deleted")}
        </span>
      );
    }

    if (tag.isShield) {
      return (
        <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-sm">
          {shieldText}
        </span>
      );
    }

    switch (tag.chatTextTypeName) {
      case "Delete": {
        return (
          <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-[0.8rem] wii-italic">
            {t("message-be-deleted")}
          </span>
        );
      }
      case "Text": {
        const tagMessage =
          chatRole === "Bot" &&
          typeof tagChat?.chatText === "string" &&
          tagChat?.chatTextTypeName === "Text"
            ? parse(tagChat.chatText.replaceAll("<br/>", "") ?? "")
            : tagChat?.chatText ?? "";

        return (
          <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-sm">
            {tagMessage}
          </span>
        );
      }
      case "Image": {
        return (
          <>
            {!!tag.chatText &&
              (tag.isShow || (!tag.isShow && isOwnConversation)) &&
              typeof tag.chatText === "string" && (
                <span
                  className={cn(
                    "wii-flex",
                    isOwnConversation ? "wii-ml-auto" : "wii-mr-auto"
                  )}
                >
                  <Picture
                    className={cn(
                      "h-pic-msg wii-w-fit wii-rounded-md wii-object-scale-down",
                      isOwnConversation ? "wii-ml-auto" : "wii-mr-auto",
                      tag.isShow ? "" : "wii-bg-answer"
                    )}
                    url={tag.chatText}
                    domain={
                      chatRole === "Bot"
                        ? process.env.REACT_APP_IMAGE_URL
                        : process.env.REACT_APP_CHATROOM_IMAGE_URL
                    }
                  />
                </span>
              )}

            {!tag.isShow && (
              <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-[0.8rem] wii-italic">
                {t("image-under-review")}
              </span>
            )}
          </>
        );
      }
    }
  }

  return (
    <>
      <div className={`wii-flex wii-flex-row ${classNames}`.trimEnd()}>
        <p
          className={cn(
            "message-text wii-z-[1] !wii-flex wii-items-end",
            "group-data-[state=open]:wii-cursor-default group-data-[state=open]:wii-ring-1 group-data-[state=open]:wii-ring-foreground",
            "group-data-[view=ban]:wii-outline group-data-[view=ban]:wii-bg-transparent group-data-[view=ban]:wii-text-[0.8rem] group-data-[view=ban]:wii-italic group-data-[view=ban]:wii-text-theme-color group-data-[view=ban]:!wii-outline-1 group-data-[view=ban]:wii-outline-theme-color"
          )}
        >
          <span className="wii-flex wii-w-full wii-flex-col">
            {tagChat ? (
              <span
                className={cn(
                  "wii-mb-4 wii-w-full wii-items-center wii-justify-start wii-gap-1 wii-truncate wii-rounded-lg  wii-py-1 wii-pl-2 wii-pr-4 wii-text-sm",
                  "wii-bg-gray-200/80 wii-text-foreground",
                  tagChat?.isShow ? "wii-cursor-pointer" : "wii-cursor-default",
                  chatRecordType === "Q" ? "wii-ml-auto" : "wii-mr-auto"
                )}
                onClick={(e) => {
                  if (!scrollToTag) return;

                  e.stopPropagation();
                  scrollToTag(tagChat?.id, tagChat?.isShow);
                }}
              >
                <span
                  className={cn(
                    "wii-flex wii-min-w-0 wii-flex-col wii-items-start wii-border-l-[0.2rem]  wii-border-foreground wii-pl-2"
                  )}
                >
                  <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-sm wii-font-bold">
                    {prefixName}
                  </span>

                  {getTagContext(tagChat)}
                </span>
              </span>
            ) : null}
            <span
              className={cn(
                "wii-flex wii-shrink-0 wii-grow wii-items-start wii-justify-start",
                className
              )}
            >
              {children}
            </span>

            <span
              className={cn(
                "wii-mt-1 wii-flex wii-w-full wii-items-center wii-justify-end wii-gap-2"
              )}
            >
              <span className="">
                <PushpinFilled
                  className={cn(
                    "wii-hidden wii-text-[1rem] group-data-[top=true]:wii-block"
                  )}
                />
              </span>

              {edited && (
                <span className="wii-text-[0.75rem] wii-opacity-80">
                  {t("edited")}
                </span>
              )}
            </span>
          </span>
          {read && (
            <span className="wi-text-end wii-ml-2 wii-flex-shrink-0 wii-text-[0.75rem] wii-opacity-80">
              已讀
            </span>
          )}
        </p>

        <Timestamp
          datetime={time}
          className={"timestamp wii-self-end group-hover:!wii-block"}
        />
      </div>
    </>
  );
};

export default memo(MessageState);
