import React, { Suspense, useEffect, useRef, useState } from "react";
import { useTypedSelector, useAppDispatch } from "@/store/store";
import { useMemberStore, useMessages } from "@/hooks";
import { useSignalRConnect } from "./services/signalr/context/signalR.context";
import { useTranslation } from "react-i18next";
import { useGetLoginSettingsQuery } from "./services/api/Setting/settingApi";
import { useGetCSOnlineStatusQuery } from "./services/api/Chatbot/botApi";

import useCheckEmbeddedAuthQuery from "@/store/hooks/useCheckEmbeddedAuthQuery";

import { Image } from "@/components";
import { ChatContent, Menu } from "@/containers";
import { LoginOptions } from "@/containers/GuestLogin";
import { RateContent, WaitingContent } from "@/containers/CsService";
import { FloatModal, Modal } from "@/components/Modal";
import { Backdrop } from "@/components/ui";

import { csActions } from "@/store/cs-slice";
import { missionActions } from "@/store/mission-slice";
import { TogglesActions } from "@/store/toggles-slice";
import { MemberActions, defaultUserInfo } from "@/store/member-slice";
import { ChatbotService } from "@/services";

import transformAnsToQues from "@/utils/answer-to-question";

import {
  CS_STATUE_MSG,
  LOGIN_METHOD_ICON,
  REATING_STARS_NUMBER,
} from "./utils/constants";

import { ActiveStatus, ChatFulfillType, LoginKind } from "@/models";

import type { setChatFulfillProps, VisitorInfo } from "@/models";

import { HubConnectionState } from "@microsoft/signalr";
import MinusOutlined from "@ant-design/icons/MinusOutlined";

import Ping from "./components/ui/Ping";
import LoginPage from "./containers/LoginPage";
import CategoryMessage from "./containers/CategoryMessage/CategoryMessage";

import FadingBalls from "react-cssfx-loading/lib/FadingBalls";
import ThemeLayout from "./containers/Theme/Layout";
import { useGetBotSettingsHubQuery } from "./store/hooks/useGetBotSettingsHubQuery";

let firstCheck = true;

const App = () => {
  const [leaveToggle, setLeaveToggle] = useState(false);

  const [toggleMedia, setToggleMedia] = useState(false);
  const [stars, setStars] = useState(REATING_STARS_NUMBER);
  const closeElement = useRef<HTMLLabelElement>(null);
  const dispatch = useAppDispatch();
  const { memberInfo, activeStatus } = useMemberStore();
  const [send] = useMessages();
  const { SignalRHub, disconnectSignalR, connectBotSignalR } =
    useSignalRConnect();

  const { data: botSetting } = useGetBotSettingsHubQuery();

  const {
    isLink,
    ratingCsServiceState: showRatingState,
    checkingWaitingState: showWaitingState,
    clickLoginChangeToCsUser,
    enabledWait,
    keepUsingRatingState,
  } = useTypedSelector((state) => state.csService);

  const { isEmbed } = useTypedSelector((state) => state.settings.embedSetting);

  const { data: csStatus } = useGetCSOnlineStatusQuery();
  const { data: loginOptionList } = useGetLoginSettingsQuery();
  const showLoginList = loginOptionList?.filter((i) =>
    isEmbed ? i.kind !== LoginKind.Member : i.kind !== LoginKind.Embed
  );

  const showGuestLoginOption = memberInfo.sessionNo && !memberInfo.token;

  const [t] = useTranslation();

  const { data: authData, isLoading: loadingAuth } =
    useCheckEmbeddedAuthQuery();

  useEffect(() => {
    if (
      !SignalRHub &&
      memberInfo.sessionNo &&
      +memberInfo.sessionNo !== 0 &&
      !isLink &&
      activeStatus !== ActiveStatus.Disconnect &&
      !clickLoginChangeToCsUser
    ) {
      connectBotSignalR(memberInfo.token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    SignalRHub,
    connectBotSignalR,
    memberInfo.token,
    memberInfo.sessionNo,
    isLink,
  ]);

  useEffect(() => {
    if (firstCheck && memberInfo.isAutoRealTime) {
      dispatch(csActions.isLoginToCsUser(true));
    }
    if (firstCheck && memberInfo.token) {
      firstCheck = false;
    }
  }, [dispatch, memberInfo.isAutoRealTime, memberInfo.token]);

  const initialState = () => {
    dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));
    dispatch(csActions.initialCsService());
    dispatch(missionActions.initialMissionService());
    setStars(REATING_STARS_NUMBER);
  };

  const initialClose = () => {
    closeElement.current?.click();
    dispatch(TogglesActions.setShowBotIconToggle(true));
    firstCheck = true;
  };

  const closeChatModal: React.MouseEventHandler<HTMLElement> = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (memberInfo.sessionNo && event.isTrusted) {
      event.preventDefault();
      setLeaveToggle(true);
      return;
    }

    if (!memberInfo.sessionNo) {
      setLeaveToggle(false);
      initialState();
    }
    // if (
    //   SignalRHub &&
    //   (SignalRHub.state === HubConnectionState.Connecting ||
    //     SignalRHub.state === HubConnectionState.Connected)
    // ) {
    //   SignalRHub?.stop();
    // }
  };

  const hiddenChatModal: React.MouseEventHandler<HTMLElement> = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    const toggleBotClose = document.getElementById("chatbot");
    toggleBotClose?.click();
  };

  const handleDisconnect = async () => {
    const parmas: setChatFulfillProps = {
      sessionNo: memberInfo.sessionNo,
      star: null,
      isRealTime: false,
      fullfillType: ChatFulfillType.CrossClose,
    };

    if (isLink) {
      dispatch(csActions.toggleRateCsModalState(true));

      return;
    }

    if (!isLink) {
      ChatbotService.disconnect(parmas).then(async () => {
        await disconnectSignalR();
        dispatch(MemberActions.logout());

        setLeaveToggle(false);
        initialState();
        initialClose();
      });
    }
  };

  const disconnectAndRating = async (isRate: boolean) => {
    const parmas: setChatFulfillProps = {
      sessionNo: memberInfo.sessionNo,
      star: isRate ? stars : null,
      isRealTime: true,
      fullfillType: ChatFulfillType.CrossClose,
    };

    await ChatbotService.disconnect(parmas);
    await disconnectSignalR();
    initialState();
    initialClose();

    dispatch(MemberActions.logout());
  };

  const keepUsingButRating = async (isRate: boolean) => {
    const parmas: setChatFulfillProps = {
      sessionNo: memberInfo.sessionNo,
      star: isRate ? stars : null,
      isRealTime: true,
      fullfillType: ChatFulfillType.CrossClose,
    };

    await ChatbotService.disconnect(parmas);

    if (isEmbed) {
      const newSession = await ChatbotService.getNewChatSession({
        loginKind: 2,
      });
      dispatch(MemberActions.setNewSession(newSession));
    } else {
      const newSession = await ChatbotService.getNewChatSession({
        loginKind: 1,
      });
      dispatch(MemberActions.setNewSession(newSession));
    }
    dispatch(csActions.toggleKeepUsingRateModalState(false));

    await disconnectSignalR();
  };

  const reconnect = () => {
    initialState();
    ChatbotService.getVisitorInfo().then(
      ({ askerId, sessionNo, memberKey }: VisitorInfo) => {
        dispatch(
          MemberActions.setUserInfo({
            ...defaultUserInfo,
            askerId,
            sessionNo,
            memberKey,
            isAutoRealTime: false,
          })
        );
        dispatch(MemberActions.setActiveStatus(ActiveStatus.Reconnect));
      }
    );
  };

  const cancelWaitingQueue = async () => {
    await ChatbotService.cancelRealTimeQueue(memberInfo.sessionNo)
      .then((_) => {})
      .finally(() => {
        dispatch(csActions.initialCsService());
        send(
          transformAnsToQues({
            replyId: 0,
            kindId: 0,
            taskType: "cs",
            kind: "Divider",
            message: t("cancel-customer-service-queue"),
            chatRecordType: "A",
          })
        );
      });
    await disconnectSignalR();
  };

  return (
    <>
      <ThemeLayout>
        <div className="wii-flex wii-flex-grow wii-items-center">
          <div className="wii-w-8 wii-self-center wii-rounded-full">
            <Image url={botSetting?.headerUrl} />
          </div>
          <div className="wii-flex wii-flex-col wii-pl-2">
            <h2 className="wii-w-fit wii-text-left">{botSetting?.name}</h2>
            <div className="wii-flex wii-w-fit wii-animate-pureFade wii-items-center wii-gap-x-1 wii-transition-all wii-duration-300 wii-ease-in-out">
              {memberInfo.sessionNo && csStatus?.status !== undefined ? (
                <>
                  <Ping status={csStatus.status} />

                  <span
                    className="wii-animate-pureFade wii-text-[12px]"
                    key={csStatus?.status ?? "0"}
                  >
                    {t(CS_STATUE_MSG[csStatus?.status])}
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {showGuestLoginOption ? (
          <div
            className="wii-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setToggleMedia((toggle) => !toggle);
            }}
          >
            <img
              src={LOGIN_METHOD_ICON}
              alt="social media options"
              title={t("other-platforms")}
              className="wii-mr-2 wii-h-8 wii-w-8  wii-transition-all wii-duration-150 active:wii-scale-110"
            />
          </div>
        ) : null}

        <label
          id="wii-modal-hidden"
          htmlFor="chatbot"
          className="wii-z-10 wii-mr-2 wii-flex wii-cursor-pointer wii-items-center wii-p-1 hover:wii-scale-110"
          onClick={hiddenChatModal}
        >
          <MinusOutlined />
        </label>

        <label
          id="wii-modal-close"
          ref={closeElement}
          htmlFor="chatbot"
          className="wii-z-10 wii-mr-1 wii-cursor-pointer wii-p-1 hover:wii-scale-110"
          onClick={closeChatModal}
        >
          ✕
        </label>

        {showGuestLoginOption && toggleMedia ? (
          <ul
            className={
              "theme-background wii-absolute wii-bottom-0 wii-left-0 wii-z-10 wii-mx-auto wii-flex wii-w-full wii-translate-y-full wii-cursor-pointer wii-items-center wii-justify-center wii-gap-2 wii-py-1 wii-shadow-lg"
            }
          >
            {showLoginList?.map((s) => (
              <LoginOptions key={s.name} loginMethod={s} />
            ))}
          </ul>
        ) : (
          <></>
        )}
      </ThemeLayout>

      {!loadingAuth && authData && authData.isAuth ? (
        <>
          <LoginPage />

          {/* // TODO: Content should be split into cs and bot */}
          {memberInfo.sessionNo ? <ChatContent /> : null}
        </>
      ) : (
        <div className="wii-flex wii-items-center">
          <Backdrop>
            <Backdrop.Item />
            <Backdrop.Content>
              <FadingBalls color={"gray"} width="1rem" height="1rem" />
            </Backdrop.Content>
          </Backdrop>
        </div>
      )}

      <Suspense fallback={false}>
        <Menu />
      </Suspense>

      {leaveToggle && (
        <Modal
          show={leaveToggle}
          title={t("close")}
          content={t("close-confirm")}
          confirmText={t("close-session")}
          cancelText={t("cancel")}
          confirmEvent={handleDisconnect}
          confirmBtnClass={`wii-bg-[#d30744] hover:wii-bg-[#b4073bfa] wii-text-white`}
          cancelEvent={(_) => setLeaveToggle(false)}
        />
      )}
      {activeStatus === ActiveStatus.Disconnect && (
        <Modal
          show={activeStatus === ActiveStatus.Disconnect}
          title={t("prompt")}
          content={t("timeout-message")}
          confirmText={t("reconnect")}
          cancelText={t("cancel")}
          confirmBtnClass="theme-color wii-text-white"
          confirmEvent={reconnect}
          cancelEvent={(_) => {
            setLeaveToggle(false);
            initialClose();
            initialState();
            dispatch(MemberActions.setUserInfo(defaultUserInfo));
          }}
        />
      )}

      <CategoryMessage />

      {showWaitingState && (
        <FloatModal
          show={showWaitingState}
          confirmText={t("waiting-in-line")}
          cancelText={t("cancel-queue")}
          confirmBtnClass="wii-bg-foreground hover:wii-bg-foreground hover:wii-opacity-80 wii-text-white"
          cancelBtnClass="wii-bg-[#949091d5] hover:wii-bg-[#949091f8]  wii-text-white"
          confirmEvent={() => {
            dispatch(csActions.toggleWaitingQueueModalState(false));
          }}
          cancelEvent={cancelWaitingQueue}
        >
          <WaitingContent enabledWait={enabledWait} />
        </FloatModal>
      )}
      {showRatingState && (
        <FloatModal
          show={showRatingState}
          confirmText={t("rating")}
          cancelText={t("close")}
          confirmBtnClass="wii-bg-foreground hover:wii-opacity-80 wii-text-white"
          cancelBtnClass="wii-bg-[#949091d5] hover:wii-bg-[#949091f8]  wii-text-white"
          confirmEvent={async () => {
            await disconnectAndRating(true);
          }}
          cancelEvent={async () => {
            await disconnectAndRating(false);
          }}
        >
          <RateContent
            amount={REATING_STARS_NUMBER}
            defaultStar={REATING_STARS_NUMBER}
            activateStar={stars}
            onChange={setStars}
          />
        </FloatModal>
      )}
      {keepUsingRatingState && (
        <FloatModal
          show={keepUsingRatingState}
          confirmText={t("rating")}
          cancelText={t("close")}
          confirmBtnClass="wii-bg-foreground hover:wii-opacity-80 wii-text-white"
          cancelBtnClass="wii-bg-[#949091d5] hover:wii-bg-[#949091f8]  wii-text-white"
          confirmEvent={async () => {
            await keepUsingButRating(true);
          }}
          cancelEvent={async () => {
            await keepUsingButRating(false);
          }}
        >
          <RateContent
            amount={REATING_STARS_NUMBER}
            defaultStar={REATING_STARS_NUMBER}
            activateStar={stars}
            onChange={setStars}
          />
        </FloatModal>
      )}
    </>
  );
};

export default App;
