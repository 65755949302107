import { createSlice } from "@reduxjs/toolkit";

const initialState: { isLoading: boolean } = {
  isLoading: false,
};

const replySlice = createSlice({
  name: "replyState",
  initialState,
  reducers: {
    isLoadingState: (state) => {
      state.isLoading = true;
    },
    isFinishedState: (state) => {
      state.isLoading = false;
    },
  },
});

export const replyActions = replySlice.actions;

export default replySlice.reducer;
