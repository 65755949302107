import { cn } from "@/utils/style-merge";
import { PropsWithChildren } from "react";

type IconWrapProps = {
  className?: string;
};
const IconWrap = (props: PropsWithChildren<IconWrapProps>) => {
  const { className = "", children } = props;
  return (
    <div
      className={cn(
        "wii-flex-items-center wii-flex group-data-[highlighted]:wii-text-gray-100",
        className
      )}
    >
      {children}
    </div>
  );
};

export default IconWrap;
