import { useConversation } from "@/chatroom/chatRoom.context";
import { Picture } from "@/components";
import { CustomStatus } from "@/models/format";
import {
  useChatRoomHeaderUrlQuery,
  useSetChatRoomMemberInfoMutation,
} from "@/services/api/ChatRoom/chatRoomApi";
import { useChatRoomUser } from "@/store/store";
import { ALLOW_IMAGE_TYPE } from "@/utils/constants";
import { cn } from "@/utils/style-merge";
import { PictureFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

type InfoEditorProps = {
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  auth: {
    isNoTalking: boolean;
    isUploadImage: boolean;
    isHeadImage: boolean;
  };
  viewMode?: "edit" | "view";
  forms: UseFormReturn<FormProps, any, undefined>;
  watchHeaderUrl: string;
};

type FormProps = {
  headerUrl: string;
  name: string;
};

const InfoEdit = (props: InfoEditorProps) => {
  const { setEditMode, auth, forms, watchHeaderUrl } = props;

  const [mediaUrl, setMediaUrl] = useState<string[] | undefined>(undefined);

  const [triggerEdit, state] = useSetChatRoomMemberInfoMutation();

  const [t] = useTranslation();

  const user = useChatRoomUser();
  const { command } = useConversation();

  const skipRequest = command === "";

  const commandArg = {
    command,
  };

  const skipArg = {
    skip: skipRequest || user.roomId === "",
  };

  const { data: chatRoomHeader } = useChatRoomHeaderUrlQuery(
    commandArg,
    skipArg
  );

  function onSubmit(value: FormProps) {
    triggerEdit({
      command: command,
      displayName: value.name,
      headerUrl: mediaUrl ? undefined : value.headerUrl,
      headerBase64: mediaUrl ? mediaUrl[0] : undefined,
    })
      .unwrap()
      .then((result) => {
        if (result.status === CustomStatus.Err) {
          forms.reset();
        }
      })
      .catch(() => {
        forms.reset();
      });

    setEditMode(false);
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const MASUPLODASIZE = 1;

    if (files) {
      const totalFilesSize = files.length;
      if (totalFilesSize > MASUPLODASIZE) {
        event.target.value = ""; // 清空已選擇的檔案
        return; // 阻止上傳
      }

      const awaitBuff: Promise<string>[] = [];
      Array.from(files).forEach((file) => {
        const fileName = file.name;
        const fileEndType = fileName.split(".").at(-1);

        if (
          fileEndType &&
          ALLOW_IMAGE_TYPE.includes(fileEndType.toLowerCase())
        ) {
          awaitBuff.push(
            new Promise<string>((resolve) => {
              const reader = new FileReader();

              reader.onload = (event) => {
                if (event.target?.result) {
                  resolve(event.target?.result as string);
                }
              };
              reader.readAsDataURL(file);
              let blobUrl = URL.createObjectURL(file);
              forms.setValue("headerUrl", blobUrl);
            })
          );
        }
      });

      const media = await Promise.all(awaitBuff);
      if (media.length === 0) {
        setMediaUrl(undefined);
      } else {
        setMediaUrl(media);
      }
    }
  };

  return (
    <>
      <div className="animate__animated animate__fadeInLeft animate__faster wii-mx-4 wii-mb-4 wii-mt-2 wii-flex wii-h-full wii-gap-2">
        <div
          className={cn(
            "wii-group wii-flex wii-h-20 wii-w-20 wii-flex-shrink-0 wii-items-center wii-justify-center wii-rounded-full wii-bg-primary",
            user.userRole !== "Cs"
              ? "wii-cursor-default"
              : "wii-cursor-pointer wii-transition-colors wii-duration-100 wii-ease-in-out hover:wii-bg-primary/90"
          )}
        >
          {watchHeaderUrl && (
            <>
              <label
                htmlFor="upload-image-cs"
                className={cn(
                  "wii-relative",
                  user.userRole !== "Cs"
                    ? "wii-cursor-default"
                    : "wii-cursor-pointer"
                )}
              >
                <Picture
                  key={watchHeaderUrl}
                  url={watchHeaderUrl}
                  domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                  className="wii-h-16 wii-w-16 wii-rounded-full wii-object-scale-down"
                  alt={user.userRole ? user.userRole : ""}
                />
                <div
                  className={cn(
                    "wii-absolute -wii-bottom-2 -wii-right-2 wii-rounded-full",
                    user.userRole !== "Cs" ? "wii-hidden" : "wii-block"
                  )}
                >
                  <PictureFilled
                    width={40}
                    height={40}
                    className="wii-rounded-full wii-bg-white/60 wii-p-1 group-hover:wii-bg-white/90"
                  />
                </div>
              </label>

              <input
                id="upload-image-cs"
                type="file"
                accept="image/*"
                multiple
                onChange={handleChange}
                onClick={(e) => {
                  const inputValue = e.target as HTMLInputElement;
                  inputValue.value = "";
                }}
                className={"wii-hidden"}
                disabled={user.userRole !== "Cs"}
              />
            </>
          )}
        </div>
        <div className="wii-flex-shirnk-0 wii-flex wii-min-h-[120px] wii-w-full wii-flex-grow wii-flex-col wii-gap-1 wii-text-sm wii-font-bold">
          <label htmlFor="" className="wii-text-sm">
            {t("nickname")}:{" "}
          </label>

          <input
            className="wii-input wii-input-bordered wii-input-xs wii-max-w-xs wii-shrink-0"
            {...forms.register("name", {
              required: "required",
            })}
          />

          {forms.formState.errors ? (
            <span className="wii-text-xs wii-text-red-500">
              {forms.formState.errors["name"]?.message}
            </span>
          ) : null}

          <div className="wii-flex wii-w-full wii-flex-wrap wii-items-center wii-gap-1">
            {auth.isHeadImage || user.userRole === "Cs"
              ? chatRoomHeader?.data.map((i, idx) => {
                  return (
                    <div
                      key={i.headerUrl}
                      className={cn(
                        "wii-h-8 wii-w-8 wii-shrink-0 wii-rounded-full",
                        "data-[focus=true]:wii-outline data-[focus=true]:wii-outline-[1.5px] data-[focus=true]:wii-outline-foreground"
                      )}
                      data-focus={i.headerUrl === watchHeaderUrl}
                      onClick={() => {
                        forms.setValue("headerUrl", i.headerUrl);
                      }}
                    >
                      <Picture
                        url={i?.headerUrl ?? ""}
                        domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                        className="wii-h-8 wii-w-8 wii-rounded-full wii-object-scale-down"
                      />
                    </div>
                  );
                })
              : null}
          </div>
          <div className="wii-mt-auto wii-flex wii-gap-2 wii-self-end">
            <button
              className="wii-flex-shrink-0 wii-cursor-pointer wii-self-end wii-text-sm wii-text-black hover:wii-opacity-90 disabled:wii-text-gray-300"
              onClick={() => {
                setEditMode(false);
                forms.reset();
              }}
              disabled={state.isLoading}
            >
              {t("cancel")}
            </button>
            <button
              className="wii-flex-shrink-0 wii-cursor-pointer wii-self-end wii-text-sm wii-text-foreground hover:wii-opacity-90 disabled:wii-text-gray-300"
              onClick={forms.handleSubmit(onSubmit)}
              disabled={state.isLoading}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoEdit;
