import { GetCSOnlineStatusEnum } from "../../models";

type PingProps = {
  status: GetCSOnlineStatusEnum;
};
const Ping = ({ status }: PingProps) => {
  function statusStyle() {
    switch (status) {
      case GetCSOnlineStatusEnum.Offline: {
        return { background: "gray", animation: "none" };
      }

      case GetCSOnlineStatusEnum.Busy: {
        return { background: "red", animation: "none" };
      }

      case GetCSOnlineStatusEnum.Waitting: {
        return { background: "red", animation: "none" };
      }

      case GetCSOnlineStatusEnum.Ok: {
        return { background: "#70dd30" };
      }
      default: {
        return { background: "lightgray", animation: "none" };
      }
    }
  }

  return (
    <span className="wii-relative wii-flex wii-h-2 wii-w-2 wii-animate-pureFade">
      <span
        className="wii-absolute wii-inline-flex wii-h-full wii-w-full wii-animate-ping wii-rounded-full wii-opacity-75"
        style={{ ...statusStyle() }}
      ></span>
      <span
        className="wii-relative wii-inline-flex wii-h-2 wii-w-2 wii-animate-pureFade wii-rounded-full"
        style={{ ...statusStyle() }}
      ></span>
    </span>
  );
};

export default Ping;
