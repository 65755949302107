import { cn } from "@/utils/style-merge";

type DateAlertProps = {
  className?: string;
  date: string;
};
const DateAlert = (props: DateAlertProps) => {
  const { className, date } = props;
  return (
    <time
      className={cn(
        "wii-z-[1] wii-mb-2.5 wii-mt-4 wii-w-fit wii-self-center wii-rounded-lg wii-bg-primary/10 wii-px-8 wii-py-0.5 wii-text-sm wii-font-semibold wii-text-primary",
        className
      )}
    >
      {date}
    </time>
  );
};

export default DateAlert;
