export function hexToRGB(hex: string | undefined) {
  // Remove the '#' character if present

  if (hex === undefined) return "";

  if (!hex?.startsWith("#")) return hex;

  let hexSplit = hex.replace("#", "");

  // Extract the red, green, and blue components
  const r = parseInt(hexSplit.substring(0, 2), 16);
  const g = parseInt(hexSplit.substring(2, 4), 16);
  const b = parseInt(hexSplit.substring(4, 6), 16);

  // Return the RGB color code as a string
  return `${r} ${g} ${b}`;
}
