import Bowser from "bowser";
import CryptoJS from "crypto-js";

export function getBrowserFingerprint() {
  const userAgent = navigator.userAgent;
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const colorDepth = window.screen.colorDepth;
  const timezoneOffset = new Date().getTimezoneOffset();
  const language = navigator.language;
  const languages = navigator.languages.join(",");
  const hardwareConcurrency = navigator.hardwareConcurrency || "unknown";
  const deviceMemory = (navigator as any).deviceMemory || "unknown";

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (ctx === null) return "";

  ctx.textBaseline = "top";
  ctx.font = "16px Arial";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125, 1, 62, 20);
  ctx.fillStyle = "#069";
  ctx.fillText("Browser Fingerprint", 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
  ctx.fillText("Browser Fingerprint", 4, 17);
  const canvasFingerprint = canvas.toDataURL();

  const fingerprintSource = `${userAgent}~${screenResolution}~${colorDepth}~${timezoneOffset}~${language}~${languages}~${hardwareConcurrency}~${deviceMemory}~${canvasFingerprint}`;

  const hash = CryptoJS.SHA256(fingerprintSource).toString();

  return hash;
}

export function getBrowserInfo() {
  const { browser, os, platform } = Bowser.parse(window.navigator.userAgent);

  return {
    browser: browser.name,
    os: os.name,
    platform: platform.type,
  };
}
