import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@/services/api/basehttp";
import {
  ChatRoomAnnouncementListRes,
  ChatRoomCsUserDetailRes,
  ChatRoomHeadUrlItemRes,
  ChatRoomIsShieldReq,
  ChatRoomIsTopReq,
  ChatRoomItem,
  ChatRoomListModule,
  ChatRoomMemberDetailRes,
  ChatRoomMessageReq,
  ChatRoomMessageRes,
  CsChatRoomReq,
  MemberChatRoomItem,
  SendChatRoomImageReq,
  SendChatRoomImageRes,
  SendChatRoomTextReq,
  SendChatRoomTextRes,
  UserAmount,
} from "@/models";
import { FormatResponse } from "@/models/format";

import { handleEncryptParams } from "@/services/utils/transformRes";

export const chatRoomApi = createApi({
  reducerPath: "chatRoom",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL || "",
  }),
  tagTypes: [
    "/api/ChatRoom/ChatRoomRecordList",
    "/api/ChatRoom/ChatRoomRecordTopList",
    "/api/ChatRoom/ChatRoomMemberDetail",
    "/api/ChatRoom/ChatRoomCsUserDetail",
    "/api/ChatRoom/ChatRoomList",
    "/api/ChatRoom/ChatRoomUsers",
    "/api/ChatRoom/ChatRoomMemberInfo",
    "/api/ChatRoom/ChatRoomCsUserInfo",
    "/api/ChatRoom/MemberChatRoomList",
    "/api/ChatRoom/ChatRoomAnnouncementList",
  ],
  endpoints: (builder) => ({
    MemberChatRoomList: builder.query<
      FormatResponse<MemberChatRoomItem[]>,
      { memberKey: number }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/MemberChatRoomList",
        method: "get",
        params: handleEncryptParams(params, "GET"),
      }),
      providesTags: ["/api/ChatRoom/MemberChatRoomList"],
    }),

    ChatRoomList: builder.query<
      FormatResponse<{ list: ChatRoomItem[] }>,
      { command: string } | void
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomList",
        method: "get",
        params: params,
      }),
      providesTags: (result, error, arg) => [
        { type: "/api/ChatRoom/ChatRoomList", id: arg?.command },
      ],
    }),

    ChatRoomRecordList: builder.query<
      FormatResponse<ChatRoomMessageRes>,
      ChatRoomMessageReq
    >({
      query: (params) => ({
        url: `/api/ChatRoom/ChatRoomRecordList`,
        method: "get",
        params: params,
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, { command, id }) => [
        { type: "/api/ChatRoom/ChatRoomRecordList", id: id },
      ],
    }),
    SendChatRoomText: builder.mutation<
      FormatResponse<SendChatRoomTextRes>,
      SendChatRoomTextReq
    >({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/SendChatRoomText",
          method: "POST",
          data: params,
        };
      },
    }),
    SendChatRoomPicture: builder.mutation<
      FormatResponse<SendChatRoomImageRes>,
      SendChatRoomImageReq
    >({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/SendChatRoomPicture",
          method: "POST",
          data: params,
        };
      },
    }),

    ChatRoomIsNoTalking: builder.mutation<
      FormatResponse<any>,
      CsChatRoomReq & { memo: string }
    >({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/ChatRoomIsNoTalking",
          method: "POST",
          data: params,
        };
      },
    }),

    ChatRoomIsKickOut: builder.mutation<
      FormatResponse<any>,
      CsChatRoomReq & { memo: string }
    >({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/ChatRoomIsKickOut",
          method: "POST",
          data: params,
        };
      },
    }),

    ChatRoomIsUploadImage: builder.mutation<FormatResponse<any>, CsChatRoomReq>(
      {
        query: ({ ...params }) => {
          return {
            url: "/api/ChatRoom/ChatRoomIsUploadImage",
            method: "POST",
            data: params,
          };
        },
      }
    ),

    ChatRoomIsHeadImage: builder.mutation<FormatResponse<any>, CsChatRoomReq>({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/ChatRoomIsHeadImage",
          method: "POST",
          data: params,
        };
      },
    }),

    ChatRoomIsTop: builder.mutation<FormatResponse<any>, ChatRoomIsTopReq>({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/ChatRoomIsTop",
          method: "POST",
          data: params,
        };
      },
      // invalidatesTags: ["/api/ChatRoom/ChatRoomRecordTopList"],
    }),

    ChatRoomIsShield: builder.mutation<
      FormatResponse<any>,
      ChatRoomIsShieldReq
    >({
      query: ({ ...params }) => {
        return {
          url: "/api/ChatRoom/ChatRoomIsShield",
          method: "POST",
          data: params,
        };
      },
    }),

    ChatRoomRecordTopList: builder.query<
      FormatResponse<ChatRoomMessageRes>,
      { command: string } | void
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomRecordTopList",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomRecordTopList"],
    }),

    ChatRoomAnnouncementList: builder.query<
      FormatResponse<{ list: ChatRoomAnnouncementListRes[] }>,
      { command: string } | void
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomAnnouncementList",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomAnnouncementList"],
    }),

    JoinChatRoom: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/JoinChatRoom",
        method: "post",
        data: params,
      }),
    }),

    LoadChatRoom: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/LoadChatRoom",
        method: "post",
        data: params,
      }),
    }),

    CsLeaveChatRoom: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/CsLeaveChatRoom",
        method: "post",
        data: params,
      }),
    }),

    ChatRoomUsers: builder.query<
      FormatResponse<UserAmount[]>,
      { command: string }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomUsers",
        method: "get",
        params: params,
      }),
      providesTags: (result, error, args) => [
        { type: "/api/ChatRoom/ChatRoomUsers", id: args.command },
      ],
    }),

    ChatRoomMemberDetail: builder.query<
      FormatResponse<ChatRoomMemberDetailRes[]>,
      { command: string }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomMemberDetail",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomMemberDetail"],
    }),

    // TODO:
    ChatRoomCsUserDetail: builder.query<
      FormatResponse<ChatRoomCsUserDetailRes[]>,
      { command: string }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomCsUserDetail",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomCsUserDetail"],
    }),

    ChatRoomMemberInfo: builder.query<
      FormatResponse<ChatRoomMemberDetailRes>,
      { command: string; id: string }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomMemberInfo",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomMemberInfo"],
    }),

    // TODO:
    ChatRoomCsUserInfo: builder.query<
      FormatResponse<ChatRoomCsUserDetailRes>,
      { command: string; id: string }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomCsUserInfo",
        method: "get",
        params: params,
      }),
      providesTags: ["/api/ChatRoom/ChatRoomCsUserInfo"],
    }),

    ChatRoomLinkButtonAnswer: builder.mutation<
      FormatResponse<{ module: ChatRoomListModule; id: string }>,
      {
        command: string;
        roomSessionNo: string;
        linkButtonId: number;
        taskType?: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomLinkButtonAnswer",
        method: "get",
        params: params,
      }),
    }),

    ChatRoomQuestionAnswer: builder.mutation<
      FormatResponse<{ module: ChatRoomListModule; id: string }>,
      {
        command: string;
        roomSessionNo: string;
        replyId: number;
        taskType?: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomQuestionAnswer",
        method: "get",
        params: params,
      }),
    }),
    ChatRoomFaqAnswer: builder.mutation<
      FormatResponse<{ module: ChatRoomListModule; id: string }>,
      {
        command: string;
        roomSessionNo: string;
        faqId: number;
        faqMessage: string;
        LanguageKey: string;
        taskType?: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomFaqAnswer",
        method: "get",
        params: params,
      }),
    }),

    ChatRoomClickAnswer: builder.mutation<
      FormatResponse<{ module: ChatRoomListModule; id: string }>,
      {
        command: string;
        clickId: number;
        taskType?: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomClickAnswer",
        method: "get",
        params: params,
      }),
    }),

    ChatRoomMessageRead: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
        id: string | undefined;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomMessageRead",
        method: "post",
        data: params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "/api/ChatRoom/ChatRoomList", id: arg?.command },
      ],
    }),

    ChatRoomHeaderUrl: builder.query<
      FormatResponse<ChatRoomHeadUrlItemRes[]>,
      {
        command: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomHeaderUrl",
        method: "get",
        params: params,
      }),
    }),
    // TODO:
    SetChatRoomMemberInfo: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
        displayName: string;
        headerUrl?: string;
        headerBase64?: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/SetChatRoomMemberInfo",
        method: "post",
        data: params,
      }),
      invalidatesTags: [
        "/api/ChatRoom/ChatRoomCsUserDetail",
        "/api/ChatRoom/ChatRoomMemberDetail",
        "/api/ChatRoom/ChatRoomMemberInfo",
        "/api/ChatRoom/ChatRoomCsUserInfo",
      ],
    }),
    ChatRoomNotificationRead: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
        id: number;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomNotificationRead",
        method: "post",
        data: params,
      }),
    }),

    ChatRoomIsDelete: builder.mutation<
      FormatResponse<unknown>,
      {
        command: string;
        id: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomIsDelete",
        method: "post",
        data: params,
      }),
    }),

    ChatRoomIsReplaceText: builder.mutation<
      FormatResponse<{ text: string }>,
      {
        command: string;
        id: string;
        text: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomIsReplaceText",
        method: "post",
        data: params,
      }),
    }),

    ChatRoomIsReplacePicture: builder.mutation<
      FormatResponse<{ text: string }>,
      {
        command: string;
        id: string;
        base64String: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/ChatRoomIsReplacePicture",
        method: "post",
        data: params,
      }),
    }),
    DetectChatRoom: builder.mutation<
      FormatResponse<{ isPass: boolean; reason: string }>,
      {
        command: string;
      }
    >({
      query: (params) => ({
        url: "/api/ChatRoom/DetectChatRoom",
        method: "post",
        data: params,
      }),
    }),
  }),
});

export const {
  useMemberChatRoomListQuery,
  useChatRoomListQuery,
  useChatRoomRecordListQuery,
  useLazyChatRoomRecordListQuery,
  useChatRoomRecordTopListQuery,
  useChatRoomAnnouncementListQuery,
  useChatRoomMemberInfoQuery,
  useChatRoomCsUserInfoQuery,
  useLazyChatRoomMemberInfoQuery,
  useLazyChatRoomCsUserInfoQuery,
  useChatRoomHeaderUrlQuery,
  useChatRoomUsersQuery,
  useChatRoomMemberDetailQuery,
  useChatRoomCsUserDetailQuery,
} = chatRoomApi;

export const {
  useChatRoomIsTopMutation,
  useChatRoomIsShieldMutation,
  useChatRoomIsNoTalkingMutation,
  useChatRoomIsKickOutMutation,
  useChatRoomIsUploadImageMutation,
  useChatRoomIsHeadImageMutation,
} = chatRoomApi;

export const { useSendChatRoomTextMutation, useSendChatRoomPictureMutation } =
  chatRoomApi;

export const {
  useChatRoomLinkButtonAnswerMutation,
  useChatRoomQuestionAnswerMutation,
  useChatRoomFaqAnswerMutation,
  useChatRoomClickAnswerMutation,
  useChatRoomMessageReadMutation,
  useSetChatRoomMemberInfoMutation,
  useJoinChatRoomMutation,
  useChatRoomNotificationReadMutation,
  useLoadChatRoomMutation,
  useCsLeaveChatRoomMutation,
  useDetectChatRoomMutation,
} = chatRoomApi;

export const {
  useChatRoomIsDeleteMutation,
  useChatRoomIsReplaceTextMutation,
  useChatRoomIsReplacePictureMutation,
} = chatRoomApi;
