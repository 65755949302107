import { FC, memo } from "react";
import { Timestamp } from "@/components";
import "./Message.module.scss";
import { cn } from "@/utils/style-merge";
import { PushpinFilled } from "@ant-design/icons";
import {
  ChatRoomMessageListItem,
  ChatRoomMessageListType,
  MessageKind,
} from "@/models";
import {
  useChatRoomClickAnswerMutation,
  useChatRoomFaqAnswerMutation,
} from "@/services/api/ChatRoom/chatRoomApi";
import { useChatRoomUser } from "@/store/store";

import { useConversation } from "@/chatroom/chatRoom.context";

type FaqClickProps = {
  id: string;
  text: string;
};

type TextClickProps = {
  id: string;
};

const Message: FC<{
  className?: string;
  paragraphClassName?: string;
  message: string | JSX.Element | JSX.Element[];
  datetime?: string | number;
  isCsRead?: boolean;
  taskType: string;
  taskLanguageKey: string;
  isEnd: boolean;
}> = ({
  className = "",
  message = "",
  datetime,
  taskType,
  taskLanguageKey,
  paragraphClassName = "",
  isCsRead,
  isEnd,
}) => {
  const { command, toShowOrToScroll, currentChatRoom, name } =
    useConversation();
  const [triggerClickAnswer] = useChatRoomClickAnswerMutation();
  const [triggerFaqAnswer] = useChatRoomFaqAnswerMutation();
  const { userId, userRole } = useChatRoomUser();

  const { isDisabled = false } = currentChatRoom ?? {};
  async function faqClick(props: FaqClickProps) {
    if (userRole === undefined) return;

    const { id, text } = props;

    // TODO: need to get the language from clicked module
    const result = await triggerFaqAnswer({
      taskType,
      command: command,
      roomSessionNo: currentChatRoom!.roomSessionNo,
      faqId: +id.replace("faq_", ""),
      faqMessage: text,
      LanguageKey: taskLanguageKey ? taskLanguageKey : "TW",
    }).unwrap();

    const reply: ChatRoomMessageListItem = {
      type: ChatRoomMessageListType.Module,
      time: new Date().toISOString(),
      user: {
        roleId: userId!,
        name: name,
        headerUrl: "",
        role: "Bot",
      },
      module: result.data.module,
      conversation: null,
    };

    toShowOrToScroll({ isEnd, reply, id: result.data.id });
  }

  async function textClick(props: TextClickProps): Promise<void> {
    if (userRole === undefined) return;

    const { id } = props;
    const result = await triggerClickAnswer({
      taskType,
      command,
      clickId: +id.replace("click_", ""),
    }).unwrap();

    const reply: ChatRoomMessageListItem = {
      type: ChatRoomMessageListType.Module,
      time: new Date().toISOString(),
      user: {
        roleId: userId!,
        name: name,
        headerUrl: "",
        role: userRole,
      },
      module: result.data.module,
      conversation: null,
    };

    // NOTE: check WebUrl type
    if (reply.module?.responseMessage[0].kind === MessageKind.WebUrl) {
      const url = reply.module?.responseMessage[0].chatContents[0].text;

      window.open(url, "_blank");
    }
  }

  async function moduleClick(event: any) {
    if (isDisabled) return;

    const element = event.target as HTMLElement;
    const text = element.innerHTML;
    // const value = element.getAttribute("value") || "";
    const id = element.id;
    const type = id.replace(/_\d+/, "");

    if (type === "csUser") {
      console.log("no action");
    }

    if (type === "faq") {
      await faqClick({ id, text });

      // webUrlCheck(response);
    }
    if (type === "click") {
      await textClick({
        id,
      });
      // webUrlCheck(response);
    }
  }

  if (!message) return <></>;

  return (
    <div className={`wii-flex wii-flex-row ${className}`.trimEnd()}>
      <p
        className={cn(
          "message-text wii-z-[1] !wii-flex wii-items-center",
          "group-data-[state=open]:wii-cursor-default group-data-[state=open]:wii-ring-1 group-data-[state=open]:wii-ring-foreground",
          "group-data-[view=ban]:!wii-outline group-data-[view=ban]:!wii-bg-transparent group-data-[view=ban]:!wii-text-theme-color group-data-[view=ban]:!wii-outline-1 group-data-[view=ban]:!wii-outline-theme-color",
          paragraphClassName
        )}
      >
        <PushpinFilled
          className={cn(
            "wii-mr-1",
            "wii-hidden group-data-[top=true]:wii-block"
          )}
        />

        <span onClick={moduleClick}>{message}</span>
      </p>
      {isCsRead && (
        <span className="wii-self-end wii-px-2 wii-text-[0.75rem] wii-text-[#888888] group-hover:wii-hidden">
          已讀
        </span>
      )}
      <Timestamp
        datetime={datetime}
        className={"timestamp wii-self-end group-hover:!wii-block"}
      />
    </div>
  );
};

export default memo(Message);
