import axios from "axios";
import i18n from "../../i18n";
import { withTranslation } from "react-i18next";
import {
  useGetLanguageListQuery,
  useUpdateMemberLanguageMutation,
} from "../../services/api/Account/accountApi";

import { cn } from "../../utils/style-merge";
import { CheckOutlined } from "@ant-design/icons";
import { LANG_HEADER } from "../../utils/constants";
import { useMemberStore } from "../../hooks";

type LanguageProps = {
  onClose: () => void;
};
const Language = ({ onClose }: LanguageProps) => {
  const { data, isLoading } = useGetLanguageListQuery();

  const [languageUpdateMutate, { isLoading: updatingLanguage }] =
    useUpdateMemberLanguageMutation();
  const memberInfo = useMemberStore();

  const changeLanguage = (lng: string) => {
    if (!data) return;

    i18n.changeLanguage(lng);

    const keyLang = data.find((i) => i.code === lng);

    if (keyLang) {
      axios.defaults.headers.common[LANG_HEADER] = keyLang.key;
      if (memberInfo.memberInfo.token) {
        languageUpdateMutate({
          memberKey: memberInfo.memberInfo.memberKey,
          languageKey: keyLang.key,
        });
      }
      // window.localStorage.setItem(CODE_LANG_STORAGE, lng);
      // window.localStorage.setItem(KEY_LANG_STORAGE, keyLang.key);
      onClose();
    }

    // NOTE: reset api with new lang if need it
    // dispatch(chatbotApi.util.invalidateTags(["LANG"]));
  };

  const currentLocale = i18n.language;

  if (!data || isLoading) return <></>;

  return (
    <ul className="wii-py-4 wii-text-lg wii-font-bold wii-text-foreground">
      {data.map((item) => {
        return (
          <li
            key={item.code}
            className={cn(
              "wii-flex wii-cursor-pointer wii-items-center wii-justify-between wii-px-6 wii-py-2 hover:wii-bg-background"
            )}
            onClick={(e) => {
              e.stopPropagation();
              if (updatingLanguage) return;
              changeLanguage(item.code);
            }}
          >
            <button>{item.name}</button>
            {currentLocale === item.code && (
              <CheckOutlined className="wii-font-bold wii-text-emerald-500" />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default withTranslation()(Language);
