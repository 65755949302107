export type TChatBotQuestionAnswer = {
  sessionNo: number;
  memberKey: number;
  kindId: number;
  linkButtonId: number;
  replyId: number;
  taskType?: string;
};

export enum ActionCodeMethod {
  "S1" = "結束對話",
  "S2" = "重新建立會話編號",
  "S3" = "接聽排隊",
  "S4" = "MemberMessage",
  "S1&S2" = "結束對話 + 重新建立會話編號",
}
