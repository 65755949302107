import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { Image } from "../../components";

type UploadImageProps = {
  csService?: boolean;
  className?: string;
  imgUrl: string;
  deleteImageHandler: () => void;
  loadingStoreState: boolean;
};

const UploadImage = (props: UploadImageProps) => {
  const {
    csService,
    className = "",
    imgUrl,
    deleteImageHandler,
    loadingStoreState,
  } = props;

  return (
    <div className={"wii-relative"}>
      <img
        src={imgUrl}
        alt="upload"
        className="wii-h-12 wii-w-12 wii-object-scale-down"
      />
      <div className="wii-absolute wii-right-0 wii-top-0">
        <CloseCircleFilled
          disabled={loadingStoreState}
          onClick={(e) => {
            e.stopPropagation();
            deleteImageHandler();
          }}
          className={`${
            loadingStoreState ? "wii-hidden" : "wii-flex"
          } wii-absolute -wii-right-1.5 -wii-top-1.5 wii-text-xl wii-text-red-600 wii-shadow-lg`}
        />
      </div>
    </div>
  );
};

export default UploadImage;
