import { FC, memo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  ChatRoomMessageListItem,
  ChatRoomMessageListType,
  GuideButton,
  ResponseMessage,
} from "@/models";

import style from "./GuideButtonsMessage.module.scss";
import { useChatRoomLinkButtonAnswerMutation } from "@/services/api/ChatRoom/chatRoomApi";
import { useConversation } from "@/chatroom/chatRoom.context";
import { useChatRoomUser } from "@/store/store";

const GuideButtonsMessage: FC<{
  guideButtons: GuideButton[] | null;
  taskType: string;
  responseInfo?: ResponseMessage[];
  isEnd: boolean;
}> = ({ guideButtons, taskType, responseInfo, isEnd }) => {
  const [guideSendStatus, setGuideSendStatus] = useState(false);
  const [triggerLinkButtonAnswer] = useChatRoomLinkButtonAnswerMutation();
  const { scrollbarRef, command, toShowOrToScroll, currentChatRoom, name } =
    useConversation();
  const { userId } = useChatRoomUser();

  const { isDisabled = false } = currentChatRoom ?? {};

  const sendMessage = async ({ linkButtonId }: { linkButtonId: number }) => {
    // NOTE: could scroll first when isEnd
    scrollbarRef.current?.scrollToBottom();
    setGuideSendStatus(true);
    const result = await triggerLinkButtonAnswer({
      command,
      taskType,
      roomSessionNo: currentChatRoom!.roomSessionNo,
      linkButtonId: linkButtonId,
    }).unwrap();

    if (result.data.module.responseMessage[0].kind === "WebUrl") {
      const url =
        result?.data?.module?.responseMessage?.[0]?.chatContents?.[0]?.text;
      window.open(url, "_blank");
      return;
    }

    const reply: ChatRoomMessageListItem = {
      type: ChatRoomMessageListType.Module,
      time: new Date().toISOString(),
      user: {
        roleId: userId!,
        name: name,
        headerUrl: "",
        role: "Bot",
      },
      module: result.data.module,
      conversation: null,
    };
    toShowOrToScroll({ isEnd, reply, id: result.data.id });
  };

  return guideSendStatus ? (
    <></>
  ) : (
    <Swiper slidesPerView={"auto"} spaceBetween={5} grabCursor={true}>
      {guideButtons?.map((btn) => (
        <SwiperSlide key={btn.buttonId}>
          <button
            disabled={isDisabled}
            className={style.faq_btn}
            onClick={() => {
              if (isDisabled) return;
              sendMessage({ linkButtonId: btn.buttonId });
            }}
          >
            {btn.buttonName}
          </button>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(GuideButtonsMessage);
