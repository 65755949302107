import { FC, memo } from "react";
import Timestamp from "../Timestamp";

import "./Message.module.scss";

import { ChatbotService } from "../../services";
import { type ResponseMessageContent } from "../../models";
import { useMemberStore, useMessages } from "../../hooks";
import { useAppDispatch } from "../../store/store";
import { TogglesActions } from "../../store/toggles-slice";
import { csActions } from "../../store/cs-slice";

import transformAnsToQues from "../../utils/answer-to-question";
import { useSignalRConnect } from "../../services/signalr/context/signalR.context";
type FaqClickProps = {
  id: string;
  text: string;
  memberKey: number;
  sessionNo: number;
};

type textClickProps = {
  id: string;
  text: string;
  value: string;
  memberKey: number;
  sessionNo: number;
};

const Message: FC<{
  className?: string;
  paragraphClassName?: string;

  message: string | JSX.Element | JSX.Element[];
  datetime?: string | number;
  isCsRead?: boolean;
}> = ({
  className = "",
  message = "",
  datetime,
  paragraphClassName = "",
  isCsRead,
}) => {
  const { memberInfo } = useMemberStore();
  const [send] = useMessages();
  const dispatch = useAppDispatch();

  const { idleBotChatTimer, disconnectBotChatTimer } = useSignalRConnect();

  async function faqClick(
    props: FaqClickProps
  ): Promise<ResponseMessageContent> {
    const { id, memberKey, text, sessionNo } = props;

    return ChatbotService.sendMessage({
      faqId: +id.replace("faq_", ""),
      message: text,
      memberKey: memberKey,
      sessionNo: sessionNo,
      user: "System",
    });
  }

  async function textClick(
    props: textClickProps
  ): Promise<ResponseMessageContent> {
    const { id, memberKey, text, value, sessionNo } = props;

    return ChatbotService.sendClickMessage({
      clickId: +id.replace("click_", ""),
      chatClickTypeId: +value,
      demand: text,
      memberKey: memberKey,
      sessionNo: sessionNo,
      taskType: "SupplyBot",
    });
  }
  async function webUrlCheck(respContent: ResponseMessageContent) {
    send(respContent);
  }

  async function moduleClick(event: any) {
    const element = event.target as HTMLElement;
    const text = element.innerHTML;
    const value = element.getAttribute("value") || "";
    const id = element.id;
    const type = id.replace(/_\d+/, "");

    const memberKey = memberInfo.memberKey;
    const sessionNo = memberInfo.sessionNo;

    idleBotChatTimer();

    if (type === "csUser") {
      if (!memberInfo.token) {
        dispatch(TogglesActions.setLoginToggle(true));
      }
      dispatch(csActions.isLoginToCsUser(true));
      disconnectBotChatTimer();
      return;
    }

    if (type === "faq") {
      send(
        transformAnsToQues({
          message: text,
          replyId: 0,
          kindId: 0,
          taskType: "",
        })
      );
    }

    if (type === "faq") {
      const response = await faqClick({ id, text, memberKey, sessionNo });
      webUrlCheck(response);
    }
    if (type === "click") {
      const response = await textClick({
        id,
        text,
        value,
        memberKey,
        sessionNo,
      });
      webUrlCheck(response);
    }
    disconnectBotChatTimer();
  }

  if (!message) return <></>;

  return (
    <div className={`wii-group wii-flex wii-flex-row ${className}`.trimEnd()}>
      <p className={`message-text ${paragraphClassName}`} onClick={moduleClick}>
        {message}
      </p>
      {isCsRead && (
        <span className="group-hover:text-red-500 wii-self-end wii-px-2 wii-text-[0.75rem] wii-text-[#888888] group-hover:wii-hidden">
          已讀
        </span>
      )}
      <Timestamp
        datetime={datetime}
        className={"timestamp wii-self-end group-hover:!wii-block"}
      />
    </div>
  );
};

export default memo(Message);
