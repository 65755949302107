import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";

import * as signalR from "@microsoft/signalr";
import { HubConnectionState } from "@microsoft/signalr";

import { HubConnection } from "@microsoft/signalr";
import { useAppDispatch, useTypedSelector } from "../../../store/store";
import { csActions } from "../../../store/cs-slice";
import {
  SignalRBotDispatch,
  SignalRDispatch,
  type SignalRDispatchArgs,
} from "../method";
import { useMemberStore, useMessages } from "../../../hooks";

import {
  useOnBroadcastMessage,
  useOnConnectPair,
  useOnNotifyMessage,
  useOnNotifyTimes,
} from "../hooks";

import { CustomerResponse, RealTimeResponseType } from "../../../models";
import { TogglesActions } from "../../../store/toggles-slice";
import transformAnsToQues from "../../../utils/answer-to-question";
import { ChatbotService } from "../../ChatbotService";
import { SignalrService } from "..";
import {
  CS_DIVIDE,
  SIGNALR_BOTHUB,
  SIGNALR_CSHUB,
} from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import useLangKey from "../../../hooks/useLangKey";
import { useGetSignalRNoQuery } from "@/services/api/Account/accountApi";

type SignalRProviderState = {
  SignalRHub: HubConnection | null;
  notifyCsMessage: (data: { message: string }) => Promise<any>;
  notifyCsUserReadMessage: (data: { message: string }) => Promise<any>;
  connectBotSignalR: (token?: string) => Promise<void>;
  connectSignalR: (token: string) => Promise<void>;
  disconnectSignalR: () => Promise<void>;
  changeToRealTimeAction: (data: CustomerResponse) => Promise<void>;
  isLoading: boolean;
  disconnectBotChatTimer: () => void;
  idleBotChatTimer: () => void;
};

export const SignalRContext = createContext<SignalRProviderState | undefined>(
  undefined
);

SignalRContext.displayName = "SignalRContext";

export const SignalRProvider = (props: PropsWithChildren<{}>) => {
  const [SignalRHub, setSignalRHub] = useState<HubConnection | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const isConnectedSignalR = useTypedSelector(
    (state) => state.csService.isConnectedSignalR
  );
  const csUserId = useTypedSelector((state) => state.csService.csUserId);

  const member = useMemberStore();
  const [send] = useMessages();
  const [t] = useTranslation();
  const { keyLang } = useLangKey();

  useOnNotifyMessage(SignalRHub);
  useOnBroadcastMessage(SignalRHub);
  useOnConnectPair(SignalRHub, disconnectSignalR);
  useOnNotifyTimes(SignalRHub, disconnectSignalR);

  const { data: signalrHubData } = useGetSignalRNoQuery();

  const hubNo = signalrHubData?.data?.signalRNo ?? "";

  const connectBotSignalR = useCallback(
    async (token?: string) => {
      if (
        SignalRHub &&
        (SignalRHub.state === HubConnectionState.Connecting ||
          SignalRHub.state === HubConnectionState.Connected)
      )
        return;

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_SIGNALR_URL}${SIGNALR_BOTHUB}${hubNo}`,
          {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: token ? () => token : undefined,
          }
        )
        .withAutomaticReconnect()
        .build();

      await connection
        .start()
        .then((_) => {
          connection.invoke(SignalRBotDispatch.AliveTimer);
        })

        .finally(() => {
          setIsLoading(false);
        });
      setSignalRHub(connection);
    },
    [SignalRHub, hubNo]
  );

  const connectSignalR = useCallback(
    async (token: string) => {
      if (isConnectedSignalR) {
        return;
      }
      setIsLoading(true);

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_SIGNALR_URL}${SIGNALR_CSHUB}${hubNo}`,
          {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets,
            accessTokenFactory: () => token,
          }
        )
        .withAutomaticReconnect()
        .build();

      if (SignalRHub) {
        await SignalRHub.stop();
      }

      // // TODO: specify cs service action

      await connection.start().finally(() => {
        dispatch(csActions.isConnected());
        connection.invoke(SignalRBotDispatch.AliveTimer);
        setIsLoading(false);
      });
      setSignalRHub(connection);
    },
    [SignalRHub, dispatch, hubNo, isConnectedSignalR]
  );

  async function disconnectSignalR() {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected)
    ) {
      dispatch(csActions.initialCsService());
      await SignalRHub.stop();
      setSignalRHub(null);
    }
  }

  const notifyCsMessage = useCallback(
    async (messages: { message: string }) => {
      if (!SignalRHub || csUserId === undefined) return;

      if (
        SignalRHub &&
        (SignalRHub.state === HubConnectionState.Connecting ||
          SignalRHub.state === HubConnectionState.Connected)
      ) {
        const data: SignalRDispatchArgs[SignalRDispatch.NotifyMessage] = {
          ...messages,
          action: "NotifyText",
          fromId: `${member.memberInfo.memberKey}`,
          toId: csUserId ? csUserId.toString() : "4",
          timestamp: new Date().toISOString(),
          languageKey: keyLang,
        };

        SignalRHub.invoke(SignalRDispatch.NotifyMessage, data);
      }
    },
    [SignalRHub, csUserId, keyLang, member.memberInfo.memberKey]
  );

  const notifyCsUserReadMessage = useCallback(
    async (messages: { message: string }) => {
      if (!SignalRHub || csUserId === undefined) return;

      if (
        SignalRHub &&
        (SignalRHub.state === HubConnectionState.Connecting ||
          SignalRHub.state === HubConnectionState.Connected)
      ) {
        const data: SignalRDispatchArgs[SignalRDispatch.NotifyMessage] = {
          ...messages,
          action: "NotifyRead",
          fromId: `${member.memberInfo.memberKey}`,
          toId: csUserId ? csUserId.toString() : "4",
          timestamp: new Date().toISOString(),
          languageKey: keyLang,
        };
        SignalRHub.invoke(SignalRDispatch.NotifyMessage, data);
      }
    },
    [SignalRHub, csUserId, keyLang, member.memberInfo.memberKey]
  );

  const changeToRealTimeAction = useCallback(
    async (resp: CustomerResponse) => {
      switch (resp.resType) {
        case RealTimeResponseType.LeaveMessage: {
          dispatch(TogglesActions.setMessageToggle(true));
          ChatbotService.getRealTimeWaitModuleMessage({
            sessionNo: member.memberInfo.sessionNo,
            resType: RealTimeResponseType.LeaveMessage,
          }).then((res) => {
            send(
              transformAnsToQues({
                message: res.responseMessage[0].chatContents[0].text,
                replyId: 0,
                kindId: 0,
                taskType: res.taskType,
                chatRecordType: "A",
              })
            );
          });

          break;
        }
        case RealTimeResponseType.StartConnection: {
          const maxRetries = 3;
          const retryDelay = 5 * 1000;

          let retryCount = 0;

          if (resp.isLink) {
            dispatch(
              csActions.isConnectedCsId({
                csId: resp.csUserId.toString(),
                chatQuestionId: resp.chatQuestionId,
              })
            );

            await connectSignalR(member.memberInfo.token);

            while (retryCount < maxRetries) {
              try {
                // TODO: check whether startRealTimeConnect success state
                await SignalrService.startRealTimeConnect({
                  memberKey: member.memberInfo.memberKey.toString(),
                  csUserId: resp.csUserId.toString(),
                }).then((_) => {
                  dispatch(csActions.isLinkCs());
                  dispatch(csActions.setCsHeaderUrl(resp.headerUrl));
                  send(
                    transformAnsToQues({
                      message: t("start-live-chat"),
                      replyId: 0,
                      kindId: 0,
                      taskType: CS_DIVIDE,
                      kind: "Divider",
                      chatRecordType: "A",
                    })
                  );

                  if (resp.chatQuestionId) {
                    ChatbotService.recordRealTimeAnswer({
                      chatQuestionId: resp.chatQuestionId,
                      answer: resp.wellcome,
                      languageAfter: keyLang,
                      isImage: false,
                      isFaq: false,
                    }).then((data) => {
                      send(
                        transformAnsToQues({
                          message: data.answer,
                          replyId: 0,
                          kindId: 0,
                          taskType: "csUser",
                          chatRecordType: "A",
                        })
                      );
                    });
                  }
                });
                retryCount = maxRetries;
              } catch (err) {
                retryCount = retryCount + 1;

                if (retryCount === maxRetries) {
                  send(
                    transformAnsToQues({
                      message: t("customer-service-busy"),
                      replyId: 0,
                      kindId: 0,
                      taskType: "QBot",
                      chatRecordType: "A",
                    })
                  );
                  throw new Error("轉真人失敗");
                }

                const sleep = (ms: number) =>
                  new Promise((resolve) => setTimeout(resolve, ms));
                await sleep(retryDelay);
              }
            }
          }
          break;
        }
        case RealTimeResponseType.CsOffline: {
          ChatbotService.getRealTimeWaitModuleMessage({
            sessionNo: member.memberInfo.sessionNo,
            resType: RealTimeResponseType.CsOffline,
          }).then((res) => {
            send(
              transformAnsToQues({
                message: res.responseMessage[0].chatContents[0].text,
                replyId: 0,
                kindId: 0,
                taskType: res.taskType,
                chatRecordType: "A",
              })
            );
          });
          break;
        }
        case RealTimeResponseType.AskedWait: {
          await connectSignalR(member.memberInfo.token);
          dispatch(csActions.toggleWaitingQueueModalState(true));
          dispatch(
            csActions.setConnectRealTimeState({
              chatTimetableId: resp.chatTimetableId,
              currentQuestionId: resp.chatQuestionId,
              enabledWait: resp.enabledWait,
              csId: resp.csUserId.toString(),
              isLink: resp.isLink,
              headerUrl: resp.headerUrl,
            })
          );

          await ChatbotService.getRealTimeWaitModuleMessage({
            sessionNo: member.memberInfo.sessionNo,
            resType: RealTimeResponseType.AskedWait,
          }).then((res) => {
            send(
              transformAnsToQues({
                kind: "Divider",
                message: t("in-queue"),
                replyId: 0,
                kindId: 0,
                taskType: res.taskType,
                chatRecordType: "A",
              })
            );
            send(
              transformAnsToQues({
                kind: res.responseMessage[0].kind,
                message: res.responseMessage[0].chatContents[0].text,
                replyId: 0,
                kindId: 0,
                taskType: res.taskType,
                chatRecordType: "A",
              })
            );
          });

          // TODO: show Modal => remind user the amount of enabledWait
          break;
        }
        case RealTimeResponseType.InlineDuplicate: {
          ChatbotService.getRealTimeWaitModuleMessage({
            sessionNo: member.memberInfo.sessionNo,
            resType: RealTimeResponseType.StartConnection,
          }).then((res) => {
            send(
              transformAnsToQues({
                message: res.responseMessage[0].chatContents[0].text,
                replyId: 0,
                kindId: 0,
                taskType: res.taskType,
                chatRecordType: "A",
              })
            );
          });
          break;
        }
      }
    },
    [dispatch, send, connectSignalR, member.memberInfo.sessionNo, keyLang, t]
  );

  const disconnectBotChatTimer = useCallback(() => {
    if (
      !SignalRHub ||
      (SignalRHub && SignalRHub.state !== signalR.HubConnectionState.Connected)
    )
      return;
    SignalRHub.invoke(SignalRBotDispatch.AliveTimer);
  }, [SignalRHub]);

  const idleBotChatTimer = useCallback(() => {
    if (
      !SignalRHub ||
      (SignalRHub && SignalRHub.state !== signalR.HubConnectionState.Connected)
    )
      return;
    SignalRHub.invoke(SignalRBotDispatch.LazyTimer);
  }, [SignalRHub]);

  const value = {
    SignalRHub: SignalRHub,
    notifyCsMessage: notifyCsMessage,
    connectBotSignalR: connectBotSignalR,
    connectSignalR: connectSignalR,
    disconnectSignalR: disconnectSignalR,
    changeToRealTimeAction: changeToRealTimeAction,
    isLoading: isLoading,
    notifyCsUserReadMessage: notifyCsUserReadMessage,
    disconnectBotChatTimer: disconnectBotChatTimer,
    idleBotChatTimer: idleBotChatTimer,
  };

  return (
    <SignalRContext.Provider value={value}>
      {props.children}
    </SignalRContext.Provider>
  );
};

export const useSignalRConnect = () => {
  const context = React.useContext(SignalRContext);

  if (context === undefined) {
    throw new Error(`useSignalR must be used within a SignalRProvider`);
  }

  return context;
};
