import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMessages } from "../../../../hooks";
import { useTypedSelector } from "../../../../store/store";

import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { ChatbotService } from "../../../ChatbotService";
import { SignalREvents, SignalREventsArgs } from "../../method";
import { replyActions } from "../../../../store/reply-slice";

import transformAnsToQues from "../../../../utils/answer-to-question";
import useLangKey from "../../../../hooks/useLangKey";
import { TogglesActions } from "@/store/toggles-slice";

const useOnNotifyMessage = (SignalRHub: HubConnection | null) => {
  const [send] = useMessages();

  const { keyLang } = useLangKey();

  const dispatch = useDispatch();
  const { currentQuestionId, isConnectedSignalR } = useTypedSelector(
    (state) => state.csService
  );

  useEffect(() => {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected)
    ) {
      SignalRHub.on(
        SignalREvents.OnNotifyMessage,
        (params: SignalREventsArgs[SignalREvents.OnNotifyMessage]) => {
          if (isConnectedSignalR && currentQuestionId) {
            if (params.action === "NotifyImage") {
              send(
                transformAnsToQues({
                  replyId: 0,
                  kindId: 0,
                  taskType: "cs",
                  kind: "Picture",
                  message: params.message,
                  pictureUrl: params.message,
                  chatRecordType: "A",
                })
              );
              ChatbotService.recordRealTimeAnswer({
                chatQuestionId: currentQuestionId!,
                answer: params.message,
                isImage: true,
                isFaq: false,
              }).then((_) => {
                dispatch(replyActions.isFinishedState());
              });
            }

            // TODO: add lang into faq
            if (params.action === "NotifyFaq") {
              ChatbotService.convertFaq({
                faq: params.message,
              }).then((res) => {
                send(
                  transformAnsToQues({
                    replyId: 0,
                    kindId: 0,
                    taskType: "cs",
                    kind: "Text",
                    message: "",
                    chatRecordType: "A",
                    messages: res,
                  })
                );
              });
              ChatbotService.recordRealTimeAnswer({
                chatQuestionId: currentQuestionId!,
                answer: params.message,
                isImage: false,
                isFaq: true,
              }).then((_) => {
                dispatch(replyActions.isFinishedState());
              });
            }

            if (
              params.action === "NotifyMessage" ||
              params.action === "NotifyText"
            ) {
              ChatbotService.recordRealTimeAnswer({
                chatQuestionId: currentQuestionId!,
                answer: params.message,
                languageBefore: params.languageKey,
                languageAfter: keyLang,
                isImage: false,
                isFaq: false,
              }).then((data) => {
                send(
                  transformAnsToQues({
                    replyId: 0,
                    kindId: 0,
                    taskType: "cs",
                    kind: "Text",
                    message: data.answer,
                    chatRecordType: "A",
                  })
                );

                dispatch(replyActions.isFinishedState());
              });
            }

            if (params.action === "NotifyRead") {
              console.log("read");
            }
          }

          if (params.action === "InviteCsChat") {
            const accept = (params.message as "True" | "False") === "True";

            const csInfo = params.fromId as `${string},${string}}`;
            const [csId, csName] = csInfo.split(",");

            dispatch(
              TogglesActions.setInvitationToggle({
                csId: accept ? csId : "",
                csName: accept ? csName : "",
                accept,
              })
            );
          }
        }
      );
    }

    return () => {
      if (SignalRHub) {
        SignalRHub.off(SignalREvents.OnNotifyMessage);
      }
    };
  }, [
    keyLang,
    isConnectedSignalR,
    currentQuestionId,
    dispatch,
    SignalRHub,
    send,
  ]);
};

export default useOnNotifyMessage;
