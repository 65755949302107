export enum CustomStatus {
  Err,
  Ok,
}
export type FormatResponse<T> = {
  data: T;
  status: CustomStatus;
  errorCode: string;
  message: string;
};
