import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useTypedSelector } from "../../store/store";
import { Modal } from "../../components/Modal";
import { FaqCategoryOption, MessageKind } from "../../models";
import { TogglesActions } from "../../store/toggles-slice";
import { ChatbotService } from "../../services";
import { useMemberStore, useMessages } from "../../hooks";
import transformAnsToQues from "../../utils/answer-to-question";
import { MESSAGE_TOGGLE } from "../../utils/constants";

type ValidateValue<T> = {
  value: T;
  dirty?: boolean;
  isValid?: boolean;
};

const CategoryMessage = () => {
  const [t] = useTranslation();
  const toggles = useTypedSelector((state) => state.toggles);
  const [categorySettings, setCategorySettings] = useState<
    FaqCategoryOption[] | undefined
  >(undefined);
  const [categoryFaqSettingId, setCategoryFaqSettingId] = useState<
    ValidateValue<number>
  >({ value: -1 });
  const [message, setMessage] = useState<ValidateValue<string>>({ value: "" });

  const dispatch = useAppDispatch();
  const { memberInfo } = useMemberStore();
  const [send] = useMessages();

  const validateMessage = (value: string) =>
    setMessage({ value, dirty: true, isValid: !!value });
  const validateFaqSettingId = (value: number) =>
    setCategoryFaqSettingId({ value, dirty: true, isValid: value > -1 });
  const closeMessageToggle = () => {
    setMessage({ value: "" });
    setCategoryFaqSettingId({ value: -1 });
    dispatch(TogglesActions.setMessageToggle(false));
  };

  const sendMessage = () => {
    validateMessage(message.value);
    validateFaqSettingId(categoryFaqSettingId.value);
    if (message.isValid && categoryFaqSettingId.isValid) {
      closeMessageToggle();
      ChatbotService.createMemberMessage(
        categoryFaqSettingId.value,
        memberInfo.sessionNo,
        memberInfo.memberKey,
        message.value
      ).then((_) =>
        send(
          transformAnsToQues({
            replyId: 0,
            kindId: 0,
            taskType: "Bot",
            kind: MessageKind.Text,
            message: t(MESSAGE_TOGGLE),
            chatRecordType: "A",
          })
        )
      );
    }
  };

  useEffect(() => {
    if (
      memberInfo.token &&
      toggles.messageToggle &&
      categorySettings === undefined
    ) {
      ChatbotService.getFaqCategoryOptions().then((resp: FaqCategoryOption[]) =>
        setCategorySettings(resp)
      );
    }
  }, [memberInfo.token, toggles.messageToggle, categorySettings]);

  return toggles.messageToggle ? (
    <Modal
      show={toggles.messageToggle}
      title={t("leave-message")}
      confirmText={t("create-message")}
      cancelText={t("cancel")}
      confirmEvent={sendMessage}
      confirmBtnClass={`wii-bg-foreground hover:wii-bg-theme-color wii-text-white`}
      cancelEvent={closeMessageToggle}
    >
      <>
        <h4 className="wii-text-left">
          <strong>{t("welcome-message")}</strong>
        </h4>
        <div className="wii-flex wii-py-2 wii-text-left">
          <select
            value={categoryFaqSettingId.value}
            className="wii-select wii-select-bordered wii-select-sm wii-w-[40%]"
            onChange={(e) => validateFaqSettingId(+e.currentTarget.value)}
            onBlur={(e) => validateFaqSettingId(+e.currentTarget.value)}
          >
            <option disabled={true} value={-1}>
              {t("issue-category")}
            </option>
            {categorySettings?.map((setting) => (
              <option key={setting.description} value={setting.id}>
                {setting.name}
              </option>
            ))}
          </select>
          <div
            className="wii-mx-1 wii-self-center wii-text-left"
            hidden={!categoryFaqSettingId.dirty || categoryFaqSettingId.isValid}
          >
            <span className="wii-text-error">{t("category-required")}</span>
          </div>
        </div>
        <textarea
          value={message.value}
          rows={2}
          className="wii-textarea wii-textarea-bordered wii-mb-2 wii-max-h-32 wii-min-h-20 wii-w-full"
          placeholder={t("message-body-placeholder")}
          onChange={(e) => validateMessage(e.currentTarget.value)}
          onBlur={(e) => validateMessage(e.currentTarget.value)}
        />
        <div
          className="wii-m-1 wii-text-left"
          hidden={!message.dirty || message.isValid}
        >
          <span className="wii-text-error">{t("message-required")}</span>
        </div>
      </>
    </Modal>
  ) : null;
};

export default CategoryMessage;
