import { useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "../store";

import { fetchEmbeddedCheckSettings } from "../settings-slice";

const useCheckEmbeddedAuthQuery = (
  props: void | {
    enable: boolean;
  }
) => {
  const { enable = true } = props || {};
  const dispatch = useAppDispatch();
  const status = useTypedSelector((state) => state.settings.embedStatus);
  const data = useTypedSelector((state) => state.settings.embedSetting);

  useEffect(() => {
    if (status === undefined && enable) {
      dispatch(fetchEmbeddedCheckSettings());
    }
  }, [status, dispatch, enable]);

  const isUninitialized = status === undefined;
  const isLoading = status === "pending" || status === undefined;
  const isError = status === "rejected";
  const isSuccess = status === "fulfilled";

  return {
    data,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useCheckEmbeddedAuthQuery;
