import React, { FC, memo, Fragment } from "react";
import { useTypedSelector } from "../../store/store";

import FadingBalls from "react-cssfx-loading/lib/FadingBalls";

import { Picture } from "../../components";
import {
  TextMessage,
  QuestionMessage,
  PictureMessage,
  GuideButtonsMessage,
  Divider,
} from "../../containers";

import {
  MessageKind,
  ResponseMessageProp,
  ResponseMessageContent,
} from "../../models";
import { CS_DIVIDE } from "../../utils/constants";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";

const MessageItems: FC<{
  chatContents: ResponseMessageContent[];
  isHistoryMessage?: boolean;
  csRead?: boolean;
  csHeaderUrl?: string;
}> = ({ chatContents, isHistoryMessage, csRead, csHeaderUrl = "" }) => {
  const { data: botSetting } = useGetBotSettingsHubQuery();

  const loadingStoreState = useTypedSelector(
    (state) => state.replyLoading.isLoading
  );

  const messageComponents: Record<MessageKind, FC<ResponseMessageProp>> = {
    [MessageKind.Text]: TextMessage,
    [MessageKind.Question]: QuestionMessage,
    [MessageKind.Picture]: PictureMessage,
    [MessageKind.Divider]: Divider,
    [MessageKind.WebUrl]: TextMessage,
  };

  const rootElement = document.querySelector(":root") as Element;
  const color = getComputedStyle(rootElement).getPropertyValue("--color");

  if (!botSetting) return <></>;

  return (
    <>
      {chatContents.map((chatContent, cIndex) => {
        const isBot =
          chatContent.taskType &&
          chatContent.taskType !== CS_DIVIDE &&
          chatContent.taskType !== "OffBot";

        return (
          <Fragment key={`chat-content-${cIndex}`}>
            <div className={`wii-flex ${isBot ? "" : "wii-justify-end"}`}>
              {isBot ? (
                <div className="wii-avatar wii-flex-col wii-justify-end">
                  <div className="wii-m-1 wii-w-8 wii-rounded-full">
                    <Picture
                      url={
                        chatContent.taskType === "csUser" ||
                        chatContent.taskType === "cs"
                          ? csHeaderUrl
                          : botSetting.headerUrl
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="message-content">
                {chatContent.responseMessage.map((responseMessage, mIndex) => {
                  const messageComponent =
                    messageComponents[responseMessage.kind] || TextMessage;
                  const taskType = chatContent.taskType;
                  const datetime = responseMessage.createdTime;

                  const taskLanguageKey = chatContent.taskLanguageKey;

                  const isReplyHide =
                    responseMessage.chatContents[0].isReplyHide;

                  const isCsRead =
                    cIndex === chatContents.length - 1 &&
                    mIndex === chatContent.responseMessage.length - 1 &&
                    !isBot
                      ? csRead
                      : false;
                  return React.createElement(messageComponent, {
                    key: `chat-content-${cIndex}.response-message-${mIndex}`,
                    responseMessage,
                    taskType,
                    taskLanguageKey,
                    datetime,
                    isReplyHide,
                    isCsRead,
                  });
                })}
              </div>
            </div>
            {(chatContent.guideButtons?.length || 0) > 0 &&
            chatContents.length - 1 === cIndex ? (
              <div
                className={`wii-transform-all wii-duration-100 wii-ease-in-out ${
                  chatContents.length - 1 === cIndex ? "" : "wii-hidden"
                }`}
              >
                <GuideButtonsMessage
                  guideButtons={chatContent.guideButtons}
                  taskType={chatContent.taskType}
                  responseInfo={chatContent.responseMessage}
                />
              </div>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}

      {!isHistoryMessage && (
        <div
          key={"loading"}
          className={`wii-flex${loadingStoreState ? "" : " wii-hidden"}`}
        >
          <div className="wii-avatar wii-flex-col wii-justify-end">
            <div className="wii-m-1 wii-w-8 wii-rounded-full">
              <Picture url={botSetting.headerUrl} />
            </div>
          </div>
          <div className="message-content wii-flex wii-grow wii-self-center">
            <div>
              <FadingBalls color={color} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(MessageItems);
