import React from "react";
import styles from "./PictureButton.module.scss";
import { Timestamp, Button } from "@/components";
import {
  ChatRoomMessageListType,
  type ChatRoomMessageListItem,
  type TChatReply,
} from "@/models";
import { useChatRoomLinkButtonAnswerMutation } from "@/services/api/ChatRoom/chatRoomApi";
import { useConversation } from "@/chatroom/chatRoom.context";
import { useChatRoomUser } from "@/store/store";

const PictureButton: React.FC<{
  data: TChatReply[];
  dateTime?: string | number;
  taskType: string;
  isEnd: boolean;
}> = (props) => {
  const { data, dateTime = "", taskType, isEnd } = props;

  const [triggerLinkButtonAnswer] = useChatRoomLinkButtonAnswerMutation();
  const { scrollbarRef, command, toShowOrToScroll, currentChatRoom, name } =
    useConversation();

  const { isDisabled = false } = currentChatRoom ?? {};

  const { userId, userRole } = useChatRoomUser();

  const sendMessage = async ({ linkButtonId }: { linkButtonId: number }) => {
    if (userRole === undefined) return;

    // NOTE: could scroll first when isEnd
    scrollbarRef.current?.scrollToBottom();
    const result = await triggerLinkButtonAnswer({
      command,
      taskType,
      roomSessionNo: currentChatRoom!.roomSessionNo,
      linkButtonId: linkButtonId,
    }).unwrap();

    if (result.data.module.responseMessage[0].kind === "WebUrl") {
      const url =
        result?.data?.module?.responseMessage?.[0]?.chatContents?.[0]?.text;
      window.open(url, "_blank");
      return;
    }

    const reply: ChatRoomMessageListItem = {
      type: ChatRoomMessageListType.Module,
      time: new Date().toISOString(),
      user: {
        roleId: userId!,
        name: name,
        headerUrl: "",
        role: "Bot",
      },
      module: result.data.module,
      conversation: null,
    };
    toShowOrToScroll({ isEnd, reply, id: result.data.id });
  };

  return (
    <div className={`${styles["button-message"]} wii-flex`}>
      <div className="wii-flex wii-flex-col">
        {data.map((reply, index) => (
          <Button
            key={`btn.${index + reply.replyId}`}
            className={styles["message-btn"]}
            onClick={() => {
              if (isDisabled) return;

              sendMessage({
                linkButtonId: reply.linkButtonId,
              });
            }}
            btnTxt={reply.text}
          />
        ))}
      </div>
      <Timestamp className="wii-self-end" datetime={dateTime} />
    </div>
  );
};

export default PictureButton;
