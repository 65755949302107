import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
  BotSetting,
  EmbeddedCheckType,
  NotifyTimeSetting,
} from "../models";
import { ChatbotService } from "../services";
import http from "../services/api.service";

type RequestState = "pending" | "fulfilled" | "rejected";

/**
 * @deprecated
 */
export const fetchNotifyTimes = createAsyncThunk(
  "settings/notifytimes",
  async () => (await ChatbotService.getNotifyTimes()) as NotifyTimeSetting
);

/**
 * @deprecated
 */
export const fetchBotSettings = createAsyncThunk<
  BotSetting,
  { botKind: string }
>("/api/Chatbot/getBotUserInfo", async () => {
  const data = await http.get<
    BotSetting,
    { botKind: string; signalrHubNo?: string }
  >(`/api/Chatbot/getBotUserInfo`, {
    botKind: process.env.REACT_APP_BOTKIND ?? `${0}`,
  });

  return data;
});

const embedWhiteList = ["localhost:3000", "localhost:3001"];
export const fetchEmbeddedCheckSettings = createAsyncThunk<EmbeddedCheckType>(
  "/api/Account/checkEmbeddedAuth",
  async () => {
    const siteUrlConfig = new URL(document.URL);
    const siteCheckUrl =
      siteUrlConfig.port.length > 0
        ? `${siteUrlConfig.hostname}:${siteUrlConfig.port}`
        : siteUrlConfig.hostname;

    if (embedWhiteList.includes(siteCheckUrl)) {
      return { isAuth: true, isEmbed: false };
    }
    const data = await http.post<EmbeddedCheckType, { url: string }>(
      "/api/Account/checkEmbeddedAuth",
      {
        url: siteCheckUrl,
      }
    );

    return data;
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    isGuset: false,
    notifytimes: undefined as NotifyTimeSetting | undefined,
    botSetting: {
      color: {
        primary: "",
        backend: "",
        demand: "",
        supply: "",
      },
      headerUrl: "",
      name: "",
    } as BotSetting,
    embedSetting: {
      isAuth: false,
      isEmbed: false,
    } as EmbeddedCheckType,
    botStatus: undefined as RequestState | undefined,
    embedStatus: undefined as RequestState | undefined,
  },
  reducers: {
    setBotSetting: (state, action: PayloadAction<BotSetting>) =>
      Object.assign({}, state, { botSetting: action.payload }),
  },
  extraReducers: (builder) => {
    // NOTE: botsetting
    builder.addCase(fetchBotSettings.pending, (state, action) => {
      state.botStatus = "pending";
    });
    builder.addCase(
      fetchBotSettings.fulfilled,
      (state, action: PayloadAction<BotSetting>) => {
        state.botStatus = "fulfilled";
        state.botSetting = action.payload;
      }
    );
    builder.addCase(fetchBotSettings.rejected, (state, action) => {
      state.botStatus = "rejected";
    });

    // NOTE: Embedded
    builder.addCase(fetchEmbeddedCheckSettings.pending, (state, action) => {
      state.embedStatus = "pending";
    });
    builder.addCase(
      fetchEmbeddedCheckSettings.fulfilled,
      (state, action: PayloadAction<EmbeddedCheckType>) => {
        state.embedStatus = "fulfilled";
        state.embedSetting = action.payload;
      }
    );
    builder.addCase(fetchEmbeddedCheckSettings.rejected, (state, action) => {
      state.embedStatus = "rejected";
    });
  },
});

export const SettingActions = settingsSlice.actions;

export default settingsSlice.reducer;
