import { MemberActions } from "@/store/member-slice";
import store from "@/store/store";
import { WII_MEMBER_STORAGE, WII_SESSION_STORAGE } from "@/utils/constants";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await axios({
        url: baseUrl + url.startsWith("/") ? url.slice(0) : url,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (
        err?.response?.status === 401 &&
        localStorage.getItem(WII_MEMBER_STORAGE)
      ) {
        localStorage.removeItem(WII_SESSION_STORAGE);
        localStorage.removeItem(WII_MEMBER_STORAGE);

        window.WiiApp.connectedState = "unknown";
        window.WiiApp.role = undefined;

        const toggleBotOpen = document.querySelector<HTMLDivElement>(
          "wii-chatbot > div > label"
        );
        toggleBotOpen?.click();
        store.dispatch(MemberActions.logout());
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
