import { Backdrop } from "@/components/ui";
import { cn } from "@/utils/style-merge";
import { CloseCircleFilled } from "@ant-design/icons";
import { PropsWithChildren } from "react";

type InfoModalProps = {
  show: boolean;
  title?: string;
  onClose: () => void;
};

const InfoModal = (props: PropsWithChildren<InfoModalProps>) => {
  const { show, title = "", onClose, children } = props;

  return (
    <Backdrop>
      {show ? (
        <Backdrop.Item
          onClick={onClose}
          className={cn(
            "wii-z-[5] wii-bg-gray-100 wii-blur-xl",
            show ? "wii-overflow-hidden" : "wii-overflow-hidden"
          )}
        />
      ) : null}

      <Backdrop.Content
        // className={cn("wii-bg-red-500", show ? "wii-h-40" : "wii-hidden")}
        className={cn(
          "!wii-fixed wii-bottom-1/2 wii-left-0 wii-right-0 wii-top-1/2 wii-m-4 wii-mx-4 wii-rounded-lg wii-border-2 wii-bg-white wii-shadow-sm",
          "wii-flex-col !wii-justify-start",
          show ? "min-wii-h-20 wii-h-fit wii-overflow-hidden" : "wii-hidden"
        )}
      >
        <div className="wii-flex wii-h-6 wii-w-full wii-items-center wii-justify-center wii-px-2 wii-py-4">
          {title !== "" && (
            <div className="wii-ml-2 wii-text-sm wii-font-bold wii-text-foreground">
              {title}
            </div>
          )}
          <div
            className="wii-ml-auto wii-mr-1 wii-w-fit wii-cursor-pointer hover:wii-opacity-90"
            onClick={onClose}
          >
            <CloseCircleFilled />
          </div>
        </div>
        {children}
      </Backdrop.Content>
    </Backdrop>
  );
};

export default InfoModal;
