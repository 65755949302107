export const WII_MEMBER_STORAGE = "wii-MemberToken";
export const WII_SESSION_STORAGE = "wii-SessionNo";

export const MESSAGE_TOGGLE = "message-toggle";

export const EMBEDDED_STORAGE = "royal_user";

export const REATING_STARS_NUMBER = 5;

export const SIGNALR_CSHUB = process.env.REACT_APP_SIGNALR_CSHUB || "CsHub";

export const SIGNALR_BOTHUB =
  process.env.REACT_APP_SIGNALR_BOTHUB || "BotChatHub";

export const END_BOT1 = "EndBot1";

export const CS_DIVIDE = "csDivideBot";

export const ALLOW_IMAGE_TYPE = [
  // "tif",
  // "tiff",
  // "bmp",
  // "svg",
  // "webp",
  "jpg",
  "jpeg",
  "gif",
  "png",
];

export const ALLOWQ_IMAGE_SIZE = 5200000;

export const CS_STATUE_MSG = [
  "cs-offline",
  "cs-busy",
  "cs-waitting",
  "cs-serving",
];

export const LANG_HEADER = "Lang";

export const CODE_LANG_STORAGE = "i18WiiLng";

export const KEY_LANG_STORAGE = "_wiiLang";

export const CHATROOM_OFFSET = {
  offsetAbove: 20,
  offsetBelow: 50,
};

export const CHATROOM_HUBNAME =
  process.env.REACT_APP_SIGNALR_CHATROOM || "ChatRoomHub";

export const MAX_UNREAD_NUM = 999;

export const IMAGE_CHATROOM_DEFAULT = {
  Header: "Header",
  Cs: "Cs",
  Member: "Member",
  Bot: "Bot",
};

export const IMAGE_CHATROOM_DEFAULT_PATH: { [key: string]: string } = {
  Header: `${process.env.REACT_APP_DEFAULT_IMAGE_URL}header.jpg`,
  Cs: `${process.env.REACT_APP_DEFAULT_IMAGE_URL}cs.jpg`,
  Member: `${process.env.REACT_APP_DEFAULT_IMAGE_URL}member.jpg`,
  Bot: `${process.env.REACT_APP_DEFAULT_IMAGE_URL}header.jpg`,
};

export const IMAGE_PLACEHOLDER_PATH = `${process.env.REACT_APP_DEFAULT_IMAGE_URL}placeholder.jpg`;
export const VIP_ICON = `${process.env.REACT_APP_DEFAULT_IMAGE_URL}vip.jpg`;
export const SLEEP_ICON = `${process.env.REACT_APP_DEFAULT_IMAGE_URL}ZZZ.png`;
export const LOGIN_METHOD_ICON = `${process.env.REACT_APP_DEFAULT_IMAGE_URL}social_media.png`;
