import { MouseEventHandler } from "react";
import "./Modal.module.scss";

export interface ModalProps {
  show: boolean,
  title?: string,
  content?: string,
  confirmText: string,
  cancelText: string,
  confirmBtnClass?: string,
  cancelBtnClass?: string,
  children?: JSX.Element[] | JSX.Element,
  confirmEvent?: MouseEventHandler<HTMLButtonElement>,
  cancelEvent?: MouseEventHandler<HTMLButtonElement>
}

const Modal = (props: ModalProps) => {
  return <div className="wii-modal wii-modal-bottom wii-pointer-events-auto" data-active={props.show}>
    <div className="wii-modal-box wii-w-full wii-text-center">
      <div className="wii-text-2xl wii-font-bold">{props.title || ""}</div>
      {
        props.children ? props.children : <p>{props.content || ""}</p>
      }
      <div className="wii-flex wii-flex-col">
        <button className={`wii-btn wii-border-0 w-full wii-rounded-2xl wii-mb-2 wii-text-msg ${props.confirmBtnClass}`}
          onClick={props.confirmEvent}>{props.confirmText}</button>
        <button className={`wii-btn wii-btn-ghost wii-w-full wii-rounded-2xl wii-mb-2 wii-text-msg ${props.cancelBtnClass}`}
          onClick={props.cancelEvent}>{props.cancelText}</button>
      </div>
    </div>
  </div>
}

export default Modal;