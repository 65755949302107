import { useEffect } from "react";
import { useAppDispatch, useTypedSelector } from "../store/store";
import { useMemberStore } from "./index";

import { ChatbotService } from "../services";

import { useSignalRConnect } from "../services/signalr/context/signalR.context";
import { csActions } from "../store/cs-slice";
import { useTranslation } from "react-i18next";

// NOTE: 客服彈窗
const useClickCs = () => {
  const { memberInfo } = useMemberStore();

  const dispatch = useAppDispatch();
  const { changeToRealTimeAction } = useSignalRConnect();
  const {
    enabledWait,
    isLink,
    isConnectedSignalR,
    checkingWaitingState,
    clickLoginChangeToCsUser,
  } = useTypedSelector((state) => state.csService);

  const [t] = useTranslation();

  // TODO: 轉真人
  useEffect(() => {
    if (!checkingWaitingState && memberInfo.token && clickLoginChangeToCsUser) {
      dispatch(csActions.isLoginToCsUser(false));
      if (enabledWait > 0 && !isLink && isConnectedSignalR) {
        dispatch(csActions.toggleWaitingQueueModalState(true));
        return;
      }
      if (isLink && isConnectedSignalR) {
        // TODO: 跳出是否要結束對話
        return;
      }
      ChatbotService.changeToRealTimeCustomerService({
        sessionNo: memberInfo.sessionNo,
        memberKey: memberInfo.memberKey,
        demand: t("to-cs"),
      }).then((resp) => {
        changeToRealTimeAction(resp).then((res) => {
          if (enabledWait > 0 && !isLink && isConnectedSignalR) {
            dispatch(csActions.toggleWaitingQueueModalState(true));
          }
        });
      });
    }
  }, [
    t,
    clickLoginChangeToCsUser,
    memberInfo.sessionNo,
    memberInfo.token,
    changeToRealTimeAction,
    memberInfo.memberKey,
    dispatch,
    enabledWait,
    isLink,
    isConnectedSignalR,
    checkingWaitingState,
  ]);
};

export default useClickCs;
