import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Fetch from "i18next-fetch-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { CODE_LANG_STORAGE } from "./utils/constants";

i18n
  .use(Fetch)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // lng: "zh-TW", // if you're using a language detector, do not define the lng option
    fallbackLng: "zh-TW",
    backend: {
      allowMultiLoading: true,
      requestOptions: {
        mode: "cors",
        cache: "no-cache",
      },
      // TODO:
      loadPath: `${process.env.REACT_APP_IMAGE_URL}Image/Localization/Setting/C1/WiiChat/{{lng}}.json`,
    },
    detection: {
      order: ["localStorage", "htmlTag", "navigator", "subdomain"],
      lookupLocalStorage: CODE_LANG_STORAGE,
    },
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

i18n.on("languageChanged", (lng) => (document.documentElement.lang = lng));

export default i18n;
