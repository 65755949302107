import { FC, useState } from "react";
import { useTypedSelector, useAppDispatch } from "../../store/store";

import { TogglesActions } from "../../store/toggles-slice";
import { MemberActions } from "../../store/member-slice";

import { ChatbotService, MemberService } from "../../services";
import { ActiveStatus, LoginKind, LoginStatus } from "../../models";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useTranslation } from "react-i18next";
import {
  useGetLanguageListQuery,
  useGetSignalRNoQuery,
} from "../../services/api/Account/accountApi";
import axios from "axios";
import { LANG_HEADER } from "../../utils/constants";
import i18n from "../../i18n";
import { getBrowserFingerprint, getBrowserInfo } from "@/utils/browser";
import { encryptLogin } from "@/utils/crypto";
interface LoginModel {
  userName: string;
  password: string;
}

const MemberLogin: FC = () => {
  const dispatch = useAppDispatch();
  const toggles = useTypedSelector((state) => state.toggles);
  const isEmbed = useTypedSelector(
    (state) => state.settings.embedSetting.isEmbed
  );
  const [loginModel, setLoginModel] = useState({ userName: "", password: "" });
  const [showLoginError, setShowLoginError] = useState<false | string>(false);

  const memberInfo = useTypedSelector((state) => state.member.info);
  const { data } = useGetLanguageListQuery();

  const [t] = useTranslation();
  const [_, setLocalStorageValue] = useLocalStorage();
  const { data: signalRNo } = useGetSignalRNoQuery();

  const login = async (model: LoginModel) => {
    const password = encryptLogin(model.password);
    if (isEmbed) {
      const embeddedMemberInfo = await MemberService.loginEmbedded({
        email: model.userName,
        password: password,
      });

      const { isAuth, memberId, memberToken } = embeddedMemberInfo;

      if (!isAuth) {
        // TODO: check LoginStatus
        setShowLoginError(t("account-password-error"));
        return;
      }

      setLocalStorageValue({
        memberKey: memberId,
        token: memberToken,
      });
      setShowLoginError(false);
      setLoginModel({ userName: "", password: "" });
      dispatch(TogglesActions.setLoginToggle(false));
      dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));

      return;
    }

    let askId = memberInfo.askerId;
    let sessionNo = memberInfo.sessionNo;

    // TODO: get valid askerId and session =>
    if (!askId) {
      const resp = await ChatbotService.getVisitorInfo();
      askId = resp.askerId;
      sessionNo = resp.sessionNo;
    }

    const fingerprint = getBrowserFingerprint();
    const browser = getBrowserInfo();

    const memberInfoNew = await MemberService.login({
      userName: model.userName,
      password: password,
      askerId: askId,
      sessionNo: sessionNo,
      fingerPrint: fingerprint,
      signalRHubNo: signalRNo?.data.signalRNo
        ? signalRNo?.data.signalRNo
        : undefined,
      ...browser,
    });

    const { isAuth, token, userInfo, isAutoRealTime, defaultLanguage, status } =
      memberInfoNew;

    if (!isAuth) {
      switch (status) {
        case LoginStatus.Err: {
          setShowLoginError(t("account-password-error"));
          break;
        }
        case LoginStatus.Cs: {
          setShowLoginError(t("cs-connecting-error"));
          break;
        }
        case LoginStatus.Ok: {
          setShowLoginError(t("account-password-error"));
          break;
        }
        default: {
          setShowLoginError(t("account-password-error"));
        }
      }

      return;
    }

    if (defaultLanguage && data) {
      const isInLangList = data.find((i) => i.key === defaultLanguage);
      if (isInLangList) {
        i18n.changeLanguage(isInLangList.code);
        axios.defaults.headers.common[LANG_HEADER] = isInLangList.key;
      }

      if (!isInLangList) {
        const isDefaultList = data.find((i) => i.isDefault);
        if (isDefaultList) {
          i18n.changeLanguage(isDefaultList.code);
          axios.defaults.headers.common[LANG_HEADER] = isDefaultList.key;
        }
      }
    }

    setShowLoginError(false);
    setLoginModel({ userName: "", password: "" });
    dispatch(TogglesActions.setLoginToggle(false));
    dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));
    dispatch(
      MemberActions.setUserInfo({
        askerId: askId,
        memberKey: userInfo.memberKey,
        sessionNo: userInfo.sessionNo,
        token: token,
        isAutoRealTime: isAutoRealTime,
        loginAccount: userInfo.loginAccount,
        accountId: userInfo.accountId,
        name: userInfo.name,
        email: userInfo.email,
        pictureUrl: userInfo.pictureUrl,
        provider: userInfo.provider,
        loginKind: userInfo.loginKind,
        isVIP: userInfo.isVIP,
      })
    );
    return;
  };

  return (
    <>
      {toggles.loginToggle ? (
        <div
          id={LoginKind.Member}
          className="wii-modal wii-modal-bottom wii-pointer-events-auto"
          data-active={toggles.loginToggle}
        >
          <div className="wii-modal-box wii-absolute wii-w-full wii-py-1">
            <h2 className="wii-p-2 wii-text-center wii-text-2xl wii-font-bold">
              {t("login")}
            </h2>
            <form action="">
              {showLoginError !== false && (
                <p
                  className="wii-text-sm wii-text-red-600"
                  hidden={!showLoginError}
                >
                  {/* {t("account-password-error")} */}
                  {showLoginError}
                </p>
              )}
              <div className="wii-form-control wii-mb-2">
                <input
                  type="text"
                  placeholder={t("account")}
                  className="wii-input wii-input-bordered wii-w-full"
                  value={loginModel.userName}
                  onChange={(e) =>
                    setLoginModel({
                      userName: e.currentTarget.value,
                      password: loginModel.password,
                    })
                  }
                />
              </div>
              <div className="wii-form-control wii-mb-2">
                <input
                  type="password"
                  placeholder={t("password")}
                  className="wii-input wii-input-bordered wii-w-full"
                  value={loginModel.password}
                  onChange={(e) =>
                    setLoginModel({
                      userName: loginModel.userName,
                      password: e.currentTarget.value,
                    })
                  }
                />
              </div>
              <div className="wii-flex wii-flex-col">
                <button
                  className="theme-color wii-btn wii-mb-2 wii-w-full wii-border-0"
                  onClick={async (e) => {
                    e.preventDefault();
                    await login(loginModel);
                  }}
                >
                  {t("login")}
                </button>
                <button
                  className="wii-btn wii-btn-ghost wii-mb-2 wii-w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoginModel({ userName: "", password: "" });
                    dispatch(TogglesActions.setLoginToggle(false));
                  }}
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MemberLogin;
