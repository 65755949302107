import { FC } from "react";
import { useAppDispatch } from "../../store/store";

import { MemberActions } from "../../store/member-slice";

import { ChatbotService } from "../../services";
import { LoginSetting, ActiveStatus } from "../../models";

import styles from "./GuestLogin.module.scss";
import LoginOptions from "./LoginOptions";
import { useTranslation } from "react-i18next";

interface LoginProps {
  loginSettings: LoginSetting[];
}

const GuestLogin: FC<LoginProps> = ({ loginSettings }) => {
  const [t] = useTranslation();

  const dispatch = useAppDispatch();
  const guestLogin = () => {
    dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));
    ChatbotService.getVisitorInfo().then((resp) =>
      dispatch(
        MemberActions.setMemberInfo({
          ...resp,
          token: "",
          isAutoRealTime: false,
        })
      )
    );
  };

  return (
    <>
      <div className={styles["login-box"]}>
        <div className="wii-mb-2 wii-text-center">{t("login-methods")}</div>
        <ul className="wii-flex wii-cursor-pointer wii-justify-center">
          {loginSettings.map((s) => (
            <LoginOptions key={s.name} loginMethod={s} />
          ))}
        </ul>
        <span className={"demarcation"}>{t("or")}</span>
        <div className="wii-p-5 wii-text-center">
          <button className={styles["guest-login-btn"]} onClick={guestLogin}>
            {t("login-with-guest")}
          </button>
        </div>
      </div>
    </>
  );
};

export default GuestLogin;
