import { LoginStatus } from "./member";

export type EmbeddedMemberLoginRequest = {
  email: string;
  password: string;
};

export type EmbeddedMemberLoginResponse = {
  isAuth: boolean;
  memberId: number;
  memberToken: string;
};

export type EmbeddedMemberInfoRequest = {
  memberId: number;
  memberToken: string;
  sessionNo: number;
  askerId: number;

  fingerPrint: string;
  browser?: string;
  os?: string;
  platform?: string;
  signalRHubNo: string | number | undefined;
};

export type EmbeddedMemberInfoResponse = {
  isAuth: boolean;
  token: string;

  userInfo: EmbeddedUserInfo;
  isAutoRealTime: boolean;
  defaultLanguage: string;

  status: LoginStatus;
};

enum UserLoginSite {
  Guest,
  Member,
  Embedding,
}

export type EmbeddedUserInfo = {
  memberKey: number;
  sessionNo: number;
  loginAccount: string;
  accountId: string;
  name: string;
  email: string;
  pictureUrl: string;
  provider: string;
  loginKind: UserLoginSite;
  isVIP: boolean;
};

export type EmbeddedCheckType = { isAuth: boolean; isEmbed: boolean };
