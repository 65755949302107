import { ContextMenuItemProps } from "@radix-ui/react-context-menu";
import React, { forwardRef } from "react";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { cn } from "@/utils/style-merge";

const ContextItemWrap = forwardRef<
  React.ForwardRefExoticComponent<
    ContextMenuItemProps & React.RefAttributes<HTMLDivElement>
  >,
  ContextMenuItemProps
>((props, ref) => {
  const { className = "", ...rest } = props;
  return (
    <ContextMenu.Item
      className={cn(
        "wii-group wii-relative wii-my-1 wii-flex wii-cursor-pointer wii-select-none wii-items-center wii-gap-1 wii-rounded-sm wii-py-2 wii-pl-2 wii-pr-4 wii-text-sm wii-leading-none wii-outline-none md:wii-select-auto",
        "data-[highlighted]:wii-bg-theme-color data-[highlighted]:wii-text-white",
        "data-[disabled]:wii-pointer-events-none data-[disabled]:wii-text-gray-200",
        className
      )}
      {...rest}
    >
      {props.children}
    </ContextMenu.Item>
  );
});

export default ContextItemWrap;
