import {FC, memo, MouseEventHandler} from 'react';

const Button: FC<{
    className: string,
    btnTxt?: string,
    onClick?: MouseEventHandler<HTMLButtonElement>
}> =
    ({className, btnTxt, onClick}) => {
        return <button className={className} onClick={onClick}>{btnTxt}</button>
    }

export default memo(Button);