import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";

import settingsReducer from "@/store/settings-slice";
import memberReducer, { MemberActions } from "@/store/member-slice";
import togglesReucer from "@/store/toggles-slice";

import replyReucer from "@/store/reply-slice";
import csReucer from "@/store/cs-slice";
import missionReducer from "@/store/mission-slice";
import chatRoomReucer from "@/store/chatRoom-slice";

import { WII_MEMBER_STORAGE, WII_SESSION_STORAGE } from "@/utils/constants";
import { chatbotApi } from "@/services/api/Chatbot/botApi";
import { accountApi } from "@/services/api/Account/accountApi";
import { settingApi } from "@/services/api/Setting/settingApi";
import { chatRoomApi } from "@/services/api/ChatRoom/chatRoomApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

localStorage.removeItem(WII_SESSION_STORAGE);
localStorage.removeItem(WII_MEMBER_STORAGE);

const appReducer = combineReducers({
  member: memberReducer,
  toggles: togglesReucer,
  replyLoading: replyReucer,
  csService: csReucer,
  missionService: missionReducer,
  chatRoomService: chatRoomReucer,
  [chatRoomApi.reducerPath]: chatRoomApi.reducer,
});

const sumReducer = combineReducers({
  settings: settingsReducer,
  [chatbotApi.reducerPath]: chatbotApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
});

const allReducer = combineReducers({
  member: memberReducer,
  toggles: togglesReucer,
  replyLoading: replyReucer,
  csService: csReucer,
  missionService: missionReducer,
  chatRoomService: chatRoomReucer,
  [chatRoomApi.reducerPath]: chatRoomApi.reducer,
  settings: settingsReducer,
  [chatbotApi.reducerPath]: chatbotApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [settingApi.reducerPath]: settingApi.reducer,
});

const rootReducer = (
  state: ReturnType<typeof allReducer>,
  action: AnyAction
) => {
  /* if you are using RTK, you can import your action and use it's type property instead of the literal definition of the action  */

  if (action.type === MemberActions.logout.type) {
    return {
      ...appReducer(undefined, action),
      ...sumReducer(
        {
          settings: state.settings,
          [chatbotApi.reducerPath]: state[chatbotApi.reducerPath],
          [accountApi.reducerPath]: state[accountApi.reducerPath],
          [settingApi.reducerPath]: state[settingApi.reducerPath],
        },
        action
      ),
    };
  }

  return allReducer(state, action);
};

const store = configureStore({
  reducer: (state: any, action: AnyAction) => {
    return {
      ...rootReducer(state, action),
    };
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      chatbotApi.middleware,
      accountApi.middleware,
      settingApi.middleware,
      chatRoomApi.middleware
    ),
});

// ** export store type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// ** export redux method type
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

// ** select hook
export const useChatRoomUser = () =>
  useTypedSelector((state) => state.chatRoomService);

setupListeners(store.dispatch);
