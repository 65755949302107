import { cn } from "@/utils/style-merge";
import { useCallback, useEffect, useState } from "react";

import { Autoplay, EffectFlip } from "swiper";
import SwiperType from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MessageItemsModule } from "@/chatroom/Bot";
import { Picture } from "@/components";

import parse from "html-react-parser";
import {
  ChatRoomAnnouncementListRes,
  ChatRoomItem,
  ChatRoomListConversation,
  ChatRoomMessageListItem,
} from "@/models";

import TopItemButton from "./TopItemButton";
import AnnounceButton from "./AnnounceButton";
import {
  useChatRoomIsTopMutation,
  useChatRoomNotificationReadMutation,
} from "@/services/api/ChatRoom/chatRoomApi";
import { themeParser } from "@/utils/check-theme";
import { Backdrop } from "@/components/ui";
import { useTranslation } from "react-i18next";

import * as ContextMenu from "@radix-ui/react-context-menu";
import { ContextItemWrap } from "../ContextMenu";
import { useConversation } from "@/chatroom/chatRoom.context";
import { CustomStatus } from "@/models/format";

type BulletinProps = {
  announceList: ChatRoomAnnouncementListRes[] | undefined;
  topList: ChatRoomMessageListItem[] | undefined;
  isEnd: boolean;
  scrollToTag: (tagId: string | undefined, showTag?: boolean) => void;
  currentChatRoom: ChatRoomItem | undefined;
  command: string;
};

const Bulletin = (props: BulletinProps) => {
  const {
    announceList = [],
    topList = [],
    isEnd,
    scrollToTag,
    currentChatRoom,
    command,
  } = props;

  const { responseMessage, setResponseMessage } = useConversation();

  const { isDisabled = false } = currentChatRoom ?? {};

  const [announceItem, setAnnounceItem] = useState<number | null>(null);
  const [topItem, setTopItem] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [bulletin, setBulletin] = useState(announceList.length > 0);
  const [triggerAnnounceRead] = useChatRoomNotificationReadMutation();
  const [triggerTop] = useChatRoomIsTopMutation();

  const [t] = useTranslation();

  const slideToNext = useCallback(() => {
    if (swiper) swiper.slideNext();
  }, [swiper]);

  const hasAnnounce = announceList.length > 0;
  const hasTopItem = topList.length > 0;

  const themeTransform = themeParser(
    announceItem === null
      ? {
          primary: "",
          backend: "",
          demand: "",
          supply: "",
        }
      : {
          primary: announceList?.[announceItem].colorUrl ?? "",
          backend: announceList?.[announceItem].colorBackUrl ?? "",
          demand: announceList?.[announceItem].colorDemandUrl ?? "",
          supply: announceList?.[announceItem].colorDemandUrl ?? "",
        }
  );

  const themeBackground = themeTransform?.backend;
  useEffect(() => {
    if (!swiper) return;

    if (announceItem !== null) {
      swiper?.autoplay?.stop();
    } else {
      swiper?.autoplay?.start();
    }
  }, [swiper, announceItem]);

  function handleClickReset() {
    setTopItem(false);
    setAnnounceItem(null);
  }

  function replaceConversationMultiItem(
    responseMessage: ChatRoomMessageListItem[],
    id: number,
    ...pairs: [
      keyof ChatRoomListConversation,
      ChatRoomListConversation[keyof ChatRoomListConversation]
    ][]
  ): ChatRoomMessageListItem[] {
    const kV = Object.fromEntries(pairs) as Partial<ChatRoomListConversation>;
    const findItem = responseMessage.map((i) => {
      if (i?.conversation?.id && +i?.conversation?.id === +id) {
        return {
          ...i,
          conversation: {
            ...i?.conversation,
            ...kV,
          },
        };
      }
      return i;
    });

    return findItem;
  }

  return (
    <>
      <style>{`
      .bulletin-module > div {
        width:100%;
      }
      .bulletin-module .message-content .message-text {
         max-width:100%;
         align-items:center;
      }
      .bulletin-module .message-content {
        padding-top:0;
        padding-bottom:0;
        padding-left:2rem;
        padding-right:1.8rem;
      }
      .bulletin-module .wii-avatar {
        display: none;
      }
      .bulletin-module .timestamp {
        display: none !important;
      }
      .bulletin-module > div {
        position:relative;
        padding:0.5rem 0rem;
        min-height:100%;
        height:auto;
        width:100%;
      }
      .bulletin-module .message-content {
         width:100%!important;
      }
      .bulletin-module > div::after {
        content: '';
        position: absolute;
        width: 100%;
        height:100%;
        padding:2.5rem;
        top:0;
        left:0;
        background-color:${themeBackground};
        z-index:-1;
      }
      .bulletin-swiper::after {
        content: '';
        position: absolute;
        width: 100%;
        height:100%;
        top:0;
        left:0;
        background-color:${themeBackground};
        z-index:-1;
      }
      `}</style>
      <div
        className={cn(
          "wii-absolute wii-left-1/2 wii-z-[5] wii-mx-auto wii-mt-1.5 wii-w-[96%] -wii-translate-x-1/2 wii-overflow-hidden",
          announceItem !== null || topItem ? "wii-h-1/2" : "wii-h-fit"
        )}
      >
        <AnnounceButton
          hasAnnounce={hasAnnounce}
          bulletin={bulletin}
          slideToNext={slideToNext}
          setBulletin={setBulletin}
          setTopItem={setTopItem}
        />

        <TopItemButton
          hasTopItem={hasTopItem}
          bulletin={bulletin}
          topItem={topItem}
          setBulletin={setBulletin}
          setTopItem={setTopItem}
          setAnnounceItem={setAnnounceItem}
        />

        {bulletin && hasAnnounce && (
          <div
            className={cn(
              "wii-flex wii-flex-col",
              announceItem !== null ? "wii-h-full" : "wii-h-fit"
            )}
          >
            <Swiper
              onSwiper={(e) => {
                setSwiper(e);
              }}
              direction={"vertical"}
              effect={"flip"}
              grabCursor={true}
              pagination={true}
              modules={[Autoplay, EffectFlip]}
              className="bulletin-swiper wii-z-[10] wii-h-10 wii-w-full wii-shrink-0"
              style={{
                backgroundColor: "white",
              }}
              onClick={(s, e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              loop={true}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              onSlideChange={(swiper) => {
                if (announceItem !== null) {
                  setAnnounceItem(swiper.realIndex);
                } else {
                  setAnnounceItem(null);
                }
              }}
            >
              {announceList.map((i, idx) => {
                return (
                  <SwiperSlide
                    className="wii-flex wii-items-center wii-shadow-lg"
                    key={idx}
                  >
                    <div
                      className={cn(
                        "wii-w-full wii-truncate wii-px-8",
                        "wii-flex wii-justify-center"
                      )}
                      onClick={(e) => {
                        e.stopPropagation();

                        if (announceItem !== null) {
                          setAnnounceItem(null);
                        } else {
                          if (announceList?.[idx].module !== null) {
                            setAnnounceItem(idx);
                          }

                          if (isDisabled) return;
                          triggerAnnounceRead({
                            command,
                            id: i.id,
                          });
                        }
                      }}
                    >
                      {i.titleUrl ? (
                        <img
                          src={
                            process.env.REACT_APP_CHATROOM_IMAGE_URL +
                            i.titleUrl
                          }
                          className="wii-h-10 wii-w-80 wii-overflow-hidden wii-object-cover"
                          alt={i.title}
                        />
                      ) : (
                        <p className="wii-w-full wii-cursor-pointer wii-truncate wii-text-sm wii-font-bold hover:wii-text-foreground hover:wii-underline hover:wii-underline-offset-2">
                          {i.title}
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {announceItem !== null &&
              announceList?.[announceItem].module !== null && (
                <div
                  className={cn(
                    "bulletin-module wii-relative wii-top-0 wii-z-[6] wii-h-full wii-w-full wii-overflow-y-auto wii-shadow-xl",
                    announceItem !== null
                      ? "wii-visible wii-h-fit wii-translate-y-0"
                      : "wii-invisible -wii-translate-y-[48px]"
                  )}
                  style={{ backgroundColor: "white" }}
                  onClick={(e) => {
                    const ele = e.target as HTMLElement;
                    const tagName = ele.tagName.toLowerCase();
                    if (tagName === "button" || tagName === "a") {
                      handleClickReset();
                    }
                  }}
                >
                  <MessageItemsModule
                    chatContents={[announceList?.[announceItem].module]}
                    isEnd={isEnd}
                  />
                </div>
              )}
          </div>
        )}

        {!bulletin && hasTopItem && (
          <div
            className={cn(
              "wii-relative wii-h-10 wii-w-full wii-bg-answer wii-shadow-lg",
              announceItem !== null ? "wii-invisible" : "wii-z-[100]",
              topItem ? "wii-h-full wii-overflow-y-auto wii-pb-6" : ""
            )}
          >
            <div className="">
              {topList.slice(0, !topItem ? 1 : topList.length).map((i, idx) => {
                const user = i.user;
                const conversation = i.conversation;
                const prefixName =
                  user?.role === "Bot" || user?.role === "Cs"
                    ? `[${user.role}] `
                    : undefined;

                const tagMessage =
                  typeof conversation?.chatText === "string" &&
                  conversation?.chatTextTypeName === "Text" &&
                  (i.user.role === "Bot" || i.user.role === "Cs")
                    ? parse(conversation.chatText.replaceAll("<br/>", "") ?? "")
                    : conversation?.chatText ?? "";

                return (
                  <div
                    className={cn(
                      "wii-flex wii-w-full wii-min-w-0 wii-grow wii-flex-col wii-overflow-hidden wii-truncate wii-px-8 wii-pt-1",
                      "wii-bg-answer",
                      " hover:wii-bg-[--theme-background]",
                      !conversation?.isShow
                        ? "wii-cursor-default"
                        : "wii-cursor-pointer"
                    )}
                    key={conversation?.id ?? idx}
                    onClick={(e) => {
                      e.stopPropagation();
                      scrollToTag(i?.conversation?.id, !!conversation?.isShow);
                      if (conversation?.isShow) {
                        handleClickReset();
                      }
                    }}
                  >
                    <ContextMenu.Root>
                      <ContextMenu.Trigger disabled={isDisabled}>
                        <span
                          className={cn(
                            "wii-max-h-5 wii-w-full wii-min-w-0 wii-truncate wii-text-sm wii-font-bold [&>*]:!wii-my-0",
                            "wii-my-1.5",
                            "wii-w-full hover:wii-text-foreground ",
                            "wii-flex",
                            !conversation?.isShow
                              ? "wii-cursor-default"
                              : "hover:wii-underline hover:wii-underline-offset-2 [&>*]:!wii-cursor-pointer"
                          )}
                        >
                          <span className="wii-mr-1 wii-block">
                            {idx + 1}.{" "}
                          </span>
                          {conversation?.chatTextTypeName === "Image" &&
                            conversation.isShow &&
                            !conversation.isDeleted && (
                              <Picture
                                key={conversation?.chatText ? "show" : "hidden"}
                                className={
                                  "wii-mx-2 wii-h-5 wii-w-fit wii-object-scale-down"
                                }
                                url={conversation?.chatText}
                                domain={
                                  process.env.REACT_APP_CHATROOM_IMAGE_URL
                                }
                                pictureMemo=""
                              />
                            )}

                          {conversation?.chatTextTypeName === "Image" &&
                            !conversation.isShow && (
                              <span className="wii-w-full wii-min-w-0 wii-truncate wii-text-[0.8rem] wii-italic">
                                {t("image-under-review")}
                              </span>
                            )}

                          <span className="wii-max-h-5 wii-w-full wii-min-w-0 wii-truncate [&>*]:!wii-my-0">
                            {conversation?.isShield
                              ? currentChatRoom?.shieldText
                              : conversation?.chatTextTypeName === "Delete" ||
                                conversation?.isDeleted
                              ? t("message-be-deleted")
                              : conversation?.chatTextTypeName === "Text"
                              ? tagMessage
                              : ""}
                          </span>
                        </span>

                        {topItem && (
                          <span className="wii-mb-2 wii-w-full wii-min-w-0 wii-truncate wii-text-xs">
                            {prefixName
                              ? prefixName + (user.name ?? "")
                              : user.name ?? ""}
                          </span>
                        )}
                      </ContextMenu.Trigger>
                      <ContextMenu.Portal>
                        <ContextMenu.Content
                          className="wii-z-[1005] wii-min-w-[8rem] wii-overflow-hidden wii-rounded-md wii-border-2 wii-border-background wii-bg-answer wii-py-1.5 wii-shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
                          // @ts-ignore
                          sideOffset={0}
                          align="end"
                        >
                          <ContextItemWrap
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onSelect={(e) => {
                              e.stopPropagation();

                              if (!conversation) return;

                              const id = conversation?.id;
                              const findItem = replaceConversationMultiItem(
                                responseMessage,
                                +id,
                                ["isTop", !conversation.isTop]
                              );
                              setResponseMessage(findItem);
                              triggerTop({
                                command,
                                optionSwitch: false,
                                id: conversation?.id,
                              })
                                .unwrap()
                                .then((data) => {
                                  if (data.data.status === CustomStatus.Err) {
                                    setResponseMessage(responseMessage);
                                  }
                                })
                                .catch((err) => {
                                  setResponseMessage(responseMessage);
                                });
                            }}
                          >
                            {t("unpin")}
                          </ContextItemWrap>
                        </ContextMenu.Content>
                      </ContextMenu.Portal>
                    </ContextMenu.Root>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {(topItem || announceItem !== null) && (
        <Backdrop>
          <Backdrop.Item
            onClick={handleClickReset}
            className={cn(
              "wii-z-[2] wii-bg-transparent wii-blur-xl",
              "wii-overflow-hidden"
            )}
          />
        </Backdrop>
      )}
    </>
  );
};

export default Bulletin;
