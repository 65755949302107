import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import * as signalR from "@microsoft/signalr";

import { HubConnection } from "@microsoft/signalr";

import { CHATROOM_HUBNAME, WII_MEMBER_STORAGE } from "@/utils/constants";
import { OnChatRoomNotifyMessageType, WaitForVerifyArgs } from "../method";
import { useAppDispatch, useChatRoomUser } from "@/store/store";

import { useMemberStore } from "@/hooks";
import { useGetSignalRNoQuery } from "@/services/api/Account/accountApi";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";
import { ChatroomActions } from "@/store/chatRoom-slice";
import { chatbotApi } from "@/services/api/Chatbot/botApi";
import { useSignalrOnline } from "@/hooks/useOnline";
import { useLoadChatRoomMutation } from "@/services/api/ChatRoom/chatRoomApi";
import { encryptToSend } from "@/utils/crypto";

type SignalRProviderState = {
  SignalRHub: HubConnection | null;
  connectSignalR: () => Promise<void>;
  disconnectSignalR: () => Promise<void>;
  connectionId: string | null;
  isLoading: boolean;
  isOnline: boolean;
};

export const SignalRChatRoomContext = createContext<
  SignalRProviderState | undefined
>(undefined);

SignalRChatRoomContext.displayName = "SignalRChatRoomContext";

/**
 *
 * @param cb: get connectId
 */
async function wiiAsyncConnect(cb: (connectId: string) => Promise<void>) {
  const connectId = window.WiiApp._wiiSignalRKey ?? "";
  await cb(connectId);
}

export const SignalRChatRoomProvider = (props: PropsWithChildren<{}>) => {
  const [SignalRHub, setSignalRHub] = useState<HubConnection | null>(null);
  const [connectionId, setConnectionId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { data: signalrHubData } = useGetSignalRNoQuery();
  const [isOnline, setIsOnline] = useSignalrOnline();
  const [triggerLoadChatRoom] = useLoadChatRoomMutation();

  const member = useMemberStore();
  const user = useChatRoomUser();

  const hubNo = signalrHubData?.data?.signalRNo ?? "";

  const { data: botSetting } = useGetBotSettingsHubQuery();

  const isChatRoom = botSetting?.kind === "ChatRoom";

  const connectSignalR = useCallback(async () => {
    if (SignalRHub) {
      await SignalRHub.stop();
    }
    if (!isChatRoom && !member.memberInfo.memberKey) return;

    setIsLoading(true);

    window.WiiApp.connectedState = "unknown";

    let newConnectionUrl = `${process.env.REACT_APP_SIGNALR_URL}${CHATROOM_HUBNAME}${hubNo}`;

    if (!isChatRoom) {
      newConnectionUrl = `${process.env.REACT_APP_SIGNALR_URL}${CHATROOM_HUBNAME}${hubNo}?userId=${member.memberInfo.memberKey}`;
    }

    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(newConnectionUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    window.WiiApp.connectedState = "connecting";
    newConnection.on("OnChatRoomNotifyMessage", (data: WaitForVerifyArgs) => {
      if (data.action === "WaitForVerify") {
        console.log(data.message);
        window.WiiApp._wiiSignalRKey = data.message;
        setConnectionId(data.message);
      }
    });

    try {
      await newConnection.start();
      window.WiiApp.connectedState = "connected";
      setSignalRHub(newConnection);
      setIsLoading(false);
    } catch (error) {
      setTimeout(() => connectSignalR(), 5000);
      console.error("Signalr Connection Error: ", error);

      window.WiiApp.connectedState = "disconnected";
    }
  }, [SignalRHub, hubNo, isChatRoom, member.memberInfo.memberKey]);

  const disconnectSignalR = useCallback(async () => {
    if (SignalRHub) {
      try {
        await SignalRHub.stop();

        setSignalRHub(null);
      } catch (error) {
        console.error("SignalR Disconnection Error: ", error);
      }
    }
  }, [SignalRHub]);

  useEffect(() => {
    if (SignalRHub) {
      SignalRHub.onreconnecting(() => {
        setIsOnline(false);
      });
      SignalRHub.onreconnected((connectedId) => {
        if (connectedId) {
          console.log("reconnected");
          window.WiiApp._wiiSignalRKey = connectedId;
          setConnectionId(connectedId);
        }

        if (user.userId) {
          const command = encryptToSend({
            role: user.userRole,
            content: user.userId as "Member" | "Cs",
          });

          triggerLoadChatRoom({ command });
          setIsOnline(true);
        }
      });
    }
  }, [
    SignalRHub,
    setIsOnline,
    triggerLoadChatRoom,
    user.userId,
    user.userRole,
  ]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window.WiiApp) return;

    window.WiiApp.init = connectSignalR;
    window.WiiApp.connect = wiiAsyncConnect;
  }, [connectSignalR]);

  useEffect(() => {
    if (!isChatRoom) return;
    if (SignalRHub === null && user.userRole === undefined) {
      connectSignalR();
    }
    return () => {
      if (user.userRole) {
        disconnectSignalR();
      }
    };
  }, [
    SignalRHub,
    connectSignalR,
    disconnectSignalR,
    isChatRoom,
    user.userRole,
  ]);

  useEffect(() => {
    if (SignalRHub && user.userRole === undefined) {
      SignalRHub.on(
        "OnChatRoomNotifyMessage",
        (data: OnChatRoomNotifyMessageType) => {
          if (data.action === "StartChatRoom") {
            const userInfo = data.message.chatRoomUserInfo;
            const myChatRooms = data.message.chatRoom;
            const token = data.message.token;

            window.WiiApp.role = userInfo.userRole;

            if (!myChatRooms) return;

            dispatch(
              ChatroomActions.setUser({
                userId: userInfo.userId,
                userRole: userInfo.userRole,
              })
            );

            dispatch(
              ChatroomActions.setChat({
                roomId: myChatRooms.roomId,
              })
            );

            // TODO: TOKEN
            localStorage.setItem(WII_MEMBER_STORAGE, token || "");

            // TODO: chatRoomColor
            dispatch(
              chatbotApi.util.updateQueryData(
                "getBotUserInfo",
                signalrHubData?.data?.signalRNo !== undefined
                  ? `${signalrHubData?.data?.signalRNo}`
                  : "",
                (sss) => {
                  return {
                    ...sss,
                    color: {
                      primary: myChatRooms.colorUrl,
                      backend: myChatRooms.colorBackUrl,
                      demand: myChatRooms.colorDemandUrl,
                      supply: myChatRooms.colorSupplyUrl,
                    },
                  };
                }
              )
            );
          }
        }
      );
    }
    return () => {
      if (SignalRHub && user.userRole) {
        SignalRHub.off("OnChatRoomNotifyMessage");
      }
    };
  }, [
    SignalRHub,
    dispatch,
    hubNo,
    signalrHubData?.data?.signalRNo,
    user.userRole,
  ]);

  const value = {
    SignalRHub: SignalRHub,
    connectionId: connectionId,
    connectSignalR: connectSignalR,
    disconnectSignalR: disconnectSignalR,
    isLoading: isLoading,
    isOnline,
  };

  return (
    <SignalRChatRoomContext.Provider value={value}>
      {props.children}
    </SignalRChatRoomContext.Provider>
  );
};

export const useSignalRChatRoom = () => {
  const context = useContext(SignalRChatRoomContext);

  if (context === undefined) {
    throw new Error(
      `useSignalRChatRoom must be used within a SignalRChatRoomProvider`
    );
  }

  return context;
};
