import { useCallback, useSyncExternalStore } from "react";
import {
  SiteUserSchemaType,
  getSiteStoreUser,
} from "../utils/embed/get-site-auth";
import { EMBEDDED_STORAGE } from "../utils/constants";

export const useLocalStorage = () => {
  const item = useSyncExternalStore(subscribe, getSnapshot, () => undefined);

  const value: SiteUserSchemaType =
    typeof item === "string"
      ? { ...JSON.parse(item), memberKey: +JSON.parse(item).memberKey }
      : {
          memberKey: 0,
          token: "",
        };

  const setValue = useCallback((data: SiteUserSchemaType) => {
    const memberKeyToString = data.memberKey.toString();
    localStorage.setItem(
      EMBEDDED_STORAGE,
      JSON.stringify({
        ...data,
        memberKey: memberKeyToString,
      } as SiteUserSchemaType)
    );
    window.dispatchEvent(new StorageEvent("storage"));
  }, []);

  return [value, setValue] as const;
};

function subscribe(callback: () => void) {
  window.addEventListener("storage", callback);
  return () => {
    window.removeEventListener("storage", callback);
  };
}

function getSnapshot() {
  return JSON.stringify(getSiteStoreUser({ storeName: EMBEDDED_STORAGE }));
}
