import { useMemo, useState } from "react";
import StarIcon from "../../Icon/StarIcon";
import { useTranslation } from "react-i18next";

type RateFieldProps = {
  onChange: (input: number) => void;
  amount: number;
  activateStar: number;
  defaultStar: number;
};

const RateContent = (props: RateFieldProps) => {
  const { amount, activateStar, onChange, defaultStar = 5 } = props;
  const [onHover, setOnHover] = useState<number>(defaultStar);

  const [t] = useTranslation();

  const starArr = useMemo(() => {
    return Array.from({ length: amount }, (_, index) => index + 1);
  }, [amount]);

  function activeParser(value: number, idx: number): boolean {
    if (value === undefined) return false;
    const currentRate = Number(value);
    return currentRate >= idx;
  }

  return (
    <div className="wii-flex wii-flex-col wii-items-center">
      <div className="wii-mb-2 wii-text-2xl wii-font-bold">
        {t("customer-service-rating")}
      </div>
      <div className="wii-mb-2">{t("customer-service-rating-content")}</div>

      <ul className="wii-mb-4 wii-flex wii-items-center wii-gap-x-1">
        {starArr.map((item) => {
          return (
            <li key={item}>
              <StarIcon
                onMouseEnter={() => {
                  setOnHover(item);
                }}
                onClick={(e) => {
                  setOnHover(item);
                  onChange(item);
                }}
                className={`wii-w-8 wii-cursor-pointer wii-text-amber-300 wii-transition-all wii-duration-150 wii-ease-linear hover:wii-scale-110 ${
                  activeParser(activateStar, item)
                    ? "wii-fill-amber-300 wii-text-amber-300"
                    : activeParser(onHover, item)
                    ? "wii-fill-amber-50 wii-text-amber-300"
                    : "hover:wii-text-amber-300 "
                }`}
                data-rate={item}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RateContent;
