import { PropsWithChildren } from "react";
import { Backdrop } from "../../../components/ui";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

type MenuDrawerProps = {
  show: boolean;
  title: string;
  onClose: () => void;
};
const MenuDrawer = ({
  show,
  title,
  onClose,
  children,
}: PropsWithChildren<MenuDrawerProps>) => {
  const [t] = useTranslation();

  return (
    <Backdrop>
      <Backdrop.Content
        className={`theme-background wii-top-[0px] wii-h-full wii-w-full wii-opacity-95 wii-transition-transform wii-duration-300 wii-ease-in-out ${
          show ? "wii-translate-x-0 wii-shadow-2xl" : "-wii-translate-x-full"
        }`}
      >
        <div className="wii-flex wii-w-full wii-flex-col">
          <div className="theme-color wii-flex wii-items-center wii-gap-x-4 wii-border-b wii-border-b-gray-200 wii-px-6 wii-py-4 wii-font-bold wii-text-white">
            <ArrowLeftOutlined onClick={onClose} />
            <div>{t(title)}</div>
          </div>
          {children}
        </div>
      </Backdrop.Content>
    </Backdrop>
  );
};

export default MenuDrawer;
