import { MouseEventHandler } from "react";

export type BotSetting = {
  name: string;
  headerUrl: string;
  isDisplay: boolean;
  kind: "ChatBot" | "ChatRoom";
  color: {
    primary: string;
    backend: string;
    demand: string;
    supply: string;
  };
};

export type LoginSetting = {
  kind: LoginKind;
  name: string;
  iconUrl: string;
  url: string;
  loginUrl: string;
  thirdPartyEnabled: boolean;
  sort: number;
};

export interface LoginButtonEvent {
  onClick: MouseEventHandler<HTMLElement> | undefined;
}

export enum LoginKind {
  Member = "MB",
  Line = "LN",
  Telegram = "TG",
  Wecaht = "WC",
  Discord = "DC",
  Messenger = "MS",
  Embed = "EM",
}

export type ChatbotState = {
  isGuset: boolean;
  botSetting: BotSetting;
};

export type EnvSetting = {
  API_URL: string;
  PHOTO_URL: string;
};

export enum Site {
  Normai = 1,
  OfficialSite = 2,
}

export type LanguageListItem = {
  code: string;
  key: string;
  name: string;
  isDefault: boolean;
};

export type LanguageListRes = LanguageListItem[];
