import {
  ChatRoomCsUserDetailRes,
  ChatRoomItem,
  ChatRoomMessageListItem,
  ChatRoomMessageReq,
} from "@/models";
import {
  useChatRoomCsUserInfoQuery,
  useChatRoomListQuery,
  useChatRoomMemberInfoQuery,
  useChatRoomMessageReadMutation,
} from "@/services/api/ChatRoom/chatRoomApi";
import { useChatRoomUser } from "@/store/store";
import { CHATROOM_OFFSET } from "@/utils/constants";
import { encryptToSend } from "@/utils/crypto";
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Scrollbar } from "react-scrollbars-custom";

type ChatRoomContextProps = {
  responseMessage: ChatRoomMessageListItem[];
  setResponseMessage: React.Dispatch<
    React.SetStateAction<ChatRoomMessageListItem[]>
  >;
  recordParam: ChatRoomMessageReq | undefined;
  setRecordParam: React.Dispatch<
    React.SetStateAction<ChatRoomMessageReq | undefined>
  >;

  moduleClickBuffer: {
    [key: string]: ChatRoomMessageListItem;
  } | null;
  setModuleClickBuffer: React.Dispatch<
    React.SetStateAction<{
      [key: string]: ChatRoomMessageListItem;
    } | null>
  >;

  command: string;
  scrollbarRef: React.RefObject<Scrollbar>;
  toShowOrToScroll: (data: {
    isEnd: boolean;
    reply: ChatRoomMessageListItem;
    id: string | undefined;
  }) => void;
  currentChatRoom: ChatRoomItem | undefined;
  currentId: React.MutableRefObject<string | undefined>;
  name: string;
  csAuthority: ChatRoomCsUserDetailRes["authority"];
};

const ChatRoomContext = createContext<ChatRoomContextProps | undefined>(
  undefined
);
ChatRoomContext.displayName = "ChatRoomContext";

type ChatRoomProviderProps = {
  tag?: string;
};

export const ChatRoomProvider: React.FC<
  PropsWithChildren<ChatRoomProviderProps>
> = (props) => {
  const { children } = props;

  const scrollbarRef = useRef<Scrollbar>(null);
  const user = useChatRoomUser();

  const command = useMemo(() => {
    return user.userId !== null
      ? encryptToSend({ role: user.userRole, content: user.userId })
      : "";
  }, [user.userId, user.userRole]);

  const { data: userInfo } = useChatRoomMemberInfoQuery(
    {
      command: command,
      id: user.userId!,
    },
    { skip: command === "" || user.userRole !== "Member" }
  );

  const { data: csInfo } = useChatRoomCsUserInfoQuery(
    {
      command: command,
      id: user.userId!,
    },
    { skip: command === "" || user.userRole !== "Cs" }
  );

  const csAuthority = csInfo?.data?.authority ?? {
    isGrantNoTalking: false,
    isGrantKickOut: false,
    isGrantUploadImage: false,
    isGrantHeadImage: false,
    isGrantShield: false,
  };

  const name =
    user.userRole === "Member" ? userInfo?.data.name : csInfo?.data.name;

  const currentChatRoom = useCurrentChatRoomInfo(command, user.userId);

  const currentId = useRef<string | undefined>(undefined);
  const readLatestId = useRef<string | undefined>(undefined);
  const unreads = useRef<string | undefined>(undefined);

  const [triggerRead] = useChatRoomMessageReadMutation();

  const [responseMessage, setResponseMessage] = useState<
    ChatRoomMessageListItem[]
  >([]);

  const [moduleClickBuffer, setModuleClickBuffer] = useState<{
    [key: string]: ChatRoomMessageListItem;
  } | null>(null);

  const [recordParam, setRecordParam] = useState<
    ChatRoomMessageReq | undefined
  >(undefined);

  currentId.current =
    responseMessage[responseMessage.length - 1]?.conversation?.id;

  readLatestId.current = currentChatRoom?.readLatestId ?? undefined;

  useEffect(() => {
    if (!currentChatRoom?.roomId) return;

    if (readLatestId.current !== undefined) {
      const sumId = +readLatestId.current;
      setRecordParam({
        command: command,
        id: `${sumId}`,
        offsetAbove: CHATROOM_OFFSET.offsetAbove,
        offsetBelow: CHATROOM_OFFSET.offsetBelow,
      });
    } else {
      setRecordParam({
        command: command,
        id: undefined,
        offsetAbove: CHATROOM_OFFSET.offsetAbove,
        offsetBelow: CHATROOM_OFFSET.offsetBelow,
      });
    }
  }, [command, currentChatRoom?.roomId]);

  useEffect(() => {
    if (!command) return;

    if (!currentChatRoom?.roomId) return;

    const readLitestId =
      readLatestId.current !== undefined ? +readLatestId.current : 0;

    let recordId = readLitestId;

    if (currentId.current !== undefined && +currentId.current > +readLitestId) {
      recordId = +currentId.current;
    }

    triggerRead({
      command,
      id: `${recordId}`,
    });

    return () => {
      if (!command) return;

      const readLitestId =
        readLatestId.current !== undefined ? +readLatestId.current : 0;

      if (
        currentId.current !== undefined &&
        +currentId.current > +readLitestId
      ) {
        recordId = +currentId.current;
      }

      triggerRead({
        command,
        id: recordId !== undefined ? `${recordId}` : recordId,
      });
    };
  }, [command, currentChatRoom?.roomId, triggerRead]);

  function toShowOrToScroll({
    isEnd,
    reply,
    id,
  }: {
    isEnd: boolean;
    reply: ChatRoomMessageListItem;
    id: string | undefined;
  }) {
    if (isEnd) {
      scrollbarRef?.current?.scrollToBottom();
      setResponseMessage((prev) => {
        return [...prev, reply];
      });

      setTimeout(() => {
        if (scrollbarRef.current) {
          scrollbarRef?.current?.scrollToBottom();
        }
      }, 100);
    } else {
      if (id) {
        setModuleClickBuffer((prev) => {
          if (prev !== null) {
            return { ...prev, [id]: reply };
          }
          return {
            [id]: reply,
          };
        });
      }
      setRecordParam((prev) => {
        if (prev !== undefined) {
          return {
            ...prev,
            offsetAbove: CHATROOM_OFFSET.offsetAbove,
            offsetBelow: 12,
            id: undefined,
          };
        }
        return {
          command,
          offsetAbove: CHATROOM_OFFSET.offsetAbove,
          offsetBelow: 12,
          id: undefined,
        };
      });
    }
  }

  return (
    <ChatRoomContext.Provider
      value={{
        command,
        currentChatRoom,
        scrollbarRef,
        responseMessage,
        setResponseMessage,
        recordParam,
        setRecordParam,
        moduleClickBuffer,
        setModuleClickBuffer,
        toShowOrToScroll,
        currentId: currentId,
        name: name ?? "",
        csAuthority: csAuthority,
      }}
    >
      {children}
    </ChatRoomContext.Provider>
  );
};

export const useConversation = () => {
  const context = useContext(ChatRoomContext);
  if (context === undefined) {
    throw new Error(`useConversation must be used within a ChatRoomContext`);
  }

  return context;
};

export const useCurrentChatRoomInfo = (
  command: string,
  userId: string | null
) => {
  const { data } = useChatRoomListQuery(
    {
      command,
    },
    {
      skip: command === "",
    }
  );

  const list = useMemo(() => {
    return data?.data?.list?.find((i) => i.id === userId);
  }, [data, userId]);

  return list;
};
