import { FC, Fragment, memo, useState } from "react";
import { useChatRoomUser } from "@/store/store";

import parse from "html-react-parser";

import { Message } from "../../components";

import {
  ChatRoomMessageListItem,
  ChatRoomMessageListType,
  ResponseMessageProp,
} from "@/models";

import { useChatRoomQuestionAnswerMutation } from "@/services/api/ChatRoom/chatRoomApi";

import { useConversation } from "@/chatroom/chatRoom.context";

const QuestionMessage: FC<ResponseMessageProp & { isEnd: boolean }> = ({
  responseMessage,
  isEnd,
  taskType,
  isReplyHide,
}) => {
  const { command, toShowOrToScroll, currentChatRoom, name } =
    useConversation();

  const { isDisabled = false } = currentChatRoom ?? {};

  const { userId, userRole } = useChatRoomUser();

  const [replyStatus, setReplyStatus] = useState(false);

  const [triggerQuestionAnswer, stateQuestionAnswer] =
    useChatRoomQuestionAnswerMutation();

  const sendAnswerMessage = async ({ replyId }: { replyId: number }) => {
    if (userRole === undefined) return;

    setReplyStatus(isReplyHide);
    const result = await triggerQuestionAnswer({
      command,
      taskType,
      replyId,
      roomSessionNo: currentChatRoom!.roomSessionNo,
    }).unwrap();

    const reply: ChatRoomMessageListItem = {
      type: ChatRoomMessageListType.Module,
      time: new Date().toISOString(),
      user: {
        roleId: userId!,
        name: name,
        headerUrl: "",
        role: "Bot",
      },
      module: result.data.module,
      conversation: null,
    };

    toShowOrToScroll({ isEnd, reply, id: result.data.id });
  };

  return (
    <>
      {responseMessage.chatContents.map((chatContent, index) => {
        return (
          <Fragment key={"q" + index}>
            <Message
              message={parse(chatContent.text)}
              datetime={responseMessage.createdTime}
            />
            <div className="wii-flex wii-flex-wrap wii-space-x-1">
              {replyStatus ? (
                <></>
              ) : (
                chatContent.replies.map((reply, index) => (
                  <button
                    disabled={isDisabled || stateQuestionAnswer.isLoading}
                    key={`r${reply.replyId}-${index}`}
                    className="reply-btn"
                    onClick={() => {
                      sendAnswerMessage({
                        replyId: reply.replyId,
                      });
                    }}
                  >
                    {reply.text}
                  </button>
                ))
              )}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(QuestionMessage);
