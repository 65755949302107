import { FC, memo } from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Picture } from "@/components";
import { ResponseMessageProp } from "@/models";

import styles from "./PictureMessage.module.scss";
import PictureButton from "./PictureButton";

const PictureMessage: FC<ResponseMessageProp & { isEnd: boolean }> = ({
  responseMessage,
  taskType,
  isEnd,
}) => {
  if (responseMessage.chatRecordType === "Q") {
    return (
      <div className="wii-mr-4 wii-mt-2 wii-flex wii-justify-end">
        {responseMessage.chatContents.map((chatContent, index) => (
          <SwiperSlide
            key={chatContent.pictureUrl ?? index}
            className={styles["w-pic"]}
          >
            <a
              className="wii-flex wii-flex-col wii-items-end wii-rounded-t-md"
              href={chatContent?.pictureSetUrl}
              target={"_blank"}
              rel="noreferrer"
            >
              <Picture
                className="h-pic-msg wii-w-fit wii-rounded-t-md wii-object-scale-down"
                url={chatContent.pictureUrl || ""}
                pictureMemo={chatContent.pictureMemo}
                domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
              />
            </a>
            <PictureButton
              data={chatContent.replies}
              taskType={taskType}
              isEnd={isEnd}
            />
          </SwiperSlide>
        ))}
      </div>
    );
  }

  return (
    <>
      <div className="wii-mt-2 wii-pr-2">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={10}
          grabCursor={true}
          centeredSlides={false}
          navigation={true}
          modules={[Navigation]}
        >
          {responseMessage.chatContents.map((chatContent, index) => (
            <SwiperSlide
              key={chatContent.pictureUrl ?? index}
              className={styles["w-pic"]}
            >
              <a
                className="wii-flex wii-flex-col wii-rounded-t-md"
                href={chatContent?.pictureSetUrl}
                target={"_blank"}
                rel="noreferrer"
              >
                <Picture
                  className="h-pic-msg wii-rounded-t-md wii-object-scale-down"
                  url={chatContent.pictureUrl || ""}
                  pictureMemo={chatContent.pictureMemo}
                  domain={process.env.REACT_APP_IMAGE_URL}
                />
              </a>
              <PictureButton
                data={chatContent.replies}
                taskType={taskType}
                isEnd={isEnd}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default memo(PictureMessage);
