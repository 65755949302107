import React from "react";

const ThemeLayout: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;

  return (
    <div className="theme-color wii-relative wii-top-0 wii-flex wii-h-14 wii-items-center wii-p-2 wii-text-white">
      {children}
    </div>
  );
};

export default ThemeLayout;
