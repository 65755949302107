import { z } from "zod";

const siteUserSchema = z.object({
  token: z.string(),
  memberKey: z.union([z.string(), z.number()]),
});

export type SiteUserSchemaType = z.infer<typeof siteUserSchema>;

export function getSiteStoreUser({
  storeName,
}: {
  storeName: string;
}): SiteUserSchemaType {
  if (!window)
    return {
      memberKey: 0,
      token: "",
    };

  const siteUser: unknown = JSON.parse(localStorage.getItem(storeName) || "{}");
  const validateUser = siteUserSchema.safeParse(siteUser);

  if (!validateUser.success) {
    localStorage.removeItem(storeName);
    return {
      memberKey: 0,
      token: "",
    };
  }
  const memberKeyToString = z.coerce
    .string()
    .safeParse(validateUser.data.memberKey);

  if (memberKeyToString.success) {
    return { ...validateUser.data, memberKey: memberKeyToString.data };
  }

  return validateUser.data;
}
