import { PropsWithChildren, useEffect, useState, useTransition } from "react";

import { themeParser } from "../../utils/check-theme";

import { useAppDispatch, useTypedSelector } from "../../store/store";
import { TogglesActions } from "../../store/toggles-slice";
import styles from "../../App.module.scss";
import Image from "../Image";
import { useGetLanguageListQuery } from "../../services/api/Account/accountApi";
import {
  CODE_LANG_STORAGE,
  KEY_LANG_STORAGE,
  LANG_HEADER,
  SLEEP_ICON,
} from "../../utils/constants";
import i18n from "../../i18n";
import axios from "axios";
import { hexToRGB } from "@/utils/hex-to-rgb";
import { useGetBotSettingsHubQuery } from "@/store/hooks/useGetBotSettingsHubQuery";

const ThemeComponent = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const [sleepBot, setSleepBot] = useState(false);
  const [isPending, startTransition] = useTransition();

  // check default lang and localstorage lang and set to header
  const { data: langOption } = useGetLanguageListQuery();

  const { data: botSetting } = useGetBotSettingsHubQuery();

  const dispatch = useAppDispatch();

  const showBotIcon = useTypedSelector((state) => state.toggles.showBotIcon);

  useEffect(() => {
    if (!showBotIcon) {
      const containerEle =
        document.getElementById("wii-modal-container") !== null;

      setTimeout(() => {
        startTransition(() => {
          setSleepBot(containerEle);
        });
      });
    } else {
      setSleepBot(false);
    }
  }, [showBotIcon]);

  useEffect(() => {
    if (langOption) {
      const browserLanguage = navigator.language;
      const hasLangSetting = langOption.find((i) => i.code === browserLanguage);
      if (hasLangSetting) {
        i18n.changeLanguage(hasLangSetting.code);
        axios.defaults.headers.common[LANG_HEADER] = hasLangSetting.key;
        return;
      }

      const defaultLang = langOption.find((i) => i.isDefault);
      if (defaultLang) {
        i18n.changeLanguage(defaultLang.code);
        axios.defaults.headers.common[LANG_HEADER] = defaultLang.key;
        window.localStorage.setItem(CODE_LANG_STORAGE, defaultLang.code);
        window.localStorage.setItem(KEY_LANG_STORAGE, defaultLang.key);
      }
    }
  }, [langOption]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!window.WiiApp) return;

    const toggleBotOpen = document.querySelector<HTMLDivElement>(
      "wii-chatbot > div > label"
    );
    window.WiiApp.click = () => toggleBotOpen?.click();
  }, []);

  // if (!botSetting || isLoading || langLoading) return <></>;

  const themeTransform = themeParser(botSetting?.color);
  const themeColor = themeTransform?.primary;
  const themeBackground = themeTransform?.backend;
  const themeQuestion = themeTransform?.demand;
  const themeAnswer = themeTransform?.supply;

  const activateIcon = botSetting?.isDisplay;

  const displayOriginalIcon = !!activateIcon;

  const primaryRGB = hexToRGB(botSetting?.color.primary);

  return (
    <>
      <div
        className={
          displayOriginalIcon
            ? `wii-fixed wii-bottom-0.5 wii-right-0.5 wii-z-[1000]`
            : "wii-pointer-events-none"
        }
        hidden={!showBotIcon}
        onClick={(_) => {
          dispatch(TogglesActions.setShowBotIconToggle(false));
        }}
      >
        <label
          htmlFor="chatbot"
          className={`${styles.chatbot} ${
            displayOriginalIcon ? "" : "wii-pointer-events-none"
          }`}
        >
          {displayOriginalIcon ? (
            <Image
              url={botSetting.headerUrl}
              className="wii-toggle-icon wii-h-16"
            />
          ) : (
            <></>
          )}
        </label>
      </div>

      {!isPending && sleepBot && !showBotIcon && displayOriginalIcon ? (
        <div
          className={`wii wii-fixed wii-bottom-0.5 wii-right-0.5 wii-z-[900] `}
          onClick={(_) => {
            const toggleBotClose = document.getElementById("chatbot");
            toggleBotClose?.click();
          }}
        >
          <label className={styles.chatbot} aria-label="hidden-bot">
            <Image
              url={botSetting.headerUrl}
              alt="hidden-bot"
              className="wii-toggle-icon-hidden wii-h-16 wii-animate-fadeRorate wii-opacity-60 [transform:rotateY(180deg)]"
            />
            <img
              src={SLEEP_ICON}
              alt="sleep icon"
              className="wii-toggle-icon-hidden wii-absolute -wii-top-2 wii-right-2 wii-animate-fade wii-opacity-80"
            />
          </label>
        </div>
      ) : null}

      <>{children}</>

      <style
        dangerouslySetInnerHTML={{
          __html: `
      :root {
      --color:${
        botSetting?.color?.primary
          ? botSetting?.color?.primary
          : themeColor?.slice(0, -2)
      };
      --theme-color:${themeColor};
      --theme-background:${themeBackground};
      --theme-question:${themeQuestion};
      --theme-answer:${themeAnswer};
      --color-primary:${primaryRGB};
      --spec-width:260px;
      --pic-message-height:200px;
      `,
        }}
      ></style>
      {/* <style>{`
      :root {
      --color:${
        botSetting?.color?.primary
          ? botSetting?.color?.primary
          : themeColor?.slice(0, -2)
      };
      --theme-color:${themeColor};
      --theme-background:${themeBackground};
      --theme-question:${themeQuestion};
      --theme-answer:${themeAnswer};
      --spec-width:260px;
      --pic-message-height:200px;
      `}</style> */}
    </>
  );
};

export default ThemeComponent;
