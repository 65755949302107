import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  menuToggle: false,
  loginToggle: false,
  messageToggle: false,
  showBotIcon: true,
  invitation: {
    accept: false,
    csId: "",
    csName: "",
  },
};

const togglesSlice = createSlice({
  name: "toggles",
  initialState,
  reducers: {
    setLoginToggle: (state, action: PayloadAction<boolean>) => {
      state.loginToggle = action.payload;
    },
    setMenuToggle: (state, action: PayloadAction<boolean>) => {
      state.menuToggle = action.payload;
    },
    setMessageToggle: (state, action: PayloadAction<boolean>) => {
      state.messageToggle = action.payload;
    },
    setShowBotIconToggle: (state, action: PayloadAction<boolean>) => {
      state.showBotIcon = action.payload;
    },
    setInvitationToggle: (
      state,
      action: PayloadAction<{ accept: boolean; csId: string; csName: string }>
    ) => {
      state.invitation.accept = action.payload.accept;
      state.invitation.csId = action.payload.csId;
      state.invitation.csName = action.payload.csName;
    },
  },
});

export const TogglesActions = togglesSlice.actions;

export default togglesSlice.reducer;
