import { useConversation } from "@/chatroom/chatRoom.context";
import { Image } from "@/components";
import { useChatRoomUsersQuery } from "@/services/api/ChatRoom/chatRoomApi";
import { IMAGE_CHATROOM_DEFAULT } from "@/utils/constants";

const ChatRoomInfo = () => {
  const { currentChatRoom, command } = useConversation();

  const { data: userAmount } = useChatRoomUsersQuery(
    {
      command,
    },
    {
      skip: command === "",
    }
  );

  const sum =
    +(userAmount?.data?.[0]?.members ?? 0) +
    +(userAmount?.data?.[0]?.csUsers ?? 0);

  return (
    <div className="wii-flex wii-flex-grow wii-items-center">
      <div className="wii-w-8 wii-self-center wii-rounded-full">
        {currentChatRoom?.roomName ? (
          <Image
            url={`${process.env.REACT_APP_CHATROOM_IMAGE_URL}${currentChatRoom?.headerUrl}`}
            alt={IMAGE_CHATROOM_DEFAULT.Header}
          />
        ) : null}
      </div>
      <div className="wii-flex wii-items-start wii-pl-2">
        <h2 className="wii-w-fit wii-text-left">
          {currentChatRoom?.roomName ?? "聊天室"}
        </h2>
        {sum ? <p className="wii-ml-1">({sum})</p> : null}
      </div>
    </div>
  );
};

export default ChatRoomInfo;
