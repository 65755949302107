import React, { FC, Fragment, memo, useEffect, useState } from "react";
import TextMessage from "@/chatroom/containers/TextMessage";
import { Picture } from "@/components";
import { cn } from "@/utils/style-merge";

import { MessageItemsModule } from "@/chatroom/Bot/";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {
  DeleteOutlined,
  EditOutlined,
  IssuesCloseOutlined,
  PushpinOutlined,
  RollbackOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  ChatRoomListConversation,
  ChatRoomListTagChat,
  ChatRoomMemberDetailRes,
  ChatRoomMessageListItem,
  ChatRoomMessageListType,
  ChatRoomMessageReq,
  ChatRoomRole,
} from "@/models";
import { CircularProgress, FadingBalls } from "react-cssfx-loading";
import { CHATROOM_OFFSET, IMAGE_CHATROOM_DEFAULT } from "@/utils/constants";
import { encryptToSend } from "@/utils/crypto";
import {
  chatRoomApi,
  useChatRoomCsUserDetailQuery,
  useChatRoomIsDeleteMutation,
  useChatRoomIsHeadImageMutation,
  useChatRoomIsKickOutMutation,
  useChatRoomIsNoTalkingMutation,
  useChatRoomIsShieldMutation,
  useChatRoomIsTopMutation,
  useChatRoomIsUploadImageMutation,
  useChatRoomMemberDetailQuery,
  useLazyChatRoomMemberInfoQuery,
} from "@/services/api/ChatRoom/chatRoomApi";
import { useAppDispatch, useChatRoomUser } from "@/store/store";
import { CustomStatus } from "@/models/format";
import { useConversation } from "@/chatroom/chatRoom.context";
import { FloatModal } from "@/components/Modal";

import InfoModal from "@/components/Modal/InfoModal";
import InfoDetail from "../Menu/Info/InfoDetail";
import { useTranslation } from "react-i18next";
import { ContextItemWrap, IconWrap } from "@/chatroom/containers/ContextMenu";
import dayjs from "dayjs";
import DateAlert from "../Date/DateAlert";
import MessageState from "./MessageState";

import { useSignalRChatRoom } from "@/services/signalr-chat/context/signalR.context";

type MessageItemsProps = {
  isTop: boolean;
  isEnd: boolean;
  searchId: string | number | undefined;
  inputRef: React.RefObject<HTMLInputElement>;
  fetchNextChatMessage: (data: ChatRoomMessageReq) => Promise<any>;
  tagChat: ChatRoomListTagChat | null;
  setTagChat: React.Dispatch<React.SetStateAction<ChatRoomListTagChat | null>>;
  isTopPadding: boolean;
  setShowToBottom: React.Dispatch<React.SetStateAction<boolean>>;
};

const MessageItems: FC<MessageItemsProps> = (props) => {
  const {
    searchId = 0,
    isTop,
    isEnd,
    fetchNextChatMessage,
    tagChat,
    setTagChat,
    inputRef,
    isTopPadding,
    setShowToBottom,
  } = props;

  const [t] = useTranslation();
  const { userId, userRole } = useChatRoomUser();

  const {
    command,
    scrollbarRef,
    responseMessage,
    setResponseMessage,
    setRecordParam,
    moduleClickBuffer,
    currentChatRoom,
    csAuthority,
  } = useConversation();

  const {
    isDisabled = false,
    shieldText = "",
    isChatTextDel = false,
    isChatTextReplace = false,
    isCsChatTextDel = false,
    isCsChatTextReplace = false,
  } = currentChatRoom ?? {};

  const textDel = userRole === "Cs" ? isCsChatTextDel : isChatTextDel;

  const textReplace =
    userRole === "Cs" ? isCsChatTextReplace : isChatTextReplace;

  const skipRequest = command === "";

  const { data: userList } = useChatRoomMemberDetailQuery(
    { command },
    { skip: skipRequest }
  );

  const { data: csList } = useChatRoomCsUserDetailQuery(
    { command },
    { skip: skipRequest }
  );

  const [triggerMemberDetail, memberState] = useLazyChatRoomMemberInfoQuery();
  // const [triggerCsUserDetail, csState] = useLazyChatRoomCsUserInfoQuery();

  const [memoText, setMemoText] = useState<string | undefined>(undefined);

  const [showWarningModal, setShowWarningModal] = useState<
    | undefined
    | {
        key: "isNoTalking" | "isKickOut";
        username: string;
        roleId: string;
        command: string;
        optionSwitch: boolean;
        memo: "";
      }
  >(undefined);

  const [showUserStateModal, setShowUserStateModal] = useState<
    | undefined
    | {
        id: string;
        command: string;
        role: ChatRoomRole;
      }
  >(undefined);

  const dispatch = useAppDispatch();
  const { isOnline } = useSignalRChatRoom();

  const [triggerTop] = useChatRoomIsTopMutation();
  const [triggerShield] = useChatRoomIsShieldMutation();
  const [triggerTalk] = useChatRoomIsNoTalkingMutation();
  const [triggerKick] = useChatRoomIsKickOutMutation();
  const [triggerUpload] = useChatRoomIsUploadImageMutation();
  const [triggerHeadImage] = useChatRoomIsHeadImageMutation();
  const [triggerDelete] = useChatRoomIsDeleteMutation();

  const userInfo = memberState.data?.data.authority ?? {
    isNoTalking: true,
    isUploadImage: false,
    isHeadImage: false,
    isKickOut: false,
  };

  const bottom = responseMessage.length - 1;

  const csActionMap = [
    {
      name: userInfo?.isNoTalking ? t("unmute") : t("mute"),
      value: !!userInfo?.isNoTalking,
      key: "isNoTalking" as keyof ChatRoomMemberDetailRes,
      disabled: !csAuthority.isGrantNoTalking,
      action: triggerTalk,
    },
    {
      name: !userInfo?.isUploadImage
        ? t("allow-image-uploads")
        : t("disallow-image-uploads"),
      value: !!userInfo?.isUploadImage,
      key: "isUploadImage" as keyof ChatRoomMemberDetailRes,
      disabled: !csAuthority.isGrantUploadImage,
      action: triggerUpload,
    },
    {
      name: !userInfo?.isHeadImage
        ? t("allow-profile-picture-changes")
        : t("disallow-profile-picture-changes"),
      value: !!userInfo?.isHeadImage,
      key: "isHeadImage" as keyof ChatRoomMemberDetailRes,

      disabled: !csAuthority.isGrantHeadImage,
      action: triggerHeadImage,
    },
    {
      name: t("remove-from-group"),
      value: !!userInfo?.isKickOut,
      key: "isKickOut",
      disabled: !csAuthority.isGrantKickOut,
      action: triggerKick,
    },
  ];

  /**
   * @param tagId
   * @param showTag 能不能點擊來移動
   * @returns
   */
  async function scrollToTag(tagId: string | undefined, showTag?: boolean) {
    if (!tagId || !showTag) return;

    const currentTagNode = document.querySelector(
      `[data-obs="${tagId}"]`
    ) as HTMLDivElement;

    if (!currentTagNode) {
      setRecordParam((prev) => {
        if (prev) {
          return {
            ...prev,
            offsetAbove: CHATROOM_OFFSET.offsetAbove,
            offsetBelow: CHATROOM_OFFSET.offsetBelow,
            id: tagId,
          };
        }
        return {
          command,
          offsetAbove: CHATROOM_OFFSET.offsetAbove,
          offsetBelow: CHATROOM_OFFSET.offsetBelow,
          id: tagId,
        };
      });
    } else {
      currentTagNode.classList.add("wii-bg-primary/20");
      currentTagNode.classList.add("animate__animated", "animate__headShake");

      const tagNode = currentTagNode?.previousElementSibling;

      const scrollNode = tagNode ?? currentTagNode;

      scrollNode.scrollIntoView({ behavior: undefined, block: "center" });

      setTimeout(() => {
        currentTagNode.classList.remove(
          "wii-bg-primary/20",
          "animate__animated",
          "animate__headShake"
        );
      }, 4000);
    }
  }

  useEffect(() => {
    if (!isOnline) {
      scrollbarRef.current?.scrollToBottom();
    }
  }, [isOnline, scrollbarRef]);

  function replaceConversationMultiItem(
    responseMessage: ChatRoomMessageListItem[],
    id: number,
    ...pairs: [
      keyof ChatRoomListConversation,
      ChatRoomListConversation[keyof ChatRoomListConversation]
    ][]
  ): ChatRoomMessageListItem[] {
    const kV = Object.fromEntries(pairs) as Partial<ChatRoomListConversation>;
    const findItem = responseMessage.map((i) => {
      if (i?.conversation?.id && +i?.conversation?.id === +id) {
        return {
          ...i,
          conversation: {
            ...i?.conversation,
            ...kV,
          },
        };
      }
      return i;
    });

    return findItem;
  }

  function getCsCommand(ownerId: string, memberId: string) {
    const data = `[Cs]${ownerId}[Member]${memberId}`;
    const command = encryptToSend({ content: data });

    return command;
  }

  async function handleGetMemberInfo(ownerId: string, memberId: string) {
    // const command = getCsCommand(ownerId, memberId);
    return await triggerMemberDetail({ command, id: memberId });
  }

  if (responseMessage.length === 0) return null;

  const topItem = responseMessage?.find((i) => i?.conversation !== null);

  const showCsActionState =
    memberState.isSuccess && !memberState.isLoading && !memberState.isFetching;

  const currentUser =
    showUserStateModal !== undefined && showUserStateModal.role === "Member"
      ? userList?.data?.find((i) => i.id === showUserStateModal.id)
      : showUserStateModal !== undefined && showUserStateModal.role === "Cs"
      ? csList?.data?.find((i) => i.id === showUserStateModal.id)
      : undefined;

  const dataMap: { [key: string]: string } = {};
  const today = dayjs(new Date()).format("YYYY-MM-DD");

  const _datelist = responseMessage.reduce(
    (acc: { [key: string]: ChatRoomMessageListItem }, i, index) => {
      const day = dayjs(i.time).format("YYYY-MM-DD");
      if (acc[day]) return acc;

      dataMap[`${index}`] = day;
      acc[day] = i;
      return acc;
    },
    {}
  );

  return (
    <>
      <section className="wii-flex wii-flex-col wii-gap-1 wii-py-4">
        {isTop && isTopPadding && <div className="wii-pt-12"></div>}
        {!isTop && (
          <div
            key={`isTop-${searchId}-${topItem?.conversation?.id ?? 0}`}
            className="wii-flex wii-items-center wii-justify-center wii-py-2"
            ref={(node) => {
              if (!node || isTop || !topItem) return;

              const observer = new IntersectionObserver(
                (entries, obs) => {
                  if (entries[0].isIntersecting) {
                    fetchNextChatMessage({
                      command: command,
                      id: topItem!.conversation!.id,
                      offsetAbove: 20,
                      offsetBelow: 1,
                      dir: "top",
                    }).then(() => {
                      obs.unobserve(node);
                    });
                  }
                },
                {
                  threshold: 0.6,
                }
              );
              observer.observe(node);
            }}
          >
            <FadingBalls color={"gray"} width="0.6rem" height="0.6rem" />
          </div>
        )}
        {responseMessage.map((chatContent, cIndex) => {
          const conversation = chatContent.conversation;
          const user = chatContent.user;
          const module = chatContent.module;
          const chatType = chatContent.type;
          const isOwnConversation = user.roleId === userId;

          if (
            chatType === ChatRoomMessageListType.Conversation &&
            conversation
          ) {
            const chatText =
              user.role === "Bot" &&
              chatContent.type === ChatRoomMessageListType.Conversation &&
              typeof conversation?.chatText === "string"
                ? conversation?.chatText?.replaceAll(
                    'src="Image',
                    `src="${process.env.REACT_APP_IMAGE_URL}Image`
                  )
                : conversation?.chatText;

            const getContent = ({
              isOwnConversation,
              conversation,
              time,
            }: {
              isOwnConversation: boolean;
              conversation: ChatRoomListConversation;
              time: string;
            }) => {
              const defaultProps = {
                time,
                read: false,
                chatRecordType: isOwnConversation ? "Q" : ("A" as "Q" | "A"),
                chatRole: user.role,
                tagChat: conversation.tagChat,
                shieldText,
                scrollToTag,
              };
              if (conversation.isShield) {
                return (
                  <MessageState {...defaultProps}>
                    <TextMessage
                      chatContents={shieldText}
                      chatRole={defaultProps.chatRole}
                    />
                  </MessageState>
                );
              }

              switch (conversation.chatTextTypeName) {
                case "Delete": {
                  return (
                    <MessageState {...defaultProps}>
                      <TextMessage
                        chatContents={t("message-be-deleted")}
                        chatRole={defaultProps.chatRole}
                      />
                    </MessageState>
                  );
                }
                case "Image": {
                  // TODO: image Picture component should add top message
                  return (
                    <MessageState
                      {...defaultProps}
                      className="wii-flex wii-flex-col wii-items-end"
                    >
                      {!!conversation.chatText &&
                        (conversation.isShow ||
                          (!conversation.isShow && isOwnConversation)) &&
                        typeof conversation.chatText === "string" && (
                          <span
                            className={cn(
                              "wii-flex",
                              isOwnConversation ? "wii-ml-auto" : "wii-mr-auto"
                            )}
                          >
                            <Picture
                              className={cn(
                                "h-pic-msg wii-w-fit wii-rounded-md wii-object-scale-down",
                                isOwnConversation
                                  ? "wii-ml-auto"
                                  : "wii-mr-auto",
                                conversation.isShow ? "" : "wii-bg-answer"
                              )}
                              url={chatText}
                              domain={
                                user.role === "Bot"
                                  ? process.env.REACT_APP_IMAGE_URL
                                  : process.env.REACT_APP_CHATROOM_IMAGE_URL
                              }
                            />
                          </span>
                        )}

                      {conversation.isDeleted && (
                        <TextMessage
                          chatContents={t("message-be-deleted")}
                          chatRole={defaultProps.chatRole}
                        />
                      )}

                      {!conversation.isShow && isOwnConversation && (
                        <TextMessage
                          chatContents={t("image-under-review")}
                          chatRole={defaultProps.chatRole}
                        />
                      )}
                    </MessageState>
                  );
                }
                case "Text": {
                  if (conversation?.isDeleted) {
                    return (
                      <MessageState {...defaultProps}>
                        <TextMessage
                          chatContents={t("message-be-deleted")}
                          chatRole={defaultProps.chatRole}
                        />
                      </MessageState>
                    );
                  }
                  return (
                    <MessageState
                      {...defaultProps}
                      edited={conversation?.isReplace}
                    >
                      <TextMessage
                        chatContents={chatText ?? ""}
                        chatRole={defaultProps.chatRole}
                      />
                    </MessageState>
                  );
                }
              }
            };

            if (
              !conversation.isShield &&
              conversation.tagChat?.roleId === userId &&
              moduleClickBuffer &&
              !!moduleClickBuffer[conversation?.id]
            ) {
              return (
                <>
                  {dataMap[cIndex] ? (
                    <DateAlert
                      date={
                        dataMap[cIndex] === today ? t("today") : dataMap[cIndex]
                      }
                    />
                  ) : null}
                  <MessageItemsModule
                    key={conversation?.id}
                    chatContents={
                      [moduleClickBuffer[conversation?.id].module] as any
                    }
                    isEnd={isEnd}
                  />
                </>
              );
            }

            if (
              !conversation.isShow &&
              !isOwnConversation &&
              conversation.chatTextTypeName === "Image"
            ) {
              return (
                <Fragment key={conversation?.id}>
                  {dataMap[cIndex] ? (
                    <DateAlert
                      date={
                        dataMap[cIndex] === today ? t("today") : dataMap[cIndex]
                      }
                    />
                  ) : null}
                </Fragment>
              );
            }

            return (
              <div
                key={conversation?.id}
                className="wii-flex wii-flex-col"
                ref={(node) => {
                  if (!node) return;
                  if (responseMessage?.[bottom]?.conversation?.id === undefined)
                    return;

                  const observer = new IntersectionObserver(
                    (entries, obs) => {
                      if (entries[0].isIntersecting && cIndex === bottom - 1) {
                        setShowToBottom(false);

                        scrollbarRef.current?.scrollToBottom();
                        obs.unobserve(node);
                      } else if (
                        !entries[0].isIntersecting &&
                        cIndex === bottom - 1
                      ) {
                        setShowToBottom(true);
                        obs.unobserve(node);
                      } else {
                        obs.unobserve(node);
                      }
                    },
                    {
                      threshold: 0.6,
                    }
                  );
                  observer.observe(node);
                }}
              >
                {dataMap[cIndex] ? (
                  <DateAlert
                    date={
                      dataMap[cIndex] === today ? t("today") : dataMap[cIndex]
                    }
                  />
                ) : null}

                <div
                  data-obs={conversation?.id}
                  className={cn(
                    "wii-relative wii-flex wii-gap-y-4",
                    isOwnConversation ? "wii-justify-end" : ""
                  )}
                >
                  {!isOwnConversation && (
                    <ContextMenu.Root
                      onOpenChange={async (e) => {
                        if (e && chatContent.user.role === "Member") {
                          await handleGetMemberInfo(userId ?? "", user.roleId);
                        }
                      }}
                    >
                      <ContextMenu.Trigger
                        className="wii-h-fit"
                        disabled={
                          isDisabled ||
                          userRole !== "Cs" ||
                          chatContent.user.role === "Cs" ||
                          chatContent.user.role === "Bot"
                        }
                        onClick={(e) => {
                          if (user.role === "Bot") return;

                          setShowUserStateModal({
                            command,
                            id: user.roleId,
                            role: user.role,
                          });
                        }}
                      >
                        <div className="wii-avatar wii-shrink-0 wii-flex-col wii-justify-end">
                          <div className="wii-m-1 wii-w-8 wii-rounded-full">
                            <Picture
                              url={user?.headerUrl ?? ""}
                              domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                              alt={
                                user.role !== undefined
                                  ? IMAGE_CHATROOM_DEFAULT[
                                      user.role as keyof typeof IMAGE_CHATROOM_DEFAULT
                                    ]
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </ContextMenu.Trigger>

                      <ContextMenu.Portal>
                        <ContextMenu.Content
                          className="wii-z-[1005] wii-min-w-[8rem] wii-overflow-hidden wii-rounded-md wii-border-2 wii-border-background wii-bg-answer wii-py-1.5 wii-shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
                          // @ts-ignore
                          sideOffset={0}
                          align="end"
                        >
                          {!showCsActionState ? (
                            <ContextItemWrap>
                              <IconWrap>
                                <FadingBalls
                                  color="gray"
                                  width="0.5rem"
                                  height="0.5rem"
                                />{" "}
                              </IconWrap>
                            </ContextItemWrap>
                          ) : userInfo.isKickOut ? (
                            <ContextItemWrap className="hover:wii-cursor-default">
                              <IconWrap>
                                <WarningOutlined
                                  color="gray"
                                  width="0.5rem"
                                  height="0.5rem"
                                />
                              </IconWrap>
                              {t("user-has-left")}
                            </ContextItemWrap>
                          ) : (
                            csActionMap.map((i, index) => {
                              return (
                                <ContextItemWrap
                                  key={i.name}
                                  disabled={i.disabled}
                                  onSelect={async (e) => {
                                    const command = getCsCommand(
                                      userId ?? "",
                                      user.roleId
                                    );
                                    if (i.key === "isNoTalking") {
                                      setShowWarningModal({
                                        key: i.key,
                                        username: user.name,
                                        roleId: user.roleId,
                                        command: command,
                                        optionSwitch: !i.value,
                                        memo: "",
                                      });

                                      return;
                                    }

                                    if (i.key === "isKickOut") {
                                      setShowWarningModal({
                                        key: i.key,
                                        username: user.name,
                                        roleId: user.roleId,
                                        command: command,
                                        optionSwitch: true,
                                        memo: "",
                                      });
                                      return;
                                    }

                                    await i.action({
                                      command: command,
                                      optionSwitch: !i.value,
                                      memo: "",
                                    });

                                    dispatch(
                                      chatRoomApi.util.updateQueryData(
                                        "ChatRoomMemberInfo",
                                        { command, id: user.roleId },
                                        (data) => ({
                                          ...data,
                                          data: {
                                            ...data.data,
                                            authority: {
                                              ...data.data.authority,
                                              [i.key]: !i.value,
                                            },
                                          },
                                        })
                                      )
                                    );
                                  }}
                                >
                                  <IconWrap>
                                    <IssuesCloseOutlined />
                                  </IconWrap>

                                  <div>{i.name}</div>
                                </ContextItemWrap>
                              );
                            })
                          )}
                        </ContextMenu.Content>
                      </ContextMenu.Portal>
                    </ContextMenu.Root>
                  )}
                  <ContextMenu.Root>
                    <div className="wii-flex wii-w-full wii-flex-col">
                      {!isOwnConversation && (
                        <span className="wii-ml-2 wii-mt-2 wii-text-xs wii-font-medium wii-text-foreground">
                          {user.name}
                        </span>
                      )}

                      <ContextMenu.Trigger
                        className="message-content wii-group wii-shrink wii-select-none md:wii-select-auto"
                        data-view={
                          !conversation?.isShield &&
                          conversation?.isShow &&
                          !conversation?.isDeleted
                            ? "show"
                            : "ban"
                        }
                        data-top={conversation?.isTop ? "true" : "false"}
                        disabled={isDisabled || !conversation?.isShow}
                      >
                        {getContent({
                          conversation,
                          isOwnConversation,
                          time: chatContent.time,
                        })}
                      </ContextMenu.Trigger>
                    </div>
                    <ContextMenu.Portal>
                      <ContextMenu.Content
                        className="wii-z-[1005] wii-min-w-[8rem] wii-overflow-hidden wii-rounded-md wii-border-2 wii-border-background wii-bg-answer wii-py-1.5 wii-shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]"
                        // @ts-ignore
                        sideOffset={0}
                        align="end"
                        onCloseAutoFocus={(e) => {
                          e.preventDefault();
                        }}
                      >
                        {isOwnConversation &&
                          textDel &&
                          !conversation.isDeleted && (
                            <ContextItemWrap
                              disabled={
                                !conversation ||
                                !conversation?.id ||
                                (conversation && !conversation.isShow)
                              }
                              onSelect={(e) => {
                                e.stopPropagation();

                                if (!conversation?.id) return;
                                setTagChat(null);

                                triggerDelete({
                                  command: command,
                                  id: conversation.id,
                                })
                                  .unwrap()
                                  .then((data) => {
                                    if (data.status === CustomStatus.Err) {
                                      setTagChat(tagChat);
                                    }
                                  })
                                  .catch(() => {
                                    setTagChat(tagChat);
                                  });

                                setTimeout(() => {
                                  if (inputRef.current) {
                                    inputRef.current.focus();
                                  }
                                }, 0);
                              }}
                            >
                              <IconWrap>
                                <DeleteOutlined />
                              </IconWrap>
                              <div>{t("delete")}</div>
                            </ContextItemWrap>
                          )}

                        {isOwnConversation &&
                          textReplace &&
                          !conversation.isDeleted &&
                          conversation.chatTextTypeName !== "Delete" && (
                            <ContextItemWrap
                              disabled={
                                !conversation ||
                                !conversation?.id ||
                                (conversation && !conversation.isShow)
                              }
                              onSelect={(e) => {
                                e.stopPropagation();

                                if (!conversation?.id) return;

                                setTagChat({
                                  id: conversation.id,
                                  chatText: conversation.chatText,
                                  chatTextTypeName:
                                    conversation.chatTextTypeName,
                                  isShield: conversation.isShield,
                                  isShow: conversation.isShow,
                                  roleId: user.roleId,
                                  name: user.name,
                                  role: user.role,
                                  headerUrl: user.headerUrl,
                                  isDeleted: conversation.isDeleted,
                                  isReplace: true,

                                  replyTagType: "Edit",
                                });

                                setTimeout(() => {
                                  if (inputRef.current) {
                                    if (
                                      conversation.chatTextTypeName === "Text"
                                    ) {
                                      inputRef.current.value = chatText;
                                    }
                                    inputRef.current.style.height = `${
                                      inputRef.current?.scrollHeight ?? 40
                                    }px`;

                                    inputRef.current.focus();
                                  }
                                }, 0);
                              }}
                            >
                              <IconWrap>
                                <EditOutlined />
                              </IconWrap>
                              <div>{t("edit")}</div>
                            </ContextItemWrap>
                          )}
                        <ContextItemWrap
                          disabled={
                            !conversation ||
                            !conversation?.id ||
                            (conversation && !conversation.isShow)
                          }
                          onSelect={(e) => {
                            e.stopPropagation();

                            if (!conversation?.id) return;

                            setTagChat({
                              id: conversation.id,
                              chatText: conversation.chatText,
                              chatTextTypeName: conversation.chatTextTypeName,
                              isShield: conversation.isShield,
                              isShow: conversation.isShow,
                              roleId: user.roleId,
                              name: user.name,
                              role: user.role,
                              headerUrl: user.headerUrl,
                              isDeleted: conversation.isDeleted,
                              isReplace: conversation.isReplace,

                              replyTagType: "Reply",
                            });

                            setTimeout(() => {
                              if (inputRef.current) {
                                inputRef.current.focus();
                              }
                            }, 0);
                          }}
                        >
                          <IconWrap>
                            <RollbackOutlined />
                          </IconWrap>
                          <div>{t("reply")}</div>
                        </ContextItemWrap>

                        <ContextItemWrap
                          disabled={
                            !conversation ||
                            (conversation && !conversation.isShow)
                          }
                          onSelect={() => {
                            const id = conversation?.id;
                            const findItem = replaceConversationMultiItem(
                              responseMessage,
                              +id,
                              ["isTop", !conversation.isTop]
                            );
                            setResponseMessage(findItem);
                            triggerTop({
                              command,
                              optionSwitch: !conversation.isTop,
                              id: conversation?.id,
                            })
                              .unwrap()
                              .then((data) => {
                                if (data.data.status === CustomStatus.Err) {
                                  setResponseMessage(responseMessage);
                                }
                              })
                              .catch((err) => {
                                setResponseMessage(responseMessage);
                              });
                          }}
                        >
                          <IconWrap>
                            <PushpinOutlined />
                          </IconWrap>
                          <div>
                            {conversation?.isTop ? t("unpin") : t("pin")}
                          </div>
                        </ContextItemWrap>

                        {userRole === "Cs" && (
                          <>
                            <ContextMenu.Separator className="wii-m-[5px] wii-h-[1px] wii-bg-foreground wii-opacity-20" />

                            <ContextItemWrap
                              onSelect={() => {
                                const command = getCsCommand(
                                  userId ?? "",
                                  user.roleId
                                );
                                const id = conversation.id;
                                const findItem = replaceConversationMultiItem(
                                  responseMessage,
                                  +id,
                                  ["isShield", !conversation.isShield],
                                  [
                                    "chatText",
                                    // @ts-ignore
                                    <CircularProgress
                                      color={"gray"}
                                      width="1.2rem"
                                      height="1.2rem"
                                    />,
                                  ]
                                );
                                setResponseMessage(findItem);
                                triggerShield({
                                  command,
                                  optionSwitch: !conversation.isShield,
                                  id: conversation.id,
                                })
                                  .unwrap()
                                  .then((data) => {
                                    if (data.data.status === CustomStatus.Err) {
                                      setResponseMessage(responseMessage);
                                    } else {
                                      const findItem =
                                        replaceConversationMultiItem(
                                          responseMessage,
                                          +id,
                                          ["isShield", !conversation.isShield],
                                          [
                                            "chatText",
                                            !conversation.isShield
                                              ? shieldText
                                              : data.message || "",
                                          ]
                                        );

                                      const findTagItem = findItem.map((i) => {
                                        if (
                                          i.conversation?.tagChat?.id &&
                                          +i.conversation?.tagChat?.id === +id
                                        ) {
                                          return {
                                            ...i,
                                            conversation: {
                                              ...i.conversation,
                                              tagChat: {
                                                ...i.conversation?.tagChat,
                                                isShield:
                                                  !conversation.isShield,
                                                chatText: !conversation.isShield
                                                  ? shieldText
                                                  : data.message || "",
                                              },
                                            },
                                          };
                                        }
                                        return i;
                                      });

                                      setResponseMessage(findTagItem);
                                    }
                                  })
                                  .catch((err) => {
                                    setResponseMessage(responseMessage);
                                  });
                              }}
                              disabled={
                                !csAuthority.isGrantShield ||
                                !conversation ||
                                !conversation?.id ||
                                (conversation && !conversation.isShow)
                              }
                            >
                              <div className="wii-flex-items-center wii-flex group-data-[highlighted]:wii-text-red-500">
                                <IssuesCloseOutlined />
                              </div>
                              <div>
                                {!conversation.isShield
                                  ? t("block")
                                  : t("unblock")}
                              </div>
                            </ContextItemWrap>
                          </>
                        )}
                      </ContextMenu.Content>
                    </ContextMenu.Portal>
                  </ContextMenu.Root>
                </div>
              </div>
            );
          }

          if (chatType === ChatRoomMessageListType.Module && module) {
            return (
              <Fragment key={`chat-content-${cIndex}`}>
                {dataMap[cIndex] ? (
                  <DateAlert
                    date={
                      dataMap[cIndex] === today ? t("today") : dataMap[cIndex]
                    }
                  />
                ) : null}
                <MessageItemsModule chatContents={[module]} isEnd={isEnd} />
              </Fragment>
            );
          }
          return (
            <Fragment key={`chat-content-${cIndex}`}>
              {dataMap[cIndex] ? (
                <DateAlert
                  date={
                    dataMap[cIndex] === today ? t("today") : dataMap[cIndex]
                  }
                />
              ) : null}
            </Fragment>
          );
        })}

        {!isOnline && <DateAlert date={t("offline")} />}

        {!isEnd && (
          <div
            key={`isBottom-${searchId}-${
              responseMessage?.[bottom]?.conversation?.id ?? 0
            }`}
            className="wii-flex wii-items-center wii-justify-center"
            ref={(node) => {
              if (!node || isEnd) return;
              if (responseMessage?.[bottom]?.conversation?.id === undefined)
                return;

              const observer = new IntersectionObserver(
                (entries, obs) => {
                  if (entries[0].isIntersecting) {
                    fetchNextChatMessage({
                      command: command,
                      id: responseMessage?.[bottom]?.conversation!.id,
                      offsetAbove: 1,
                      offsetBelow: CHATROOM_OFFSET.offsetBelow,
                      dir: "bottom",
                    }).then(() => {
                      obs.unobserve(node);
                    });
                  }
                },
                {
                  threshold: 0.6,
                }
              );
              observer.observe(node);
            }}
          >
            <FadingBalls color={"gray"} width="0.6rem" height="0.6rem" />
          </div>
        )}

        {/* {isEnd && (
          <div
            className="wii-flex wii-h-20 wii-items-center wii-justify-center wii-bg-red-500"
            ref={(node) => {
              if (!node) return;
              if (responseMessage?.[bottom]?.conversation?.id === undefined)
                return;

              const observer = new IntersectionObserver(
                (entries, obs) => {
                  if (entries[0].isIntersecting) {
                    scrollbarRef.current?.scrollToBottom();
                    // obs.unobserve(node);
                  }
                },
                {
                  threshold: 0,
                  rootMargin: "-240px 40px 40px 40px",
                }
              );
              observer.observe(node);
            }}
          >
            22222222222
          </div>
        )} */}

        {showWarningModal !== undefined && (
          <FloatModal
            show={showWarningModal !== undefined}
            confirmText={t("confirm")}
            cancelText={t("back")}
            confirmBtnClass="wii-text-sm wii-btn-sm wii-bg-foreground hover:wii-bg-foreground hover:wii-opacity-80 wii-text-white"
            cancelBtnClass="wii-text-sm wii-btn-sm wii-bg-gray-300 hover:wii-bg-gray-300/80 wii-text-white"
            confirmEvent={() => {
              if (!memoText) return;
              if (showWarningModal?.key === "isNoTalking") {
                const { key, username, ...confirmData } = showWarningModal;
                triggerTalk({
                  ...confirmData,
                  memo: memoText,
                })
                  .unwrap()
                  .then((data) => {
                    dispatch(
                      chatRoomApi.util.updateQueryData(
                        "ChatRoomMemberInfo",
                        { command, id: showWarningModal.roleId },
                        (data) => ({
                          ...data,
                          data: {
                            ...data.data,
                            authority: {
                              ...data.data.authority,
                              [key]: confirmData.optionSwitch,
                            },
                          },
                        })
                      )
                    );
                  })
                  .finally(() => {
                    setMemoText(undefined);
                    setShowWarningModal(undefined);
                  });
              }

              if (showWarningModal?.key === "isKickOut") {
                const { key, username, ...confirmData } = showWarningModal;
                triggerKick({
                  ...confirmData,
                  memo: memoText,
                })
                  .unwrap()
                  .finally(() => {
                    setMemoText(undefined);
                    setShowWarningModal(undefined);
                  });
              }
            }}
            cancelEvent={() => {
              setMemoText(undefined);
              setShowWarningModal(undefined);
            }}
          >
            <>
              <h4 className="wii-mb-1 wii-text-left">
                <div>
                  {showWarningModal?.key === "isNoTalking" &&
                  showWarningModal?.optionSwitch === true
                    ? t("mute")
                    : showWarningModal?.key === "isNoTalking" &&
                      showWarningModal?.optionSwitch === false
                    ? t("unmute")
                    : showWarningModal?.key === "isKickOut"
                    ? t("kick-out")
                    : ""}

                  <strong className="wii-text-foreground">
                    {showWarningModal?.username}
                  </strong>
                </div>
              </h4>

              <label className="wii-mr-auto wii-block wii-w-full wii-pb-2 wii-text-left wii-text-sm">
                {t("reason")} :
              </label>

              <textarea
                rows={2}
                value={memoText === undefined ? "" : memoText}
                className="wii-textarea wii-textarea-bordered wii-max-h-32 wii-min-h-20 wii-w-full"
                onChange={(e) => {
                  setMemoText(e.target.value);
                }}
                onFocus={() => {
                  if (memoText === undefined) {
                    setMemoText("");
                  }
                }}
              />
              {memoText !== undefined && memoText === "" ? (
                <p className="wii-w-fit wii-text-sm wii-text-red-500">
                  {t("reason-require")}
                </p>
              ) : null}
            </>
          </FloatModal>
        )}
      </section>

      {showUserStateModal !== undefined && (
        <InfoModal
          show={showUserStateModal !== undefined}
          title={t("chat-room-info")}
          onClose={() => {
            setMemoText(undefined);
            setShowUserStateModal(undefined);
          }}
        >
          <InfoDetail
            currentUser={currentUser as any}
            auth={userInfo}
            viewMode="view"
          />
        </InfoModal>
      )}
    </>
  );
};

export default memo(MessageItems);
