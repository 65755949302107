import { useEffect } from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { ChatbotService } from "../../../ChatbotService";
import { SignalREvents, SignalREventsArgs } from "../../method";
import { useAppDispatch, useTypedSelector } from "../../../../store/store";
import { csActions } from "../../../../store/cs-slice";
import { useMemberStore, useMessages } from "../../../../hooks";
import transformAnsToQues from "../../../../utils/answer-to-question";
import { SignalrService } from "../..";
import { chatbotApi } from "../../../api/Chatbot/botApi";
import { CS_DIVIDE } from "../../../../utils/constants";
import useLangKey from "../../../../hooks/useLangKey";

const useOnBroadcastMessage = (SignalRHub: HubConnection | null) => {
  const dispatch = useAppDispatch();
  const { chatTimetableId, currentQuestionId, isLink } = useTypedSelector(
    (state) => state.csService
  );
  const member = useMemberStore();

  const [send] = useMessages();

  const { keyLang } = useLangKey();

  useEffect(() => {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected)
    ) {
      SignalRHub.on(
        SignalREvents.OnBroadcastMessage,
        async (data: SignalREventsArgs[SignalREvents.OnBroadcastMessage]) => {
          const maxRetries = 3;
          const retryDelay = 5 * 1000;

          let retryCount = 0;

          if (data.action === "UpdateCSStatus") {
            dispatch(
              chatbotApi.util.invalidateTags(["/api/Chatbot/getCSOnlineStatus"])
            );
            return;
          }
          if (!isLink && chatTimetableId && currentQuestionId) {
            while (retryCount < maxRetries) {
              await ChatbotService.getMemberRealTimeInfo(chatTimetableId).then(
                // eslint-disable-next-line no-loop-func
                async (resp) => {
                  if (resp.isFinish) {
                    // TODO:
                    console.log("對話結束");
                    // SignalRHub.stop();
                    retryCount = maxRetries;
                    return;
                  }
                  if (resp.isCancel) {
                    // TODO:
                    console.log("對話取消");
                    // SignalRHub.stop();
                    retryCount = maxRetries;
                    return;
                  }

                  dispatch(
                    csActions.setConnectRealTimeState({
                      chatTimetableId: chatTimetableId,
                      currentQuestionId: currentQuestionId,
                      enabledWait: resp.enabledWait,
                      csId: resp.csUserId.toString(),
                      isLink: resp.isLink,
                      headerUrl: resp.headerUrl,
                    })
                  );

                  if (resp.isLink) {
                    // TODO: check whether startRealTimeConnect success state
                    await SignalrService.startRealTimeConnect({
                      memberKey: member.memberInfo.memberKey.toString(),
                      csUserId: resp.csUserId.toString(),
                    });
                    send(
                      transformAnsToQues({
                        message: "開始真人客服",
                        replyId: 0,
                        kindId: 0,
                        taskType: CS_DIVIDE,
                        kind: "Divider",
                        chatRecordType: "A",
                      })
                    );

                    send(
                      transformAnsToQues({
                        message: resp.wellcome,
                        replyId: 0,
                        kindId: 0,
                        taskType: "csUser",
                        chatRecordType: "A",
                      })
                    );
                    ChatbotService.recordRealTimeAnswer({
                      chatQuestionId: currentQuestionId!,
                      answer: resp.wellcome,
                      languageAfter: keyLang,
                      isImage: false,
                      isFaq: false,
                    }).then(() => {
                      dispatch(csActions.toggleWaitingQueueModalState(false));
                    });

                    retryCount = maxRetries;
                  } else {
                    retryCount = retryCount + 1;
                    const sleep = (ms: number) =>
                      new Promise((resolve) => setTimeout(resolve, ms));
                    await sleep(retryDelay);
                  }
                }
              );
            }
          }
        }
      );
    }
    return () => {
      if (SignalRHub) {
        SignalRHub.off("OnBroadcastMessage");
      }
    };
  }, [
    isLink,
    keyLang,
    SignalRHub,
    chatTimetableId,
    currentQuestionId,
    dispatch,
    send,
    member.memberInfo.memberKey,
  ]);
};

export default useOnBroadcastMessage;
