import { FC, Fragment, memo, useEffect, useState } from "react";
import { useAppDispatch, useTypedSelector } from "../../store/store";

import parse from "html-react-parser";

import { Message } from "../../components";

import { useMemberStore, useMessages } from "../../hooks";
import { MemberActions, defaultUserInfo } from "../../store/member-slice";

import {
  ActionCodeReplyServiceMappingType,
  ChatFulfillType,
  MissionActionType,
  MissionTaskReq,
  ResponseMessageProp,
  SaveRecordsRequest,
  TChatBotQuestionAnswer,
  TChatReply,
} from "../../models";

import { ChatbotService } from "../../services";
import transformAnsToQues from "../../utils/answer-to-question";

import { useSignalRConnect } from "../../services/signalr/context/signalR.context";
import { csActions } from "../../store/cs-slice";
import { TogglesActions } from "../../store/toggles-slice";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { missionActions } from "../../store/mission-slice";
import { HubConnectionState } from "@microsoft/signalr";
import { useTranslation } from "react-i18next";

const QuestionMessage: FC<ResponseMessageProp> = ({
  responseMessage,
  taskType,
  datetime,
  isReplyHide,
}) => {
  const dispatch = useAppDispatch();
  const { memberInfo } = useMemberStore();
  const [replyStatus, setReplyStatus] = useState(false);
  const [send, receive$] = useMessages();

  const { isLink } = useTypedSelector((state) => state.csService);
  const { idleBotChatTimer, SignalRHub } = useSignalRConnect();
  const [localStorageValue] = useLocalStorage();
  const { currentMissionId } = useTypedSelector(
    (state) => state.missionService
  );

  const [t] = useTranslation();

  const actionCodeReplyServiceMapping: ActionCodeReplyServiceMappingType = {
    S1: (data: SaveRecordsRequest) => {
      if (
        isLink &&
        SignalRHub &&
        (SignalRHub.state === HubConnectionState.Connecting ||
          SignalRHub.state === HubConnectionState.Connected)
      ) {
        dispatch(csActions.toggleRateCsModalState(true));
        return;
      }
      ChatbotService.saveRecords(data).then(() => {
        dispatch(MemberActions.setUserInfo(defaultUserInfo));
        dispatch(missionActions.initialMissionService());
      });
      dispatch(TogglesActions.setShowBotIconToggle(true));
      document.getElementById("wii-modal-close")?.click();
      if (
        SignalRHub &&
        (SignalRHub.state === HubConnectionState.Connecting ||
          SignalRHub.state === HubConnectionState.Connected)
      ) {
        SignalRHub?.stop();
      }
    },
    "S1&S2": (data: SaveRecordsRequest) => {
      ChatbotService.saveRecordsAndNewSession(
        Object.assign(data, {
          fullfillType: ChatFulfillType.EndRemindClose,
        })
      )
        .then((newSession) =>
          dispatch(
            MemberActions.setUserInfo({
              ...memberInfo,
              sessionNo: newSession,
            })
          )
        )
        .finally(() => {
          ChatbotService.getWelcomeInfo().then(send);
        });
      if (SignalRHub && SignalRHub.state === HubConnectionState.Connected) {
        SignalRHub?.stop();
      }
    },
    S3: () => {
      if (!memberInfo.token) {
        dispatch(TogglesActions.setLoginToggle(true));
      }
      dispatch(csActions.isLoginToCsUser(true));
    },
    M0: (data: MissionTaskReq) => {
      ChatbotService.getMissionTask(data).then((resp) => {
        send({
          responseMessage: resp.replyData,
          guideButtons: [],
          taskType: "MBot",
          taskLanguageKey: "TW",
        });
        dispatch(missionActions.initialMissionService());
      });
    },
    M1: (data: MissionTaskReq) => {
      ChatbotService.getMissionTask(data).then((resp) => {
        if (resp.isSuccess) {
          send({
            responseMessage: resp.replyData,
            guideButtons: [],
            taskType: "MBot",
            taskLanguageKey: "TW",
          });
        }
        if (resp.isMission === true) {
          console.log("get mission");
          dispatch(
            missionActions.setMissionState({
              currentMissionId: data.missionId,
              isMission: resp.isMission,
            })
          );
        }
        if (resp.isMission === false) {
          console.log("mission end");
          dispatch(missionActions.initialMissionService());
        }
      });
    },
    MI: () => {},
  };

  const noActionCodeReplyService = (data: TChatBotQuestionAnswer) => {
    ChatbotService.sendAnswerMessage(data).then((resp) => {
      resp.taskType = taskType;

      // NOTE: WebUrl has been setset up
      // resp.responseMessage[0].kind === "WebUrl"
      //   ? window.open(resp.responseMessage[0].chatContents[0].text, "_blank")
      //   : (() => {
      //       send(resp);
      //     })();
      send(resp);
    });
  };

  useEffect(() => {
    const receiveSubscription = receive$().subscribe((_) => {
      setReplyStatus(isReplyHide ?? false);
    });
    return () => {
      receiveSubscription.unsubscribe();
    };
  }, []);

  const sendReplyMessage = ({
    kindId,
    replyId,
    message,
  }: {
    kindId: number;
    replyId: number;
    message: string;
  }) => {
    const ansToQuestion = transformAnsToQues({
      kindId,
      message,
      replyId,
      taskType: "",
      chatRecordType: "Q",
    });

    send(ansToQuestion);
  };

  const sendAnswerMessage = ({
    sessionNo,
    memberKey,
    linkButtonId,
    replyId,
    kindId,
    actionCode,
    message,
    reply,
  }: TChatBotQuestionAnswer & {
    actionCode: keyof ActionCodeReplyServiceMappingType | "" | null;
    message: string;
    reply: TChatReply;
  }) => {
    // NOTE: add to chatbox

    sendReplyMessage({ replyId, kindId, message });

    if (actionCode !== "S1" && actionCode !== "S1&S2") {
      idleBotChatTimer();
    }
    if (!actionCode) {
      noActionCodeReplyService({
        sessionNo,
        memberKey,
        linkButtonId,
        replyId,
        kindId,
        taskType,
      });
      return;
    }
    if (
      actionCode in actionCodeReplyServiceMapping &&
      (actionCode === "S1" || actionCode === "S1&S2")
    ) {
      const params: SaveRecordsRequest = {
        sessionNo,
        memberKey,
        taskType,
        fullfillType: ChatFulfillType.EndRemindClose,
        replyName: "Yes",
        demand: t("end-conversation") + "<q>Yes,60</q><q>No,61</q>",
      };
      actionCodeReplyServiceMapping[actionCode](params);
      return;
    }

    if (actionCode in actionCodeReplyServiceMapping && actionCode === "S3") {
      actionCodeReplyServiceMapping[actionCode]();
      return;
    }

    if (actionCode in actionCodeReplyServiceMapping && actionCode === "M0") {
      const params: MissionTaskReq = {
        missionId: currentMissionId,
        memberKey: memberInfo.memberKey,
        memberId: +localStorageValue.memberKey,
        memberToken: localStorageValue.token,
        sessionNo: memberInfo.sessionNo,
        faqSettingId: 0,
        kindId: reply.kindId,
        replyId: reply.replyId,
        text: reply.text,
        actionType: MissionActionType.Leave,
      };
      actionCodeReplyServiceMapping[actionCode](params);
      return;
    }

    if (actionCode in actionCodeReplyServiceMapping && actionCode === "M1") {
      const params: MissionTaskReq = {
        missionId: reply.linkButtonId,
        memberKey: memberInfo.memberKey,
        memberId: +localStorageValue.memberKey,
        memberToken: localStorageValue.token,
        sessionNo: memberInfo.sessionNo,
        faqSettingId: 0,
        kindId: reply.kindId,
        replyId: reply.replyId,
        text: reply.text,
        actionType: MissionActionType.Enter,
      };

      actionCodeReplyServiceMapping[actionCode](params);

      return;
    }

    console.log(
      "Invalid actionCode, please set up the corresponding  behavior"
    );
  };

  return (
    <>
      {responseMessage.chatContents.map((chatContent, index) => {
        return (
          <Fragment key={"q" + index}>
            <Message
              message={parse(chatContent.text)}
              datetime={responseMessage.createdTime}
            />
            <div className="wii-flex wii-flex-wrap wii-space-x-1">
              {replyStatus ? (
                <></>
              ) : (
                chatContent.replies.map((reply, index) => (
                  <button
                    className="reply-btn"
                    key={`r${reply.replyId}-${index}`}
                    onClick={() => {
                      sendAnswerMessage({
                        sessionNo: memberInfo.sessionNo,
                        memberKey: memberInfo.memberKey,
                        linkButtonId: reply.linkButtonId,
                        kindId: reply.kindId,
                        replyId: reply.replyId,
                        actionCode: reply.actionCode,
                        message: reply.text,
                        reply: reply,
                      });
                    }}
                  >
                    {reply.text}
                  </button>
                ))
              )}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default memo(QuestionMessage);
