import CryptoJS from "crypto-js";

export function encrypt(text: string) {
  const keyHex = CryptoJS.enc.Utf8.parse("9TdqyoADPpnttGHB");
  const ivHex = CryptoJS.enc.Utf8.parse("");
  const encrypted = CryptoJS.AES.encrypt(text, keyHex, {
    iv: ivHex,
  });

  return encrypted.toString();
}

export function decrypt(ciphertext: string) {
  const keyHex = CryptoJS.enc.Utf8.parse("9TdqyoADPpnttGHB");
  const ivHex = CryptoJS.enc.Utf8.parse("");

  const bytes = CryptoJS.AES.decrypt(ciphertext, keyHex, {
    iv: ivHex,
  });

  return bytes.toString(CryptoJS.enc.Utf8);
}

export function encryptToSend({
  role,
  content,
}: {
  role?: "Member" | "Cs";
  content: string;
}): string {
  switch (role) {
    case "Member": {
      return encrypt(`[Member]${content}`);
    }

    case "Cs": {
      return encrypt(`[Cs]${content}`);
    }

    default:
      return encrypt(content);
  }
}

export function encryptLogin(text: string) {
  const keyHex = CryptoJS.enc.Utf8.parse("d4fa8633f2c7fdb6");
  const ivHex = CryptoJS.enc.Utf8.parse("");
  const encrypted = CryptoJS.AES.encrypt(text, keyHex, {
    iv: ivHex,
  });

  return encrypted.toString();
}

export function decryptLogin(ciphertext: string) {
  const keyHex = CryptoJS.enc.Utf8.parse("d4fa8633f2c7fdb6");
  const ivHex = CryptoJS.enc.Utf8.parse("");

  const bytes = CryptoJS.AES.decrypt(ciphertext, keyHex, {
    iv: ivHex,
  });

  return bytes.toString(CryptoJS.enc.Utf8);
}

const KEYPARAMS = {
  GET: "GHrGKf8tWFd2HdztaUZ4Q4Twy28urehh",
  POST: "kZAfTR6CSuhnXSxTk7pYYHkC33chVwPs",
};
export function encryptParams(
  text: number | string,
  type: "GET" | "POST" = "GET"
) {
  const key = type === "GET" ? KEYPARAMS.GET : KEYPARAMS.POST;
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse("");
  const encrypted = CryptoJS.AES.encrypt(`${text}`, keyHex, {
    iv: ivHex,
  });

  return encrypted.toString();
}

export function decryptParams(
  ciphertext: string,
  type: "GET" | "POST" = "GET"
) {
  const key = type === "GET" ? KEYPARAMS.GET : KEYPARAMS.POST;
  const keyHex = CryptoJS.enc.Utf8.parse(key);
  const ivHex = CryptoJS.enc.Utf8.parse("");

  const bytes = CryptoJS.AES.decrypt(ciphertext, keyHex, {
    iv: ivHex,
  });

  return bytes.toString(CryptoJS.enc.Utf8);
}
