import { FC, memo } from 'react';
import { ResponseMessageProp } from '../../models';

import styles from './Divider.module.scss';

const Divider: FC<ResponseMessageProp> = ({ responseMessage }) => {
  return <div className={styles.divider}>
      <span className={styles.line}>{responseMessage.chatContents[0].text}</span>
  </div>
}

export default memo(Divider);
