import React from "react";
import styles from "./PictureButton.module.scss";
import { Timestamp, Button } from "../../components";
import { useMemberStore, useMessages } from "../../hooks";
import { ChatbotService } from "../../services";
import transformAnsToQues from "../../utils/answer-to-question";
import type { TChatBotQuestionAnswer, TChatReply } from "../../models";
import { useSignalRConnect } from "../../services/signalr/context/signalR.context";

const PictureButton: React.FC<{
  data: TChatReply[];
  dateTime?: string | number;
  taskType: string;
}> = (props) => {
  const { data, dateTime = "", taskType } = props;
  const { memberInfo } = useMemberStore();
  const [send] = useMessages();
  const { idleBotChatTimer } = useSignalRConnect();

  const sendMessage = ({
    sessionNo,
    memberKey,
    linkButtonId,
    replyId,
    kindId,
    actionCode,
    message,
  }: TChatBotQuestionAnswer & { actionCode: string; message: string }) => {
    send(
      transformAnsToQues({
        message: message,
        kindId,
        taskType: "",
        replyId,
      })
    );
    idleBotChatTimer();
    ChatbotService.sendButtonMessage({
      sessionNo,
      memberKey,
      linkButtonId,
      replyId,
      kindId,
      taskType,
    }).then((resp) => send(resp));
  };

  return (
    <div className={`${styles["button-message"]} wii-flex`}>
      <div className="wii-flex wii-flex-col">
        {data.map((reply, index) => (
          <Button
            key={`btn.${index + reply.replyId}`}
            className={styles["message-btn"]}
            onClick={(_) =>
              sendMessage({
                sessionNo: memberInfo.sessionNo,
                memberKey: memberInfo.memberKey,
                linkButtonId: reply.linkButtonId,
                kindId: reply.kindId,
                replyId: reply.replyId,
                actionCode: "",
                message: reply.text,
              })
            }
            btnTxt={reply.text}
          />
        ))}
      </div>
      <Timestamp className="wii-self-end" datetime={dateTime} />
    </div>
  );
};

export default PictureButton;
