import React from "react";

import { PushpinFilled, PushpinOutlined } from "@ant-design/icons";

type TopItemProps = {
  hasTopItem: boolean;
  bulletin: boolean;
  topItem: boolean;
  setBulletin: React.Dispatch<React.SetStateAction<boolean>>;
  setTopItem: React.Dispatch<React.SetStateAction<boolean>>;
  setAnnounceItem: React.Dispatch<React.SetStateAction<number | null>>;
};

const TopItemButton = (props: TopItemProps) => {
  const {
    hasTopItem,
    bulletin,
    topItem,
    setBulletin,
    setTopItem,
    setAnnounceItem,
  } = props;

  if (!hasTopItem) return null;

  const Pushpin = bulletin ? PushpinOutlined : PushpinFilled;
  return (
    <>
      <button
        className="wii-absolute wii-right-2 wii-top-0 wii-z-[1001] wii-flex wii-h-10 wii-items-center"
        onClick={() => {
          if (bulletin) {
            setBulletin(false);
            setAnnounceItem(null);
          } else {
            setTopItem((tt) => !tt);
          }
        }}
      >
        <Pushpin
          style={{
            fontSize: "20px",
            color: "var(--theme-question)",
            rotate: topItem ? "-45deg" : "0deg",
          }}
          type="setting"
        />
      </button>
    </>
  );
};

export default TopItemButton;
