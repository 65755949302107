import React, { FC, memo, useReducer, useState } from "react";
import {
  useTypedSelector,
  useAppDispatch,
  useChatRoomUser,
} from "../../store/store";

import {
  SmileFilled,
  QuestionOutlined,
  FireFilled,
  StarFilled,
  RocketFilled,
  GlobalOutlined,
  CheckOutlined,
  WarningFilled,
} from "@ant-design/icons";

import { TogglesActions } from "../../store/toggles-slice";

import styles from "./Menu.module.scss";
import MenuDrawer from "./Drawer/MenuDrawer";
import LanguageContainer from "./Drawer/LanguageContainer";
import { useTranslation } from "react-i18next";
import {
  chatRoomApi,
  useDetectChatRoomMutation,
  useJoinChatRoomMutation,
  useLoadChatRoomMutation,
  useMemberChatRoomListQuery,
} from "@/services/api/ChatRoom/chatRoomApi";
import { useMemberStore, useMessages } from "@/hooks";

import { cn } from "@/utils/style-merge";
import { Image } from "@/components";
import { Scrollbar } from "react-scrollbars-custom";
import { ChatroomActions } from "@/store/chatRoom-slice";
import { encryptToSend } from "@/utils/crypto";
import { chatbotApi } from "@/services/api/Chatbot/botApi";
import { useSignalRChatRoom } from "@/services/signalr-chat/context/signalR.context";
import { useGetSignalRNoQuery } from "@/services/api/Account/accountApi";
import {
  IMAGE_CHATROOM_DEFAULT,
  REATING_STARS_NUMBER,
} from "@/utils/constants";
import { CustomStatus } from "@/models/format";
import { RateContent } from "../CsService";
import { FloatModal } from "@/components/Modal";
import { ChatFulfillType, setChatFulfillProps } from "@/models";
import { ChatbotService } from "@/services";
import { MemberActions } from "@/store/member-slice";
import transformAnsToQues from "@/utils/answer-to-question";
import { useSignalRConnect } from "@/services/signalr/context/signalR.context";

type MenuButtonDataset =
  | "MEMBER"
  | "FAQ"
  | "HOT"
  | "language-settings"
  | "PLAYGROUND"
  | "NEWS"
  | "CHATROOM";

type MenuReducerState = {
  name?: MenuButtonDataset;
  open: boolean;
};

type MenuReducerAction =
  | {
      type: MenuButtonDataset;
      value: boolean;
    }
  | {
      type: "INIT";
    };

const menuReducer = (
  state: MenuReducerState,
  action: MenuReducerAction
): MenuReducerState => {
  switch (action.type) {
    case "language-settings": {
      return {
        name: action.type,
        open: action.value,
      };
    }
    case "INIT": {
      return {
        name: undefined,
        open: false,
      };
    }
    default:
      return {
        name: action.type,
        open: action.value,
      };
  }
};

const initialMenuState = {
  name: undefined,
  open: false,
};

const Menu: FC = () => {
  const [stars, setStars] = useState(REATING_STARS_NUMBER);
  const [checkEndToCs, setCheckEndToCs] = useState(false);
  const [confirmToLeaveCs, setConfirmToLeaveCs] = useState(false);

  const [state, dispatchReducer] = useReducer(menuReducer, initialMenuState);

  const dispatch = useAppDispatch();

  const isLinkCs = useTypedSelector((state) => state.csService.isLink);
  const toggles = useTypedSelector((state) => state.toggles);
  const { isEmbed } = useTypedSelector((state) => state.settings.embedSetting);

  const user = useChatRoomUser();
  const member = useMemberStore();
  const { data } = useMemberChatRoomListQuery(
    {
      memberKey: member.memberInfo.memberKey,
    },
    {
      skip: member.memberInfo.token === "" || !member.memberInfo.memberKey,
    }
  );

  const [triggerJoinChatRoom] = useJoinChatRoomMutation();
  const [triggerLoadChatRoom] = useLoadChatRoomMutation();
  const { SignalRHub, connectSignalR } = useSignalRChatRoom();

  const { disconnectSignalR } = useSignalRConnect();

  const { data: signalrHubData } = useGetSignalRNoQuery();

  const [triggerDetect] = useDetectChatRoomMutation();

  const [t] = useTranslation();
  const [send] = useMessages();

  function menuClickHandler(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();

    const buttonEle = (e.target as Element).closest(
      "[data-menu]"
    ) as HTMLButtonElement;

    if (!buttonEle) return;

    const buttonAction = buttonEle.dataset["menu"] as MenuButtonDataset;
    dispatchReducer({ type: buttonAction, value: true });
  }

  function onClose() {
    dispatchReducer({ type: "INIT" });
  }

  const ratingToChatroom = async (isRate: boolean) => {
    const parmas: setChatFulfillProps = {
      sessionNo: member.memberInfo.sessionNo,
      star: isRate ? stars : null,
      isRealTime: true,
      fullfillType: ChatFulfillType.CrossClose,
    };

    await ChatbotService.disconnect(parmas);

    send(
      transformAnsToQues({
        message: t("close-session"),
        replyId: 0,
        kindId: 0,
        taskType: "OffBot",
        kind: "Divider",
        chatRecordType: "A",
      })
    );

    if (isEmbed) {
      const newSession = await ChatbotService.getNewChatSession({
        loginKind: 2,
      });
      dispatch(MemberActions.setNewSession(newSession));
    } else {
      const newSession = await ChatbotService.getNewChatSession({
        loginKind: 1,
      });
      dispatch(MemberActions.setNewSession(newSession));
    }

    await disconnectSignalR();
  };

  return (
    <>
      {toggles.menuToggle && (
        <div
          className={`wii-modal wii-modal-bottom ${styles.menu}`}
          onClick={(_) => {
            dispatchReducer({ type: "INIT" });
            dispatch(TogglesActions.setMenuToggle(false));
          }}
          data-active={toggles.menuToggle}
        >
          <div
            className="wii-flex wii-flex-wrap wii-justify-center wii-bg-foreground wii-pt-1"
            onClick={menuClickHandler}
          >
            <button
              className={`${styles["menu-button"]} disabled:wii-bg-gray-400`}
              data-menu={"member-center"}
              disabled={member.memberInfo.token === ""}
            >
              <SmileFilled className={`${styles["menu-icon"]}`} />
              <span>{t("member-center")}</span>
            </button>
            <button
              className={styles["menu-button"]}
              data-menu={"common-questions"}
            >
              <QuestionOutlined className={styles["menu-icon"]} />
              <span>{t("common-questions")}</span>
            </button>
            <button
              className={styles["menu-button"]}
              data-menu={"hot-questions"}
            >
              <FireFilled className={styles["menu-icon"]} />
              <span>{t("hot-questions")}</span>
            </button>
            <button
              className={styles["menu-button"]}
              data-menu={"language-settings"}
            >
              <GlobalOutlined className={styles["menu-icon"]} />
              <span>{t("language-settings")}</span>
            </button>
            <button className={styles["menu-button"]} data-menu={"playground"}>
              <StarFilled className={styles["menu-icon"]} />
              <span>{t("playground")}</span>
            </button>
            <button
              className={`${styles["menu-button"]} disabled:wii-bg-gray-400`}
              data-menu={"CHATROOM"}
              disabled={member.memberInfo.token === ""}
              onClick={() => {
                if (isLinkCs) {
                  setCheckEndToCs(true);
                }
              }}
            >
              <RocketFilled className={styles["menu-icon"]} />
              <span>{t("chat-room")}</span>
            </button>
          </div>
        </div>
      )}

      {state.name === "language-settings" && (
        <MenuDrawer
          show={state.name === "language-settings"}
          title={state.name!}
          onClose={onClose}
        >
          {state.name === "language-settings" && (
            <LanguageContainer onClose={onClose} />
          )}
        </MenuDrawer>
      )}

      {state.name === "CHATROOM" && member.memberInfo.token !== "" && (
        <MenuDrawer
          show={state.name === "CHATROOM" && member.memberInfo.token !== ""}
          title={t("chat-room")}
          onClose={onClose}
        >
          {isLinkCs && checkEndToCs ? (
            <FloatModal
              show={isLinkCs}
              confirmText={t("confirm")}
              cancelText={t("back")}
              confirmBtnClass="wii-bg-foreground hover:wii-opacity-80 wii-text-white"
              cancelBtnClass="wii-bg-[#949091d5] hover:wii-bg-[#949091f8]  wii-text-white"
              confirmEvent={() => {
                setCheckEndToCs(false);
                setConfirmToLeaveCs(true);
              }}
              cancelEvent={() => {
                dispatchReducer({ type: "INIT" });
                setCheckEndToCs(false);
              }}
            >
              <div className="wii-mb-4 wii-mt-2 wii-flex wii-flex-col wii-gap-1">
                <div className="wii-font-semibold">
                  {t("cs-to-chatroom-alert")}
                </div>
                <div className="wii-text-sm">({t("cs-to-chatroom-text")})</div>
              </div>
            </FloatModal>
          ) : isLinkCs && confirmToLeaveCs ? (
            <FloatModal
              show={isLinkCs}
              confirmText={t("rating")}
              cancelText={t("close")}
              confirmBtnClass="wii-bg-foreground hover:wii-opacity-80 wii-text-white"
              cancelBtnClass="wii-bg-[#949091d5] hover:wii-bg-[#949091f8]  wii-text-white"
              confirmEvent={async () => {
                await ratingToChatroom(true);
                setCheckEndToCs(false);
                setConfirmToLeaveCs(false);
              }}
              cancelEvent={async () => {
                await ratingToChatroom(false);
                setCheckEndToCs(false);
                setConfirmToLeaveCs(false);
              }}
            >
              <RateContent
                amount={REATING_STARS_NUMBER}
                defaultStar={REATING_STARS_NUMBER}
                activateStar={stars}
                onChange={setStars}
              />
            </FloatModal>
          ) : (
            <Scrollbar>
              <div className="wii-mb-4 wii-mt-2 wii-flex wii-h-1/2 wii-w-full wii-flex-col wii-overflow-y-auto wii-break-words wii-px-2">
                {data?.data?.map((i) => {
                  return (
                    <div
                      key={i.id}
                      id={i.id}
                      className={cn(
                        "wii-mb-1 wii-flex wii-items-center wii-gap-2 wii-break-words wii-rounded-lg wii-px-4 wii-py-1.5 wii-transition-colors wii-duration-150 wii-ease-in-out",
                        i.isDisabled
                          ? "wii-bg-primary/40 wii-opacity-60"
                          : "hover:wii-cursor-pointer hover:wii-bg-foreground hover:wii-text-answer"
                      )}
                      onClick={async () => {
                        if (i.isDisabled) return;

                        try {
                          const command = encryptToSend({
                            role: "Member",
                            content: i.id,
                          });
                          const checkResult = await triggerDetect({
                            command: command,
                          }).unwrap();

                          if (
                            !checkResult.data.isPass ||
                            checkResult.status === CustomStatus.Err
                          ) {
                            dispatch(
                              chatRoomApi.util.invalidateTags([
                                "/api/ChatRoom/MemberChatRoomList",
                              ])
                            );

                            return;
                          }

                          if (!SignalRHub) {
                            await connectSignalR();
                          }

                          const isJoin = i.isJoin;

                          if (!isJoin) {
                            await triggerJoinChatRoom({
                              command,
                            });
                          }

                          await triggerLoadChatRoom({
                            command,
                          });

                          // FIXME:
                          dispatch(
                            ChatroomActions.setUser({
                              userId: i.id,
                              userRole: "Member",
                            })
                          );

                          dispatch(
                            ChatroomActions.setChat({
                              roomId: i.roomId,
                            })
                          );

                          dispatch(
                            chatbotApi.util.updateQueryData(
                              "getBotUserInfo",
                              signalrHubData?.data?.signalRNo !== undefined
                                ? `${signalrHubData?.data?.signalRNo}`
                                : "",
                              (sss) => {
                                return {
                                  ...sss,
                                  color: {
                                    primary: i.colorUrl,
                                    backend: i.colorBackUrl,
                                    demand: i.colorDemandUrl,
                                    supply: i.colorSupplyUrl,
                                  },
                                };
                              }
                            )
                          );
                        } catch (err) {
                          console.log("failed");
                        }
                      }}
                    >
                      <div className="wii-flex wii-h-10 wii-w-10 wii-items-center wii-rounded-full wii-bg-theme-color">
                        <Image
                          url={i.headerUrl}
                          domain={process.env.REACT_APP_CHATROOM_IMAGE_URL}
                          className="wii-rounded-full wii-object-scale-down"
                          alt={IMAGE_CHATROOM_DEFAULT.Header}
                        />
                      </div>
                      <div className="wii-flex-col">
                        <div>{i.roomName}</div>
                        <p className="wii-text-xs wii-font-extralight">
                          {i.description}
                        </p>
                      </div>
                      {i.id === user.roomId && !i.isDisabled && (
                        <span className="wii-ml-auto">
                          <CheckOutlined
                            className={cn("wii-stroke-2 wii-text-green-500")}
                          />
                        </span>
                      )}
                      {i.isDisabled ? (
                        <div className="wii-ml-auto wii-flex wii-cursor-default wii-items-center wii-gap-1 wii-rounded-md wii-bg-white wii-px-1.5 wii-py-0.5 wii-text-xs wii-text-foreground">
                          <WarningFilled
                            className={cn("wii-text-primary/40")}
                          />
                          <span className="wii-text-xs">
                            {t("deactivated")}
                          </span>
                        </div>
                      ) : i.isJoin ? (
                        <span className="wii-ml-auto wii-rounded-md wii-bg-foreground wii-px-1.5 wii-py-0.5 wii-text-xs wii-text-white">
                          {t("join")}
                        </span>
                      ) : (
                        <span className="wii-ml-auto wii-rounded-md wii-bg-white wii-px-1.5 wii-py-0.5 wii-text-xs wii-text-foreground">
                          {t("not-join")}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </Scrollbar>
          )}
        </MenuDrawer>
      )}
    </>
  );
};

export default memo(Menu);
