import { PropsWithChildren } from "react";
import useCheckEmbeddedAuthQuery from "../../store/hooks/useCheckEmbeddedAuthQuery";

const AuthComponent = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const { data, isLoading } = useCheckEmbeddedAuthQuery();

  return <>{children}</>;
};

export default AuthComponent;
