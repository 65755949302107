import { MouseEventHandler } from "react";
import "./Modal.module.scss";
import { cn } from "@/utils/style-merge";

export interface ModalProps {
  show: boolean;
  title?: any;
  content?: string;
  confirmText: string;
  cancelText: string;
  confirmBtnClass?: string;
  cancelBtnClass?: string;
  children?: JSX.Element[] | JSX.Element;
  confirmEvent?: MouseEventHandler<HTMLButtonElement>;
  cancelEvent?: MouseEventHandler<HTMLButtonElement>;
}

const FloatModal = (props: ModalProps) => {
  return (
    <div className="wii-modal wii-pointer-events-auto" data-active={props.show}>
      <div className="wii-modal-box wii-mx-4 wii-w-full wii-rounded-lg  wii-text-center">
        <div className="wii-text-2xl wii-font-bold">{props.title || ""}</div>
        {props.children ? props.children : <p>{props.content || ""}</p>}
        <div className="wii-flex wii-flex-row wii-items-center wii-justify-end wii-gap-1 wii-overflow-hidden">
          <button
            className={cn(
              "wii-btn wii-btn-outline wii-btn-sm wii-border-0",
              props.cancelBtnClass
            )}
            onClick={async (e) => {
              if (props.cancelEvent) {
                props.cancelEvent(e);
              }
            }}
          >
            {props.cancelText}
          </button>
          <button
            className={cn(
              "wii-btn wii-btn-outline wii-btn-sm wii-border-0 wii-border-primary/80  wii-text-primary hover:wii-border-primary hover:wii-bg-primary disabled:wii-bg-primary/60",
              props.confirmBtnClass
            )}
            onClick={async (e) => {
              if (props.confirmEvent) {
                props.confirmEvent(e);
              }
            }}
          >
            {props.confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FloatModal;
