import {
  useEffect,
  useRef,
  memo,
  useState,
  MutableRefObject,
  useCallback,
} from "react";
import { useAppDispatch, useTypedSelector } from "../../store/store";
import {
  useMemberStore,
  useMessages,
  useClickCs,
  useBeforeunload,
} from "@/hooks";

import { Scrollbar } from "react-scrollbars-custom";
import dayjs from "dayjs";

import { MessageItems, MessageInput } from "../../containers";
import { MemberActions } from "../../store/member-slice";

import { ChatbotService } from "../../services";
import transformAnsToQues from "../../utils/answer-to-question";

import styles from "./ChatContent.module.scss";

import { ActiveStatus, MessageKind, RealTimeResponseType } from "../../models";

import type { ResponseMessage, ResponseMessageContent } from "../../models";
import { useSignalRConnect } from "../../services/signalr/context/signalR.context";
import {
  SignalREvents,
  SignalREventsArgs,
} from "../../services/signalr/method";
import { END_BOT1 } from "../../utils/constants";
import { debounce } from "../../utils/get-debounce";
import { HubConnectionState } from "@microsoft/signalr";
import { useTranslation } from "react-i18next";
import useLangKey from "../../hooks/useLangKey";
import { cn } from "@/utils/style-merge";
import { AlertFilled } from "@ant-design/icons";
import { TogglesActions } from "@/store/toggles-slice";
import {
  useAcceptCsInviteMutation,
  useGetMemberRealTimeInfoMutation,
} from "@/services/api/Chatbot/botApi";

import { csActions } from "@/store/cs-slice";

import Spin from "react-cssfx-loading/lib/Spin";

const ChatContent = () => {
  useBeforeunload();
  const endDate = useRef(
    dayjs()
      .add(+1, "day")
      .startOf("day")
  );
  const currentHeight = useRef(0);
  const showRecordsBtnRef = useRef<boolean | undefined>(undefined);
  const historyMessagesRef = useRef<ResponseMessageContent[]>([]);
  const scrollbarRef = useRef<Scrollbar>(null);
  const checkBoxRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();
  const [loadingInvitation, setLoadingInvitation] = useState(false);

  const loadingStoreState = useTypedSelector(
    (state) => state.replyLoading.isLoading
  );
  const { isLink: isLinkCs, csHeaderUrl } = useTypedSelector(
    (state) => state.csService
  );

  const { accept, csId, csName } = useTypedSelector(
    (state) => state.toggles.invitation
  );
  const [triggerAccept] = useAcceptCsInviteMutation();
  const [triggerGetRealTimeInfo] = useGetMemberRealTimeInfoMutation();

  const { memberInfo, activeStatus } = useMemberStore();

  const [showCurrentChatContent, setShowCurrentChatContent] = useState(false);
  const [responseMessageContents, setResponseMessageContent] = useState<
    ResponseMessageContent[]
  >([]);
  const [historyMessageContents, setHistoryMessageContents] = useState<
    ResponseMessageContent[]
  >([]);

  const [csMessageContents, setCsMessageContents] = useState<
    ResponseMessageContent[]
  >([]);

  const [send, receive$] = useMessages();
  const [t] = useTranslation();

  const { keyLang } = useLangKey();

  const getWelcomeInfo = () =>
    ChatbotService.getWelcomeInfo().then((data) =>
      send({ ...data, language: keyLang })
    );

  const setActiveStatus = useCallback(() => {
    dispatch(MemberActions.setActiveStatus(ActiveStatus.Active));
  }, [dispatch]);

  useClickCs();

  const { SignalRHub, disconnectBotChatTimer, changeToRealTimeAction } =
    useSignalRConnect();
  const { currentQuestionId, isConnectedSignalR } = useTypedSelector(
    (state) => state.csService
  );
  const [csRead, setCsRead] = useState(false);

  const clickLoginChangeToCsUser = useTypedSelector(
    (state) => state.csService.clickLoginChangeToCsUser
  );

  const loadHistoryRecords = (date: MutableRefObject<dayjs.Dayjs>) => {
    if (!memberInfo.token) return;
    ChatbotService.getChatHistoryRecords({
      memberKey: memberInfo.memberKey,
      day: 2,
      endDate: date.current.format("YYYY/MM/DD"),
    }).then((resp) => {
      if (
        showRecordsBtnRef.current === undefined &&
        resp.responseMessage?.length > 0 &&
        !clickLoginChangeToCsUser
      ) {
        showRecordsBtnRef.current = true;
        setResponseMessageContent((state) => [
          transformAnsToQues({
            message: t("today"),
            kind: MessageKind.Divider,
            kindId: 0,
            replyId: 0,
            taskType: "History",
          }),
          ...state,
        ]);
      } else {
        showRecordsBtnRef.current = resp.hadRows || false;
      }

      if (resp.responseMessage?.length > 0) {
        endDate.current = dayjs(resp.responseMessage[0].historyDate);
        let responseMessage: ResponseMessage[] = [];
        resp.responseMessage.forEach((item) => {
          const content: ResponseMessage = {
            kind: MessageKind.Divider,
            chatRecordType: "A",
            createdTime: "",
            chatContents: [
              {
                text: item.historyDate,
                replies: [],
                isReplyHide: false,
                kindId: 0,
                replyId: 0,
                pictureMemo: "",
                pictureUrl: "",
                sort: 0,
                linkButtonId: 0,
                actionCode: "",
              },
            ],
          };
          responseMessage = responseMessage.concat([content, ...item.chatData]);
        });

        historyMessagesRef.current = [
          {
            responseMessage,
            guideButtons: resp.guideButtons ? resp.guideButtons : null,
            taskType: resp.taskType,
            taskLanguageKey: "",
          },
        ];
      }
    });
  };

  const bindHistoryMessages = () => {
    if (endDate.current.isBefore(dayjs().add(-2, "month").startOf("day"))) {
      showRecordsBtnRef.current = false;
      return;
    }

    currentHeight.current = scrollbarRef.current?.scrollHeight || 0;
    setHistoryMessageContents((state) => [
      ...historyMessagesRef.current,
      ...state,
    ]);
    loadHistoryRecords(endDate);
  };

  useEffect(() => {
    scrollbarRef.current?.scrollTo(
      0,
      scrollbarRef.current?.scrollHeight - currentHeight.current - 100
    );
  }, [historyMessageContents]);

  useEffect(() => {
    if (!clickLoginChangeToCsUser) {
      getWelcomeInfo();
    }
  }, []);

  useEffect(() => {
    loadHistoryRecords(endDate);
  }, [memberInfo.token]);

  useEffect(() => {
    // NOTE: initialize
    if (activeStatus === ActiveStatus.Reconnect) {
      endDate.current = dayjs()
        .add(+1, "day")
        .startOf("day");
      showRecordsBtnRef.current = undefined;
      setHistoryMessageContents([]);
      setResponseMessageContent([]);
      setCsMessageContents([]);
      setShowCurrentChatContent(false);
      loadHistoryRecords(endDate);
    }
  }, [activeStatus]);

  useEffect(() => {
    scrollbarRef.current?.scrollToBottom();
  }, [csMessageContents, responseMessageContents, loadingStoreState]);

  useEffect(() => {
    const receiveSubscription = receive$().subscribe((resp) => {
      if (resp.taskType !== "" && resp.taskType !== END_BOT1) {
        disconnectBotChatTimer();
      }

      if (memberInfo.token) {
        const checkFirstSend =
          responseMessageContents.length > 0 &&
          responseMessageContents.filter((i) => i?.taskType === "").length ===
            0 &&
          resp.taskType === "";

        if (checkFirstSend && !isLinkCs) {
          ChatbotService.enterChatBot({ sessionNo: memberInfo.sessionNo });
        }
      }

      const isUrl = resp?.responseMessage[0]?.kind === "WebUrl";
      const text = resp?.responseMessage[0]?.chatContents?.[0]?.text;

      if (isUrl) {
        window.open(text, "_blank");
      }

      if (
        resp.taskType === "csUser" ||
        (isLinkCs && resp.taskType !== "OffBot")
      ) {
        setCsMessageContents((preState) => [...preState, resp]);
      } else {
        setResponseMessageContent((preState) => [
          ...preState,
          ...csMessageContents,
          resp,
        ]);
        setCsMessageContents([]);
      }
    });

    return () => {
      receiveSubscription.unsubscribe();
    };
  }, [
    isLinkCs,
    receive$,
    disconnectBotChatTimer,
    csMessageContents,
    responseMessageContents,
    memberInfo.sessionNo,
    memberInfo.token,
  ]);

  useEffect(() => {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected)
    ) {
      SignalRHub.on(
        SignalREvents.OnNotifyMessage,
        (params: SignalREventsArgs[SignalREvents.OnNotifyMessage]) => {
          if (isConnectedSignalR && currentQuestionId) {
            if (params.action === "NotifyRead") {
              setCsRead(true);
            } else {
              setCsRead(false);
            }
          }
        }
      );
    }

    return () => {
      if (SignalRHub) {
        SignalRHub.off(SignalREvents.OnNotifyMessage);
      }
    };
  }, [SignalRHub, isConnectedSignalR, currentQuestionId]);

  return (
    <>
      <div className="wii-relative wii-flex wii-flex-1 wii-flex-col">
        {accept && (
          <div
            className={cn(
              "animate__fadeInDown animate__animated wii-absolute wii-left-1 wii-top-8 wii-z-[5] wii-mt-1.5 wii-flex wii-w-[96%] wii-overflow-hidden wii-bg-white",
              "wii-items-center wii-border wii-border-dashed wii-border-primary wii-shadow-lg",
              "wii-px-4 wii-py-2.5"
            )}
          >
            <div className="wii-flex wii-shrink-0 wii-grow wii-flex-col wii-items-start">
              <div className="wii-flex wii-items-center wii-gap-1.5">
                <div>
                  <AlertFilled className="wii-text-primary" />
                </div>
                <div className="wii-text-sm">
                  <strong className="wii-mx-1 wii-text-foreground">
                    [{csName}]
                  </strong>
                  {t("invite-cs")}
                </div>
              </div>
              <div className="wii-flex wii-w-full wii-flex-col wii-items-center wii-justify-between wii-gap-1.5">
                <div className="wii-form-control wii-ml-5">
                  <label className="wii-label wii-cursor-pointer">
                    <input
                      type="checkbox"
                      ref={checkBoxRef}
                      className="wii-checkbox wii-checkbox-xs wii-rounded-md"
                    />
                    <span className="wii-label-text wii-mx-1">
                      {t("no-invitation")}
                    </span>
                  </label>
                </div>
                <div className="wii-flex wii-gap-2">
                  <button
                    disabled={loadingInvitation}
                    className="wii-btn wii-btn-outline wii-btn-xs"
                    onClick={() => {
                      if (!checkBoxRef.current) return;

                      const checked = checkBoxRef.current.checked;

                      triggerAccept({
                        isAccept: false,
                        memberKey: memberInfo.memberKey,
                        sessionNo: memberInfo.sessionNo,
                        csUserId: csId,
                        noInvite: checked,
                      });

                      dispatch(
                        TogglesActions.setInvitationToggle({
                          csId: "",
                          csName: "",
                          accept: false,
                        })
                      );
                    }}
                  >
                    {t("reject")}
                  </button>
                  <button
                    disabled={loadingInvitation}
                    className="wii-btn wii-btn-outline wii-btn-xs wii-border-primary/80  wii-text-primary hover:wii-border-primary hover:wii-bg-primary disabled:wii-bg-primary/60"
                    onClick={async () => {
                      if (!checkBoxRef.current) return;

                      const checked = checkBoxRef.current.checked;

                      try {
                        setLoadingInvitation(true);
                        const result = await triggerAccept({
                          isAccept: true,
                          memberKey: memberInfo.memberKey,
                          sessionNo: memberInfo.sessionNo,
                          csUserId: csId,
                          noInvite: checked,
                        }).unwrap();

                        const chatTimetableId = result.chatTimetableId;
                        const {
                          headerUrl,
                          csUserKey,
                          wellcome: welcome,
                          isLink,
                          enabledWait,
                        } = await triggerGetRealTimeInfo({
                          chatTimetableId: chatTimetableId,
                        }).unwrap();

                        // NOTE: set information
                        const questionId =
                          await ChatbotService.recordRealTimeQuestion({
                            sessionNo: memberInfo.sessionNo,
                            memberKey: memberInfo.memberKey,
                            message: t("start-live-chat"),
                            csUserId: csId,
                            isImage: false,
                          });

                        dispatch(
                          csActions.setCurrentQuestionId({
                            questionId: questionId,
                          })
                        );

                        const answer =
                          await ChatbotService.recordRealTimeAnswer({
                            chatQuestionId: questionId,
                            answer: welcome,
                            languageAfter: keyLang,
                            isImage: false,
                            isFaq: false,
                          });

                        await changeToRealTimeAction({
                          chatTimetableId: chatTimetableId,
                          csUserId: +csId,
                          csUserKey: csUserKey,
                          enabledWait: enabledWait,
                          isLink: isLink,
                          resType: RealTimeResponseType.StartConnection,
                          token: "",
                          wellcome: welcome,
                          chatQuestionId: 0,
                          headerUrl: headerUrl,
                        }).then(() => {
                          setLoadingInvitation(false);
                        });

                        send(
                          transformAnsToQues({
                            message: t("start-live-chat"),
                            replyId: 0,
                            kindId: 0,
                            taskType: "",
                            chatRecordType: "Q",
                          })
                        );

                        send(
                          transformAnsToQues({
                            message: answer.answer,
                            replyId: 0,
                            kindId: 0,
                            taskType: "csUser",
                            chatRecordType: "A",
                          })
                        );
                      } catch (err) {
                        console.log(err, "erer");
                        setLoadingInvitation(false);
                      } finally {
                        dispatch(
                          TogglesActions.setInvitationToggle({
                            csId: "",
                            csName: "",
                            accept: false,
                          })
                        );
                      }
                    }}
                  >
                    {t("accept")}
                  </button>
                </div>
              </div>
            </div>

            {loadingInvitation && (
              <Spin
                width="1.5rem"
                height="1.5rem"
                className="wii-ml-4"
                style={{
                  borderTopColor: "var(--theme-color)",
                }}
              />
            )}
          </div>
        )}
        <Scrollbar
          noScrollX={true}
          onMouseDown={setActiveStatus}
          onTouchStart={setActiveStatus}
          removeTrackYWhenNotUsed={true}
          onMouseEnter={() => {}}
          onMouseMove={debounce(() => {
            if (activeStatus === ActiveStatus.Active) {
              if (
                (responseMessageContents.length > 0 &&
                  responseMessageContents[responseMessageContents.length - 1]
                    .taskType !== "" &&
                  responseMessageContents[responseMessageContents.length - 1]
                    .taskType !==
                    "_" + END_BOT1) ||
                (csMessageContents.length > 0 &&
                  csMessageContents[csMessageContents.length - 1].taskType !==
                    "" &&
                  csMessageContents[csMessageContents.length - 1].taskType !==
                    "_" + END_BOT1)
              ) {
                disconnectBotChatTimer();
              }
            }
          }, 150)}
          ref={scrollbarRef as any}
        >
          <div className="wii-relative wii-h-full wii-pb-3 wii-text-lg">
            {showRecordsBtnRef.current ||
            (isLinkCs && !showCurrentChatContent) ? (
              <>
                <div className={styles["history-message"]}>
                  <button
                    className={styles["history-btn"]}
                    onClick={() => {
                      if (isLinkCs && !showCurrentChatContent) {
                        setShowCurrentChatContent(true);
                      } else {
                        bindHistoryMessages();
                      }
                    }}
                  >
                    {t("load-previous-chat-history")}
                  </button>
                </div>
                <br />
              </>
            ) : (
              <></>
            )}

            {(!isLinkCs || showCurrentChatContent) && (
              <MessageItems
                chatContents={historyMessageContents}
                isHistoryMessage={true}
                csHeaderUrl={csHeaderUrl}
              />
            )}

            {(!isLinkCs || showCurrentChatContent) && (
              <MessageItems
                chatContents={responseMessageContents}
                csHeaderUrl={csHeaderUrl}
              />
            )}
            {isLinkCs && (
              <MessageItems
                chatContents={csMessageContents}
                csRead={csRead}
                csHeaderUrl={csHeaderUrl}
              />
            )}
          </div>
        </Scrollbar>

        <MessageInput setCsRead={setCsRead} />
      </div>
    </>
  );
};

export default memo(ChatContent);
