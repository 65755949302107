import { ResponseMessageContent } from "./chatbot";

export type MemberChatRoomItem = {
  id: string;
  roomId: string;
  roomName: string;
  description: string;
  isJoin: boolean;
  isDisabled: boolean;
  headerUrl: string;
  colorUrl: string;
  colorBackUrl: string;
  colorDemandUrl: string;
  colorSupplyUrl: string;
};

export type ChatRoomItem = {
  id: string;
  roomId: string;
  roomName: string;
  description: string;
  headerUrl: string;
  colorUrl: string;
  colorBackUrl: string;
  colorDemandUrl: string;
  colorSupplyUrl: string;
  shieldText: string;
  isDisabled: boolean;
  unreads: string | null;
  readLatestId: string | null;
  roomSessionNo: string;

  isChatTextReplace: boolean;
  isChatTextDel: boolean;
  isCsChatTextReplace: boolean;
  isCsChatTextDel: boolean;
};

export enum ChatRoomMessageListType {
  Module,
  Conversation,
}

export type ChatRoomRole = "Member" | "Cs" | "Bot";

export type ChatRoomMessageListItem = {
  type: ChatRoomMessageListType;
  time: string;
  user: {
    roleId: string;
    name: string;
    headerUrl: null | string;
    role: ChatRoomRole;
  };
  module: ChatRoomListModule | null;
  conversation: ChatRoomListConversation | null;
};

export type ChatTextTypeNameDisplay = "Text" | "Image" | "Delete";

export type ChatRoomListConversation = {
  id: string;
  roomSessionNo: string;
  chatTextTypeName: ChatTextTypeNameDisplay;
  chatText: string;
  languageKey: string;
  tagChat: ChatRoomListTagChat | null;
  isShow: boolean;
  isShield: boolean;
  isTop: boolean;

  isReplace: boolean;
  isDeleted: boolean;
};

export type ChatRoomListModule = ResponseMessageContent;

export type ChatRoomListTagChat = {
  id: string;
  chatText: string;
  chatTextTypeName: ChatTextTypeNameDisplay;
  isShield: boolean;
  isShow: boolean;

  roleId: string;
  name: string;
  role: string;
  headerUrl: string | null;

  replyTagType: "Reply" | "Edit";

  isDeleted: boolean;
  isReplace: boolean;
};

export type ChatRoomImageRefresh = {
  id: string;
  chatText: string;
  isShow: boolean;
};

export type ChatRoomMessageRes = {
  list: ChatRoomMessageListItem[];
  status: {
    isTop: boolean;
    isEnd: false;
  };
};

export type ChatRoomMessageReq = {
  command: string;
  id?: string;
  // offset: number;
  offsetAbove: number;
  offsetBelow: number;
  dir?: "top" | "bottom";
};

export type SendChatRoomTextReq = {
  command: string;
  roomSessionNo: string;
  chatText: string;
  tagChatTextId: string | number | undefined;
};

export type SendChatRoomTextRes = {
  id: string;
  text: string;
};

export type SendChatRoomImageReq = {
  command: string;
  roomSessionNo: string;
  base64String: string;
  tagChatTextId: string | number | undefined;
};

export type SendChatRoomImageRes = {
  id: string;
  text: string;
};

export type ChatRoomIsTopReq = {
  command: string;
  optionSwitch: boolean;
  id: string | number;
};

export type ChatRoomIsShieldReq = {
  command: string;
  optionSwitch: boolean;
  id: string | number;
};

export enum CsChatRoomAction {
  MuteMember,
  KickMember,
  DisableUploadImage,
  DisableChangeAvatar,
}

export type CsChatRoomReq = {
  command: string;
  optionSwitch: boolean;
};

export type ChatRoomMemberDetailRes = {
  id: string;
  name: string;
  headerUrl: null | string;
  isOnline: boolean;

  authority: {
    isNoTalking: boolean;
    isUploadImage: boolean;
    isHeadImage: boolean;
    isKickOut: boolean;
  };
};

export type ChatRoomCsUserDetailRes = {
  id: string;
  name: string;
  headerUrl: null | string;
  isOnline: boolean;

  authority: {
    isGrantNoTalking: boolean;
    isGrantKickOut: boolean;
    isGrantUploadImage: boolean;
    isGrantHeadImage: boolean;
    isGrantShield: boolean;
  };
};

export type ChatRoomAnnouncementListRes = {
  id: number;
  title: string;
  titleUrl: string;
  colorUrl: string;
  colorBackUrl: string | null;
  colorDemandUrl: string | null;
  colorSupplyUrl: string | null;
  module: ChatRoomListModule;
};

export type ChatRoomHeadUrlItemRes = {
  id: number;
  headerUrl: string;
  memo: string;
};

export type UserAmount = {
  members: string;
  onlineMembers: string;
  csUsers: string;
  onlineCsUsers: string;
};
