import { useEffect } from "react";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";

import { useDispatch } from "react-redux";
import { useMemberStore, useMessages } from "../../../../hooks";

import { SignalRBotEvents } from "../../method";
import { ChatbotService } from "../../../ChatbotService";
import { MemberActions } from "../../../../store/member-slice";
import { ActiveStatus, ChatFulfillType } from "../../../../models";
import {
  END_BOT1,
  SIGNALR_BOTHUB,
  SIGNALR_CSHUB,
} from "../../../../utils/constants";
import { useTypedSelector } from "../../../../store/store";

const useOnNotifyTimes = (
  SignalRHub: HubConnection | null,
  disconnect: () => Promise<void>
) => {
  const dispatch = useDispatch();
  const [send] = useMessages();
  const { memberInfo } = useMemberStore();
  const { ratingCsServiceState } = useTypedSelector((state) => state.csService);

  useEffect(() => {
    if (
      SignalRHub &&
      (SignalRHub.state === HubConnectionState.Connecting ||
        SignalRHub.state === HubConnectionState.Connected) &&
      SignalRHub.baseUrl.includes(SIGNALR_BOTHUB)
    ) {
      SignalRHub.on(
        SignalRBotEvents.OnNotifyTimes,
        async (data: {
          action: string;
          fromId: string;
          toId: string;
          message: string;
          timestamp: string;
        }) => {
          if (data.action === "EndBotUserQ") {
            ChatbotService.endChatNotification(memberInfo.sessionNo).then(
              (resp) => send({ ...resp, taskType: END_BOT1 })
            );
          }
          if (data.action === "EndBotUserQWait") {
            dispatch(MemberActions.setActiveStatus(ActiveStatus.Disconnect));
            await ChatbotService.disconnect({
              sessionNo: memberInfo.sessionNo,
              star: null,
              isRealTime: true,
              fullfillType: ChatFulfillType.EndRemindExpire,
            });
            await disconnect();
          }
          if (data.action === "LazyBotUserA") {
            console.log("???");
            dispatch(MemberActions.setActiveStatus(ActiveStatus.Idle));
            ChatbotService.idleNotification(memberInfo.sessionNo).then((resp) =>
              send(resp)
            );
          }
          if (data.action === "ChatTaskS") {
            ChatbotService.getChatRecover(memberInfo.sessionNo).then((resp) => {
              if (resp.responseMessage) {
                send(resp);
              }
            });
          }
        }
      );
    }

    if (
      SignalRHub &&
      SignalRHub.state === HubConnectionState.Connected &&
      SignalRHub.baseUrl.includes(SIGNALR_CSHUB)
    ) {
      SignalRHub.on(
        SignalRBotEvents.OnNotifyTimes,
        async (data: {
          action: string;
          fromId: string;
          toId: string;
          message: string;
          timestamp: string;
        }) => {
          console.log("onNotifyTime");
          if (data.action === "EndCsUserQ") {
            ChatbotService.endChatNotification(memberInfo.sessionNo).then(
              (resp) => send({ ...resp, taskType: END_BOT1 })
            );
          }
          if (data.action === "EndCsUserQWait" && !ratingCsServiceState) {
            dispatch(MemberActions.setActiveStatus(ActiveStatus.Disconnect));
            await ChatbotService.disconnect({
              sessionNo: memberInfo.sessionNo,
              star: null,
              isRealTime: true,
              fullfillType: ChatFulfillType.EndRemindExpire,
            });
            await disconnect();
          }

          if (data.action === "LazyCsUserA") {
            dispatch(MemberActions.setActiveStatus(ActiveStatus.Idle));
            ChatbotService.idleNotification(memberInfo.sessionNo).then((resp) =>
              send(resp)
            );
          }
        }
      );
    }

    return () => {
      if (SignalRHub) {
        SignalRHub.off(SignalRBotEvents.OnNotifyTimes);
      }
    };
  }, [SignalRHub, SignalRHub?.state, dispatch, send, memberInfo.sessionNo]);
};

export default useOnNotifyTimes;
