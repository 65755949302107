import React, { useCallback, useEffect, useRef } from "react";
import {
  useAppDispatch,
  useChatRoomUser,
  useTypedSelector,
} from "@/store/store";
import { ChatContent } from "@/chatroom/containers";
import ThemeLayout from "@/containers/Theme/Layout";
import { ChatRoomProvider } from "./chatRoom.context";
import ChatRoomLabel from "./containers/NabBar/ChatRoomLabel";
import ChatRoomInfo from "./containers/NabBar/ChatRoomInfo";
import { MemberActions } from "@/store/member-slice";
import { useSignalRChatRoom } from "@/services/signalr-chat/context/signalR.context";
import { TogglesActions } from "@/store/toggles-slice";

const ChatRoomApp = () => {
  const closeElement = useRef<HTMLLabelElement>(null);
  const dispatch = useAppDispatch();

  const { userId, userRole } = useChatRoomUser();
  const { disconnectSignalR } = useSignalRChatRoom();
  const toggle = useTypedSelector((state) => state.toggles.showBotIcon);

  const hiddenChatModal: React.MouseEventHandler<HTMLElement> = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    const toggleBotClose = document.getElementById("chatbot");
    toggleBotClose?.click();
  };

  const closeChatModal = useCallback(async () => {
    closeElement?.current?.click();

    if (!toggle && userRole === undefined) {
      dispatch(TogglesActions.setShowBotIconToggle(true));
    }

    if (!toggle && userRole !== undefined) {
      window.WiiApp.role = undefined;
      dispatch(MemberActions.logout());
      window.WiiApp.connectedState = "unknown";
      await disconnectSignalR();
    }
  }, [disconnectSignalR, dispatch, toggle, userRole]);

  useEffect(() => {
    if (window.WiiApp) {
      window.WiiApp.close = closeChatModal;
    }
  }, [closeChatModal]);

  return (
    <>
      <ChatRoomProvider key={`${userId}-${userRole ?? ""}}`}>
        <ThemeLayout>
          <ChatRoomInfo />

          <ChatRoomLabel hiddenChatModal={hiddenChatModal} />

          <label
            id="wii-modal-close"
            ref={closeElement}
            htmlFor="chatbot"
            className="wii-z-10 wii-mr-1 wii-cursor-pointer wii-p-1 hover:wii-scale-110"
            onClick={closeChatModal}
          >
            ✕
          </label>
        </ThemeLayout>

        <ChatContent key={`${userId}}`} />
      </ChatRoomProvider>
    </>
  );
};

export default ChatRoomApp;
